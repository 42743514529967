import React, { Fragment, useState, useEffect } from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import MaterialTable from "material-table";
import MaterialTable from "@material-table/core";
import { hostInfo, TableColTitle } from "../../store";

import AddReport from "./components/AddReport";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     "& > *": {
//       borderBottom: "unset",
//     },
//   },
//   containerIcon: {
//     display: "flex",
//     alignItems: "center",
//     // paddin: spacing(2),
//   },
//   inactive: {
//     background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
//     border: 0,
//     borderRadius: 3,
//     boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
//     color: "white",
//     marginRight: theme.spacing(2),
//   },
//   active: {
//     background: "linear-gradient(45deg, #279b37 30%, #7ac143 80%)",
//     border: 0,
//     borderRadius: 3,
//     boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
//     color: "white",

//     marginRight: theme.spacing(2),
//   },
//   subVisit: {
//     flexGrow: 1,
//     padding: theme.spacing(2),
//   },
//   infoIcon: {
//     color: "#8BB80E",
//     // backgroundColor: "black",
//     fontSize: 30,
//   },
// }));

const DataTables = (props) => {
  const { types, deleteData, Edit } = props;
  console.log(types);
  const EditReport = (name) => {
    Edit(name);
  };
  const [tableData, setTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);

  // const [meds, setMeds] = React.useState([]);

  // const EditReport = (name) => {
  //   setMeds(name);
  // };
  // console.log(meds);
  const columns = [
    { title: "No.", field: "num" },
    {
      title: TableColTitle[types],
      field: "name",
    },
  ];

  useEffect(() => {
    setTableData(props.dataList);
  }, [props.dataList]);
  // console.log(props.dataList);

  useEffect(() => {
    // console.log("tableData updated:", tableData);
    // setTableData(tableData);
  }, [tableData]);
  const getToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.authToken;
  };

  return (
    <div>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
      <Fragment>
        <MaterialTable
          title="List"
          columns={columns}
          // data={[{ Id: "1", Name: "abc" }]}
          data={tableData.map((data, idx) => ({
            num: idx + 1,
            Id: data.Id,
            name: data.Name,
          }))}
          actions={[
            {
              icon: "edit",
              onClick: (event, rowData) => {
                setSelectedRow(rowData);
                setEditDialogOpen(true);
              },

              tooltip: "Edit",
              // showSelectCheckbox: false,
            },
            {
              icon: "delete",
              tooltip: "Delete data",
              onClick: (event, rowData) => {
                var url;
                var ids = [];
                // console.log("On delete action data:");
                // console.log(ids);
                for (var i = 0; i < rowData.length; ++i) {
                  ids.push(rowData[i]["Id"]);
                }
                // console.log("To be deleted ids----------");
                // console.log(ids);

                if (types === "reps") {
                  // url = "http://" + hostInfo + "/deleterep?id=" + rowData.Id;
                  url = hostInfo + "/deletereplist";
                } else if (types === "meds") {
                  // url = "http://" + hostInfo + "/deletemed?id=" + rowData.Id;
                  url = hostInfo + "/deletemedlist";
                } else if (types === "payment") {
                  // url = "http://" + hostInfo + "/deletemed?id=" + rowData.Id;
                  url = hostInfo + "/deletepaymentlist";
                } else if (types === "symt") {
                  // url = "http://" + hostInfo + "/deletesympt?id=" + rowData.Id;
                  url = hostInfo + "/deletesymptlist";
                } else if (types === "dise") {
                  // url = "http://" + hostInfo + "/deletedis?id=" + rowData.Id;
                  url = hostInfo + "/deletedislist  ";
                }
                if (
                  window.confirm(
                    "You want to delete " + ids.length,
                    " records ???"
                  )
                ) {
                  // console.log("dELETING URL:", url);
                  fetch(url, {
                    headers: {
                      Accept: "*/*",
                      "Content-type": "application/json; charset=UTF-8",
                      Authorization: `Bearer ${getToken()}`,
                    },
                    method: "POST",
                    // mode: "no-cors",
                    body: JSON.stringify(ids),
                  })
                    .then(() => {
                      for (var i = 0; i < ids.length; ++i) {
                        // Remove the filtered items from tableData
                        deleteData(parseInt(ids));
                      }
                    })
                    .catch((error) => {
                      console.error("There was an error!", error);
                    });
                }
              },
            },
          ]}
          options={{
            selection: true,
            headerStyle: {
              // textItems: "center",
              backgroundColor: "#AFE1CE",
              color: "#000",
            },
            // rowStyle:{
            //  height: 50
            // },
            // maxBodyHeight: 50,
            // cellStyle: {
            //   Items: "center",
            // },
            // detailPanelColumnAlignment: "right",
            sorting: true,
            // search: true,

            minBodyHeight: "auto",

            actionsColumnIndex: -1,
          }}
        />
        {isEditDialogOpen && (
          <AddReport
            EditReport={EditReport}
            dataList={props.dataList}
            isEditDialogOpen={isEditDialogOpen}
            handleClickClose={() => setEditDialogOpen(false)}
            selectedRow={selectedRow}
            types={types}
          />
        )}
        {/* <EditReport
          open={isEditDialogOpen}
          onClose={() => setEditDialogOpen(false)}
          selectedRow={selectedRow}
          types={types.slice(0, 3)}
        /> */}
      </Fragment>
    </div>
  );
};

export default DataTables;
