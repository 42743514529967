import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Paper from "@material-ui/core/Paper";
import VisitDetailInfo from "./VisitDetailInfo";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionActions";

import { visitFormfields } from "../../../../store";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import weightIcon from "../../../../icons/weight.png";
import moment from "moment";

import {
  Grid,
  Box,
  Avatar,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    width: "100%",
    fontSize: 2,
  },
  inner: {
    minWidth: "100%",
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  containerIcon: {
    display: "flex",
    alignItems: "center",
  },
  containerValue: {
    marginLeft: theme.spacing(1),
    wordWrap: "break-word",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },

  visitBox: {
    borderColor: "#ffc107",
  },
  actions: {
    justifyContent: "flex-end",
  },
  active: {
    color: "#fff",
    background:
      "radial-gradient( circle 1087px at 1.1% 107.6%, #9FE09B 0.7%, rgba(252,196,173,1) 33%, rgba(177,248,200,1) 77.9%, rgba(139,255,212,0.87) 98.1% )",
  },
  prescription: {
    minWidth: "40%",
    margin: theme.spacing(1),
  },
}));

const VisitRow = (props) => {
  const { visit } = props;
  const classes = useStyles();
  // console.log(idx);
  // console.log(visit);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // console.log(visit.dr_notes);
  // console.log(visit.patient_notes);

  const drNotes = JSON.parse(visit.dr_notes);
  const ptNotes = JSON.parse(visit.patient_notes);
  const dateStr = moment(visit.date_time, "YYYY-MM-DDTHH:mm:ss").format(
    "DD-MM-YYYY hh:mm A"
  );

  return (
    <div className={classes.root}>
      <Box
        boxShadow={2}
        borderLeft={10}
        borderRadius={2}
        borderColor={"#E36756"}
      >
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Grid container direction="row" alignItems="center" spacing={2}>
              <Grid item style={{ width: "150px" }} align="justify">
                <div className={classes.containerIcon}>
                  <Avatar
                    variant="rounded"
                    className={classes.active}
                    style={{
                      fontSize: visit.id.length > 2 ? "0.8rem" : "0.8rem", // Adjust font size based on ID length
                    }}
                  >
                    {visit.id}
                  </Avatar>

                  <div className={classes.containerValue}>
                    <Typography
                      color="textSecondary"
                      style={{ fontSize: "12px" }}
                    >
                      {dateStr}
                    </Typography>
                  </div>
                </div>
              </Grid>
              {/* <Grid item xs>
                <Typography color="textPrimary" align="justify">
                  {visitFormfields["complain"]}
                </Typography>
                <Typography color="textSecondary" align="justify">
                  {ptNotes.length ? ptNotes.join(", ") : "-"}
                </Typography>
              </Grid> */}
              <Grid item xs>
                <Typography
                  color="textPrimary"
                  align="justify"
                  style={{ fontSize: "12px" }}
                >
                  {visitFormfields["notes"]}
                </Typography>
                <Typography color="textSecondary" style={{ fontSize: "12px" }}>
                  {visit.notes ? visit.notes : "-"}
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography
                  color="textPrimary"
                  alignItems="center"
                  // align="justify"
                  style={{ fontSize: "12px" }}
                >
                  Advice
                </Typography>
                <Typography
                  style={{ fontSize: "12px" }}
                  color="textSecondary"
                  align="justify"
                  marginright={"2"}
                >
                  {visit.dia_notes ? visit.dia_notes : "-"}
                </Typography>
              </Grid>

              {/* <Grid item xs={2}>
                <div className={classes.containerIcon} marginleft={2}>
                  <div className={classes.containerValue}>
                    <Typography color="textPrimary" align="justify">
                      {visitFormfields["notes"]}
                    </Typography>
                    <Typography color="textSecondary">{visit.notes}</Typography>
                  </div>
                </div>
              </Grid> */}
              <Grid item style={{ width: "80px" }}>
                <Typography
                  color="textPrimary"
                  alignItems="center"
                  align="justify"
                  style={{ fontSize: "12px" }}
                >
                  Weight
                </Typography>
                <Typography color="textSecondary" style={{ fontSize: "12px" }}>
                  {visit.weight ? visit.weight : "-"}
                </Typography>
              </Grid>
              <Grid item style={{ width: "80px" }}>
                <Typography
                  color="textPrimary"
                  align="justify"
                  style={{ fontSize: "12px" }}
                >
                  Age
                </Typography>
                <Typography color="textSecondary" style={{ fontSize: "12px" }}>
                  {visit.age ? visit.age + " Yrs" : "-"}
                </Typography>
              </Grid>
              <Grid item style={{ width: "100px" }}>
                <Typography
                  color="textPrimary"
                  align="justify"
                  style={{ fontSize: "12px" }}
                >
                  <span>Charges (&#8377;) </span>
                </Typography>
                <Typography color="textSecondary" style={{ fontSize: "12px" }}>
                  {visit.paid_amount} / {visit.amount}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <VisitDetailInfo visit={visit} />
          </AccordionDetails>
        </Accordion>
      </Box>
    </div>
  );
};

const PatientVisitList = (props) => {
  const classes = useStyles();
  const { visitlist } = props;

  // console.log("Current visit list -------");
  // console.log(visitlist);

  return (
    <div className={classes.root}>
      {visitlist
        .sort((a, b) => (a.id > b.id ? 1 : -1))
        .reverse()
        .map((visit, idx) => (
          <VisitRow key={idx} visit={visit} idx={idx} />
        ))}
    </div>
  );
};

PatientVisitList.propTypes = {
  className: PropTypes.string,
};

export default PatientVisitList;
