import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import { hostInfo } from "../../../store";

const useStyles = makeStyles((theme) => ({
  addBtn: {
    background: "#9FC088",
    marginTop: theme.spacing(2),
  },
  tabBar: {
    background: "#9FC088",
    // borderRadius: 3,
  },
  kwdBtn: {
    // background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    background: "#00C39B",
    borderRadius: 3,
    border: 0,
    color: "white",
    // padding: '0 30px',
    // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  },
  fieldTxt: {
    marginRight: theme.spacing(1),
  },
}));

const AddKwDia = (props) => {
  const {
    open,
    appendNewPayment,
    appendNewMeds,
    appendNewReps,
    appendNewDis,
    appendNewSymt,
    appendNewDrs,
    handleClickOpen,
    handleClickClose,
  } = props;

  const tabs = ["meds", "reps", "dis", "symt", "drs", "payment"];

  const [meds, setMeds] = useState({
    medName: "",
    medCmp: "",
    medType: "",
  });

  const [payment, setPayment] = useState({
    paymentName: "",
    Price: "",
  });

  const [reps, setReps] = useState({
    repName: "",
  });

  const [dis, setDis] = useState({
    disName: "",
  });

  const [symt, setSymt] = useState({
    symName: "",
  });

  const [drs, setDrs] = useState({
    drFirstNm: "",
    drLastNm: "",
    drSpecl: "",
    drMob: "",
  });

  const classes = useStyles();

  const [value, setValue] = React.useState(tabs[0]);

  const validateName = (name) => {
    if (name.trim() === "") {
      console.log("Invalid string to add in DB: ", name);
      return 1;
    } else if (name.trim().length > 100) {
      console.log("Invalid string: Too long, Str: ", name);
      return 1;
    }
    return 0;
  };
  const getToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.authToken;
  };

  const handleClickAdd = (e, value) => {
    // console.log("Dialog Add button got ----------");
    // var name = e.target.name;
    // var value1 = e.target.value;
    // console.log("Name: ", name, " Value: ", value1);
    // console.log("arg Value :", value);

    if (value === tabs[0]) {
      if (validateName(meds.medName)) {
        alert("Invalid entry:", meds.medName, "  !!! Please correct ");
        return;
      }

      var me = {
        Name: meds.medName,
        // Company: meds.medCmp,
        Type: meds.medType,
      };
      let url = hostInfo + "/addmeds";
      fetch(url, {
        method: "POST",
        // mode: "no-cors",
        headers: {
          Accept: "*/*",
          "Content-type": "application/json; charset=UTF-8",

          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(me),
      });

      appendNewMeds(meds.medType + ". " + meds.medName);
    } else if (value === tabs[4]) {
      if (validateName(payment.paymentName)) {
        alert("Invalid entry:", payment.paymentName, "  !!! Please correct ");
        return;
      }

      var me = {
        Name: payment.paymentName,
        // Company: meds.medCmp,
        Price: parseInt(payment.Price, 10),
      };
      let url = hostInfo + "/addpayment";
      fetch(url, {
        method: "POST",
        // mode: "no-cors",
        headers: {
          Accept: "*/*",
          "Content-type": "application/json; charset=UTF-8",

          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(me),
      });

      appendNewPayment(payment.Price + ". " + payment.paymentName);
    } else if (value === tabs[1]) {
      if (validateName(reps.repName)) {
        alert("Invalid entry:", reps.repName, "  !!! Please correct ");
        return;
      }
      var re = {
        report: reps.repName,
      };
      let url = hostInfo + "/addreps";
      fetch(url, {
        method: "POST",
        // mode: "no-cors",
        headers: {
          Accept: "*/*",
          "Content-type": "application/json; charset=UTF-8",

          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(re),
      });

      appendNewReps(reps.repName);
    } else if (value === tabs[2]) {
      if (validateName(dis.disName)) {
        alert("Invalid entry:", dis.disName, "  !!! Please correct ");
        return;
      }

      var di = {
        name: dis.disName,
        description: "-",
      };

      let url = hostInfo + "/adddis";
      fetch(url, {
        method: "POST",
        // mode: "no-cors",
        headers: {
          Accept: "*/*",
          "Content-type": "application/json; charset=UTF-8",

          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(di),
      });

      appendNewDis(dis.disName);
    } else if (value === tabs[3]) {
      // console.log("arg Value :", value);

      if (validateName(symt.symName)) {
        alert("Invalid entry:", symt.symName, "  !!! Please correct ");
        return;
      }

      var sy = {
        name: symt.symName,
      };

      let url = hostInfo + "/addsympt";
      fetch(url, {
        method: "POST",
        // mode: "no-cors",
        headers: {
          Accept: "*/*",
          "Content-type": "application/json; charset=UTF-8",

          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(sy),
      });

      appendNewSymt(symt.symName);
    } else if (value === tabs[4]) {
      let name = drs.drFirstNm + " " + drs.drLastNm;
      if (validateName(name)) {
        alert(
          "Invalid entry of first name: ",
          drs.drFirstNm,
          " & last name:",
          drs.drLastNm,
          "  !!! Please correct "
        );
        return;
      }
      var dr = {
        firstname: drs.drFirstNm,
        lastname: drs.drLastNm,
        speciality: drs.drSpecl,
        mobile: drs.drMob,
      };
      // console.log("Mitesh --- ");
      // console.log(JSON.stringify(dr));

      let url = hostInfo + "/adddrinfo";
      fetch(url, {
        method: "POST",
        // mode: "no-cors",
        headers: {
          Accept: "*/*",
          "Content-type": "application/json; charset=UTF-8",

          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(dr),
      });

      appendNewDrs(name);
    }

    // console.log(this.keywordData);
    // this.setState({ keywordData: initKeywordData });
    // console.log(this.keywordData);
    handleClickClose();
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Meds -----------
  const handleChangeMedProps = (e) => {
    // console.log("Inside handleChangeMedProps onCange------");
    var name = e.target.name;
    var value = e.target.value;
    // console.log("Name: ", name, " Value: ", value);

    if (["medName", "medType"].includes(name)) {
      setMeds({ ...meds, [name]: value });
    }
  };

  //payment------------
  const handleChangePaymentProps = (e) => {
    // console.log("Inside handleChangeMedProps onCange------");
    var name = e.target.name;
    var value = e.target.value;
    // console.log("Name: ", name, " Value: ", value);

    if (["paymentName", "Price"].includes(name)) {
      setPayment({ ...payment, [name]: value });
    }
  };

  // Reps -----------
  const handleChangeRepProps = (e) => {
    // console.log("Inside handleChangeRepProps onCange------");
    var name = e.target.name;
    var value = e.target.value;
    // console.log("Name: ", name, " Value: ", value);

    if (["repName"].includes(name)) {
      setReps({ [name]: value });
    }
    // console.log(reps);
  };

  // Dis -----------
  const handleChangeDisProps = (e) => {
    // console.log("Inside handleChangeDisProps onCange------");
    var name = e.target.name;
    var value = e.target.value;
    // console.log("Name: ", name, " Value: ", value);

    if (["disName"].includes(name)) {
      setDis({ [name]: value });
    }
  };

  // Symt -----------
  const handleChangeSymtProps = (e) => {
    // console.log("Inside handleChangeSymtProps onCange------");
    var name = e.target.name;
    var value = e.target.value;
    // console.log("Name: ", name, " Value: ", value);

    if (["symName"].includes(name)) {
      setSymt({ [name]: value });
    }
  };

  // Dr -----------
  // const handleChangeDrProps = (e) => {
  //   // console.log("Inside handleChangeDrProps onCange------");
  //   var name = e.target.name;
  //   var value = e.target.value;
  //   // console.log("Name: ", name, " Value: ", value);

  //   if (["drFirstNm", "drLastNm", "drSpecl", "drMob"].includes(name)) {
  //     setDrs({ ...drs, [name]: value });
  //   }
  // };

  // const handleAddRepClose = (e, name) => {
  //   console.log("Report name : ");

  //   setKeywordData({ ...initFmField });
  //   console.log(keywordData);
  // };

  return (
    <div>
      <Button
        style={{ height: "30px" }}
        variant="contained"
        className={classes.kwdBtn}
        onClick={handleClickOpen}
      >
        Add Keywords
      </Button>
      <Fragment>
        <Dialog
          open={open}
          // color={"primary"}
          fullWidth={true}
          maxWidth={"md"}
          onClose={handleClickClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          {/* <DialogTitle id="alert-dialog-slide-title">
            {"Use Google's location service?"}
          </DialogTitle> */}
          <DialogContent>
            <div className={classes.root}>
              <TabContext value={value}>
                <AppBar
                  position="static"
                  className={classes.tabBar}
                  // size="small"
                >
                  <TabList
                    centered
                    onChange={handleChange}
                    aria-label="simple tabs example"
                    variant="scrollable"
                  >
                    <Tab label="Medicine Name" value={tabs[0]} />
                    <Tab label="Reports name" value={tabs[1]} />
                    <Tab label="Disease" value={tabs[2]} />
                    <Tab label="Symptoms" value={tabs[3]} />
                    <Tab label="Payment" value={tabs[4]} />
                  </TabList>
                </AppBar>
                <TabPanel value={tabs[0]}>
                  <form
                    className={classes.root}
                    noValidate
                    autoComplete="off"
                    onChange={handleChangeMedProps}
                  >
                    <TextField
                      margin="normal"
                      id="med-dia"
                      name="medName"
                      label="Medicine"
                      type="string"
                      className={classes.fieldTxt}

                      // onChange={(e) => handleOnMedValChange(e, "medName")}
                    />
                    {/* <TextField
                      margin="normal"
                      id="med-dia1"
                      name="medCmp"
                      label="Brand"
                      type="string"
                      className={classes.fieldTxt}

                      // onChange={(e) => handleOnMedValChange(e, "medCmp")}
                    /> */}
                    <TextField
                      margin="normal"
                      id="med-dia2"
                      name="medType"
                      label="Type"
                      type="string"
                      className={classes.fieldTxt}

                      // onChange={(e) => handleOnMedValChange(e, "medType")}
                    />
                    {/* <Button
                      variant="contained"
                      color="primary"
                      onClick={handleClickAdd}
                      className={classes.addBtn}
                      // color="primary"
                    >
                      Add
                    </Button> */}
                  </form>
                </TabPanel>
                <TabPanel value={tabs[1]}>
                  <form
                    className={classes.root}
                    noValidate
                    autoComplete="off"
                    onChange={handleChangeRepProps}
                  >
                    <TextField
                      margin="normal"
                      id="rep-dia"
                      name="repName"
                      label="Report"
                      type="string"
                      className={classes.fieldTxt}
                    />
                    {/* <Button
                      variant="contained"
                      onClick={handleClickAdd}
                      color="primary"
                      className={classes.addBtn}
                    >
                      Add
                    </Button> */}
                  </form>
                </TabPanel>
                <TabPanel value={tabs[2]}>
                  <form
                    className={classes.root}
                    noValidate
                    autoComplete="off"
                    onChange={handleChangeDisProps}
                  >
                    <TextField
                      margin="normal"
                      id="dis-dia"
                      name="disName"
                      label="Disease"
                      type="string"
                      className={classes.fieldTxt}
                    />

                    {/* <Button
                      variant="contained"
                      color="primary"
                      onClick={handleClickAdd}
                      className={classes.addBtn}
                      // color="primary"
                    >
                      Add
                    </Button> */}
                  </form>
                </TabPanel>
                <TabPanel value={tabs[3]}>
                  <form
                    className={classes.root}
                    noValidate
                    autoComplete="off"
                    onChange={handleChangeSymtProps}
                  >
                    <TextField
                      margin="normal"
                      id="sym-dia"
                      name="symName"
                      label="Symptom"
                      type="string"
                      className={classes.fieldTxt}
                    />

                    {/* <Button
                      variant="contained"
                      color="primary"
                      onClick={handleClickAdd}
                      className={classes.addBtn}
                      // color="primary"
                    >
                      Add
                    </Button> */}
                  </form>
                </TabPanel>
                <TabPanel value={tabs[4]}>
                  <form
                    className={classes.root}
                    noValidate
                    autoComplete="off"
                    onChange={handleChangePaymentProps}
                  >
                    <TextField
                      margin="normal"
                      id="payment-dia"
                      name="paymentName"
                      label="Payment Name"
                      type="string"
                      className={classes.fieldTxt}

                      // onChange={(e) => handleOnMedValChange(e, "medName")}
                    />
                    {/* <TextField
                      margin="normal"
                      id="med-dia1"
                      name="medCmp"
                      label="Brand"
                      type="string"
                      className={classes.fieldTxt}

                      // onChange={(e) => handleOnMedValChange(e, "medCmp")}
                    /> */}
                    <TextField
                      margin="normal"
                      id="payment-dia2"
                      name="Price"
                      label="Price"
                      type="number"
                      className={classes.fieldTxt}

                      // onChange={(e) => handleOnMedValChange(e, "medType")}
                    />
                    {/* <Button
                      variant="contained"
                      color="primary"
                      onClick={handleClickAdd}
                      className={classes.addBtn}
                      // color="primary"
                    >
                      Add
                    </Button> */}
                  </form>
                </TabPanel>
                {/* <TabPanel value={tabs[4]}>
                  <form
                    className={classes.root}
                    noValidate
                    autoComplete="off"
                    onChange={handleChangeDrProps}
                  >
                    <TextField
                      margin="normal"
                      id="dr-fst-dia"
                      name="drFirstNm"
                      label="First Name"
                      type="string"
                      className={classes.fieldTxt}
                    />
                    <TextField
                      margin="normal"
                      id="dr-lst-dia"
                      name="drLastNm"
                      label="Last Name"
                      type="string"
                      className={classes.fieldTxt}
                    />
                    <TextField
                      margin="normal"
                      id="dr-spl-dia"
                      name="drSpecl"
                      label="Specialist"
                      type="string"
                      className={classes.fieldTxt}
                    />
                    <TextField
                      margin="normal"
                      id="dr-mob-dia"
                      name="drMob"
                      label="Mobile"
                      type="string"
                      className={classes.fieldTxt}
                    />
            
                  </form>
                </TabPanel> */}
              </TabContext>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={(e) => handleClickAdd(e, value)}
              // onChange={(e) => handleOnMedValChange(e, "medCmp")}
              className={classes.addBtn}
              // color="primary"
            >
              Add
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleClickClose}
              className={classes.addBtn}
              // color="primary"
            >
              Close
            </Button>
            {/* <Button onClick={handleClickClose} color="primary">
            Agree
          </Button> */}
          </DialogActions>
        </Dialog>
      </Fragment>
    </div>
  );
};

export default AddKwDia;
