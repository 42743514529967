import React, { Fragment } from "react";

import { withStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { hostInfo } from "../../../store";
import { genderArr } from "../../../store";
// import  "../../../fingerscripts/jquery-1.8.2";
// import "../../../fingerscripts/mfs100";
// import * as finger "../../../fingerscripts/finger";
import FormGroup from "@material-ui/core/FormGroup";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
// import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import FingerScan from "../../../FingerScan/FingerScan";

//import { countryArr } from "../../../../store";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { Country, State, City } from "country-state-city";

// import { ICountry, IState, ICity } from "country-state-city";
import { Grid, Paper } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      minWidth: 250,
    },
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  formControl: {
    // margin: theme.spacing(1),
    width: 250,
  },
  grpControl: {
    marginTop: theme.spacing(2),
    width: 250,
  },
  imgpaper: {
    padding: theme.spacing(2),
    margin: "auto",
    // with: "150px",
    height: "220px",
  },
  countryControl: {
    marginRight: theme.spacing(1),
    width: 165,
  },
  toggleSwitch: {
    marginTop: theme.spacing(3),
  },
  location: {
    direction: "row",
    alignItems: "spce-between",
  },
  addressControl: {
    minWidth: "95%",
  },
  autocomp: {
    '&[data-focus="true"], &:hover': {
      backgroundColor: "#CFE8A9",
    },
  },
});

// const genderArr = [
//   {
//     value: "M",
//     label: "Male",
//   },
//   {
//     value: "F",
//     label: "Female",
//   },
//   {
//     value: "O",
//     label: "Other",
//   },
// ];
export default withStyles(styles)(
  class EditPatient extends React.Component {
    state = {
      formdetail: {
        id: 0,
        firstname: "",
        lastname: "",
        email: "",
        gender: "",
        age: "",
        birthdate: null,
        disability: false,
        country: "101",
        state: "12",
        city: "1041",
        mobile: "",
        address: "",
        group_id: 0,
        illness: "",
        iso_key: "",
        patient_grp_name: "",

        firstnameError: false,
        lastnameError: false,
        emailError: false,
        birthdateError: false,
        ageError: false,
        countryError: false,
        stateError: false,
        cityError: false,

        firstnameErrorTxt: "",
        lastnameErrorTxt: "",
        emailErrorTxt: "",
        birthdateErrorTxt: "",
        ageErrorTxt: "",
        disabilityErrorTxt: "",
        countryErrorTxt: "",
        stateErrorTxt: "",
        cityErrorTxt: "",
      },
    };
    countryArr = Country.getAllCountries();
    stateArr = State.getStatesOfCountry("101");
    cityArr = City.getCitiesOfState("12");
    selectedCountry = "India";
    selectedState = "Gujarat";
    selectedCity = "Surat";
    stateDisabled = true;
    cityDisabled = true;

    // shouldComponentUpdate() {
    //   console.log("shouldComponentUpdate --------------------");
    // }

    // componentDidUpdate(prevProps) {
    //   if (Object.keys(this.props.patientInfo).length === 0) {
    //     console.log("undefined patient --------------------");
    //     return;
    //   } else if (
    //     prevProps.patientInfo.firstname !== this.props.patientInfo.firstname
    //   ) {
    //     return;
    //   }
    //   // console.log(this.props.patientInfo);
    //   console.log("componentDidUpdate  --------------------");
    //   console.log(prevProps.patientInfo);
    //   let patient = this.props.patientInfo;
    //   let formdetail = {
    //     firstname: patient.firstname,
    //     lastname: patient.lastname,
    //     email: patient.mail_id,
    //     gender: patient.gender,
    //     age: patient.age,
    //     // disability: false,
    //     country: patient.country,
    //     state: patient.state,
    //     city: patient.city,
    //     mobile: patient.mobile,
    //     address: patient.address,

    //     firstnameError: false,
    //     lastnameError: false,
    //     emailError: false,
    //     ageError: false,
    //     countryError: false,
    //     stateError: false,
    //     cityError: false,

    //     firstnameErrorTxt: "",
    //     lastnameErrorTxt: "",
    //     emailErrorTxt: "",
    //     ageErrorTxt: "",
    //     disabilityErrorTxt: "",
    //     countryErrorTxt: "",
    //     stateErrorTxt: "",
    //     cityErrorTxt: "",
    //   };

    //   this.setState({ ...this.state, formdetail });
    //   console.log(this.props.patientInfo);
    // }

    // handleChildUnmount() {
    //   console.log("--------Component Un MOUNT! -------------");
    //

    async componentDidMount() {
      // console.log("Component DID MOUNT! -------------");

      let formdetail = { ...this.state.formdetail };

      let patient = this.props.patientInfo;
      // console.log("patient.birthdate --- ", patient.birthdate);
      // console.log("Got patioent from server---");
      // console.log(patient);
      formdetail.id = patient.id;
      formdetail.firstname = patient.firstname;
      formdetail.lastname = patient.lastname;
      formdetail.email = patient.mail_id;
      formdetail.illness = patient.illness;
      // formdetail.birthdate = moment(patient.birthdate, "DD-MM-YYYY");
      // formdetail.birthdate = patient.birthdate;
      formdetail.birthdate = patient.birthdate.length
        ? moment(patient.birthdate, "DD-MM-YYYY")
        : null;
      // birthdate: patient.birthdate
      formdetail.gender = patient.gender.length
        ? patient.gender === "M"
          ? "Male"
          : patient.gender === "F"
          ? "Female"
          : null
        : null;
      formdetail.age = patient.age;
      formdetail.group_id = patient.patient_grp_id;
      formdetail.patient_grp_name = patient.patient_grp_name;
      // disability: false
      formdetail.country = patient.country;
      formdetail.iso_key = patient.iso_key;
      formdetail.state = patient.state;
      formdetail.city = patient.city;
      formdetail.mobile = patient.mobile;
      formdetail.address = patient.address;
      formdetail.firstnameError = false;
      formdetail.lastnameError = false;
      formdetail.emailError = false;
      formdetail.birthdateError = false;
      formdetail.ageError = false;
      formdetail.countryError = false;
      formdetail.stateError = false;
      formdetail.cityError = false;
      formdetail.firstnameErrorTxt = "";
      formdetail.lastnameErrorTxt = "";
      formdetail.emailErrorTxt = "";
      formdetail.birthdateErrorTxt = "";
      formdetail.ageErrorTxt = "";
      formdetail.disabilityErrorTxt = "";
      formdetail.countryErrorTxt = "";
      formdetail.stateErrorTxt = "";
      formdetail.cityErrorTxt = "";
      this.setState({ formdetail });

      // this.setState({ ...this.state, formdetail });
      // console.log(this.props.patientInfo);

      // let patient;
      // let url =
      //   "http://" + hostInfo + "/getpatientbyid?patient_id=" + this.patientId;
      // await fetch(url)
      //   .then((res) => res.json())
      //   .then((data) => {
      //     // setTimeout(() => setStory(data), 1500);
      //     // console.log("Success ", data);
      //     // if (!Object.entries(data).length) {
      //     //   window.location = "/dashboard";
      //     // }
      //     if (data != null) {
      //       patient = data;
      //     }
      //   });
      // console.log("Did mount call ---End-----");
      // console.log(patient);
      // appendScript("/fingerscripts/jquery-1.8.2.js");
      // appendScript("/fingerscripts/mfs100.js");
      // appendScript("/fingerscripts/finger.js");
      // console.log(formdetail);
    }

    validate = () => {
      return false;
      let isError = false;
      const { formdetail } = this.state;

      // Clean old error status
      formdetail.firstnameError = false;
      formdetail.lastnameError = false;
      formdetail.emailError = false;
      formdetail.birthdateError = false;
      formdetail.countryError = false;
      formdetail.stateError = false;
      formdetail.cityError = false;
      formdetail.ageError = false;
      formdetail.disabilityError = false;

      formdetail.firstnameErrorTxt = "";
      formdetail.lastnameErrorTxt = "";
      formdetail.emailErrorTxt = "";
      formdetail.birthdateErrorTxt = "";
      formdetail.countryErrorTxt = "";
      formdetail.stateErrorTxt = "";
      formdetail.cityErrorTxt = "";
      formdetail.genderErrorTxt = "";
      formdetail.ageErrorTxt = "";

      // firstname Validation----
      // 1. Length
      // 2. special character check
      if (formdetail.firstname.length < 2) {
        isError = true;
        formdetail.firstnameError = true;
        formdetail.firstnameErrorTxt =
          "firstname needs to be atleast 2 characters long";
      }

      // Lastname Validation
      if (formdetail.lastname.length < 2) {
        isError = true;
        formdetail.lastnameError = true;
        formdetail.lastnameErrorTxt =
          "Lastname needs to be atleast 2 characters long";
      }

      // Email Validation
      // 1. @ check
      if (formdetail.email.length < 1) {
        formdetail.email = "-";
      }

      // Mobile
      if (formdetail.mobile.length < 1) {
        formdetail.mobile = "-";
      }

      // console.log(
      //   "Selected C: " +
      //     this.selectedCountry +
      //     "S: " +
      //     this.selectedState +
      //     "city: " +
      //     this.selectedCity
      // );
      // console.log(this.cityArr);
      // Country, state, city validation
      if (!this.selectedCountry.length) {
        // console.log("contry vaidation --------");
        // console.log(this.selectedCountry);
        // console.log(this.countryArr);
        isError = true;
        formdetail.countryError = true;
        formdetail.countryErrorTxt = "Invalid Country";
      }
      if (false === this.validateInArry(this.stateArr, this.selectedState)) {
        // console.log("State vaidation --------");
        // console.log(this.selectedState);
        // console.log(this.stateArr);
        // console.log(this.cityArr);
        isError = true;
        formdetail.stateError = true;
        formdetail.stateErrorTxt = "Invalid State";
      }

      // City
      if (false === this.validateInArry(this.cityArr, this.selectedCity)) {
        // console.log("city arry len: " + this.cityArr.length);
        // console.log(this.selectedCity);
        // console.log(this.cityArr.indexOf[this.selectedCity]);
        isError = true;
        formdetail.cityError = true;
        formdetail.cityErrorTxt = "Invalid City";
      }

      this.setState({
        ...this.state.formdetail,
      });

      return isError;
    };

    handleSubmit = async () => {
      const err = this.validate();

      // const err = false;
      if (!err) {
        // On submit form success
        let { formdetail } = this.state;
        // console.log("---------", formdetail.birthdate);

        // Date Validation...
        if (
          formdetail.birthdate &&
          moment(formdetail.birthdate, "DD-MM-YYYY").isValid() === false
        ) {
          return false;
        }

        let formObj = {
          id: formdetail.id,
          firstname: formdetail.firstname,
          lastname: formdetail.lastname,
          illness: formdetail.illness,
          mail_id: formdetail.email,
          birthdate: formdetail.birthdate
            ? moment(formdetail.birthdate).format("DD-MM-YYYY")
            : null,
          // birthdate: moment(formdetail.birthdate).format("DD-MM-YYYY"),
          gender:
            formdetail.gender === "Male"
              ? "M"
              : formdetail.gender === "Female"
              ? "F"
              : null,
          age: formdetail.age,
          // disability: formdetail.disability,
          city: this.selectedCity,
          state: this.selectedState,
          country: this.selectedCountry,
          address: formdetail.address,
          mobile: formdetail.mobile,
          iso_key: formdetail.iso_key,
          patient_grp_id: formdetail.group_id,
        };
        const getToken = () => {
          const cookieString = document.cookie;
          const cookies = cookieString.split("; ").reduce((acc, cookie) => {
            const [name, value] = cookie.split("=");
            acc[name] = value;
            return acc;
          }, {});
          return cookies.authToken;
        };

        // console.log("Final from object...");
        // console.log(JSON.stringify(formObj));
        let url = hostInfo + "/updatepatient";
        await fetch(url, {
          method: "POST",
          // mode: "no-cors",
          headers: {
            Accept: "*/*",
            "Content-type": "application/json; charset=UTF-8",

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(formObj),
        });

        // console.log("response id --------");

        // const content = await rawResponse.json();
        // console.log(content);
        this.props.closeHdl();
        window.location.reload();
        // window.location = "/patients/patientprofile?patient_id=" + id;
        return true;
      }
      //Error in Submit..............
      // console.log("Validation failed, Error in Submit--------");
      return false;
    };

    handleDiaClose = () => {
      this.props.closeHdl();
    };

    handleClickOpen = () => {
      this.setState({ open: true });
    };

    handleClose = () => {
      this.setState({ open: false });
    };

    handleGrpChange = (e, name, value) => {
      // console.log("Inside handle Gen change change---");
      // // console.log(id);
      // console.log(name);
      // console.log(value);

      this.setState({
        formdetail: { ...this.state.formdetail, [name]: value.id },
      });
    };

    handleNumericChange = (name, val) => {
      // console.log(name);
      // console.log(val);
      const value = val.floatValue;
      // this.setState({ ...this.state.formdetail, [name]: value });
      this.setState({
        formdetail: { ...this.state.formdetail, [name]: value },
      });
    };

    handleDateChange = (name, value) => {
      // setSelectedEndDate(date);
      // console.log("On Date change log.......");
      // console.log(name);
      // console.log(value);
      this.setState({
        formdetail: { ...this.state.formdetail, birthdate: value },
      });
    };

    handleOnChange = (e, name) => {
      // console.log("On Change log.......");
      // console.log(name);
      // console.log(e.target.value);

      const value = e.target.value;
      // this.setState({ ...this.props.patientInfo, [name]: value });
      this.setState({
        formdetail: { ...this.state.formdetail, [name]: value },
      });
      // console.log(this.state.formdetail);
    };

    handleGenChange = (e, name, value) => {
      // console.log("Inside handle Gen change change---");
      // console.log(id);
      // console.log(name);
      // console.log(value.label);

      // this.setState({ ...this.props.patientInfo, [name]: value.label });
      this.setState({
        formdetail: { ...this.state.formdetail, [name]: value.label },
      });
    };

    handleFingerScan = (value) => {
      // console.log(value);

      this.setState({
        formdetail: {
          ...this.state.formdetail,
          iso_key: value,
        },
      });
    };

    render() {
      const {
        // open,
        formdetail: {
          firstname,
          illness,
          lastname,
          // email,
          birthdate,
          address,
          // age,
          mobile,
          // group_id,
          gender,
          patient_grp_name,

          firstnameError,
          lastnameError,
          mobileError,
          addressError,

          firstnameErrorTxt,
          lastnameErrorTxt,
          mobileErrorTxt,
          addressErrorTxt,
        },
      } = this.state;
      // const {} = this;
      // const {  } = this.props;
      // console.log(this.countryArr);
      // console.log(this.cityArr);
      const {
        patientInfo,
        // patientId,
        classes,
        // closeHdl,
        open,
        EditReport,
        patientGrpList,
      } = this.props;
      // console.log("patient state Info --------------");
      // console.log(this.state.formdetail);
      return (
        // <div>
        <Fragment>
          <Dialog
            open={open}
            onClose={this.handleDiaClose}
            // aria-labelledby="Add User"
          >
            <DialogTitle id="AddNewPatient">Edit Patient Profile</DialogTitle>
            <DialogContent>
              <DialogContentText>Fill the patient details</DialogContentText>
              <form className={classes.root} autoComplete="off">
                <TextField
                  //////////////////  First Name
                  autoFocus
                  margin="dense"
                  id="firstname"
                  error={firstnameError}
                  value={firstname}
                  onChange={(e) => {
                    this.handleOnChange(e, "firstname");
                  }}
                  label="Firstname"
                  type="firstname"
                  helperText={firstnameErrorTxt}
                />
                <TextField
                  //////////////////  Last Name
                  margin="dense"
                  id="lastname"
                  error={lastnameError}
                  value={lastname}
                  onChange={(e) => {
                    this.handleOnChange(e, "lastname");
                  }}
                  label="Lastname"
                  type="lastname"
                  helperText={lastnameErrorTxt}
                />

                <Grid container direction="row">
                  <Grid item xs>
                    <Autocomplete
                      id="gender"
                      margin="dense"
                      classes={{ option: classes.autocomp }}
                      // autoFocus
                      className={classes.formControl}
                      disableClearable
                      options={genderArr}
                      value={gender}
                      getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.label
                      }
                      onChange={(e, value) => {
                        this.handleGenChange(e, "gender", value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Gender"
                          name="gender"
                          margin="dense"
                          InputProps={{
                            ...params.InputProps,
                            // autoComplete: "new-password",
                          }}
                        />
                      )}
                    />
                    <MuiPickersUtilsProvider
                    // libInstance={moment}
                    // utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        id="birthdate"
                        label="Birthday"
                        keyboard
                        className={classes.formControl}
                        placeholder="DD-MM-YYYY"
                        format={"dd-MM-yyyy"}
                        value={birthdate}
                        onChange={(e) => {
                          this.handleDateChange("birthdate", e);
                        }}
                        animateYearScrolling={true}
                        autoOk={true}
                        clearable
                        onInputChange={(e) =>
                          console.log("Keyboard:", e.target.value)
                        }
                      />
                    </MuiPickersUtilsProvider>

                    <TextField
                      ///////////////////////// Mobile
                      className={classes.formControl}
                      // autoFocus
                      id="mobile"
                      error={mobileError}
                      value={mobile}
                      onChange={(e) => {
                        this.handleOnChange(e, "mobile");
                      }}
                      label="Mobile"
                      type="mobile"
                      helperText={mobileErrorTxt}
                    />
                    <Autocomplete
                      id="group"
                      // margin="dense"
                      classes={{ option: classes.autocomp }}
                      // autoFocus
                      className={classes.grpControl}
                      disableClearable
                      // value={{
                      //   label: { name: patient_grp_name },
                      // }}
                      // defaultValue={{ name: patient_grp_name }}
                      value={patient_grp_name}
                      options={patientGrpList}
                      getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.name
                      }
                      // getOptionSelected={(option, value) => option.iso === value.iso}
                      // onChange={(e) => {
                      //   this.handleOnChange(e, "group_id");
                      // }}
                      onChange={(e, value) => {
                        this.handleGrpChange(e, "group_id", value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Group"
                          name="group_id"
                          margin="dense"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs>
                    <Paper className={classes.imgpaper}>
                      <FingerScan handleFingerScan={this.handleFingerScan} />
                    </Paper>
                  </Grid>
                </Grid>
                <TextField
                  className={classes.addressControl}
                  //////////////////  First Name
                  autoFocus
                  margin="dense"
                  id="illness"
                  // error={firstnameError}
                  value={illness}
                  onChange={(e) => {
                    this.handleOnChange(e, "illness");
                  }}
                  label="illness"
                  type="illness"
                  // helperText={firstnameErrorTxt}
                />
                <TextField
                  //////////////////  Address
                  className={classes.addressControl}
                  // autoFocus
                  margin="dense"
                  id="Address"
                  error={addressError}
                  value={address}
                  onChange={(e) => {
                    this.handleOnChange(e, "address");
                  }}
                  label="Address"
                  type="address"
                  // disabled={true}
                  // fullWidth
                  helperText={addressErrorTxt}
                />

                <FormGroup row={true}>
                  <TextField
                    className={classes.countryControl}
                    id="country"
                    // autoFocus
                    value={patientInfo.country}
                    disabled={true}
                    margin="normal"
                    variant="outlined"
                  />
                  <TextField
                    className={classes.countryControl}
                    id="state"
                    // autoFocus
                    value={patientInfo.state}
                    disabled={true}
                    margin="normal"
                    variant="outlined"
                  />
                  <TextField
                    className={classes.countryControl}
                    id="city"
                    // autoFocus
                    value={patientInfo.city}
                    disabled={true}
                    margin="normal"
                    variant="outlined"
                  />
                </FormGroup>
              </form>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.handleSubmit();
                }}
                color="primary"
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </Fragment>
        // </div>
      );
    }
  }
);
