import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  TextField,
  Button,
  DialogActions,
} from "@material-ui/core";
import { hostInfo } from "../../../store";

function AddNewExpenses({ open, closeHdl }) {
  const [form, setForm] = useState({
    purpose: "",
    amount: "",
    purposeError: false,
    purposeErrorTxt: "",
  });

  // Token extraction function
  const getToken = () => {
    const cookies = document.cookie.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.authToken || "";
  };

  // Form validation
  const validate = () => {
    let isError = false;
    const updatedForm = { ...form };

    if (form.purpose.length < 2) {
      isError = true;
      updatedForm.purposeError = true;
      updatedForm.purposeErrorTxt = "Purpose must be at least 2 characters";
    } else {
      updatedForm.purposeError = false;
      updatedForm.purposeErrorTxt = "";
    }

    setForm(updatedForm);
    return isError;
  };

  // Form submission
  const handleSubmit = async () => {
    if (!validate()) {
      try {
        const url = `${hostInfo}/addexpenses`;
        const response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify({
            purpose: form.purpose,
            amount: parseFloat(form.amount),
          }),
        });

        const result = await response.json();
        if (response.ok) {
          alert("Expense added successfully!");
          if (typeof closeHdl === "function") {
            closeHdl(); // Call the close handler
          }
          window.location.reload();
        } else {
          alert(result.error || "Failed to add expense.");
        }
      } catch (error) {
        alert("An error occurred. Please try again.");
      }
    }
  };

  // Form field change handler
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value,
    });
  };

  return (
    <Dialog open={open} onClose={closeHdl}>
      <DialogContent>
        <DialogContentText>Enter expense details</DialogContentText>
        <TextField
          autoFocus
          id="purpose"
          label="Purpose"
          value={form.purpose}
          onChange={handleChange}
          error={form.purposeError}
          helperText={form.purposeErrorTxt}
          fullWidth
          margin="dense"
        />
        <TextField
          id="amount"
          label="Amount"
          value={form.amount}
          onChange={handleChange}
          type="number"
          fullWidth
          margin="dense"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeHdl}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddNewExpenses;
