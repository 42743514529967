// import React, { useEffect, useState } from "react";
// // import React, { useEffect } from "react";
// import { makeStyles } from "@material-ui/styles";
// import {
//   Grid,
//   Paper,
//   Divider,
//   Typography,
//   Button,
//   Link,
// } from "@material-ui/core";
// import CssBaseline from "@material-ui/core/CssBaseline";
// import { Container, Row, Col } from "react-bootstrap";
// // import { BackupNow } from "./components";
// import { hostInfo } from "../store";

// const useStyles = makeStyles((theme) => ({
//   "@global": {
//     body: {
//       backgroundColor: theme.palette.common.white,
//     },
//   },
//   paper: {
//     marginTop: theme.spacing(8),
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//   },
//   avatar: {
//     margin: theme.spacing(1),
//     padding: theme.spacing(0.5),
//     background:
//       "radial-gradient( circle farthest-corner at 3.8% 11.2%, rgba(89,162,162,1)  0%, rgba(153,215,184,61) 90.2% )",
//   },
//   form: {
//     width: "100%", // Fix IE 11 issue.
//     marginTop: theme.spacing(3),
//   },
//   submit: {
//     background:
//       "radial-gradient( circle farthest-corner at 3.8% 11.2%, rgba(89,162,162,1)  0%, rgba(153,215,184,61) 90.2% )",
//     margin: theme.spacing(3, 0, 2),
//     padding: "10px",
//   },

//   root: {
//     margin: theme.spacing(1),
//     // backgroundColor: "green",
//   },
//   title: {
//     marginTop: theme.spacing(3),
//     margin: theme.spacing(2),
//   },
// }));

// const TableColummn = () => {
//   const classes = useStyles();
//   // const [dashboardCard, setDashboardCard] = useState({});
//   const [usernames, setUsernames] = useState([]);
//   console.log(usernames);
//   const [selectedUsers, setSelectedUsers] = useState([]);
//   const getToken = () => {
//     const cookieString = document.cookie;
//     const cookies = cookieString.split("; ").reduce((acc, cookie) => {
//       const [name, value] = cookie.split("=");
//       acc[name] = value;
//       return acc;
//     }, {});
//     return cookies.authToken;
//   };
//   useEffect(() => {
//     const fetchUsers = async () => {
//       try {
//         const response = await fetch(`${hostInfo}/usernames`, {
//           headers: {
//             Authorization: `Bearer ${getToken()}`,
//           },
//         });

//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }

//         const data = await response.json();

//         setUsernames(data.users.filter((i) => i.accesses_flag !== 2));
//         setSelectedUsers(
//           data.users
//             .filter((user) => user.accesses_flag === 1)
//             .map((user) => user.username)
//         );
//       } catch (error) {
//         console.error("Error fetching usernames:", error);
//         alert(error);
//       }
//     };

//     fetchUsers();
//   }, []);

//   const handleCheckboxChange = (username) => {
//     setSelectedUsers((prevState) => {
//       if (prevState.includes(username)) {
//         return prevState.filter((user) => user !== username);
//       }
//       return [...prevState, username];
//     });
//   };

//   const handleGrantAccess = () => {
//     fetch(`${hostInfo}/grant-access`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${getToken()}`,
//       },
//       body: JSON.stringify({ users: selectedUsers }),
//     })
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }
//         return response.json();
//       })
//       .then((data) => {
//         alert("Access granted!");
//         // setSelectedUsers([]);
//       })
//       .catch((error) => {
//         console.error("There was an error granting access!", error);
//       });
//   };

//   return (
//     <>
//       {/* <Typography
//         className={classes.title}
//         variant="h2"
//         gutterBottom
//         color="textSecondary"
//       >
//         Table Column
//       </Typography>
//       <Divider className={classes.deviderCls} /> */}

//       {/* <Divider className={classes.deviderCls} />
//       <div className={classes.root}>
//         <Container fluid className="main-content-container px-1">
//           <Row>
//             <Col lg="4">
//               <div>
//                 <Typography
//                   className="pl-2"
//                   gutterBottom
//                   variant="h5"
//                   component="h2"
//                   color="textSecondary"
//                 >
//                   User List
//                 </Typography>
//               </div>
//             </Col>
//             <Grid container direction="row" className="pl-3">
//               <Grid className="pl-3">
//                 <ul style={{ listStyle: "none", marginLeft: "-40px" }}>
//                   {usernames.length > 0 &&
//                     usernames.map((user) => (
//                       <li
//                         key={user.username}
//                         style={{
//                           marginBottom: "5px",
//                           marginLeft: "0",
//                           display: "flex",
//                           alignItems: "center",
//                         }}
//                       >
//                         <input
//                           type="checkbox"
//                           checked={selectedUsers.includes(user.username)}
//                           onChange={() => handleCheckboxChange(user.username)}
//                         />
//                         <label
//                           style={{
//                             textAlign: "center",
//                             // marginLeft: "5px",
//                             color: "black",
//                             padding: "4px 0px 0px 5px",
//                           }}
//                         >
//                           {user.username}
//                         </label>
//                       </li>
//                     ))}
//                 </ul>

//                 {usernames.length > 0 ? (
//                   <Button
//                     onClick={handleGrantAccess}
//                     target="_blank"
//                     variant="contained"
//                     color="primary"
//                   >
//                     Grant Access
//                   </Button>
//                 ) : (
//                   <div>User Not Available</div>
//                 )}
//               </Grid>

//             </Grid>

//           </Row>
//         </Container>
//       </div> */}

//       <Container component="main" maxWidth="xs">
//         <CssBaseline />
//         <div className={classes.paper}>
//           {/* <Avatar src={myLogo} className={classes.avatar} /> */}
//           {/* <Avatar className={classes.avatar}></Avatar> */}
//           <Typography component="h1" variant="h5">
//             Table Column
//           </Typography>
//           <form className={classes.form} noValidate>
//             <Row>
//               <Col lg="4">
//                 <div>
//                   <Typography
//                     className="pl-2"
//                     gutterBottom
//                     variant="h5"
//                     component="h2"
//                     color="textSecondary"
//                   >
//                     Column List
//                   </Typography>
//                 </div>
//               </Col>
//               <Grid container direction="row" className="pl-3">
//                 <Grid className="pl-3">
//                   <ul style={{ listStyle: "none", marginLeft: "-40px" }}>
//                     {usernames.length > 0 &&
//                       usernames.map((user) => (
//                         <li
//                           key={user.username}
//                           style={{
//                             marginBottom: "5px",
//                             marginLeft: "0",
//                             display: "flex",
//                             alignItems: "center",
//                           }}
//                         >
//                           <input
//                             type="checkbox"
//                             checked={selectedUsers.includes(user.username)}
//                             onChange={() => handleCheckboxChange(user.username)}
//                           />
//                           <label
//                             style={{
//                               textAlign: "center",
//                               // marginLeft: "5px",
//                               color: "black",
//                               padding: "4px 0px 0px 5px",
//                             }}
//                           >
//                             {user.username}
//                           </label>
//                         </li>
//                       ))}
//                   </ul>

//                   {/* </Typography> */}
//                   {/* {usernames.length > 0 ? (
//                     <Button
//                       onClick={handleGrantAccess}
//                       target="_blank"
//                       variant="contained"
//                       color="primary"
//                     >
//                       Grant Access
//                     </Button>
//                   ) : (
//                     <div>User Not Available</div>
//                   )} */}
//                 </Grid>
//                 {/* <Grid>
//                 <Restore />
//               </Grid> */}
//               </Grid>

//               {/* <Typography className="pt-5">
//               <BackupNow />
//               <Restore />
//             </Typography> */}
//             </Row>
//             <Button
//               type="button"
//               fullWidth
//               variant="contained"
//               color="primary"
//               className={classes.submit}
//               // onClick={handleLogin}
//             >
//               Save
//             </Button>
//             <Grid container justify="flex-end">
//               <Grid item>
//                 <Link href="/dashboard" variant="body2">
//                   Skip
//                 </Link>
//               </Grid>
//             </Grid>
//           </form>
//         </div>
//       </Container>
//       {/* <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}> */}
//     </>
//   );
// };

// export default TableColummn;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Paper,
  Typography,
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { hostInfo } from "../store";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#f0f4f8",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(4),
    borderRadius: 16,
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
    maxWidth: 500,
    width: "100%",
    background: "linear-gradient(135deg, #ffffff, #f9fafc)",
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: 500,
    fontSize: "1.4rem",
    textAlign: "center",
    color: "#333",
  },
  subtitle: {
    marginBottom: theme.spacing(3),
    textAlign: "center",
    fontSize: "1rem",
    color: "#666",
  },
  list: {
    maxHeight: 300,
    overflowY: "auto",
    marginBottom: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#fff",
    border: "1px solid #e0e0e0",
  },
  listItem: {
    borderRadius: 8,
    "&:hover": {
      backgroundColor: "#f1f5f9",
    },
  },
  button: {
    background:
      "radial-gradient( circle farthest-corner at 3.8% 11.2%, rgba(89,162,162,1)  0%, rgba(153,215,184,61) 90.2% )",
    color: "#fff",
    fontWeight: 600,
    fontSize: "1rem",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
  },
  skipButton: {
    color: "#666",
    fontWeight: 600,
    fontSize: "1rem",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 300,
  },
}));

const TableColumn = () => {
  const history = useHistory();
  const classes = useStyles();
  const [usernames, setUsernames] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  console.log(selectedUsers);
  const [isLoading, setIsLoading] = useState(true);

  const getToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.authToken;
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${hostInfo}/columnnames`, {
          headers: { Authorization: `Bearer ${getToken()}` },
        });

        if (!response.ok) throw new Error("Network response was not ok");

        const data = await response.json();
        setUsernames(data.users);

        // Pre-select users with access flag 1
        setSelectedUsers(
          data.users
            .filter((user) => user.accesses_flag === 1)
            .map((user) => user.username)
        );
      } catch (error) {
        console.error("Error fetching usernames:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleCheckboxChange = (username, isChecked) => {
    // Update the selectedUsers state when a checkbox is clicked
    setSelectedUsers((prevState) =>
      isChecked
        ? [...prevState, username]
        : prevState.filter((user) => user !== username)
    );
  };

  const handleGrantAccess = async () => {
    try {
      const response = await fetch(
        "http://localhost:8000/column-grant-access",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify({
            selectedUsers: selectedUsers, // Array of selected users
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update access flags");
      }

      const result = await response.json();
      if (result.success) {
        alert("Access updated successfully");
        history.push("/dashboard");
      } else {
        alert("Failed to update the database");
      }
    } catch (error) {
      console.error("Error granting access:", error);
      alert("Error granting access");
    }
  };

  const handleSkip = () => {
    history.push("/dashboard");
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography className={classes.title}>
          Manage Complain Table Column
        </Typography>
        <Typography className={classes.subtitle}>
          Select column to grant access. You can skip if needed.
        </Typography>
        {isLoading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        ) : (
          <List className={classes.list}>
            {usernames.length > 0 ? (
              usernames.map((user) => (
                <ListItem
                  key={user.username}
                  button
                  className={classes.listItem}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={selectedUsers.includes(user.username)}
                      onChange={(e) =>
                        handleCheckboxChange(user.username, e.target.checked)
                      }
                      color="primary"
                    />
                  </ListItemIcon>
                  <ListItemText primary={user.username} />
                </ListItem>
              ))
            ) : (
              <Typography variant="body1" color="textSecondary">
                No columns available.
              </Typography>
            )}
          </List>
        )}
        <Button
          fullWidth
          variant="contained"
          className={classes.button}
          onClick={handleGrantAccess} // Now handles multiple users
          disabled={usernames.length === 0}
        >
          Save
        </Button>

        <Button fullWidth className={classes.skipButton} onClick={handleSkip}>
          Skip
        </Button>
      </Paper>
    </div>
  );
};

export default TableColumn;
