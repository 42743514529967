import React from "react";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { makeStyles } from "@material-ui/core/styles";
import { medSchd } from "../../../../store";
import { medSchdTime } from "../../../../store";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Divider,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    fontSize: 12,
  },
  fieldSpace: {
    "& > *": {
      margin: theme.spacing(1),
      // marginLeft: theme.spacing(2),
    },
  },
  // row: {
  //   height: "42px",
  //   display: "flex",
  //   alignItems: "center",
  //   // marginTop: theme.spacing(1),
  // },
  tableHeader: { textAlign: "center" },
  // formField: {
  //   margin: theme.spacing(3),
  // },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 250,
  },
  countryControl: {
    // margin: theme.spacing(1),
    minWidth: 150,
  },
  medName: {
    marginRight: theme.spacing(1),
    width: "28%",
  },
  medSchd: {
    marginRight: theme.spacing(1),
    width: "12%",
  },
  medSchdTime: {
    marginRight: theme.spacing(1),
    width: "18%",
  },
  medCountField: {
    marginRight: theme.spacing(1),
    width: "10%",
  },
  fabBut: {
    marginRight: theme.spacing(1),
    minWidth: "8%",
  },
  addBut: {
    background: "#00C39B",
    "&:hover": {
      background: "#9BE27A",
    },
  },
  autocomp: {
    '&[data-focus="true"], &:hover': {
      backgroundColor: "#CFE8A9",
    },
  },
}));

const EditVisitMedicine = (props) => {
  const classes = useStyles();
  // console.log("----------medicine list inside med component");
  // console.log(props.medList);

  const { handleChange } = props;
  const [checked, setChecked] = React.useState(false);

  const handleChangeCheckbox = (event, idx) => {
    setChecked(event.target.checked);
    handleChange(idx, "medOut", event.target.checked);
  };

  // return (props.medList || []).map((val, idx) => {
  // console.log(val);
  // console.log("index---------" + idx);
  return (
    <>
      <Typography
        gutterBottom
        color="textSecondary"
        // variant="h6"
        // color="black"
        // style={{ fontWeight: "bold" }}
      >
        Rx
      </Typography>
      <Divider />
      {props.medList.map((val, idx) => (
        <div
          className={classes.fieldSpace}
          key={val.index}
          style={{ marginBottom: "-30px" }}
        >
          <Grid
            container
            direction="row"
            // justify="space-between"
            // className={classes.medName}
            // alignItems="center"
          >
            <Grid className={classes.medName}>
              <Autocomplete
                id={idx.toString()}
                margin="dense"
                classes={{ option: classes.autocomp }}
                // autoFocus
                autoSelect
                freeSolo
                disableClearable
                options={props.medSug}
                value={val.medName}
                getOptionLabel={(option) => option}
                getOptionSelected={(option, value) =>
                  value === "" || option === value
                }
                onChange={(e, value) => {
                  props.handleChange(idx, "medName", value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Meds"
                    margin="dense"
                    InputProps={{
                      ...params.InputProps,
                      // autoComplete: "new-password",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid>
              <TextField
                style={{ width: "80px", marginRight: "5px" }}
                id={idx.toString()}
                label="Dose"
                // placeholder="Qnt"
                name="medQnt"
                value={val.medQnt}
                margin="dense"
                // type="number"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid className={classes.medSchd}>
              <Autocomplete
                id={idx.toString()}
                margin="dense"
                classes={{ option: classes.autocomp }}
                // autoFocus
                freeSolo
                // disableClearable
                options={medSchd}
                // clearOnBlur
                value={val.medSchd}
                getOptionLabel={(option) => option}
                getOptionSelected={(option, value) =>
                  value === "" || option === value
                }
                onChange={(e, value) => {
                  props.handleChange(idx, "medSchd", value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Schd"
                    margin="dense"
                    InputProps={{
                      ...params.InputProps,
                      // autoComplete: "new-password",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid className={classes.medSchdTime}>
              <Autocomplete
                id={idx.toString()}
                margin="dense"
                classes={{ option: classes.autocomp }}
                // autoFocus
                freeSolo
                // disableClearable
                // clearOnBlur
                options={medSchdTime}
                value={val.medTime}
                getOptionLabel={(option) => option}
                getOptionSelected={(option, value) =>
                  value === "" || option === value
                }
                onChange={(e, value) => {
                  props.handleChange(idx, "medTime", value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Schd Time"
                    margin="dense"
                    InputProps={{
                      ...params.InputProps,
                      // autoComplete: "new-password",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid className={classes.medCountField}>
              <TextField
                margin="dense"
                id={idx.toString()}
                data-id={idx}
                name="medCount"
                value={val.medCount}
                label="Count"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid>
              <Tooltip title="Is from outside ?">
                <Checkbox
                  checked={val.medOut}
                  onChange={(e) => handleChangeCheckbox(e, idx)}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </Tooltip>
            </Grid>
            <Grid>
              {idx === props.medList.length - 1 ? (
                <Fab
                  color="primary"
                  aria-label="add"
                  size="small"
                  onClick={() => props.add()}
                  className={classes.addBut}
                >
                  <AddIcon />
                </Fab>
              ) : (
                <Fab
                  color="inherit"
                  aria-label="add"
                  size="small"
                  onClick={() => props.delete(val.index)}
                >
                  <RemoveIcon />
                </Fab>
              )}
            </Grid>
          </Grid>
        </div>
      ))}
    </>
  );
  // });
};
export default EditVisitMedicine;
