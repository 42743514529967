import React, { Fragment, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import { useReactToPrint } from "react-to-print";
import PdfValue from "./PdfValue";

const useStyles = makeStyles((theme) => ({
  addBtn: {
    background: "#9FC088",
    marginTop: theme.spacing(2),
  },
  fieldTxt: {
    marginRight: theme.spacing(1),
  },
}));

const AddReport = (props) => {
  const { open, handleClickClose, values } = props;
  const classes = useStyles();

  // State for form fields
  const [meds, setMeds] = useState({
    medName: "",
    medType: "",
  });

  // Ref for the printable content
  const printRef = useRef();

  // This function will be used to trigger the print dialog
  const handlePrint = useReactToPrint({
    content: () => printRef.current, // Content to print
    documentTitle: "Report", // Title for the printed document
  });

  // Function to handle changes in form inputs
  const handleChangeMedProps = (e) => {
    const { name, value } = e.target;
    setMeds((prevState) => ({ ...prevState, [name]: value }));
  };

  // Function to handle form submission and printing
  const handleSubmit = () => {
    setTimeout(() => {
      handlePrint(); // Trigger print after form submission
    }, 100);
  };

  return (
    <div>
      <Fragment>
        <Dialog
          open={open}
          fullWidth={true}
          maxWidth={"md"}
          onClose={handleClickClose}
        >
          <DialogContent>
            <div style={{ padding: "20px" }}>
              <h1>Add {values}</h1>
              <form
                className={classes.root}
                noValidate
                autoComplete="off"
                onChange={handleChangeMedProps}
              >
                <TextField
                  margin="normal"
                  id="med-dia"
                  name="medName"
                  label="Name"
                  type="string"
                  className={classes.fieldTxt}
                  value={meds.medName}
                  onChange={handleChangeMedProps}
                />

                <TextField
                  margin="normal"
                  id="med-dia2"
                  name="medType"
                  style={{ width: 500 }}
                  label="Purpose"
                  type="string"
                  className={classes.fieldTxt}
                  value={meds.medType}
                  onChange={handleChangeMedProps}
                />
              </form>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleSubmit} // Trigger print after form submission
              className={classes.addBtn}
            >
              Print
            </Button>

            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleClickClose}
              className={classes.addBtn}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/* This is the part that will be printed */}
        <div
          style={{
            visibility: "hidden",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <PdfValue ref={printRef} meds={meds} values={values} />
        </div>
      </Fragment>
    </div>
  );
};

export default AddReport;
