import React, { useState, useEffect } from "react";
// import clsx from "clsx";
import PropTypes from "prop-types";
// import moment from "moment";
// import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import VisitDetailInfo from "./VisitDetailInfo";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { hostInfo } from "../../../store";
// import PrintIcon from "@material-ui/icons/Print";
import { visitFormfields } from "../../../store";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Grid, Box, Avatar, Typography } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import HtmlContentByInfo from "./PrintHtml/HtmlContentByInfo";
// import { useReactToPrint } from "react-to-print";
import PrintHtml from "./PrintHtml/PrintHtml";
// import { getInitials } from "../../../../helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    width: "100%",
    // fontSize: 2,
  },
  //   content: {
  //     padding: 0,
  //   },
  inner: {
    minWidth: "100%",
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  containerIcon: {
    display: "flex",
    alignItems: "center",
    // spacing: theme.spacing(2),
  },
  containerValue: {
    // marginLeft: theme.spacing(1),
    // align: "justify",
    wordWrap: "break-word",
  },
  avatar: {
    // marginRight: theme.spacing(2),
  },

  visitBox: {
    borderColor: "#ffc107",
  },
  actions: {
    justifyContent: "flex-end",
  },
  smallfont: {
    fontSize: 8,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  active: {
    color: "#fff",
    background:
      "radial-gradient( circle 1087px at 1.1% 107.6%, #E8C55F 0.7%, rgba(252,196,173,1) 33%, rgba(177,248,200,1) 77.9%, rgba(139,255,212,0.87) 98.1% )",
  },
  // active: {
  //   color: "#fff",
  //   backgroundColor: "#8C7B85",
  // },
}));

const VisitRow = (props) => {
  // const componentRef = useRef();
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });

  const { visit, handleClickDelete, user } = props;
  // console.log(visit);
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const dateStr = moment(visit.date_time, "YYYY-MM-DDTHH:mm:ss").format(
    "DD-MM-YYYY hh:mm A"
  );

  return (
    <>
      {false ? (
        <HtmlContentByInfo visit={visit} user={user} />
      ) : (
        <div className={classes.root} style={{ paddingRight: "10px" }}>
          <Box
            boxShadow={2}
            borderLeft={10}
            borderRadius={2}
            borderColor={"#40D0CC"}
          >
            <Accordion
              expanded={expanded === true}
              onChange={handleChange(true)}
            >
              <AccordionSummary
                style={{ padding: "0px 4px" }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Grid
                  container
                  direction="row"
                  //   justify="space-between"
                  alignItems="center"
                  // spacing={1}
                >
                  <Grid item xs>
                    <div className={classes.containerIcon}>
                      <Tooltip title={"Ref Dr: " + visit.ref_dr} interactive>
                        <Avatar
                          variant="rounded"
                          className={classes.active}
                          style={{
                            fontSize: visit.id.length > 2 ? "0.8rem" : "0.8rem", // Adjust font size based on ID length
                          }}
                        >
                          {visit.id}
                        </Avatar>
                      </Tooltip>

                      <div className={classes.containerValue}>
                        {/* <Typography color="textSecondary">{visit.id}</Typography> */}
                        <Typography
                          color="textSecondary"
                          style={{ fontSize: "12px" }}
                        >
                          {dateStr}
                        </Typography>
                      </div>
                    </div>
                  </Grid>
                  {/* <Grid item xs>
                    <div className={classes.containerIcon}>
                      <div className={classes.containerValue}>
                        <Typography color="textPrimary" align="justify">
                          {visitFormfields.notes}
                        </Typography>
                        <Typography color="textSecondary" align="justify">
                          {visit.notes ? visit.notes : "-"}
                        </Typography>
                      </div>
                    </div>
                  </Grid> */}
                  <Grid item style={{ width: "45px" }}>
                    <Typography
                      color="textPrimary"
                      align="justify"
                      style={{ fontSize: "12px" }}
                    >
                      Weight
                    </Typography>
                    <Typography
                      color="textSecondary"
                      style={{ fontSize: "12px" }}
                    >
                      {visit.weight ? visit.weight + " kg" : "-"}
                    </Typography>
                  </Grid>

                  {/* <Grid item xs>
                <div className={classes.containerIcon}>
                  <img src={patientNtIcon} width="30" height="30" alt="C" />
                  <div className={classes.containerValue}>
                    <Typography color="textPrimary" align="justify">
                      {visitFormfields.complain}
                    </Typography>
                    <Typography color="textSecondary" align="justify">
                      {visit.patient_notes ? visit.patient_notes : "-"}
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs>
                <div className={classes.containerIcon}>
                  <img src={drNtIcon} width="30" height="30" alt="C" />
                  <div className={classes.containerValue}>
                    <Typography color="textPrimary" align="justify">
                      {visitFormfields.diagnose}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      align="justify"
                      marginright={"2"}
                    >
                      {visit.dr_notes ? visit.dr_notes : "-"}
                    </Typography>
                  </div>
                </div>
              </Grid> */}
                  <Grid item xs={3} style={{ marginLeft: "10px" }}>
                    {/* <div className={classes.containerIcon} marginleft={2}>
                  <img src={weightIcon} width="30" height="30" alt="C" /> */}
                    <div className={classes.containerValue}>
                      <Typography
                        color="textPrimary"
                        align="justify"
                        style={{ fontSize: "12px" }}
                      >
                        Rs. (&#x20B9;)
                      </Typography>
                      <Typography
                        color="textSecondary"
                        style={{ fontSize: "12px" }}
                      >
                        {visit.amount ? visit.amount : "-"} /{" "}
                        {visit.paid_amount ? visit.paid_amount : "-"}
                      </Typography>
                    </div>
                    {/* </div> */}
                  </Grid>
                  {/* <Grid
                    style={{ marginTop: "15px" }}
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  > */}
                  {/* <Grid item xs>
                      <div className={classes.prescription}>
                        <Typography color="textPrimary">
                          {visitFormfields.complain}
                        </Typography>
                        <Typography
                          color="textSecondary"
                          style={{ fontSize: "12px" }}
                        >
                          {visit.patient_notes.length
                            ? JSON.parse(visit.patient_notes).join(", ")
                            : "-"}
                        </Typography>
                      </div>
                    </Grid> */}
                  {/* <Grid item xs>
                      <div className={classes.prescription}>
                        <Typography color="textPrimary">
                          {visitFormfields.diagnose}
                        </Typography>
                        <Typography
                          color="textSecondary"
                          style={{ fontSize: "12px" }}
                        >
                          {visit.dr_notes.length
                            ? JSON.parse(visit.dr_notes).join(", ")
                            : "-"}
                        </Typography>
                      </div>
                    </Grid> */}
                  {/* </Grid> */}
                  {/* <Grid item xs>
                <div className={classes.containerIcon}>
                  <img src={calenderIcon} width="30" height="30" alt="C" />
                  <div className={classes.containerValue}>
                    <h6 className="mb-0 text-dark">Date</h6>
                    <Typography color="textSecondary">
                      Sat, 23 May-2020 7:42:04 PM
                    </Typography>
                  </div>
                </div>
              </Grid> */}
                </Grid>
                <Grid item xs>
                  <PrintHtml visit={visit} classes={classes} user={user} />
                </Grid>
                {/* <div style={{ display: "none" }}>
                  <HtmlContentByInfo ref={componentRef} visit={visit} />
                </div> */}
              </AccordionSummary>

              <AccordionDetails style={{ padding: "0px 4px" }}>
                <VisitDetailInfo
                  visit={visit}
                  handleClickDelete={handleClickDelete}
                />
              </AccordionDetails>
            </Accordion>
          </Box>
        </div>
      )}
    </>
  );
};

const VisitList = (props) => {
  const classes = useStyles();
  const { patient_id, user } = props;

  const [visitlist, setVisitlist] = useState([]);
  // console.log(visitlist);
  const getToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.authToken;
  };

  useEffect(() => {
    let url = hostInfo + "/getpatientvisit?patient_id=" + patient_id;
    fetch(url, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res != null) {
          // console.log(res);
          setVisitlist(res);
        }
      });
  }, [patient_id]);

  const handleClickDelete = (visit_id) => {
    let url = hostInfo + "/deleteVisit?visit_id=" + visit_id;
    fetch(url, {
      method: "POST",
      // mode: "no-cors",
      headers: {
        Accept: "*/*",
        "Content-type": "application/json; charset=UTF-8",

        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then(() => {
        if (visitlist != null) {
          setVisitlist(visitlist.filter((visit) => visit.id !== visit_id));
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  return (
    <div className={classes.root}>
      {visitlist
        .sort((a, b) => (a.id > b.id ? 1 : -1))
        .reverse()
        .map((visit, idx) => (
          <VisitRow
            user={user}
            key={idx}
            visit={visit}
            idx={idx}
            handleClickDelete={handleClickDelete}
          />
        ))}
    </div>
  );
};

VisitList.propTypes = {
  className: PropTypes.string,
};

export default VisitList;
