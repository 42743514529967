import React, { Fragment, createRef } from "react";
import {
  Container,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
import { withStyles } from "@material-ui/styles";
import {
  Button,
  Divider,
  Checkbox,
  Grid,
  Typography,
  InputAdornment,
  Modal,
  Popper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { complaintsSuggestions, hostInfo } from "../../../store";
// import FuncComp from "./testFunc";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SaveIcon from "@mui/icons-material/Save";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddKwDia from "./AddKwDia";
import ClearIcon from "@mui/icons-material/Clear";
import FormMedicine from "./components/Medicine";
import FormReport from "./components/Report";
// import RemoveIcon from "@material-ui/icons/Remove";
import { Country, State, City } from "country-state-city";
import FormGroup from "@material-ui/core/FormGroup";
import { visitFormfields } from "../../../store";

//import { countryArr } from "../../../../store";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useRef } from "react";
import { Search } from "@material-ui/icons";
import Diagnosis from "./Diagnosis";
import Reports from "./Reports";
import Advice from "./Advice";
import Payment from "./Payment";
// import PrintHtml from "./PrintHtml/PrintHtml";
// import HtmlContentByInfo from "./PrintHtml/HtmlContentByInfo";

const styles = (theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      // width: 230,
    },
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  saveBtn: {
    background: "#94B49F",
    borderRadius: 3,
    border: 0,
    color: "white",
    // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    "&:hover": {
      background: "#C6A3D4",
    },
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  formControl: {
    // margin: theme.spacing(1),
    width: 230,
  },
  // countryControl: {
  //   // margin: theme.spacing(1),
  //   minWidth: 150,
  // },
  countryControl: {
    marginRight: theme.spacing(1),
    minWidth: 170,
  },
  insurance: {
    // width: 230,
    marginTop: theme.spacing(2),
  },
  bloodGroupFld: {
    minWidth: 150,
  },
  seprator: {
    margin: theme.spacing(2),
  },
  seprator1: {
    marginTop: theme.spacing(2),
  },
  refCls: {
    color: "#D46D91",
    fontWeight: "bold",
    marginTop: theme.spacing(2),
  },
  paymentCls: {
    color: "green",
    fontWeight: "bold",
    marginTop: theme.spacing(2),
  },
  seprator2: {
    width: "100%",
    ...theme.typography.body2,
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  multilineFld: {
    display: "flex",
    flexWrap: "wrap",
  },
  amountGreen: {
    borderColor: "pink",
  },
  specialOutline: {
    borderColor: "pink",
    //borderWidth: 4
  },
  presc: {
    width: "100%",
  },
  autocomp: {
    '&[data-focus="true"], &:hover': {
      backgroundColor: "#CFE8A9",
    },
  },
  cardClr: {
    background: "#F3F8F8",
  },
  freeTextNotes: {
    width: "94%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

// var countryStateCity = require("country-state-city").default;

export default withStyles(styles)(
  class AddNewVisitForm extends React.Component {
    containerRef = createRef();
    // constructor(props) {
    //   super(props);
    //   this.inputRef = React.createRef();
    // }

    state = {
      visit: {},
      isSubmit: false,
      refMark: false,
      refDisabled: true,
      refGrpDisabled: true,
      open: false,
      openDia: false,
      panelExpand: false,
      userList: [],
      drList: [],
      symSug: [],
      filteredSymSug: [],
      formdetail: {
        userId: "",
        drId: "",
        bloodGroup: "",
        insurance: false,
        weight: 0,
        height: 0,
        bp: "",
        pulse: 0,
        spo: 0,
        rr: "",
        temperature: 0,
        age: 0,
        ptNotes: [],
        drNotes: [],
        notes: "",
        diaNotes: "",
        amount: 0,
        amountPaid: 0,
        drRef: null,
        drGrp: null,
        drRefInfo: null,
        medList: [
          {
            index: Math.random(),
            // medType: "",
            medName: "",
            medQnt: 1,
            medSchd: "",
            medTime: "After Eat",
            medCount: 0,
            medOut: false,
          },
        ],
        repList: [],

        userIdError: false,
        drIdError: false,
        bloodGroupError: false,
        insuranceError: false,
        weightError: false,
        heightError: false,
        ageError: false,
        bpError: false,
        spoError: false,
        temperatureError: false,
        pulseError: false,
        userIdErrorTxt: "",
        drIdErrorTxt: "",
        bloodGroupErrorTxt: "",
        insuranceErrorTxt: "",
        weightErrorTxt: "",
        bpErrorTxt: "",
        spoErrorTxt: "",
        temperatureErrorTxt: "",
        pulseErrorTxt: "",
        heightErrorTxt: "",
        ageErrorTxt: "",

        amountClass: "",
      },
      search: "",
      dialogOpen: false,
      BilldialogOpen: false,
      templateName: "",
      isSuggestionOpen: 1,
      isfrequencySuggestionOpen: 1,
      isseveritySuggestionOpen: 1,
      openSuggestionIndex: false,
      templates: [],
      isListVisible: false,
      isTemplateClicked: false,
      isSaveTemplateClicked: false,
      isLoadPreviousClicked: false,
      durationSuggestions: [
        { label: "__day" },
        { label: "__week" },

        { label: "__month" },
        { label: "__year" },
        // { label: "monthly" },
        // { label: "Loose Motion" },
        // { label: "Abdominal Pain" },
        // { label: "Nasal Blockage" },
      ],

      frequencySuggestions: [
        { label: "daily" },
        { label: "alternate day" },

        { label: "weekly" },
        { label: "twice weekly" },
        { label: "monthly" },
        // { label: "Loose Motion" },
        // { label: "Abdominal Pain" },
        // { label: "Nasal Blockage" },
      ],

      severitySuggestions: [
        { label: "mild" },
        { label: "moderate" },

        { label: "severe" },
        { label: "profound" },
        // { label: "monthly" },
        // { label: "Loose Motion" },
        // { label: "Abdominal Pain" },
        // { label: "Nasal Blockage" },
      ],
      // rows: [
      //   {
      //     complaint: "",
      //     frequency: "",
      //     severity: "",
      //     duration: "",
      //     date: new Date().toISOString().split("T")[0],
      //     inputNumber: "",
      //   },
      //   // Add more rows if necessary
      // ],
      rows: [
        {
          "": ["", "", "", new Date().toISOString().split("T")[0]],
        },
      ],
      payment: [
        {
          name: "total",
          price: "",
          discount: "",
          total: "",
        },
        // Add more rows if necessary
      ],
      // diagnosis: [
      //   {
      //     diagnosis: "",
      //     duration: "",
      //     date: new Date().toISOString().split("T")[0], // Default to current date
      //     inputNumber: "",
      //   },
      // ],
      diagnosis: [
        {
          "": ["", new Date().toISOString().split("T")[0]],
        },
      ],

      frequencyRefs: [],
      severityRefs: [],
      durationRefs: [],
      selectedComplaint: null,
      openModal: false,
      modalRowIndex: null,
      complaintDetails: {
        frequency: "",
        severity: "",
        duration: "",
        date: "",
      },
    };

    countryArr = Country.getAllCountries();
    stateArr = [];
    cityArr = [];
    selectedCountry = "";
    selectedState = "";
    selectedCity = "";
    stateDisabled = true;
    cityDisabled = true;
    // frequencyRefs = [];
    // severityRefs = [];
    // durationRefs = [];
    medSug = [];
    paymentSug = [];
    repSug = [];
    drSug = [];
    // symSug = [];
    disSug = [];
    drGroups = [];
    isUserIdPresentInArray(myArray, id) {
      for (var i = 0; i < myArray.length; i++) {
        if (myArray[i].id === id) {
          // console.log("User id present in list-----------");
          return true;
        }
      }
      // console.log("User id not present in list");
      return false;
    }

    isDrIdPresentInArray(myArray, id) {
      for (var i = 0; i < myArray.length; i++) {
        if (myArray[i].id === id) {
          // console.log("Doctor id is present in list--------");
          return true;
        }
      }
      // console.log("Doctor id not present in list");
      return false;
    }

    validate = () => {
      let isError = false;
      const { formdetail } = this.state;

      // console.log(this.state);

      // Clean old error status
      formdetail.userIdError = false;
      formdetail.drIdError = false;

      formdetail.userIdErrorTxt = "";
      formdetail.drIdErrorTxt = "";

      // console.log()

      // User id Validation----    Future Use...
      // 1. Length
      // 2. valid id number
      // if (
      //   !this.isUserIdPresentInArray(this.state.userList, formdetail.userId)
      // ) {
      //   isError = true;
      //   formdetail.userIdError = true;
      //   formdetail.userIdErrorTxt =
      //     "Invalid user. Please select proper username";
      // }

      // console.log(
      //   "userid: " +
      //     formdetail.userId +
      //     " and length: " +
      //     formdetail.userId.length
      // );

      // Doctor id Validation----  Future use....
      // 1. Length
      // 2. special character check
      // if (!this.isDrIdPresentInArray(this.state.drList, formdetail.drId)) {
      //   isError = true;
      //   formdetail.drIdError = true;
      //   formdetail.drIdErrorTxt = "Invalid doctor. Please select proper doctor";
      // }
      // console.log(
      //   "drid: " + formdetail.drId + " and length: " + formdetail.drId.length
      // );

      // this.setState({
      //   ...this.state.formdetail,
      // });

      return isError;
    };

    handleSubmit = (e) => {
      const getToken = () => {
        const cookieString = document.cookie;
        const cookies = cookieString.split("; ").reduce((acc, cookie) => {
          const [name, value] = cookie.split("=");
          acc[name] = value;
          return acc;
        }, {});
        return cookies.authToken;
      };

      e.preventDefault();
      // const err = this.validate();

      // if (err) {
      //   console.log("Get error in submit validation...");
      //   return;
      // }
      // On submit form success
      let { formdetail } = this.state;

      // console.log("In Submit ----");
      // console.log(formdetail);
      let medList = formdetail.medList.filter(
        (x, idx) => x.medName !== "" || idx === 0
      );
      let repList = formdetail.repList;

      let formObj = {
        user_id: parseInt(this.props.patient_id),
        opd_id: parseInt(this.props.opdId),
        // refDr: formdetail.drId, // Correct as per ref Dr :TODO
        //  bloodGroup: "",
        mediclaim: formdetail.insurance ? 1 : 0,
        disease: "none",
        weight: formdetail.weight,
        bp: formdetail.bp,
        rr: formdetail.rr,
        spo: formdetail.spo,
        pulse: formdetail.pulse,
        temperature: formdetail.temperature,
        height: formdetail.height,
        age: formdetail.age,
        meds: JSON.stringify(medList),
        reps: JSON.stringify(repList),
        complaints: this.state.rows.slice(0, -1),
        diagnosis: this.state.diagnosis.slice(0, -1),
        payment: [],
        amount: formdetail.amount,
        paid_amount: formdetail.amountPaid,
        pt_notes: JSON.stringify(formdetail.ptNotes),
        dr_notes: JSON.stringify(formdetail.drNotes),
        notes: formdetail.diaNotes,
        dia_notes: formdetail.notes,
        ref_dr:
          this.state.refMark && formdetail.drRef && formdetail.drRef.length
            ? formdetail.drRef
            : "-",
        ref_grp:
          this.state.refMark && formdetail.drGrp && formdetail.drGrp.length
            ? formdetail.drGrp
            : "-",
        ref_info:
          this.state.refMark &&
          formdetail.drRef &&
          // formdetail.drGrp &&
          formdetail.drRef.length
            ? formdetail.drRefInfo
            : "-",
        is_ref: this.state.refMark ? 1 : 0,
      };

      // console.log("Final from object...");
      this.setState({
        ...this.state,
        isSubmit: true,
        visit: formObj,
      });
      // console.log(formObj);
      let url = hostInfo + "/addcasevisit";
      // console.log(url);
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(formObj),
      })
        .then((response) => {
          if (response.ok) {
            // If the response status is 200, reload the page
            // console.log("Visit added successfully!");
            window.location.reload();
          } else {
            // If the response status is not 200, log the error
            return response.json().then((errorData) => {
              console.error("Error in API:", errorData);
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });

      // .then((response) => response.json())
      // .then((data) => {
      //   console.log("Success:", data);
      // })
      // .catch((error) => {
      //   console.error("Error:", error);
      // });

      // console.log(this.state);
      // console.log(this.state.visit);
      this.handleClose();
      // window.location.reload();
      return true;

      // //Error in Submit..............
      // console.log("Validation failed, Error in Submit--------");
      // return false;
    };
    // handleComplaintChange = (index, newValue) => {
    //   console.log(newValue);
    //   const updatedRows = [...this.state.rows];

    //   // Update the complaint field
    //   updatedRows[index].complaint = newValue ? newValue.label : "";

    //   // Here, you can set default values for frequency, severity, and duration if needed
    //   // if (newValue && newValue.label) {
    //   //   updatedRows[index].frequency = frequencySuggestions[0].label; // Example to set frequency
    //   //   updatedRows[index].severity = severitySuggestions[0].label; // Example to set severity
    //   //   updatedRows[index].duration = durationSuggestions[0].label; // Example to set duration
    //   // } else {
    //   //   // Optionally clear the other fields if the selected complaint is not "Fever"
    //   //   updatedRows[index].frequency = "";
    //   //   updatedRows[index].severity = "";
    //   //   updatedRows[index].duration = "";
    //   // }

    //   this.setState({ rows: updatedRows });

    //   // Open modal if a new value is selected
    //   if (newValue) {
    //     this.setState({
    //       selectedComplaint: newValue.label,
    //       modalRowIndex: index,
    //       openModal: true,
    //     });
    //   }
    // };

    // handleClickOutside = (event) => {
    //   if (
    //     this.inputRef.current &&
    //     !this.inputRef.current.contains(event.target)
    //   ) {
    //     this.setState({
    //       isfrequencySuggestionOpen: -1,
    //       isSuggestionOpen: -1,
    //       isseveritySuggestionOpen: -1,
    //     });
    //   }
    // };

    handleLoadPrevious = async () => {
      this.setState({ isLoadPreviousClicked: true });

      const getToken = () => {
        const cookieString = document.cookie;
        const cookies = cookieString.split("; ").reduce((acc, cookie) => {
          const [name, value] = cookie.split("=");
          acc[name] = value;
          return acc;
        }, {});
        return cookies.authToken;
      };

      try {
        const response = await fetch(
          `${hostInfo}/complaints/${this.props.patient_id}`,
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to load previous complaint");
        }

        const data = await response.json();

        const newRows = data.flatMap((item) => {
          // Check if the item is an object or array and handle accordingly
          if (Array.isArray(item)) {
            return item; // If it's an array, spread the items
          } else {
            return [item]; // Wrap object in array for consistency
          }
        });

        // Merge existing rows (excluding the last one) with the new rows
        const updatedRows = [
          ...this.state.rows.slice(0, -1), // Existing rows (excluding last one)
          ...newRows,
          { "": ["", "", "", ""] },
        ];

        // Update the state with new rows and reset suggestion state
        this.setState({
          rows: updatedRows, // Update rows with the new data
          isSuggestionOpen: -1, // Close duration suggestions
          isfrequencySuggestionOpen: -1, // Close frequency suggestions
          isseveritySuggestionOpen: -1, // Close severity suggestions
        });
      } catch (error) {
        console.error("Error loading previous complaint:", error);
      }
    };

    // handleComplaintChange = (index, value) => {
    //   const newRows = [...this.state.rows];

    //   // Set the complaint value
    //   newRows[index].complaint = value;

    //   // Check if the complaint has a value and if it's the first time adding a row
    //   if (value && value.trim() !== "" && index === newRows.length - 1) {
    //     // If the current index is the last row and there's a value, add a new empty object to the rows
    //     newRows.push({
    //       complaint: "",
    //       frequency: "",
    //       severity: "",
    //       duration: "",
    //       date: "",
    //       inputNumber: "",
    //     });
    //   }

    //   // Update state with the new rows
    //   this.setState({ rows: newRows });
    //   this.setState({ isSuggestionOpen: index });
    //   this.setState({ isfrequencySuggestionOpen: index });
    //   this.setState({ isseveritySuggestionOpen: index });
    // };

    // handleFrequencyChange(index, newValue) {
    //   const updatedRows = [...this.state.rows];
    //   updatedRows[index].frequency = newValue ? newValue : "";
    //   this.setState({ rows: updatedRows });
    // }
    // handleSeverityChange = (index, newValue) => {
    //   const updatedRows = [...this.state.rows];
    //   updatedRows[index].severity = newValue ? newValue : "";
    //   this.setState({ rows: updatedRows });
    // };

    // handleNumberInput(index, inputValue) {
    //   const rows = [...this.state.rows];
    //   rows[index].inputNumber = inputValue; // Store the number in the row
    //   this.setState({ rows });
    // }
    // handleDurationChange(index, inputNumber) {
    //   // Clone current rows state
    //   const rows = [...this.state.rows];

    //   // Update the duration for the specific index
    //   rows[index].duration = inputNumber ? `${inputNumber}` : ""; // If inputNumber is empty, reset

    //   if (inputNumber) {
    //     const parts = inputNumber.split(" ");
    //     const durationValue = parseInt(parts[0], 10); // Extract the number from input
    //     const durationUnit = parts[1] ? parts[1].toLowerCase() : ""; // Extract the unit (day/week/etc.)

    //     if (!isNaN(durationValue)) {
    //       const baseDate = new Date(); // Get the current date
    //       console.log("Base Date:", baseDate);

    //       // Adjust the base date according to the duration and unit
    //       if (durationUnit === "day" || durationUnit === "days") {
    //         baseDate.setDate(baseDate.getDate() - durationValue); // Subtract the number of days
    //       } else if (durationUnit === "week" || durationUnit === "weeks") {
    //         baseDate.setDate(baseDate.getDate() - durationValue * 7); // Subtract weeks as days
    //       } else if (durationUnit === "month" || durationUnit === "months") {
    //         baseDate.setMonth(baseDate.getMonth() - durationValue); // Subtract the number of months
    //       } else if (durationUnit === "year" || durationUnit === "years") {
    //         baseDate.setFullYear(baseDate.getFullYear() - durationValue); // Subtract the number of years
    //       }

    //       // Set the updated date in the format 'YYYY-MM-DD'
    //       rows[index].date = baseDate.toISOString().split("T")[0];
    //       console.log("Updated Date:", rows[index].date);
    //     } else {
    //       // If durationValue is not a number, clear the date
    //       rows[index].date = "";
    //     }
    //   } else {
    //     // If no inputNumber provided, clear the date
    //     rows[index].date = "";
    //   }

    //   // Update the state with the modified rows array
    //   this.setState({ rows }, () => {
    //     console.log("Updated State Rows:", this.state.rows);
    //   });
    // }
    // handleDateChange = (index, dateValue) => {
    //   const newRows = [...this.state.rows];
    //   newRows[index].date = dateValue; // Update the specific row's date
    //   this.setState({ rows: newRows }); // Update the state with the new rows
    // };

    handleComplaintChange = (index, value) => {
      const newRows = [...this.state.rows];
      const complaintKey = value ? value : ""; // Get the complaint key from the input

      // Get the existing row for the index or create a new object if it doesn't exist
      const existingRow = newRows[index] || {};

      // Get the current values for the complaint key or default to an empty array
      const complaintValues = existingRow[complaintKey] ||
        existingRow[Object.keys(existingRow)[0]] || ["", "", "", ""];

      // Create a new object with only the new complaint key and its values
      const updatedRow = {
        [complaintKey]: complaintValues, // Update the row with the new complaint key and its values
      };

      // Update the row at the specified index
      newRows[index] = updatedRow;

      // If the current index is the last row, add a new empty row
      if (index === newRows.length - 1) {
        newRows.push({ "": ["", "", "", ""] }); // Add an empty row for future input
      }

      // Update the state with the modified rows
      this.setState({ rows: newRows });

      // Open the suggestion box only if the complaint values are empty
      if (!newRows[index][complaintKey].slice(0, 3).some((v) => v !== "")) {
        this.setState({
          isfrequencySuggestionOpen: index,
          isSuggestionOpen: index,
          isseveritySuggestionOpen: index,
        });
      } else {
        // Close the suggestion boxes if values exist
        this.setState({
          isfrequencySuggestionOpen: -1,
          isSuggestionOpen: -1,
          isseveritySuggestionOpen: -1,
        });
      }
    };

    // Function to handle frequency change
    handleFrequencyChange = (index, newValue) => {
      const updatedRows = [...this.state.rows];
      const key = Object.keys(updatedRows[index])[0]; // Get the complaint key
      updatedRows[index][key][0] = newValue ? newValue : ""; // Set severity

      this.setState({ rows: updatedRows });
    };

    // Function to handle severity change
    handleSeverityChange = (index, newValue) => {
      const updatedRows = [...this.state.rows];
      const key = Object.keys(updatedRows[index])[0]; // Get the complaint key
      updatedRows[index][key][1] = newValue ? newValue : ""; // Set severity

      this.setState({ rows: updatedRows });
    };

    // Function to handle duration change and calculate the date
    handleDurationChange = (index, inputNumber) => {
      const rows = [...this.state.rows];
      const key = Object.keys(rows[index])[0]; // Get the complaint key

      rows[index][key][2] = inputNumber ? `${inputNumber}` : ""; // Set duration

      if (inputNumber) {
        const parts = inputNumber.split(" ");
        const durationValue = parseInt(parts[0], 10);
        const durationUnit = parts[1] ? parts[1].toLowerCase() : "";

        if (!isNaN(durationValue)) {
          const baseDate = new Date();

          if (durationUnit === "day" || durationUnit === "days") {
            baseDate.setDate(baseDate.getDate() - durationValue);
          } else if (durationUnit === "week" || durationUnit === "weeks") {
            baseDate.setDate(baseDate.getDate() - durationValue * 7);
          } else if (durationUnit === "month" || durationUnit === "months") {
            baseDate.setMonth(baseDate.getMonth() - durationValue);
          } else if (durationUnit === "year" || durationUnit === "years") {
            baseDate.setFullYear(baseDate.getFullYear() - durationValue);
          }

          rows[index][key][3] = baseDate.toISOString().split("T")[0]; // Set calculated date
        } else {
          rows[index][key][3] = ""; // Clear date if invalid
        }
      } else {
        rows[index][key][3] = ""; // Clear date if no duration provided
      }

      this.setState({ rows });
    };

    // Function to handle date change
    handleDateChange = (index, dateValue) => {
      const newRows = [...this.state.rows];
      const key = Object.keys(newRows[index])[0]; // Get the complaint key

      newRows[index][key][3] = dateValue; // Update date field

      this.setState({ rows: newRows });
    };

    // Function to convert data to requested format
    convertToNewFormat = () => {
      return this.state.rows;
    };

    handleDeleteComplaint = (index) => {
      this.setState((prevState) => {
        const newRows = prevState.rows.filter((_, i) => i !== index);

        // If no rows are left, insert a default empty row
        const updatedRows =
          newRows.length === 0
            ? [
                {
                  "": ["", "", "", new Date().toISOString().split("T")[0]],
                },
              ]
            : newRows;

        return {
          rows: updatedRows, // Update the rows state with the filtered array
        };
      });
    };

    handleDialogOpen = () => {
      this.setState({
        isfrequencySuggestionOpen: -1,
        isseveritySuggestionOpen: -1,
        isSuggestionOpen: -1,
      });

      this.setState({ isSaveTemplateClicked: true });
      this.setState({ dialogOpen: true });
    };

    handleDialogClose = () => {
      this.setState({ isSaveTemplateClicked: false });
      this.setState({ dialogOpen: false });
    };

    handleBillDialogOpen = () => {
      this.setState({ BilldialogOpen: true });
    };

    handleBillDialogClose = () => {
      this.setState({ BilldialogOpen: false });
    };

    handleSaveTemplate = () => {
      const getToken = () => {
        const cookieString = document.cookie;
        const cookies = cookieString.split("; ").reduce((acc, cookie) => {
          const [name, value] = cookie.split("=");
          acc[name] = value;
          return acc;
        }, {});
        return cookies.authToken;
      };

      const { templateName, rows } = this.state;

      // Filter rows to remove empty keys and values
      const filteredRows = rows.slice(0, -1).filter(
        (row) =>
          // {
          Object.keys(row)[0] // Get the key for the row
        // const values = row[key]; // Get the values for that key

        // Log the key inside the filter method to access it
        // console.log("Key:", key);

        // return (
        //   key &&
        //   key.trim() !== "" &&
        //   Array.isArray(values) &&
        //   values.every((value) => value && value.trim() !== "")
        // );
        // }
      );

      // Log filteredRows after filtering
      console.log("Filtered Rows:", filteredRows);

      if (filteredRows.length > 0 && templateName) {
        const savedData = { name: templateName, json_data: filteredRows }; // Use filtered rows for saving

        fetch(hostInfo + "/addTemplate", {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(savedData),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("API Response:", data);
            if (data.message) {
              alert("Template saved successfully");
            } else {
              alert("Error saving template");
            }

            this.setState((prevState) => ({
              templates: [...(prevState.templates || []), savedData],
              templateName: "", // Reset template name
            }));
          })
          .catch((error) => {
            console.error("Error:", error);
            alert("Failed to save template");
          });

        this.handleDialogClose();
      } else {
        alert("Please enter valid template data and name.");
        this.handleDialogClose();
        this.setState({ templateName: "" });
      }
    };

    toggleTemplateList = () => {
      this.setState((prevState) => ({
        isListVisible: !prevState.isListVisible, // Toggle the visibility state
      }));
      this.setState({ isTemplateClicked: !this.state.isTemplateClicked });
      this.setState({
        isfrequencySuggestionOpen: -1,
        isseveritySuggestionOpen: -1,
        isSuggestionOpen: -1,
      });
    };
    handleTemplateSelect = (template) => {
      this.setState(
        (prevState) => ({
          rows: [
            ...prevState.rows.slice(0, -1), // Remove the last empty row
            ...template.json_data, // Add the template's rows
            { "": ["", "", "", ""] }, // Add an empty row at the end
          ],
        })
        // () => {
        //   this.props.onRowsChange(this.state.rows); // Ensure rows are updated in parent component
        // }
      );

      // Close suggestions and toggle the list visibility
      this.setState((prevState) => ({
        isListVisible: !prevState.isListVisible,
        isTemplateClicked: false,
        openSuggestionIndex: false,
      }));
    };

    handleSearchChange = (event) => {
      this.setState({ search: event.target.value }); // Update search term
    };

    // handleClickOutside = (event) => {
    //   if (
    //     this.containerRef.current &&
    //     !this.containerRef.current.contains(event.target)
    //   ) {
    //     this.setState({ isListVisible: false, isTemplateClicked: false });
    //   }
    // };

    componentDidMount() {
      document.addEventListener("mousedown", this.handleClickOutside);
      document.addEventListener("keydown", this.handleKeyNavigation);
      // document.addEventListener("mousedown", this.handleClickOutside);
      const getToken = () => {
        const cookieString = document.cookie;
        const cookies = cookieString.split("; ").reduce((acc, cookie) => {
          const [name, value] = cookie.split("=");
          acc[name] = value;
          return acc;
        }, {});
        return cookies.authToken;
      };

      // console.log("Component DID MOUNT!");
      let url = hostInfo + "/getmeds";
      fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Data in meds ... ");
          // console.log(data);
          if (data != null) this.medSug = data;
        });

      url = hostInfo + "/getpayment";
      fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Data in meds ... ");
          // console.log(data);
          if (data != null) this.paymentSug = data;
        });

      // repSug -------------------
      url = hostInfo + "/getreps";
      fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Data in reps ... ");
          // console.log(data);
          if (data != null) this.repSug = data;
        });

      // drSug ------------------
      url = hostInfo + "/getdrs";
      fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Data in drs ... ");
          // console.log(data);
          if (data != null) this.drSug = data;
        });

      // disSug ------------------
      url = hostInfo + "/getdis";
      fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Data in dis ... ");
          // console.log(data);
          if (data != null) this.disSug = data;
        });

      // symSug ------------------
      url = hostInfo + "/getsymt";
      fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data != null) {
            // Correctly update the state
            this.setState({ symSug: data, filteredSymSug: data });
          }
        });

      // drGroup ------------------
      url = hostInfo + "/getdrgroups";
      fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Data in sym ... ");
          // console.log(data);
          if (data != null) this.drGroups = data;
        });
      // this.fetchTemplates = () => {
      fetch(hostInfo + "/gettemplates", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((response) => {
          // Return the JSON response
          return response.json(); // Added return statement here
        })
        .then((data) => {
          this.setState({ templates: data }); // Update state with fetched data
          // console.log("Fetched Templates:", data); // Log fetched data
        })
        .catch((error) => {
          console.error("Error fetching templates:", error); // Log any errors
        });

      // };
    }
    componentWillUnmount() {
      document.removeEventListener("mousedown", this.handleClickOutside);
      document.removeEventListener("keydown", this.handleKeyNavigation);
    }
    handleClickOutside = (event) => {
      // console.log("Clicked outside!");
      if (
        this.containerRef.current &&
        !this.containerRef.current.contains(event.target)
      ) {
        this.setState({ isListVisible: false, isTemplateClicked: false });
      }
    };

    handleKeyNavigation = (event) => {
      const { templates, highlightedIndex, isListVisible } = this.state;

      // Only trigger if the suggestion list is visible
      if (!isListVisible) return;

      // Prevent default scrolling behavior when navigating suggestions
      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        event.preventDefault();
      }

      // Navigate down (ArrowDown)
      if (event.key === "ArrowDown") {
        this.setState((prevState) => ({
          highlightedIndex: Math.min(
            prevState.highlightedIndex + 1,
            templates.length - 1
          ),
        }));
      }

      // Navigate up (ArrowUp)
      if (event.key === "ArrowUp") {
        this.setState((prevState) => ({
          highlightedIndex: Math.max(prevState.highlightedIndex - 1, 0),
        }));
      }

      // Select the highlighted suggestion (Enter key)
      if (event.key === "Enter" && highlightedIndex >= 0) {
        this.handleTemplateSelect(
          this.props.idx,
          "repName",
          templates[highlightedIndex]
        );
      }
    };
    // componentWillUnmount() {
    //   // Remove event listener when component unmounts
    //   document.removeEventListener("mousedown", this.handleClickOutside);
    // }

    appendNewMeds = (name) => {
      // console.log("Appending Meds ...: ", name);
      this.medSug.push(name);
    };
    appendNewPayment = (name) => {
      // console.log("Appending Meds ...: ", name);
      this.paymentSug.push(name);
    };
    appendNewReps = (name) => {
      // console.log("Appending Reps ...: ", name);
      this.repSug.push(name);
    };
    appendNewDis = (name) => {
      // console.log("Appending Dis ...: ", name);
      this.disSug.push(name);
    };
    appendNewSymt = (name) => {
      // console.log("Appending Symt ...: ", name);
      this.symSug.push(name);
    };
    appendNewDrs = (name) => {
      // console.log("Appending Drs ...: ", name);
      this.drSug.push(name);
    };

    handleClose = () => {
      this.setState({ open: false });
    };

    handleDiaClickOpen = () => {
      this.setState({ openDia: true });
    };

    handleDiaClickClose = () => {
      this.setState({ openDia: false });
    };

    handleChangeCheckbox = (event) => {
      // console.log(event.target.checked);
      if (event.target.checked === true) {
        this.setState({ refGrpDisabled: false, refMark: true });
      } else {
        this.setState({
          refGrpDisabled: true,
          refMark: false,
        });
        let formdetail = { ...this.state.formdetail };

        formdetail.drGrp = null;
        formdetail.drRef = null;
        formdetail.drRefInfo = null;
        this.setState({ formdetail });
        // console.log(formdetail);
      }
      // console.log(this.state);
      // setChecked(event.target.checked);
      // handleChange(idx, "medOut", event.target.checked);
    };

    handleOnChange = (e, name) => {
      const value = e.target.value;
      // console.log("Value change --- ");
      // console.log(e.target);
      // console.log(name);
      // console.log(value);
      this.setState({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          [name]: value,
        },
      });
    };

    handleMultiFld = (name, value) => {
      // console.log("------ Inside Multifiled handler ------");
      // console.log(name);
      // console.log(value);
      this.setState({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          [name]: value,
        },
      });
    };

    handleMedChange = (id, name, value) => {
      // console.log("Inside handleMed change---");
      // console.log(id);
      // console.log(name);
      // console.log(value);

      let medList = [...this.state.formdetail.medList];
      medList[id][name] = value;

      this.setState({ medList });
    };

    // handleRepChange = (id, name, value) => {
    //   console.log(value);

    //   // Create a copy of the existing repList from the state
    //   let repList = [...this.state.formdetail.repList];

    //   // Ensure the object at the given index exists, initialize if necessary
    //   if (!repList[id]) {
    //     repList[id] = { index: Math.random(), repName: [] }; // Ensure repName is an array
    //   }

    //   // If repName is being updated, replace the existing values with new ones
    //   if (name === "repName") {
    //     repList[id][name] = [
    //       ...this.state.formdetail.repList[0].repName,
    //       ...value,
    //     ]; // Directly set the new values, replacing existing ones
    //   }

    //   // Update the state with the modified repList
    //   this.setState((prevState) => ({
    //     formdetail: {
    //       ...prevState.formdetail,
    //       repList, // Update the repList inside formdetail
    //     },
    //   }));

    //   console.log(repList); // Log the updated repList
    // };
    // handleRepChange = (id, name, value) => {
    //   // Copy the existing repList array from the state
    //   let repList = [...this.state.formdetail.repList];

    //   // Ensure that the object at the provided id exists; if not, initialize it
    //   if (!repList[id]) {
    //     repList[id] = {};
    //   }

    //   // Update the specific field of the object (e.g., repName, index, etc.)
    //   repList[id][name] = value;

    //   // Set the updated repList back into the state
    //   this.setState({ formdetail: { ...this.state.formdetail, repList } });
    // };
    handleRepChange = (newValue) => {
      console.log(newValue);
      // Update the repList in the form detail with new values
      this.setState((prevState) => ({
        formdetail: {
          ...prevState.formdetail,
          repList: newValue, // Set repList directly to newValue from Autocomplete
        },
      }));
    };

    handleDrGrpChange = (e, value) => {
      // console.log("Inside handle dr Group change change---");
      // console.log(id);
      // console.log(e.target);
      // console.log(value);
      let formdetail = { ...this.state.formdetail };
      formdetail.drGrp = value && value.name;
      formdetail.drRef = null;
      formdetail.drRefInfo = null;
      this.setState({ formdetail });
      // this.setState({
      //   ...this.state,
      //   formdetail: {
      //     ...this.state.formdetail,
      //     drRef: null,
      //   },
      // });
      const getToken = () => {
        const cookieString = document.cookie;
        const cookies = cookieString.split("; ").reduce((acc, cookie) => {
          const [name, value] = cookie.split("=");
          acc[name] = value;
          return acc;
        }, {});
        return cookies.authToken;
      };

      if (value.id > 0) {
        this.setState({ refDisabled: false });
        // drGroup ------------------
        let url = hostInfo + "/getdrdetails?group_id=" + value.id;
        fetch(url, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            // console.log("Data dr details from db ... ");
            // console.log(data);

            if (data != null) this.setState({ drList: data });
            else this.setState({ drList: [] });
          });
      } else {
        this.setState({ refDisabled: true });
      }
      // this.setState({
      //   ...this.state,
      //   formdetail: {
      //     ...this.state.formdetail,
      //     [name]: value,
      //   },
      // });
    };

    handleDrChange = (e, name, value) => {
      // console.log("Inside handle dr change change------");
      // console.log(e.target);
      // console.log(name);
      // console.log(value.name);

      let formdetail = { ...this.state.formdetail };
      formdetail.drRef = value && value.name;
      formdetail.drRefInfo = value.description + " (" + value.mobile + ")";
      this.setState({ formdetail });

      // this.setState({
      //   ...this.state,
      //   formdetail: {
      //     ...this.state.formdetail,
      //     [name]: value.na,
      //   },
      // });
      // console.log(this.state);
    };

    handleInsurance = (name, value) => {
      // console.log(name);
      // console.log(value.target.checked);

      this.setState({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          [name]: value.target.checked,
        },
      });
    };

    // Mitesh: TODO make index incremental
    addMedRow = (e) => {
      this.setState((prevState) => ({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          medList: [
            ...prevState.formdetail.medList,
            {
              index: Math.random(),
              medName: "",
              // medType: "",
              medQnt: 1,
              medSchd: "",
              medTime: "After Eat",
              medCount: 0,
              medOut: false,
            },
          ],
        },
      }));
    };

    // addRepRow = (e) => {
    //   this.setState((prevState) => ({
    //     ...this.state,
    //     formdetail: {
    //       ...this.state.formdetail,
    //       repList: [
    //         ...prevState.formdetail.repList,
    //         // {
    //         //   index: Math.random(),
    //         //   repName: "",
    //         // },
    //       ],
    //     },
    //   }));
    // };

    deleteMedRow = (index) => {
      // console.log("Inside Delete med row.......");
      // console.log(this.state.formdetail.medList);
      // console.log(index);
      this.setState({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          medList: this.state.formdetail.medList.filter(
            (s, idx) => index !== s.index
          ),
        },
      });
      // const taskList1 = [...this.state.taskList];
      // taskList1.splice(index, 1);
      // this.setState({ taskList: taskList1 });
    };

    // deleteRepRow = (index) => {
    //   this.setState({
    //     ...this.state,
    //     formdetail: {
    //       ...this.state.formdetail,
    //       repList: this.state.formdetail.repList.filter(
    //         (s, idx) => index !== s.index
    //       ),
    //     },
    //   });
    //   // const taskList1 = [...this.state.taskList];
    //   // taskList1.splice(index, 1);
    //   // this.setState({ taskList: taskList1 });
    // };

    handleChange = (e) => {
      // Ensure we log and inspect the event

      // Check if the input is a medication-related field
      if (
        ["medName", "medSchd", "medTime", "repName"].includes(e.target.name)
      ) {
        return; // Skip handling these fields if you don't want to process them
      } else if (["medCount", "medOut", "medQnt"].includes(e.target.name)) {
        // Get the index from the target ID
        const index = parseInt(e.target.id, 10); // Parse to get the index as a number
        let medList = [...this.state.formdetail.medList]; // Copy the current medList

        // Check if the index is valid before setting the value
        if (index >= 0 && index < medList.length) {
          medList[index][e.target.name] =
            e.target.type === "number"
              ? parseInt(e.target.value) // Convert to number if type is number
              : e.target.value; // Otherwise use string

          this.setState({
            formdetail: {
              ...this.state.formdetail,
              medList, // Update medList in state
            },
          });
        } else {
          console.error(`Index ${index} is out of bounds for medList`);
        }
      } else {
        // Handle other form fields that are not part of medList
        this.setState({
          formdetail: {
            ...this.state.formdetail,
            [e.target.name]:
              e.target.type === "number"
                ? parseFloat(e.target.value) // Parse float for number fields
                : e.target.value, // Set value as is for other types
          },
        });
      }
    };
    handleDiagnosisChange = (updatedRows) => {
      // Merge the updated rows with the existing 'drNotes', ensuring no duplicates
      const mergedNotes = Array.from(
        new Set([...this.state.formdetail.drNotes, ...updatedRows])
      );

      // Update the state with the new 'drNotes' array
      this.setState((prevState) => ({
        formdetail: {
          ...prevState.formdetail,
          drNotes: mergedNotes,
        },
      }));
    };

    handleRowsChange = (updatedRows) => {
      this.setState({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          ["drNotes"]: updatedRows,
        },
      });
      // this.setState({ diagnosis: updatedRows }); // Update the rows state in the parent
    };
    handleMedRowsChange = (updatedRows) => {
      // Ensure medList exists and is an array
      this.setState(
        (prevState) => {
          // Combine existing medList with updatedRows
          const combinedList = [
            ...prevState.formdetail.medList,
            ...updatedRows,
          ];

          // Filter out entries where medName is an empty string

          // Filter out rows with both empty medName and medSchd
          let filteredList = combinedList.filter(
            (row) => !(row.medName === "" && row.medSchd === "")
          );

          // If filteredList is empty, add one empty object
          if (filteredList.length === 0) {
            filteredList = [
              {
                index: Math.random(),
                medName: "",
                medQnt: 1,
                medSchd: "",
                medTime: "After Eat",
                medCount: 0,
                medOut: false,
              },
            ];
          }

          // Update the state with the filtered medList
          return {
            formdetail: {
              ...prevState.formdetail,
              medList: filteredList,
            },
          };
        },
        () => {
          // Log the updated medList to the console
          console.log(this.state.formdetail.medList);
        }
      );
    };

    handlePaymentChange = (updatedRows) => {
      this.setState({ payment: updatedRows });
    };

    // handleDrNotesChange = (newDrNotes) => {
    //   console.log(newDrNotes);
    //   this.setState({
    //     ...this.state,
    //     formdetail: {
    //       ...this.state.formdetail,
    //       drNotes: newDrNotes,
    //     },
    //   });
    // };
    handleFocus = () => {
      // Optional: Reset filteredDisSug when the field is focused
      this.setState({ filteredDisSug: this.state.symSug });
    };
    handleAdviceChange = (advice) => {
      // Update the state by merging the new `drNotes` with the current state
      this.setState((prevState) => ({
        formdetail: {
          ...prevState.formdetail,
          notes: advice,
        },
      }));
    };

    render() {
      // console.log(this.state.formdetail);
      const {
        rows,
        dialogOpen,
        templateName,
        templates,
        search,
        isTemplateClicked,
        isLoadPreviousClicked,
        isSaveTemplateClicked,
        isListVisible,
        // isSuggestionOpen,
        // selectedComplaint,
        // openModal,
        // modalRowIndex,
        // complaintDetails,
        // durationSuggestions,
        // frequencyRefs,
        // severityRefs,
        // durationRefs,

        // drList,
        // isSubmit,
        // visit,
        formdetail: { medList, repList, drNotes, drRef, drGrp },
      } = this.state;
      // console.log(this.state.diagnosis);
      // console.log(
      //   this.state.payment
      //     .filter((item) => item.total) // Remove entries where total is empty
      //     .map((item) => parseFloat(item.total)) // Convert total to a number
      //     .reduce((acc, curr) => acc + curr, 0)
      // );

      // console.log(this.state.isSuggestionOpen);
      // console.log(this.state.diagnosis);

      // const { countryArr, stateArr, cityArr } = this;
      // // console.log(this.countryArr);
      // console.log("blood group----");
      // console.log(bloodGroups);
      // console.log("blood group End----");
      const maxVisibleSuggestions = 4; // Maximum number of visible suggestions
      const suggestionHeight = templates?.length > 0 ? 42 : 60; // Height for each suggestion item (in px)
      const searchBarHeight = 30; // Height of the search bar

      // Calculate the height of the div based on suggestions and search bar
      const divHeight =
        searchBarHeight +
        Math.min(
          templates && templates.length > 0 ? templates.length : 1,
          maxVisibleSuggestions
        ) *
          suggestionHeight;
      const { classes } = this.props;
      // console.log(patient_id);
      //const { handleSubmit, register, errors } = useForm();
      // console.log("len-----first time--" + this.cityArr.length);
      // console.log(this.state);
      // console.log("Got patient name from parent: " + patient);
      console.log(this.state.formdetail.drNotes);
      return (
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              color="textSecondary"
            >
              Add Visit Details
            </Typography>
            <div>
              <AddKwDia
                open={this.state.openDia}
                appendNewMeds={this.appendNewMeds}
                appendNewPayment={this.appendNewPayment}
                appendNewReps={this.appendNewReps}
                appendNewDis={this.appendNewDis}
                appendNewSymt={this.appendNewSymt}
                appendNewDrs={this.appendNewDrs}
                // keywordData={this.keywordData}
                handleClickOpen={this.handleDiaClickOpen}
                handleClickClose={this.handleDiaClickClose}
                // handleClickAdd={this.handleDiaClickAdd}
              />
            </div>
          </div>

          {/* <Container> */}
          {/* Complaints Table */}

          {/* Diagnosis Table */}
          {/* <Box mt={4}>
            <Typography variant="h6" gutterBottom>
              Diagnosis
            </Typography>
            <Table
              sx={{
                border: "1px solid rgba(224, 224, 224, 1)", // Table Border
              }}
              size="small" // Reduce padding in the table
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "6px",
                    }}
                  >
                    #
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "6px",
                    }}
                  >
                    Diagnosis
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "6px",
                    }}
                  >
                    Duration
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "6px",
                    }}
                  >
                    Date
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {diagnosis.map((item, index) => (
                  <TableRow key={item.id}>
                    <TableCell
                      sx={{
                        border: "1px solid rgba(224, 224, 224, 1)",
                        padding: "6px",
                      }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "1px solid rgba(224, 224, 224, 1)",
                        padding: "6px",
                      }}
                    >
                      <TextField
                        variant="standard"
                        value={item.diagnosis}
                        InputProps={{ disableUnderline: true }} // Remove underline
                        sx={{ width: "100%" }} // Stretch TextField
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "1px solid rgba(224, 224, 224, 1)",
                        padding: "6px",
                      }}
                    >
                      <TextField
                        variant="standard"
                        placeholder="Duration"
                        InputProps={{ disableUnderline: true }} // Remove underline
                        sx={{ width: "100%" }}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "1px solid rgba(224, 224, 224, 1)",
                        padding: "6px",
                      }}
                    >
                      <TextField
                        variant="standard"
                        placeholder="Date"
                        InputProps={{ disableUnderline: true }} // Remove underline
                        sx={{ width: "100%" }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
           
          </Box> */}
          {/* </Container> */}

          <Fragment>
            <form
              className={classes.root}
              autoComplete="off"
              onChange={this.handleChange}
            >
              <Typography
                gutterBottom
                variant="h6"
                color="textSecondary"
                // style={{ fontWeight: "bold" }}
              >
                Vitals
              </Typography>
              <Divider variant="middle" />

              {/* 
              // Mitesh: Optional as per Dr Request
              <TextField
                //////////////////  Patient
                disabled
                margin="normal"
                id="patient"
                name="patient"
                value={patient}
                label="Patient"
                type="strint"
              /> */}

              <FormGroup row={true}>
                <div>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="temperature"
                    name="temperature"
                    pattern="[0-9]*"
                    style={{ width: "110px" }}
                    label="Temperature"
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">°F</InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="ml-3">
                  <TextField
                    // autoFocus
                    margin="dense"
                    id="pulse"
                    style={{ width: "110px" }}
                    name="pulse"
                    pattern="[0-9]*"
                    label="Pulse"
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">/ min</InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="ml-3">
                  <TextField
                    margin="dense"
                    placeholder="120/80"
                    id="bp"
                    name="bp"
                    label="BP"
                    type="text"
                    style={{ width: "150px" }}
                    // value={this.state.bp}
                    // onChange={this.handleInputChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">mm of Hg</InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="ml-3">
                  <TextField
                    // autoFocus
                    margin="dense"
                    id="spo"
                    name="spo"
                    style={{ width: "110px" }}
                    pattern="[0-9]*"
                    label="SpO2"
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="ml-3">
                  <TextField
                    // autoFocus
                    margin="dense"
                    id="rr"
                    name="rr"
                    style={{ width: "110px" }}
                    pattern="[0-9]*"
                    label="RR"
                    type="text"
                    // InputProps={{
                    //   endAdornment: (
                    //     <InputAdornment position="end">%</InputAdornment>
                    //   ),
                    // }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="ml-3">
                  <TextField
                    //////////////////  height
                    // autoFocus
                    margin="dense"
                    id="height"
                    name="height"
                    pattern="[0-9]*"
                    style={{ width: "110px" }}
                    label="Height"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="ml-3">
                  <TextField
                    //////////////////  weight
                    // autoFocus
                    margin="dense"
                    id="weight"
                    name="weight"
                    style={{ width: "110px" }}
                    pattern="[0-9]*"
                    label="Weight"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>

                {/* <div className="ml-3">
                  <TextField
                    //////////////////  age
                    // autoFocus
                    margin="dense"
                    id="age"
                    name="age"
                    pattern="[0-9]*"
                    label="Age"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div> */}
                {/* </FormGroup> */}

                {/* <FormGroup row={true}> */}
              </FormGroup>
              <Grid container direction="row">
                <Grid item xs={12} sm={6} md={6}>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                      marginTop: "7px",
                    }}
                  >
                    {/* <Typography
                    variant="h6"
                    gutterBottom
                    style={{ fontWeight: "bold" }}
                  >
                    Complaints
                  </Typography> */}
                    <Typography
                      gutterBottom
                      variant="h6"
                      color="textSecondary"
                      // style={{ fontWeight: "bold" }}
                    >
                      Complaints (c/o)
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      style={{ marginLeft: "5px" }}
                      // onClick={() => this.handleDeleteComplaint(item.id)}
                      size="small"
                    >
                      <SkipPreviousIcon
                        // color="textSecondary"
                        // fontSize="small"
                        style={{
                          height: "15px",
                          width: "15px",
                          // color: "rgba(89, 162, 162, 1)",
                        }}
                      />
                    </Typography>
                    <Typography
                      // variant="h6"
                      gutterBottom
                      onClick={this.handleLoadPrevious}
                      style={{
                        fontWeight: "bold",
                        cursor: "pointer",
                        color: "#ff3369",
                        fontSize: "10px",
                        marginTop: "2px",
                      }}
                    >
                      Load Previous
                    </Typography>

                    <Typography
                      color="textSecondary"
                      gutterBottom
                      style={{ marginLeft: "10px" }}
                      // onClick={() => this.handleDeleteComplaint(item.id)}
                      size="small"
                    >
                      <SaveIcon
                        // fontSize="small"
                        // style={{
                        //   height: "15px",
                        //   width: "15px",
                        //   color: "rgba(89, 162, 162, 1)",
                        // }}
                        style={{
                          color: isSaveTemplateClicked
                            ? "blue"
                            : "rgba(89, 162, 162, 1)",
                          height: "12px",
                          width: "12px",
                        }}
                      />
                    </Typography>
                    <Typography
                      // variant="h6"
                      gutterBottom
                      // style={{
                      //   cursor: "pointer",
                      //   color: "rgba(89, 162, 162, 1)",
                      //   fontSize: "12px",
                      // }}
                      style={{
                        fontWeight: "bold",
                        marginLeft: "2px",
                        cursor: "pointer",
                        color: isSaveTemplateClicked ? "blue" : "#ff3369",
                        fontSize: "10px",
                        marginTop: "3px",
                      }}
                      onClick={this.handleDialogOpen}
                    >
                      Save Template
                    </Typography>
                    <Dialog open={dialogOpen} onClose={this.handleDialogClose}>
                      <DialogTitle>Save Template</DialogTitle>
                      <DialogContent>
                        <TextField
                          autoFocus
                          margin="dense"
                          label="Template Name"
                          type="text"
                          fullWidth
                          variant="outlined"
                          value={templateName}
                          onChange={(e) =>
                            this.setState({ templateName: e.target.value })
                          }
                        />
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={this.handleDialogClose}
                          color="primary"
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={this.handleSaveTemplate}
                          color="primary"
                        >
                          Save
                        </Button>
                      </DialogActions>
                    </Dialog>

                    <div
                      style={{ position: "relative", display: "inline-block" }}
                      ref={this.containerRef}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={this.toggleTemplateList} // Toggle list on click
                      >
                        <Typography
                          // variant="h6"
                          gutterBottom
                          style={{
                            fontWeight: "bold",
                            marginLeft: "10px",
                            color: isTemplateClicked ? "blue" : "#ff3369",
                            fontSize: "10px",
                            marginTop: "3px",
                          }}
                        >
                          Templates
                        </Typography>
                        <Typography
                          color="textSecondary"
                          // variant="h6"
                          gutterBottom
                          style={{ marginTop: "3px" }}
                        >
                          <ArrowDropDownIcon
                            fontSize="small"
                            style={{
                              color: isTemplateClicked
                                ? "blue"
                                : "rgba(89, 162, 162, 1)",
                            }}
                          />
                        </Typography>
                        {/* <ArrowDropDownIcon fontSize="small" /> */}
                      </div>
                      {isListVisible && (
                        <div
                          style={{
                            position: "absolute",
                            top: "65%", // Align right under the Typography
                            left: "20px", // Match marginLeft of Typography
                            zIndex: 10, // Ensure it's above other elements
                            backgroundColor: "white", // Set background color for better visibility
                            borderRadius: "4px", // Add border radius for aesthetics
                            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)", // Add subtle shadow for depth
                            marginTop: "7px",
                            height: `${divHeight}px`, // Initial height based on suggestions and search bar
                            maxHeight: "156px", // Set max height to 156px
                            overflowY: "auto", // Enable scrolling if content exceeds max height
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center", // Center the items vertically
                              flexWrap: "wrap",
                              flexDirection: "column",
                              padding: "0px 10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center", // Center the items vertically
                                flexDirection: "row",
                                marginBottom: "3px", // Add space below the search bar
                                height: `${searchBarHeight}px`, // Set the search bar height
                              }}
                            >
                              <Search
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginTop: "5px",
                                  marginRight: "5px", // Add space between the icon and the input
                                  color: "#666",
                                }}
                              />
                              <input
                                type="search"
                                placeholder="Search"
                                style={{
                                  outline: "none",
                                  border: "none", // Remove borders for a cleaner look
                                  borderBottom: "1px solid #ccc", // Keep bottom border for input
                                  padding: "5px", // Add padding for better UX
                                  flexGrow: 1, // Allow input to take available width
                                }}
                                onChange={this.handleSearchChange} // Handle search input change
                              />
                            </div>
                            <div style={{ display: "flex", width: "100%" }}>
                              <Autocomplete
                                options={
                                  templates && Array.isArray(templates)
                                    ? templates.filter((template) =>
                                        template.name
                                          .toLowerCase()
                                          .includes(search.toLowerCase())
                                      )
                                    : []
                                }
                                getOptionLabel={(option) => option.name} // Display name in the dropdown
                                disableClearable
                                open // Force the dropdown to open when the list is visible
                                onChange={(event, newValue) => {
                                  if (newValue) {
                                    this.handleTemplateSelect(newValue); // Handle the selected value
                                  }
                                }}
                                style={{ width: "100%" }} // Make the dropdown take the full width
                                ListboxProps={{
                                  style: {
                                    border: "none",
                                    padding: "0px",
                                    maxHeight: `${
                                      maxVisibleSuggestions * suggestionHeight
                                    }px`, // Limit the height of suggestions
                                    overflowY: "auto", // Add vertical scroll when needed
                                    overflowX: "hidden", // Prevent horizontal scroll
                                  },
                                }}
                                PopperComponent={(props) => (
                                  <Popper {...props} placement="bottom" />
                                )}
                                disablePortal
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    InputProps={{
                                      ...params.InputProps,
                                      style: {
                                        height: "0", // Shrink the input field's height to 0
                                        minHeight: "0", // Ensure the input field doesn't take any space
                                        visibility: "hidden", // Hide the input field but keep it in the DOM
                                        border: "none", // Remove borders from the input
                                      },
                                      endAdornment: null, // Remove the dropdown arrow if needed
                                    }}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <Divider variant="left" />
                  <div
                    style={{
                      // marginTop: "15px",
                      // border: "1px solid rgba(224, 224, 224, 1)",
                      maxWidth: "1000px", // Table Border
                      minWidth: "200px",
                      maxHeight: "190px", // Adjust height for 4 rows
                      overflowY: "auto",
                      width: "100%",
                      overflowX: "auto",
                      // marginRight: "70px",
                    }}
                  >
                    <Table
                      sx={{
                        marginTop: "15px",
                        border: "1px solid rgba(224, 224, 224, 1)",
                        // maxWidth: "700px", // Table Border
                        // minWidth: "200px",
                        // width: "200px",

                        // overflowX: "auto",
                      }}
                      // size="small" // Reduce padding in the table
                    >
                      <TableHead>
                        <TableRow
                          style={{ backgroundColor: "rgb(238, 249, 222)" }}
                        >
                          <TableCell
                            sx={{
                              border: "1px solid rgba(224, 224, 224, 1)",
                              padding: "2px",
                              width: "35px",
                              fontSize: "12px",
                              color: "#546e7a",
                              fontWeight: "bold",
                            }}
                          >
                            #
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "1px solid rgba(224, 224, 224, 1)",
                              padding: "2px",
                              width: "300px",
                              color: "#546e7a",
                              fontSize: "12px",
                              fontWeight: "bold",
                            }}
                          >
                            c/o
                          </TableCell>
                          {/* <TableCell
                        sx={{
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "2px",
                          width: "200px",
                          fontWeight: "bold",
                        }}
                      >
                        Frequency
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "2px",
                          width: "200px",
                          fontWeight: "bold",
                        }}
                      >
                        Severity
                      </TableCell> */}
                          <TableCell
                            sx={{
                              border: "1px solid rgba(224, 224, 224, 1)",
                              padding: "2px",
                              width: "200px",
                              fontSize: "12px",
                              color: "#546e7a",
                              fontWeight: "bold",
                            }}
                          >
                            Duration
                          </TableCell>
                          {/* <TableCell
                        sx={{
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "2px",
                          width: "200px",
                          fontWeight: "bold",
                        }}
                      >
                        Date
                      </TableCell> */}
                          <TableCell
                            sx={{
                              border: "1px solid white",
                              padding: "2px",
                              backgroundColor: "white",
                              width: "35px",
                            }}
                          ></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows?.map((row, index) => {
                          const complaint = Object.keys(row)[0]; // This gives the complaint (e.g., "fever")
                          const values = row[complaint]; // This gives the array of values (e.g., ["daily", "mild", "1 day", "15-10-2024"])

                          return (
                            <TableRow key={index}>
                              {/* Row Number */}
                              <TableCell
                                sx={{
                                  border: "1px solid rgba(224, 224, 224, 1)",
                                  padding: "2px",
                                }}
                              >
                                {index + 1}
                              </TableCell>

                              {/* Complaint Autocomplete */}
                              <TableCell
                                sx={{
                                  border: "1px solid rgba(224, 224, 224, 1)",
                                  padding: "2px",
                                }}
                              >
                                <Autocomplete
                                  onFocus={this.handleFocus}
                                  options={this.state.filteredSymSug} // Use filtered options
                                  getOptionLabel={(option) =>
                                    option.label || option
                                  }
                                  disableClearable
                                  freeSolo
                                  value={complaint ? complaint : null}
                                  onChange={(event, newValue) => {
                                    const updatedComplaint = newValue
                                      ? newValue
                                      : event.target.value;
                                    this.handleComplaintChange(
                                      index,
                                      updatedComplaint
                                    );
                                  }}
                                  onInputChange={(event, newInputValue) => {
                                    // Update complaint as user types
                                    this.handleComplaintChange(
                                      index,
                                      newInputValue
                                    );

                                    // Filter options based on new input value
                                    const inputValue =
                                      newInputValue.toLowerCase();
                                    const filteredOptions =
                                      this.state.symSug.filter((option) => {
                                        const label = option.label || option;
                                        return (
                                          label &&
                                          label
                                            .toLowerCase()
                                            .includes(inputValue)
                                        );
                                      });

                                    // Update filteredSymSug in state
                                    this.setState({
                                      filteredSymSug: filteredOptions,
                                    });
                                  }}
                                  PopperComponent={(props) => (
                                    <Popper
                                      {...props}
                                      placement="bottom"
                                      style={{ zIndex: 10, marginTop: "60px" }}
                                    />
                                  )}
                                  disablePortal
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="standard"
                                      InputProps={{
                                        ...params.InputProps,
                                        disableUnderline: true,
                                      }}
                                    />
                                  )}
                                />
                              </TableCell>

                              {/* Frequency Autocomplete */}
                              {/* <TableCell
                            sx={{
                              border: "1px solid rgba(224, 224, 224, 1)",
                              padding: "2px",
                            }}
                          >
                            <Autocomplete
                              open={
                                this.state.isfrequencySuggestionOpen ===
                                  index && complaint
                              } // Open only for the current index
                              options={this.state.frequencySuggestions} // Use state for options
                              disableClearable
                              freeSolo
                              getOptionLabel={(option) => option.label} // Display the label of each option
                              value={values[0] ? { label: values[0] } : null} // Set the current value
                              filterOptions={(options) => options} // Show all suggestions without filtering by input
                              onChange={(event, newValue) => {
                                // console.log(
                                //   index,
                                //   this.state.isfrequencySuggestionOpen
                                // );
                                const formattedValue = newValue
                                  ? newValue.label // Format selected value, removing '__'
                                  : ""; // Reset if no value
                                this.handleFrequencyChange(
                                  index,
                                  formattedValue
                                ); // Update the frequency

                                // Close suggestions after selection
                                this.setState({
                                  isfrequencySuggestionOpen: -1,
                                }); // Close all suggestions
                              }}
                              onInputChange={(event, newInputValue) => {
                                if (newInputValue.trim() !== "") {
                                  const newSuggestions = [
                                    { label: "daily" },
                                    { label: "alternate day" },
                                    { label: "weekly" },
                                    { label: "twice weekly" },
                                    { label: "monthly" },
                                  ];

                                  this.setState({
                                    isLoadPreviousClicked: false,
                                    openSuggestionIndex: true,
                                    frequencySuggestions: newSuggestions,
                                    isfrequencySuggestionOpen:
                                      this.state.isLoadPreviousClicked ||
                                      this.state.openSuggestionIndex === false
                                        ? -1
                                        : index, // Open suggestions for the current index
                                  });
                                  this.handleFrequencyChange(
                                    index,
                                    newInputValue
                                  ); // Update frequency input as user types
                                } else {
                                  // Reset to default suggestions if input is empty or invalid
                                  this.setState({
                                    frequencySuggestions: [
                                      { label: "daily" },
                                      { label: "alternate day" },
                                      { label: "weekly" },
                                      { label: "twice weekly" },
                                      { label: "monthly" },
                                    ],
                                    isfrequencySuggestionOpen: -1, // Close suggestions
                                  });
                                  this.handleFrequencyChange(index, ""); // Clear previous value
                                }
                              }}
                              onBlur={() => {
                                this.setState({
                                  isfrequencySuggestionOpen: -1,
                                }); // Close suggestions on blur
                              }}
                              PopperComponent={(props) => (
                                <Popper {...props} placement="bottom" />
                              )}
                              disablePortal
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true, // Disable underline for a cleaner look
                                  }}
                                  inputRef={this.inputRef}
                                />
                              )}
                            />
                          </TableCell>

                          <TableCell
                            sx={{
                              border: "1px solid rgba(224, 224, 224, 1)",
                              padding: "2px",
                            }}
                          >
                            <Autocomplete
                              open={
                                this.state.isseveritySuggestionOpen === index &&
                                complaint
                              } // Open only for the current index
                              options={this.state.severitySuggestions} // Use state for options
                              disableClearable
                              freeSolo
                              getOptionLabel={(option) => option.label} // Display the label of each option
                              value={values[1] ? { label: values[1] } : null} // Set the current value
                              filterOptions={(options) => options} // Show all suggestions without filtering by input
                              onChange={(event, newValue) => {
                                const formattedValue = newValue
                                  ? newValue.label
                                  : ""; // Format selected value or reset
                                this.handleSeverityChange(
                                  index,
                                  formattedValue
                                ); // Update the severity
                                this.setState({ isseveritySuggestionOpen: -1 }); // Close suggestions after selection
                              }}
                              onInputChange={(event, newInputValue) => {
                                if (newInputValue.trim() !== "") {
                                  // Handle regular input or deletion
                                  const newSuggestions = [
                                    { label: "mild" },
                                    { label: "moderate" },
                                    { label: "severe" },
                                    { label: "profound" },
                                  ];

                                  // Update severity value and suggestions
                                  this.setState({
                                    isLoadPreviousClicked: false,
                                    openSuggestionIndex: true,
                                    severitySuggestions: newSuggestions,
                                    isseveritySuggestionOpen:
                                      this.state.isLoadPreviousClicked ||
                                      this.state.openSuggestionIndex === false
                                        ? -1
                                        : index, // Open suggestions for the current index
                                  });
                                  // this.setState({ isTemplateClicked: false });
                                  this.handleSeverityChange(
                                    index,
                                    newInputValue
                                  ); // Store custom input value
                                } else {
                                  // Reset suggestions and open on backspace deletion
                                  const newSuggestions = [
                                    { label: "mild" },
                                    { label: "moderate" },
                                    { label: "severe" },
                                    { label: "profound" },
                                  ];

                                  this.setState({
                                    severitySuggestions: newSuggestions,
                                    isseveritySuggestionOpen: -1, // Keep suggestions open
                                  });
                                  this.handleSeverityChange(
                                    index,
                                    newInputValue
                                  ); // Clear previous value
                                }
                              }}
                              onBlur={() => {
                                this.setState({ isseveritySuggestionOpen: -1 }); // Close suggestions on blur
                              }}
                              PopperComponent={(props) => (
                                <Popper {...props} placement="bottom" />
                              )}
                              disablePortal
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true, // Disable underline for a cleaner look
                                  }}
                                  inputRef={this.inputRef}
                                />
                              )}
                            />
                          </TableCell> */}

                              {/* Duration Autocomplete */}
                              <TableCell
                                key={index}
                                sx={{
                                  border: "1px solid rgba(224, 224, 224, 1)",
                                  padding: "2px",
                                }}
                              >
                                <Autocomplete
                                  open={
                                    this.state.isSuggestionOpen === index &&
                                    complaint
                                  } // Open only for the current index
                                  options={this.state.durationSuggestions} // Use the state variable
                                  disableClearable
                                  freeSolo
                                  getOptionLabel={(option) => option.label} // Display the label of each option
                                  value={
                                    values[2] ? { label: values[2] } : null // Set the current value
                                  }
                                  filterOptions={(options) => options} // Show all suggestions without filtering by input
                                  onChange={(event, newValue) => {
                                    const formattedValue = newValue
                                      ? newValue.label.replace(/__/g, "")
                                      : ""; // Format value

                                    this.handleDurationChange(
                                      index,
                                      formattedValue.trim().replace(/\s+/g, " ")
                                    ); // Update the duration
                                    this.setState({ isSuggestionOpen: -1 }); // Close all suggestions
                                  }}
                                  onInputChange={(event, newInputValue) => {
                                    console.log(
                                      /^\d*$/.test(newInputValue) ||
                                        /^\d+\s*[a-zA-Z]*$/.test(newInputValue)
                                    );
                                    if (
                                      /^\d*$/.test(newInputValue) ||
                                      /^\d+\s*[a-zA-Z]*$/.test(newInputValue)
                                    ) {
                                      if (
                                        !isNaN(newInputValue) &&
                                        newInputValue.trim() !== ""
                                      ) {
                                        const newSuggestions = [
                                          { label: `${newInputValue} day` },
                                          { label: `${newInputValue} week` },
                                          { label: `${newInputValue} month` },
                                          { label: `${newInputValue} year` },
                                        ];
                                        this.setState({
                                          durationSuggestions: newSuggestions,
                                          isSuggestionOpen: index, // Open suggestion for the current index
                                        });
                                      } else {
                                        // Reset to default suggestions if input is empty or invalid
                                        const defaultSuggestions = [
                                          { label: "__day" },
                                          { label: "__week" },
                                          { label: "__month" },
                                          { label: "__year" },
                                        ];
                                        this.setState({
                                          durationSuggestions:
                                            defaultSuggestions,
                                          isSuggestionOpen: -1, // Close all suggestions if invalid input
                                        });
                                      }
                                    }
                                    // Check if the input is a valid number

                                    // Update duration state on input change
                                    this.handleDurationChange(
                                      index,
                                      newInputValue
                                        .trim() // Remove leading and trailing spaces
                                        .replace(/\s+/g, " ")
                                    ); // Ensure the value is updated in the state
                                  }}
                                  onBlur={() => {
                                    this.setState({ isSuggestionOpen: -1 }); // Close suggestions when the input loses focus
                                  }}
                                  PopperComponent={(props) => (
                                    <Popper {...props} placement="bottom" />
                                  )}
                                  disablePortal
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="standard"
                                      InputProps={{
                                        ...params.InputProps,
                                        disableUnderline: true,
                                      }}
                                      inputRef={this.inputRef}
                                    />
                                  )}
                                />
                              </TableCell>

                              {/* Date Display */}
                              {/* <TableCell
                            sx={{
                              border: "1px solid rgba(224, 224, 224, 1)",
                              padding: "2px",
                            }}
                          >
                            <TextField
                              type="date"
                              variant="standard"
                              value={values[3]} // Bind the date value
                              onChange={(event) =>
                                this.handleDateChange(index, event.target.value)
                              } // Update on change
                              InputProps={{
                                disableUnderline: true,
                              }}
                            />
                          </TableCell> */}
                              {/* Delete Button */}
                              <TableCell
                                tabIndex={-1} // Ensures the TableCell itself is not focusable
                                sx={{
                                  border: "1px solid white",
                                  padding: "2px",
                                  width: "35px",
                                }}
                              >
                                <IconButton
                                  onClick={() =>
                                    this.handleDeleteComplaint(index)
                                  }
                                  size="small"
                                  tabIndex={-1} // Ensures the IconButton is not focusable
                                >
                                  <ClearIcon fontSize="small" />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Diagnosis
                    patient_id={this.props.patient_id}
                    drNotes={drNotes}
                    // diagnosis={this.state.diagnosis}
                    onRowsChange={this.handleRowsChange}
                    onDiagnosisChange={this.handleDiagnosisChange}
                    disSug={this.disSug}
                  />
                </Grid>
              </Grid>
              {/* <Box> */}

              {/* <Button
              variant="contained"
              color="primary"
              style={{ marginTop: "10px" }}
            >
              Add Complaint
            </Button> */}
              {/* </Box> */}

              <Grid container direction="row">
                <Grid item xs={12} sm={6} md={6}>
                  <Reports
                    patient_id={this.props.patient_id}
                    // delete={this.deleteRepRow}
                    repList={repList}
                    handleRepChange={this.handleRepChange}
                    repSug={this.repSug}
                    // onDrNotesChange={this.handleDrNotesChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div style={{ marginTop: "5px" }}>
                    <Typography
                      gutterBottom
                      color="textSecondary"
                      // variant="h6"
                      // color="black"
                      style={{ marginLeft: "10px" }}
                    >
                      Notes
                    </Typography>
                    <Divider variant="left" style={{ marginTop: "9px" }} />
                    {/* <Grid container direction="row" spacing={2}> */}
                    {/* <Grid item xs>
                        <TextField
                          // autoFocus
                          id="outlined-basic"
                          className={classes.freeTextNotes}
                          multiline
                          rows={2}
                          label={visitFormfields.notes}
                          // placeholder={visitFormfields.notes}
                          name="notes"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid> */}
                    {/* <Grid item xs> */}
                    <TextField
                      style={{ marginLeft: "10px" }}
                      // autoFocus
                      id="outlined-basic"
                      className={classes.freeTextNotes}
                      multiline
                      rows={2}
                      label={visitFormfields.diaNotes}
                      // placeholder={visitFormfields.diaNotes}
                      name="diaNotes"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {/* </Grid> */}
                    {/* </Grid> */}
                  </div>
                </Grid>
              </Grid>

              {/* <FormReport
                add={this.addRepRow}
                delete={this.deleteRepRow}
                repList={repList}
                handleChange={this.handleRepChange}
                repSug={this.repSug}
              /> */}

              {/* <FormGroup> */}
              {/* <div> */}
              {/* <Grid container direction="row" spacing={2}>
                    <Grid item xs>
                     
                      <Autocomplete
                        //////////////////// Multifield
                        multiple
                        id="multi field"
                        options={this.symSug}
                        filterSelectedOptions
                        size="small"
                        freeSolo
                        autoHighlight={true}
                        classes={{ option: classes.autocomp }}
                        onChange={(e, newValue) => {
                          this.handleMultiFld("ptNotes", newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={visitFormfields.complain}
                            // placeholder="ptNotes"
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                     
                    </Grid>
                    <Grid item xs>
                      
                      <Autocomplete
                        //////////////////// Multifield
                        multiple
                        id="multi field"
                        // autoFocus
                        // disableClearable
                        size="small"
                        freeSolo
                        classes={{ option: classes.autocomp }}
                        options={this.disSug}
                        filterSelectedOptions
                        onChange={(e, newValue) => {
                          this.handleMultiFld("drNotes", newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // multiline
                            // rows={2}
                            variant="outlined"
                            label={visitFormfields.diagnose}
                            // placeholder="drNotes"
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid> */}

              {/* </div> */}
              <FormGroup>
                {/* <div> */}
                <FormMedicine
                  patient_id={this.props.patient_id}
                  handleMedRowsChange={this.handleMedRowsChange}
                  add={this.addMedRow}
                  delete={this.deleteMedRow}
                  medList={medList}
                  handleMedChange={this.handleMedChange}
                  medSug={this.medSug}
                />
                {/* </div> */}
                {/* <Typography
                    gutterBottom
                    variant="h6"
                    color="black"
                    // color="textSecondary"
                    style={{ fontWeight: "bold", marginTop: "10px" }}
                  >
                    Payment
                  </Typography> */}
                {/* <Divider variant="middle" /> */}
              </FormGroup>
              <Advice
                drNotes={this.state.formdetail.notes}
                patient_id={this.props.patient_id}
                onAdviceChange={this.handleAdviceChange}
              />

              <Grid container direction="row">
                {/* <Grid item xs>
                  <Payment
                    patient_id={this.props.patient_id}
                    payment={this.state.payment}
                    // paymentLable={this.state.paymentSuggestions}
                    onRowsChange={this.handlePaymentChange}
                    paymentSug={this.paymentSug}
                  />
                </Grid> */}
                <Grid item xs>
                  {" "}
                  <div>
                    <Grid
                      container
                      direction="row"
                      // justify="space-between"
                      spacing={2}
                    >
                      <Grid item>
                        <Typography
                          gutterBottom
                          // variant="h6"
                          color="textSecondary"
                          // color="black"
                          style={{ marginLeft: "10px", marginBottom: "15px" }}
                        >
                          Amount
                        </Typography>
                        {/* <Divider
                          variant="left"
                          // style={{ marginTop: "-10px" }}
                        /> */}
                        <TextField
                          // style={{ marginTop: "15px", marginLeft: "10px" }}
                          // value={this.state.payment
                          //   .filter((item) => item.total) // Remove entries where total is empty
                          //   .map((item) => parseFloat(item.total)) // Convert total to a number
                          //   .reduce((acc, curr) => acc + curr, 0)}
                          // autoFocus
                          label="Amount"
                          id="amount"
                          name="amount"
                          defaultValue="0"
                          pattern="[0-9]*"
                          variant="outlined"
                          type="number"
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          gutterBottom
                          color="textSecondary"
                          // variant="h6"
                          // color="black"
                          style={{ marginLeft: "5px", marginBottom: "15px" }}
                        >
                          Amount Paid
                        </Typography>
                        {/* <Divider
                          variant="left"
                          // style={{ marginTop: "-10px" }}
                        /> */}
                        <TextField
                          // style={{ marginTop: "15px", marginLeft: "5px" }}
                          // autoFocus
                          label="Amount Paid"
                          id="amountPaid"
                          name="amountPaid"
                          defaultValue="0"
                          variant="outlined"
                          type="number"
                          pattern="[0-9]*"
                          inputProps={{
                            classes: {
                              classes: {
                                notchedOutline: classes.specialOutline,
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item>
                        {/* <Typography
                          gutterBottom
                          variant="h5"
                          component="h2"
                          className={classes.paymentCls}
                        >
                          {this.state.payment
                            .filter((item) => item.total) // Remove entries where total is empty
                            .map((item) => parseFloat(item.total)) // Convert total to a number
                            .reduce((acc, curr) => acc + curr, 0)}
                        </Typography> */}

                        {/* <TextField
                        value={this.state.payment
                          .filter((item) => item.total) // Remove entries where total is empty
                          .map((item) => parseFloat(item.total)) // Convert total to a number
                          .reduce((acc, curr) => acc + curr, 0)}
                        // autoFocus
                        label="Amount"
                        id="amount"
                        name="amount"
                        defaultValue="0"
                        pattern="[0-9]*"
                        variant="outlined"
                        type="number"
                      /> */}
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>

              <div>
                {/* <Card className={classes.cardClr}> */}
                {/* <Card>
                    <CardContent> */}
                <Grid container direction="row" spacing={3}>
                  <Grid item>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      className={classes.refCls}
                    >
                      Refer
                      <Checkbox
                        onChange={(e) => this.handleChangeCheckbox(e)}
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Autocomplete
                      id="drGrp"
                      margin="dense"
                      value={
                        this.state.refMark === false
                          ? null
                          : drGrp
                          ? { name: drGrp }
                          : null
                      }
                      classes={{ option: classes.autocomp }}
                      disableClearable
                      disabled={this.state.refGrpDisabled}
                      options={this.drGroups}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, value) => {
                        this.handleDrGrpChange(e, value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Dr Grp"
                          // name="drGrp"
                          margin="dense"
                          InputProps={{
                            ...params.InputProps,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Autocomplete
                      id="drRef"
                      margin="dense"
                      classes={{ option: classes.autocomp }}
                      value={
                        this.state.refMark === false
                          ? null
                          : drRef
                          ? { name: drRef }
                          : null
                      }
                      disableClearable
                      disabled={this.state.refDisabled || !this.state.refMark}
                      options={this.state.drList}
                      // getOptionLabel={(option) => {
                      //   if (option.mobile && option.description) {
                      //     return `${option.name} | ${option.mobile} | ${option.description}`;
                      //   } else if (option.mobile) {
                      //     return `${option.name} | ${option.mobile} `;
                      //   } else if (option.description) {
                      //     return `${option.name} | ${option.description}`;
                      //   } else {
                      //     return option.name;
                      //   }
                      // }}
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option, value) =>
                        value.name === "" || option.name === value.name
                      }
                      onChange={(e, value) => {
                        this.handleDrChange(e, "drRef", value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Dr Reference"
                          // name="drRef"
                          margin="dense"
                          InputProps={{
                            ...params.InputProps,
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                {/* </CardContent>
                  </Card> */}
              </div>
              {/* </FormGroup> */}

              <div>
                <Button
                  onClick={(e) => {
                    this.handleSubmit(e);
                  }}
                  className={classes.saveBtn}
                  variant="outlined"
                >
                  Save & Complete
                </Button>
                {/* <Button
                  onClick={this.handleBillDialogOpen}
                  className={classes.saveBtn}
                  variant="outlined"
                  style={{ marginLeft: "15px" }}
                >
                  Payment Bill
                </Button>
                <Dialog
                  open={this.state.BilldialogOpen}
                  onClose={this.handleBillDialogClose}
                >
                  <DialogTitle>Payment Bill</DialogTitle>
                  <Payment
                    patient_id={this.props.patient_id}
                    payment={this.state.payment}
                    // paymentLable={this.state.paymentSuggestions}
                    onRowsChange={this.handlePaymentChange}
                    paymentSug={this.paymentSug}
                  />
                  <DialogActions>
                    <Button
                      variant="contained"
                      // color="primary"
                      style={{ backgroundColor: "#9FC088", color: "white" }}
                      size="small"
                      // onClick={(e) => handleClickAdd(e, value)}
                      // onChange={(e) => handleOnMedValChange(e, "medCmp")}
                      className={classes.addBtn}
                      // color="primary"
                    >
                      Add
                    </Button>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "#9FC088", color: "white" }}
                      // color="primary"
                      size="small"
                      onClick={this.handleBillDialogClose}
                      className={classes.addBtn}
                      // color="primary"
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog> */}
                {/* <Button
                  style={{ marginLeft: "20px" }}
                  onClick={(e) => {
                    this.handleSubmit(e);
                  }}
                  className={classes.saveBtn}
                  variant="outlined"
                >
                  Save & Print
                  <FuncComp isSubmit={isSubmit} visit={visit} />
                </Button> */}
                {/* {this.state.isSubmit ? (
                    <RemoveIcon />
                  ) : (
                    // <HtmlContentByInfo visit={this.state.data} />
                    {}
                  )} */}
                {/* <PrintHtml visit={visit} classes={classes} /> */}
              </div>
            </form>
          </Fragment>
        </div>
      );
    }
  }
);
