import React from "react";
import Chart from "react-apexcharts";
import { hostInfo } from "../../../../store"; // Assuming hostInfo is set correctly elsewhere

class MonthlyGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        { name: "Total", data: [] },
        { name: "Paid", data: [] },
        { name: "Remain", data: [] },
      ],
      options: {
        chart: {
          type: "bar",
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        colors: ["#4F99E8", "#E8754F", "#B3E84F"],
        title: {
          text: "Monthly Revenue",
        },
        xaxis: {
          categories: [], // Months will be filled in here dynamically
        },
        legend: {
          position: "right",
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
      },
    };
  }

  async componentDidMount() {
    const amountTotal = [];
    const amountPaid = [];
    const amountRemain = [];
    const months = [];

    const getToken = () => {
      const cookieString = document.cookie;
      const cookies = cookieString.split("; ").reduce((acc, cookie) => {
        const [name, value] = cookie.split("=");
        acc[name] = value;
        return acc;
      }, {});
      return cookies.authToken;
    };

    const url = `${hostInfo}/getmonthlyrevenue`;

    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });

      if (!response.ok) {
        console.error("Network response was not ok:", response.statusText);
        return;
      }

      const data = await response.json();

      // Logging to check structure of fetched data

      if (Array.isArray(data) && data.length > 0) {
        data.forEach((item) => {
          amountTotal.push(item.total || 0);
          amountPaid.push(item.paid || 0);
          amountRemain.push(item.remain || 0);
          months.push(item.months || "Unknown");
        });

        this.setState({
          series: [
            { name: "Total", data: amountTotal },
            { name: "Paid", data: amountPaid },
            { name: "Remain", data: amountRemain },
          ],
          options: {
            ...this.state.options,
            xaxis: {
              ...this.state.options.xaxis,
              categories: months,
            },
          },
        });
      } else {
        console.error("Data is empty or not in expected format");
        this.setState({
          options: {
            ...this.state.options,
            xaxis: {
              ...this.state.options.xaxis,
              categories: ["No data"],
            },
          },
        });
      }
    } catch (error) {
      console.error("Error fetching monthly revenue data:", error);
    }
  }

  render() {
    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type="bar"
        height={400}
      />
    );
  }
}

export default MonthlyGraph;
