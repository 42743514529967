import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import MaterialTable from "material-table";
import MaterialTable from "@material-table/core";
import { hostInfo } from "../../../store";
// import csv from "csv";
// import XLSX from "xlsx";
import { Avatar, Box, Chip, Typography } from "@material-ui/core";
import PtGroupList from "./PtGroupList";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  containerIcon: {
    display: "flex",
    alignItems: "center",
    // paddin: spacing(2),
  },
  headCardDr: {
    background: "#FEF0CA",
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    margin: theme.spacing(1),
    //
    // display: "flex",
    // alignItems: "center",
  },
  inactive: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    border: 0,
    borderRadius: 3,
    // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    marginRight: theme.spacing(2),
  },
  active: {
    background: "linear-gradient(45deg, #279b37 30%, #7ac143 80%)",
    border: 0,
    borderRadius: 3,
    // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",

    marginRight: theme.spacing(2),
  },
  headText: {
    fontFamily: "Work Sans, sans-serif",
    fontWeight: 600,
    color: "#444655",
    // textAlign: "left",
    // background: "linear-gradient(45deg, #81d4fa 10%, #dce775 90%)",
    marginTop: theme.spacing(1),
    // display: "flex",
    // alignItems: "center",
  },
  cardBottom: {
    width: "100%",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  subVisit: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  addGroupBtn: {
    background: "#A9AABC",
    borderRadius: 3,
    border: 0,
    color: "white",
    // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    "&:hover": {
      background: "#00E180",
    },
    // marginBottom: theme.spacing(2),
    margin: theme.spacing(1),
  },
  headingCls: {
    alignItems: "right",
  },
  infoIcon: {
    color: "#8BB80E",
    // backgroundColor: "black",
    fontSize: 30,
  },
}));

// Dr Add ------------------------------
// const handleDrRefUpload = (file, id) => {
//   // console.log("Inside DrRef Upload...");
//   var ext = file[0].name.split(".").pop();
//   if (ext === "xlsx") {
//     // console.log("Uploaded file is xlsx...");
//     uplofDrRefxlsx(file[0], id);
//   } else {
//     // console.log("Uploaded file is not xlsx...");
//     uplodDrRefcsv(file[0], id);
//   }
//   window.location.reload();
//   // console.log("fie " + file[0].name + " Uploaded Successfully");
// };

// const uplofDrRefxlsx = (file, id) => {
//   const reader = new FileReader();
//   const rABS = !!reader.readAsBinaryString;
//   if (id === 0) {
//     // console.log("Invalid dr group id ...");
//     return;
//   }
//   reader.onload = (e) => {
//     /* Parse data */
//     const bstr = e.target.result;
//     const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
//     /* Get first worksheet */
//     const wsname = wb.SheetNames[0];
//     const ws = wb.Sheets[wsname];
//     /* Convert array of arrays */
//     const data = XLSX.utils.sheet_to_json(ws, {
//       header: 1,
//     });

//     alert(reader.result);
//     for (var i = 1; i < data.length; ) {
//       var drList = [];
//       for (var j = 1; i < data.length && j < 20; i++, j++) {
//         // console.log(data[i]);
//         if (
//           data[i][0] === undefined ||
//           data[i][0].match(/^ *$/) !== null ||
//           data[i][2] === undefined ||
//           data[i][2].match(/^ *$/) !== null
//         ) {
//           continue;
//         }
//         var dr = {
//           name: data[i][0],
//           mobile: isNaN(data[i][1]) ? data[i][1] : String(data[i][1]),
//           description: data[i][2],
//           dr_group_id: id,
//         };
//         drList.push(dr);
//       }
//       if (drList.length > 0) {
//         let url = "http://" + hostInfo + "/adddrinfolist";
//         fetch(url, {
//           method: "POST",
//           mode: "no-cors",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(drList),
//         });
//       }
//     }
//     // console.log("Data from xlsx -------------");
//     // console.log(data);
//     /* Update state */
//     // this.setState({ data: data, cols: make_cols(ws["!ref"]) });
//   };
//   if (rABS) reader.readAsBinaryString(file);
//   else reader.readAsArrayBuffer(file);
// };

// const uplodDrRefcsv = (file) => {
//   var reader = new FileReader();
//   reader.onload = function (e) {
//     // console.log(e.target.result);
//     csv.parse(reader.result, (err, data) => {
//       if (!validateData(data)) {
//         alert(reader.result);
//         for (var i = 0; i < data.length; ) {
//           var drList = [];
//           for (var j = 0; i < data.length && j < 20; i++, j++) {
//             if (
//               data[i][0] === undefined ||
//               data[i][0].match(/^ *$/) !== null ||
//               data[i][2] === undefined ||
//               data[i][2].match(/^ *$/) !== null
//             ) {
//               continue;
//             }
//             var dr = {
//               name: data[i][0],
//               mobile: data[i][1],
//               address: data[i][2],
//             };
//             drList.push(dr);
//           }
//           if (drList.length > 0) {
//             let url = "http://" + hostInfo + "/adddrinfolist";
//             fetch(url, {
//               method: "POST",
//               mode: "no-cors",
//               headers: {
//                 "Content-Type": "application/json",
//               },
//               body: JSON.stringify(drList),
//             });
//           }
//         }
//       } else {
//         alert("Invalid data");
//       }
//       // console.log(data);
//     });

//     // const json = csv2json(text);
//     // Use reader.result
//   };
//   reader.readAsText(file);
// };

// const validateData = (data) => {
//   // console.log("Inside data validation...");
//   // Data validation remain --------- TODO

//   // console.log(data);

//   return 0;
// };

const DataTables = (props) => {
  // const { types, groupId, scrHeight, scrWidth } = props;
  // const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [tableData, setTableData] = useState([]);
  // const [drId, setDrId] = useState(0);
  // const [drName, setDrName] = useState("");
  // const [drMobile, setDrMobile] = useState("");
  // const [drDesc, setDrDesc] = useState("");
  // console.log("data list --------------");
  // console.log(tableData);

  // const handleDialogClose = (event) => {
  //   setIsDialogOpen(false);
  // };

  const handlePatientClick = (id) => {
    // console.log("Patient Id ----------");
    const json_id = {
      patient_id: id,
      opd_id: 0,
    };
    const jsonString = JSON.stringify(json_id);

    // Encode the string to Base64
    const base64Encoded = btoa(jsonString);
    window.location = "/patients/patientprofile?id=" + base64Encoded;
  };

  const MyNewTitle = ({ text = "Table Title", variant = "h6" }) => (
    // <Typography
    //   variant={variant}
    //   style={{
    //     whiteSpace: "nowrap",
    //     overflow: "hidden",
    //     textOverflow: "ellipsis",
    //   }}
    // >
    //   Group: {groupName}
    // </Typography>
    // <Card className={classes.headCardDr}>
    // {/* <CardContent> */}
    <Typography
      // gutterBottom
      variant="h5"
      component="h2"
      color="textSecondary"
      align="center"
      className={classes.headText}
      // justify="space-between"

      // margin=""
    >
      Patients
    </Typography>
    // {/* </CardContent> */}
    // </Card>
  );

  const classes = useStyles();

  const columns = [
    { title: "No.", field: "num", width: "10%" },
    {
      title: "Name",
      field: "name",
      render: (rowData) => (
        <div
          className={classes.containerIcon}
          onClick={(e, rowData) => handlePatientClick(e, rowData)}
        >
          {/* <Avatar
            variant="rounded"
            className={`${rowData.status ? classes.active : classes.inactive}`}
          >
            {rowData.case_no}
          </Avatar> */}

          {/* <Box fontSize={16} fontFamily="Helvetica" fontWeight={550}> */}
          {rowData.name}
          {/* </Box> */}
        </div>
      ),
    },
    {
      title: "Gender",
      field: "gender",
      lookup: { M: "Male", F: "Female", O: "Other" },
    },
    { title: "Current Age", field: "age", searchable: false },
    {
      title: "Mobile",
      field: "mobile",
      searchable: true,
    },
    {
      title: "Remain",
      field: "remain",
      type: "numeric",
      searchable: false,
      // align: "center",
      render: (rowData) => (
        <div>
          <Chip
            label={rowData.remain}
            color="primary"
            style={{
              backgroundColor:
                rowData.remain === 0
                  ? "#D1D1D1"
                  : rowData.remain > 0
                  ? "#FFA1A1"
                  : "#B2C8DF",
            }}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    setTableData(props.ptDetails || []);
  }, [props.ptDetails]);

  // const hadleEditClick = (data) => {
  //   // console.log("Got row data----");
  //   // console.log(data);
  //   setIsDialogOpen(true);
  //   setDrId(data.Id);
  //   setDrName(data.name);
  //   setDrMobile(data.mobile);
  //   setDrDesc(data.description);
  // };

  return (
    <div>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
      <div>
        {/* <Fragment> */}
        <MaterialTable
          title={<MyNewTitle variant="h4" text="My Cool Table" />}
          columns={columns}
          // data={[{ Id: "1", Name: "abc" }]}
          data={tableData.map(
            (data, idx) =>
              data != null &&
              data !== undefined && {
                num: idx + 1,
                Id: data.id,
                name: data.firstname + " " + data.lastname,
                gender: data.gender,
                age: data.curr_age + " Yrs",
                mobile: data.mobile,
                remain: data.remain,
              }
          )}
          // style={{ height: scrHeight - 180 }}
          options={{
            // selection: true,
            headerStyle: {
              backgroundColor: "#CEE5D0",
            },
            sorting: true,
            minBodyHeight: "auto",
            padding: "dense",
            cellStyle: { fontSize: 14 },
            actionsColumnIndex: -1,
          }}
          onRowClick={(event, rowData, togglePanel) =>
            handlePatientClick(rowData.Id)
          }
        />
      </div>
    </div>
  );
};

const PtListTable = (props) => {
  const { groupId, groupName } = props;
  console.log("Got group id: ", groupId);
  const [ptDetails, setPtDetails] = React.useState([]);
  const [grpTotalAmnt, setGrpTotalAmnt] = React.useState(0);
  console.log(ptDetails);
  // const [openAddDia, setOpenAddDia] = useState(false);

  // const handleAddClose = () => {
  //   setOpenAddDia(false);
  // };

  // const handleAddOpen = () => {
  //   setOpenAddDia(true);
  // };
  const classes = useStyles();
  const getToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.authToken;
  };

  useEffect(() => {
    // Fetch the reports *************************
    async function fetchPtInfo() {
      let url = hostInfo + "/getptbygroup?group_id=" + groupId;
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Got data Disease data  ----11111111111111");
          console.log(data);
          if (data != null) {
            setPtDetails(data);
            let tmpVal = Object.values(data).reduce(
              (total, value) => total + value.remain,
              0
            );
            setGrpTotalAmnt(tmpVal);
          } else {
            setPtDetails([]);
            setGrpTotalAmnt(0);
          }
        });
    }
    // fetchPtInfo();
    const timer = setTimeout(() => {
      if (groupId) fetchPtInfo();
    }, 300); // 300ms debounce
    return () => clearTimeout(timer);
    // console.log("---------------------------------");
  }, [groupId]);

  // const addPtDetailsInCurrentList = (data) => {
  //   setPtDetails((curPtDetails) => [...curPtDetails, data]);
  // };

  return (
    <div className={classes.headingCls}>
      <Typography
        gutterBottom
        variant="h3"
        component="h2"
        color="textSecondary"
        align="center"
        className={classes.headText}
        // justify="space-between"

        // margin=""
      >
        {groupName} Family -
        <Chip
          label={grpTotalAmnt}
          color="primary"
          style={{
            marginLeft: "10px",
            backgroundColor:
              grpTotalAmnt === 0
                ? "#D1D1D1"
                : grpTotalAmnt > 0
                ? "#FF7396"
                : "#76BA99",
          }}
        />
      </Typography>

      <DataTables
        types="disease"
        ptDetails={ptDetails}
        groupId={groupId}
        groupName={groupName}
        scrHeight={props.scrHeight}
        scrWidth={props.scrWidth}
      />
    </div>
  );
};

export default PtListTable;
