import React, { useState, useEffect, useRef } from "react";
import Paper from "@material-ui/core/Paper";
import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import moment from "moment";
import { visitFormfields } from "../../store";
import AccordionDetails from "@material-ui/core/AccordionActions";
import {
  List,
  ListItem,
  Button,
  AppBar,
  Toolbar,
  Typography,
  TableContainer,
  Table,
  Avatar,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import { Container, Row, Col } from "react-bootstrap";

import { Box } from "@material-ui/core";

import PersonalDetail from "./ProfileInfo/PersonalDetail";
import VisitList from "./ProfileInfo/VisitList";
import PaymentHistory from "./ProfileInfo/PaymentHistory";
import queryString from "query-string";
import AddNewVisitForm from "./ProfileInfo/AddNewVisitForm";
import { hostInfo } from "../../store";
import VisitsMain from "../CaseList/components/VisitsMain";
import VisitListDia from "../CaseList/components/diaComponent/VisitListDia";

import VisitDetailInfo from "./ProfileInfo/VisitDetailInfo";
// import PdfGen from "../PdfGen";
const useStyles = makeStyles((theme) => ({
  toolbarOffset: theme.mixins.toolbar,
  mainContent: {
    paddingTop: "70px",
  },
  appBar: {
    maxWidth: "100%", // Ensure AppBar width is flexible and not fixed
    width: "auto",
    margin: "0 auto",
  },
  appBarContainer: {
    padding: "0 20px", // Add padding to center the content inside the AppBar
    width: "100%", // Ensure the container takes up the full width
  },
}));
const PatientProfile = (props) => {
  const classes = useStyles();
  const { history } = props;
  const [user, setUser] = useState([]);

  const [paymentInfo, setpaymentInfo] = useState([]);
  // const [openDia, setOpenDia] = useState(false);

  let search_id = history.location.search; //  here... search_id is a patient_id
  if (!search_id) {
    window.location = "/dashboard";
  }

  const values = queryString.parse(search_id);

  const decodedString = atob(values.id);

  // Parse the JSON string back to an object
  const jsonDecoded = JSON.parse(decodedString);

  const patient_id = jsonDecoded.patient_id;
  const opdId = jsonDecoded.opd_id;
  // Log the IDs for debugging

  // console.log("Inside patien ------------------ patirnt id: ", patient_id);

  // const handleDiaClickOpen = () => {
  //   setOpenDia(true);
  // };

  // const handleDiaClickClose = () => {
  //   setOpenDia(false);
  // };
  const getToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.authToken;
  };

  useEffect(() => {
    // setOpdId(localStorage.getItem("opd_id"));

    // Clear the OPD ID from local storage
    // localStorage.removeItem("opd_id");
    // console.log("Fetching patient profile data for patient id: " + patient_id);
    let url = hostInfo + "/getpatientbyid?patient_id=" + patient_id;
    fetch(url, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // setTimeout(() => setStory(data), 1500);
        // console.log("Success ", data);
        // if (!Object.entries(data).length) {
        //   window.location = "/dashboard";
        // }
        if (data != null) setUser(data);
      });
    let paymentUrl = `${hostInfo}/getptpayinfo?patient_id=${patient_id}`;
    fetch(paymentUrl, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // setTimeout(() => setStory(data), 1500);
        // console.log("Success ", data);
        // if (!Object.entries(data).length) {
        //   window.location = "/dashboard";
        // }
        if (data != null) setpaymentInfo(data);
      });
    // .catch((error) => {
    //   console.log("Error", error);
    // });
  }, [patient_id]);

  // useEffect(() => {
  //   console.log("Fetching patient profile data for patient id: " + patient_id);
  //   let url = "http://" + hostInfo + "/getpatientbyid?patient_id=" + patient_id;
  //   fetch(url)
  //     .then((response) => response.json())
  //     .then((response) => {
  //       setUser(response);
  //       console.log(response);
  //       if (!Object.entries(response).length) {
  //         window.location = "/dashboard";
  //       }
  //     });
  // }, []);

  // console.log("Fetched user details..........");
  // console.log(user);
  const [paddingTop, setPaddingTop] = useState(65);

  useEffect(() => {
    const updatePaddingTop = () => {
      console.log(window.innerWidth);
      setPaddingTop(window.innerWidth <= 450 ? 140 : 65);
    };

    // Set initial padding
    updatePaddingTop();

    // Update padding on window resize
    window.addEventListener("resize", updatePaddingTop);

    // Clean up on component unmount
    return () => {
      window.removeEventListener("resize", updatePaddingTop);
    };
  }, []);

  return (
    <>
      <Row
        noGutters
        // ref={firstRowRef}
        className="page-header py-2"
        style={{
          backgroundColor: "rgb(238, 249, 222)",
          position: "fixed",
          zIndex: 1000,
          width: "100%",
          marginTop: "-8px",
          // pad,
        }}
      >
        {/* <Typography
          gutterBottom
          variant="h4"
          component="h2"
          color="textSecondary"
          style={{ marginBottom: "0px" }}
        >
          Patient Profile
        </Typography> */}
        <PersonalDetail
          patient_id={patient_id}
          user={user}
          paymentInfo={paymentInfo}
        />
      </Row>
      <Container fluid className="main-content-container px-3">
        <Row style={{ paddingTop: paddingTop }}>
          <Col lg="3" style={{ paddingRight: 0, paddingLeft: 0 }}>
            <div>
              {/* <PersonalDetail patient_id={patient_id} user={user} /> */}
            </div>
            <div>
              {/* <Typography
              gutterBottom
              variant="h5"
              component="h2"
              color="textSecondary"
            >
              Payment History
            </Typography> */}
              {/* <PaymentHistory patient_id={patient_id} /> */}
            </div>
            <div style={{ marginBottom: "10px" }}>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                color="textSecondary"
                className="page-header pt-1"
              >
                Consult History
              </Typography>
              <VisitList patient_id={patient_id} user={user} />
            </div>
          </Col>

          <Col lg="9" style={{ paddingRight: 0, paddingLeft: 0 }}>
            {/* <Box
            boxShadow={3}
            borderRadius={5}
            bgcolor="background.paper"
            m={1}
            p={1}
          >
            <ButtonGroup
              variant="contained"
              color="primary"
              aria-label="contained primary button group"
            >
              <AddKyDia
                open={openDia}
                handleClickOpen={handleDiaClickOpen}
                handleClickClose={handleDiaClickClose}
              />
            </ButtonGroup>
          </Box> */}
            <Box
              boxShadow={3}
              borderRadius={5}
              bgcolor="background.paper"
              // m={1}
              p={1}
            >
              {/* <Typography
                gutterBottom
                variant="h5"
                component="h2"
                color="textSecondary"
              >
                Add Visit Details
              </Typography> */}
              <AddNewVisitForm
                opdId={opdId}
                user={user}
                patient_id={patient_id}
                patient={user.firstname + " " + user.lastname}
              />
            </Box>
            {/* <div style={{ marginBottom: "10px" }}>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                color="textSecondary"
                className="page-header pt-4"
              >
                Consult History
              </Typography>
              <VisitList patient_id={patient_id} user={user} />
            </div> */}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PatientProfile;
