import React, { Fragment, useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@material-ui/core";
import AddNewPatient from "./components/AddNewPatient";
import { hostInfo } from "../../store";
import { Row, Col } from "react-bootstrap";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ButtonBase from "@material-ui/core/ButtonBase";
import { PatientsListTbl } from "./components";
import { useMediaQuery } from "@material-ui/core";
import { useReactToPrint } from "react-to-print";
import { Box } from "@material-ui/core";
import EditVisit from "../PatientProfile/ProfileInfo/EditVisit";

import HtmlContentByInfo from "../PatientProfile/ProfileInfo/PrintHtml/HtmlContentByInfo";
import BillPrint from "./components/BillPrint";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    // flexGrow: 1,
  },
  content: {
    marginTop: theme.spacing(2),
    background: "linear-gradient(45deg, #81d4fa 10%, #dce775 90%)",
    borderRadius: 3,
    border: 0,
    color: "white",
  },

  inner: {
    minWidth: "100%",
  },
  addUsrBtn: {
    background: "#5E92C2",
    borderRadius: 2,
    border: 0,
    color: "white",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    "&:hover": {
      background: "#C6A3D4",
    },
    marginRight: theme.spacing(2),
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  component: {
    padding: theme.spacing(1),
    // marginBottom: theme.spacing(1),
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  paper: {
    // padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 550,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  headText: {
    // background: "linear-gradient(45deg, #81d4fa 10%, #dce775 90%)",
    // fontFamily: "Arial",
    fontWeight: 600,
    fontSize: 20,
    color: "#444655",
    marginTop: theme.spacing(2),
    // marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    // display: "flex",
    // alignItems: "center",
  },
}));

const Opd = () => {
  const classes = useStyles();
  // const [addOpen, setAddOpen] = useState(false);
  // const [searchOpen, setSearchOpen] = useState(false);
  // const [editOpen, setEditOpen] = useState(false);
  const [patientGrpList, setPatientGrpList] = useState([]);
  const [patientList, setPatientList] = useState([]);
  // console.log(patientList);
  const [visitlist, setVisitlist] = useState([]);

  const [user, setUser] = useState("");
  const [openEditDia, setOpenEditDia] = useState(false);
  // const [products] = useState(mockData);
  // const handleClickAddOpen = () => {
  //   setAddOpen(true);
  // };

  // const handleAddClose = (value) => {
  //   setAddOpen(false);
  // };

  // const handleClickSearchOpen = () => {
  //   setSearchOpen(true);
  // };

  // const handleSearchClose = (value) => {
  //   setSearchOpen(false);
  // };

  const handleClickEditOpen = (patient_id, visit_id) => {
    setOpenEditDia(true);
    let url = hostInfo + "/getpatientvisit?patient_id=" + patient_id;
    fetch(url, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res != null) {
          console.log(res);
          console.log(visit_id);
          console.log(res.filter((i) => i.id === visit_id));
          setVisitlist(res.filter((i) => i.id === visit_id));
          // console.log(setVisitlist);
        }
      });
  };

  const handleClose = () => {
    setOpenEditDia(false);
  };
  const handleSubmit = () => {
    // console.log("Added for Submit");
    setOpenEditDia(false);
    window.location.reload();
  };
  // const handleEditClose = (value) => {
  //   setEditOpen(false);
  // };

  // const completeOpd = (opdId) => {
  //   const url = `${hostInfo}/completeOpd`; // Replace with your actual API endpoint
  //   const data = {
  //     opd_id: opdId, // Pass the OPD ID to be updated
  //   };

  //   fetch(url, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${getToken()}`, // Assuming getToken() retrieves a valid token
  //     },
  //     body: JSON.stringify(data),
  //   })
  //     .then((response) => {
  //       if (response.ok) {
  //         alert("OPD Completed successfully");
  //         async function fetchPatient() {
  //           let url = hostInfo + "/getopdptinfo"; // No patient_id in the URL

  //           await fetch(url, {
  //             headers: {
  //               Authorization: `Bearer ${getToken()}`,
  //             },
  //           })
  //             .then((res) => res.json())
  //             .then((res) => {
  //               if (res != null) {
  //                 console.log(res);
  //                 setPatientList(
  //                   res.filter(
  //                     (i) =>
  //                       new Date(i.opd_date_time)
  //                         .toISOString()
  //                         .split("T")[0] ===
  //                       new Date().toISOString().split("T")[0]
  //                   )
  //                 ); // Update the state with the fetched patient data
  //               }
  //             })
  //             .catch((error) => {
  //               console.error("Error fetching patient data:", error);
  //             });
  //         }

  //         // Example usage
  //         fetchPatient();
  //         // Optionally refresh data or update UI
  //       } else {
  //         alert("Failed to mark OPD as completed");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("There was an error!", error);
  //       alert("There was an error completing the OPD");
  //     });
  // };

  const deleteOpd = async (opdId) => {
    const url = `${hostInfo}/deleteopd`; // Ensure this matches your backend endpoint

    try {
      const response = await fetch(url + `?id=${opdId}`, {
        // Pass ID as a query parameter
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`, // Assuming getToken() retrieves a valid token
        },
      });

      if (response.ok) {
        alert("OPD Deleted successfully");

        // Fetch and update patient list
        const patientResponse = await fetch(`${hostInfo}/getopdptinfo`, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        });

        if (patientResponse.ok) {
          const patients = await patientResponse.json();
          const today = new Date().toISOString().split("T")[0];
          setPatientList(
            patients.filter(
              (i) =>
                new Date(i.opd_date_time).toISOString().split("T")[0] === today
            )
          );
        } else {
          console.error(
            "Error fetching patient data:",
            patientResponse.statusText
          );
        }
      } else {
        alert("Failed to delete OPD");
      }
    } catch (error) {
      console.error("There was an error!", error);
      alert("There was an error deleting the OPD");
    }
  };

  const getToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.authToken;
  };

  useEffect(() => {
    async function fetchPtGroup() {
      let url = hostInfo + "/getptgroup";
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res != null) {
            if (res != null) {
              setPatientGrpList(res);
            }
          }
        });
    }

    async function fetchPatient() {
      let url = hostInfo + "/getopdptinfo"; // No patient_id in the URL

      await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res != null) {
            console.log(res);
            setPatientList(
              res.filter(
                (i) =>
                  new Date(i.opd_date_time).toISOString().split("T")[0] ===
                  new Date().toISOString().split("T")[0]
              )
            ); // Update the state with the fetched patient data
          }
        })
        .catch((error) => {
          console.error("Error fetching patient data:", error);
        });
    }

    const fetchUserInfo = async () => {
      try {
        const response = await fetch(`${hostInfo}/usernames`, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        const a = getToken();
        const b = JSON.parse(atob(a.split(".")[1]));
        const user = data.users.filter((i) => i.username === b.username);
        setUser(user);
      } catch (error) {
        console.error("There was an error fetching the user info!", error);
      }
    };

    fetchUserInfo();

    // Example usage
    fetchPatient(); // Fetch all patient data

    fetchPtGroup();
    // fetchPatient();
  }, []);
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const gender = { M: "Male", F: "Female" };
  const isMobile = useMediaQuery("(max-width:600px)");
  const handlePatientClick = (id, opdId) => {
    // console.log("Patient Id ----------");
    // console.log(id);
    const json_id = {
      patient_id: id,
      opd_id: opdId,
    };
    const jsonString = JSON.stringify(json_id);

    // Encode the string to Base64
    const base64Encoded = btoa(jsonString);

    // localStorage.setItem("opd_id", opdId);
    window.location = "/patients/patientprofile?id=" + base64Encoded;
  };

  const componentRef = useRef();
  const componentBillRef = useRef();

  // Move useReactToPrint to the top level of the component
  const triggerPrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const triggerBillPrint = useReactToPrint({
    content: () => componentBillRef.current,
  });

  // Separate fetch logic for each print type

  const handlePrint = (patient_id, visit_id) => {
    const url = hostInfo + "/getpatientvisit?patient_id=" + patient_id;

    fetch(url, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res != null) {
          const filteredVisit = res.filter((i) => i.id === visit_id);
          setVisitlist(filteredVisit);

          // Use a callback to ensure print happens after the state is updated
          setTimeout(() => {
            triggerPrint(); // Trigger consult print
          }, 100); // Delay to ensure state update
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const handleBillPrint = (patient_id, visit_id) => {
    const url = hostInfo + "/getpatientvisit?patient_id=" + patient_id;

    fetch(url, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res != null) {
          const filteredVisit = res.filter((i) => i.id === visit_id);
          setVisitlist(filteredVisit);

          // Use a callback to ensure print happens after the state is updated
          setTimeout(() => {
            triggerBillPrint(); // Trigger bill print
          }, 100); // Delay to ensure state update
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  return (
    <div className={classes.root}>
      {/* <Grid container spacing={2}> */}
      {/* Left column for Pending Opd Patient List */}
      {/* <Grid item xs={6}> */}
      <Row>
        <Col lg="6">
          <Box
            boxShadow={3}
            // borderRadius={5}
            bgcolor="background.paper"
            // p={1}
            style={{ height: dimensions.height - 90, overflow: "auto" }}
          >
            <Typography
              gutterBottom
              variant="subtitle1"
              component="h5"
              color="textSecondary"
              align="center"
              className={classes.headText}
            >
              Pending Opd Patient List
            </Typography>

            {patientList.filter((i) => i.opd_status === 0).length > 0 ? (
              patientList
                .filter((i) => i.opd_status === 0)
                .map((p, index) => (
                  <div key={index}>
                    {index === 0 && (
                      <hr style={{ border: "0.5px solid #ddd", margin: "0" }} /> // Top horizontal line, only for the first item
                    )}
                    <div
                      className={classes.paper}
                      style={{
                        padding: isMobile
                          ? "8px 4px 16px 4px"
                          : "8px 0px 16px 0px",
                      }}
                    >
                      <Grid
                        container
                        spacing={isMobile ? 0 : 2} // Remove spacing in mobile to prevent horizontal scroll
                        alignItems="center"
                        // style={{ overflowX: "hidden" }}
                      >
                        <Grid item xs={2}>
                          <img
                            className="rounded-circle"
                            src={
                              p.gender === "M"
                                ? require("../../images/avatar/m9.jpg")
                                : require("../../images/avatar/f8.jpg")
                            }
                            alt={p.firstname}
                            width="70"
                          />
                        </Grid>
                        <Grid item xs={10} sm container>
                          <Grid
                            item
                            xs
                            container
                            direction="column"
                            style={{
                              padding: isMobile
                                ? "0px 0px 0px 20px"
                                : "0px 0px 0px 0px",
                            }}
                            // spacing={2}
                          >
                            <Grid
                              container
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Grid item xs>
                                <Typography
                                  gutterBottom
                                  variant="subtitle1"
                                  style={{ fontSize: "20px" }}
                                >
                                  {p.firstname} {p.lastname}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                  {p.gender ? gender[p.gender] : "No Gender"} |{" "}
                                  {p.curr_age ? p.curr_age : 0} Yrs | {p.mobile}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Tooltip title="Delete OPD">
                                  <DeleteIcon
                                    onClick={() => deleteOpd(p.id)}
                                    style={{
                                      marginLeft: 8,
                                      marginTop: -38,
                                      cursor: "pointer",
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                            </Grid>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "10px",
                                // paddingLeft: "5px",
                              }}
                            >
                              <Button
                                style={{
                                  backgroundColor:
                                    user[0]?.accesses_flag === 1 ||
                                    user[0]?.accesses_flag === 0
                                      ? "gray"
                                      : "#5E92C2", // Change color when disabled
                                  padding: "3px 3px",
                                  color: "white",
                                  fontSize: "12px",
                                }}
                                onClick={() =>
                                  handlePatientClick(p.patient_id, p.id)
                                }
                                disabled={
                                  user[0]?.accesses_flag === 1 ||
                                  user[0]?.accesses_flag === 0
                                } // Disable button when accesses_flag is 0
                              >
                                Start OPD
                              </Button>

                              {/* <Button
                                style={{
                                  backgroundColor: "#ef9a9a",
                                  padding: "3px 3px",
                                  color: "white",
                                  fontSize: "12px",
                                }}
                              >
                                Bill
                              </Button>
                              <Button
                                style={{
                                  backgroundColor: "#00A36C",
                                  padding: "3px 3px",
                                  color: "white",
                                  fontSize: "12px",
                                }}
                                onClick={() => completeOpd(p.id)}
                              >
                                Completed
                              </Button> */}
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                    <hr style={{ border: "0.5px solid #ddd", margin: "0" }} />{" "}
                    {/* Bottom horizontal line */}
                  </div>
                ))
            ) : (
              // <Paper
              //   className={classes.paper}
              //   style={{ padding: "20px 20px 20px 20px" }}
              // >
              <>
                <hr style={{ border: "0.5px solid #ddd", margin: "0" }} />
                <div
                  className={classes.paper}
                  style={{
                    padding: isMobile ? "8px 4px 8px 4px" : "8px 0px 8px 0px",
                  }}
                >
                  Not any patient
                </div>
                <hr style={{ border: "0.5px solid #ddd", margin: "0" }} />
              </>
              // </Paper>
            )}
          </Box>
        </Col>

        {/* </Grid> */}

        {/* Right column for Complete Opd Patient List */}
        <Col lg="6" style={{ marginTop: isMobile ? "20px" : "0px" }}>
          <Box
            boxShadow={3}
            // borderRadius={5}
            bgcolor="background.paper"
            // p={1}
            style={{ height: dimensions.height - 90, overflow: "auto" }}
          >
            <Typography
              gutterBottom
              variant="subtitle1"
              component="h5"
              color="textSecondary"
              align="center"
              className={classes.headText}
            >
              Complete Opd Patient List
            </Typography>
            {patientList.filter((i) => i.opd_status === 1).length > 0 ? (
              patientList
                .filter((i) => i.opd_status === 1)
                .map((p, index) => (
                  <div key={index}>
                    {index === 0 && (
                      <hr style={{ border: "0.5px solid #ddd", margin: "0" }} /> // Top horizontal line, only for the first item
                    )}
                    <div
                      className={classes.paper}
                      style={{
                        padding: isMobile
                          ? "8px 4px 16px 4px"
                          : "8px 0px 16px 0px",
                      }}
                    >
                      <Grid
                        container
                        spacing={isMobile ? 0 : 2} // Remove spacing in mobile to prevent horizontal scroll
                        alignItems="center"
                        // style={{ overflowX: "hidden" }}
                      >
                        <Grid item xs={2}>
                          <img
                            className="rounded-circle"
                            src={
                              p.gender === "M"
                                ? require("../../images/avatar/m9.jpg")
                                : require("../../images/avatar/f8.jpg")
                            }
                            alt={p.firstname}
                            width="70"
                          />
                        </Grid>
                        <Grid item xs={10} sm container>
                          <Grid
                            item
                            xs
                            container
                            direction="column"
                            style={{
                              padding: isMobile
                                ? "0px 0px 0px 20px"
                                : "0px 0px 0px 0px",
                            }}
                            // spacing={2}
                          >
                            <Grid
                              container
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Grid item xs>
                                <Typography
                                  gutterBottom
                                  variant="subtitle1"
                                  style={{ fontSize: "20px" }}
                                >
                                  {p.firstname} {p.lastname}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                  {p.gender ? gender[p.gender] : "No Gender"} |{" "}
                                  {p.curr_age ? p.curr_age : 0} Yrs | {p.mobile}
                                </Typography>
                              </Grid>
                              <Grid item>
                                {p.visit_id !== 0 && (
                                  <Tooltip title="Edit Visit">
                                    <EditIcon
                                      onClick={() =>
                                        handleClickEditOpen(
                                          p.patient_id,
                                          p.visit_id
                                        )
                                      }
                                      style={{
                                        marginLeft: 8,
                                        marginTop: -38,
                                        cursor: "pointer",
                                      }}
                                    />
                                  </Tooltip>
                                )}
                              </Grid>
                              <Dialog
                                open={openEditDia}
                                onClose={handleClose}
                                fullWidth={true}
                                maxWidth={"md"}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                              >
                                <DialogTitle id="alert-dialog-title">
                                  Edit Details
                                </DialogTitle>
                                <DialogContent>
                                  {visitlist
                                    .sort((a, b) => (a.id > b.id ? 1 : -1))
                                    .reverse()
                                    .map((visit, idx) => (
                                      <EditVisit
                                        visit={visit}
                                        handleClose={handleClose}
                                        handleSubmit={handleSubmit}
                                      />
                                    ))}
                                </DialogContent>
                              </Dialog>
                            </Grid>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "10px",
                                // paddingLeft: "5px",
                              }}
                            >
                              <Button
                                style={{
                                  backgroundColor: "#5E92C2",
                                  padding: "3px 3px",
                                  color: "white",
                                  fontSize: "12px",
                                }}
                                onClick={() =>
                                  handleBillPrint(p.patient_id, p.visit_id)
                                }
                              >
                                Bill Print
                                {visitlist.length > 0 && (
                                  <div style={{ display: "none" }}>
                                    <BillPrint
                                      ref={componentBillRef}
                                      visit={visitlist[0]}
                                      user={patientList.find(
                                        (i) => i.visit_id === visitlist[0].id
                                      )}
                                    />
                                  </div>
                                )}
                              </Button>

                              <Button
                                style={{
                                  backgroundColor: "#5E92C2",
                                  padding: "3px 3px",
                                  color: "white",
                                  fontSize: "12px",
                                }}
                                onClick={() =>
                                  handlePrint(p.patient_id, p.visit_id)
                                }
                              >
                                Consult Print
                                {visitlist.length > 0 && (
                                  <div style={{ display: "none" }}>
                                    <HtmlContentByInfo
                                      ref={componentRef}
                                      visit={visitlist[0]}
                                      user={patientList.find(
                                        (i) => i.visit_id === visitlist[0].id
                                      )}
                                    />
                                  </div>
                                )}
                              </Button>
                            </div>
                          </Grid>
                          {/* <Tooltip title="Delete OPD">
                            <DeleteIcon onClick={() => deleteOpd(p.id)} />
                          </Tooltip> */}
                        </Grid>
                      </Grid>
                    </div>
                    <hr style={{ border: "0.5px solid #ddd", margin: "0" }} />{" "}
                    {/* Bottom horizontal line */}
                  </div>
                ))
            ) : (
              // <Paper
              //   className={classes.paper}
              //   style={{ padding: "20px 20px 20px 20px" }}
              // >
              //   Not any patient
              // </Paper>
              <>
                <hr style={{ border: "0.5px solid #ddd", margin: "0" }} />
                <div
                  className={classes.paper}
                  style={{
                    padding: isMobile ? "8px 4px 8px 4px" : "8px 0px 8px 0px",
                  }}
                >
                  Not any patient
                </div>
                <hr style={{ border: "0.5px solid #ddd", margin: "0" }} />
              </>
            )}
          </Box>
        </Col>
      </Row>
      {/* </Grid> */}
    </div>
  );
};

export default Opd;
