import React, { Fragment, createRef } from "react";
import {
  Container,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
import { withStyles } from "@material-ui/styles";
import {
  Button,
  Divider,
  Checkbox,
  Grid,
  Typography,
  InputAdornment,
  Modal,
  Popper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

// import FuncComp from "./testFunc";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SaveIcon from "@mui/icons-material/Save";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import ClearIcon from "@mui/icons-material/Clear";

// import RemoveIcon from "@material-ui/icons/Remove";

import FormGroup from "@material-ui/core/FormGroup";

//import { countryArr } from "../../../../store";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useRef } from "react";
import { Search } from "@material-ui/icons";
import { hostInfo, visitFormfields } from "../../../../store";
// import PrintHtml from "./PrintHtml/PrintHtml";
// import HtmlContentByInfo from "./PrintHtml/HtmlContentByInfo";

const styles = (theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      // width: 230,
    },
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  saveBtn: {
    background: "#94B49F",
    borderRadius: 3,
    border: 0,
    color: "white",
    // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    "&:hover": {
      background: "#C6A3D4",
    },
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  formControl: {
    // margin: theme.spacing(1),
    width: 230,
  },
  // countryControl: {
  //   // margin: theme.spacing(1),
  //   minWidth: 150,
  // },
  countryControl: {
    marginRight: theme.spacing(1),
    minWidth: 170,
  },
  insurance: {
    // width: 230,
    marginTop: theme.spacing(2),
  },
  bloodGroupFld: {
    minWidth: 150,
  },
  seprator: {
    margin: theme.spacing(2),
  },
  seprator1: {
    marginTop: theme.spacing(2),
  },
  refCls: {
    color: "#D46D91",
    fontWeight: "bold",
    marginTop: theme.spacing(2),
  },
  seprator2: {
    width: "100%",
    ...theme.typography.body2,
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  multilineFld: {
    display: "flex",
    flexWrap: "wrap",
  },
  amountGreen: {
    borderColor: "pink",
  },
  specialOutline: {
    borderColor: "pink",
    //borderWidth: 4
  },
  presc: {
    width: "100%",
  },
  autocomp: {
    '&[data-focus="true"], &:hover': {
      backgroundColor: "#CFE8A9",
    },
  },
  cardClr: {
    background: "#F3F8F8",
  },
  freeTextNotes: {
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

// const diagnosisSuggestions = [
//   { label: "Fever" },
//   { label: "Fatigue" },
//   { label: "Fracture" },
//   { label: "Cough" },
//   { label: "Cold" },
//   { label: "Vomiting" },
//   { label: "Loose Motion" },
//   { label: "Abdominal Pain" },
//   { label: "Nasal Blockage" },
// ];

var countryStateCity = require("country-state-city").default;

export default withStyles(styles)(
  class EditDiagnosis extends React.Component {
    containerRef = createRef();

    state = {
      search: "",
      dialogOpen: false,
      templateName: "",
      isSuggestionOpen: 1,
      filteredDisSug: this.props.disSug,
      openSuggestionIndex: false,
      templates: [],
      isListVisible: false,
      isTemplateClicked: false,
      isSaveTemplateClicked: false,
      isLoadPreviousClicked: false,
      durationSuggestions: [
        { label: "__day" },
        { label: "__week" },

        { label: "__month" },
        { label: "__year" },
        // { label: "monthly" },
        // { label: "Loose Motion" },
        // { label: "Abdominal Pain" },
        // { label: "Nasal Blockage" },
      ],

      //   rows: this.props.diagnosis,

      frequencyRefs: [],
      severityRefs: [],
      durationRefs: [],
      selectedComplaint: null,
      openModal: false,
      modalRowIndex: null,
    };

    // countryArr = countryStateCity.getAllCountries();
    stateArr = [];
    cityArr = [];
    selectedCountry = "";
    selectedState = "";
    selectedCity = "";
    stateDisabled = true;
    cityDisabled = true;
    // frequencyRefs = [];
    // severityRefs = [];
    // durationRefs = [];
    medSug = [];
    repSug = [];
    drSug = [];
    symSug = [];
    disSug = [];
    drGroups = [];

    // handleComplaintChange = (index, value) => {
    //   const newRows = [...this.state.rows];

    //   newRows[index].diagnosis = value;

    //   if (value && value.trim() !== "" && index === newRows.length - 1) {
    //     // If the current index is the last row and there's a value, add a new empty object to the rows
    //     newRows.push({
    //       diagnosis: "",

    //       duration: "",
    //       date: "",
    //       inputNumber: "",
    //     });
    //   }

    //   // Update state with the new rows
    //   this.setState({ rows: newRows });
    //   this.props.onRowsChange(newRows);
    //   this.setState({ isSuggestionOpen: index });
    //   this.setState({ isfrequencySuggestionOpen: index });
    //   this.setState({ isseveritySuggestionOpen: index });
    // };

    handleComplaintChange = (index, value) => {
      const newRows = [...this.props.diagnosis];
      const complaintKey = value ? value : ""; // Get the complaint key from the input

      // Get the existing row for the index or create a new object if it doesn't exist
      const existingRow = newRows[index] || {};

      // Get the current values for the complaint key or default to an empty array
      const complaintValues = existingRow[complaintKey] ||
        existingRow[Object.keys(existingRow)[0]] || ["", ""];

      // Create a new object with only the new complaint key and its values
      const updatedRow = {
        [complaintKey]: complaintValues, // Update the row with the new complaint key and its values
      };

      // Update the row at the specified index
      newRows[index] = updatedRow;

      // If the current index is the last row, add a new empty row
      if (index === newRows.length - 1) {
        newRows.push({ "": ["", ""] }); // Add an empty row for future input
      }

      // Update the state with the modified rows
      this.setState({ rows: newRows });

      // Open the suggestion box only if the complaint values are empty
      if (!newRows[index][complaintKey].slice(0, 1).some((v) => v !== "")) {
        this.setState({
          isSuggestionOpen: index,
        });
      } else {
        // Close the suggestion boxes if values exist
        this.setState({
          isSuggestionOpen: -1,
        });
      }
    };

    handleLoadPrevious = async () => {
      this.setState({ isLoadPreviousClicked: true });
      const getToken = () => {
        const cookieString = document.cookie;
        const cookies = cookieString.split("; ").reduce((acc, cookie) => {
          const [name, value] = cookie.split("=");
          acc[name] = value;
          return acc;
        }, {});
        return cookies.authToken;
      };

      try {
        const response = await fetch(
          `${hostInfo}/diagnosis/${this.props.patient_id}`,
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to load previous diagnosis");
        }
        const data = await response.json();

        const newRows = data.flatMap((item) => {
          // Check if the item is an object or array and handle accordingly
          if (Array.isArray(item)) {
            return item; // If it's an array, spread the items
          } else {
            return [item]; // Wrap object in array for consistency
          }
        });

        // Merge existing rows (excluding the last one) with the new rows
        const updatedRows = [
          ...this.state.rows.slice(0, -1), // Existing rows (excluding last one)
          ...newRows,
          { "": ["", ""] },
        ];

        // Update state with new rows and reset suggestion state to prevent autocomplete opening
        this.setState({
          rows: updatedRows,
          isSuggestionOpen: -1, // Close duration suggestions
          // isfrequencySuggestionOpen: -1, // Close frequency suggestions
          // isseveritySuggestionOpen: -1, // Close severity suggestions
          // Reset other necessary suggestion states if needed
        });
        this.props.onRowsChange(newRows);
      } catch (error) {
        console.error("Error loading previous diagnosis:", error);
      }
    };

    handleNumberInput(index, inputValue) {
      const rows = [...this.props.diagnosis];
      rows[index].inputNumber = inputValue; // Store the number in the row
      this.setState({ rows });

      this.props.onRowsChange(rows);
    }
    handleDurationChange = (index, inputNumber) => {
      const rows = [...this.props.diagnosis];
      const key = Object.keys(rows[index])[0]; // Get the complaint key

      rows[index][key][0] = inputNumber ? `${inputNumber}` : ""; // Set duration

      if (inputNumber) {
        const parts = inputNumber.split(" ");
        const durationValue = parseInt(parts[0], 10);
        const durationUnit = parts[1] ? parts[1].toLowerCase() : "";

        if (!isNaN(durationValue)) {
          const baseDate = new Date();

          if (durationUnit === "day" || durationUnit === "days") {
            baseDate.setDate(baseDate.getDate() - durationValue);
          } else if (durationUnit === "week" || durationUnit === "weeks") {
            baseDate.setDate(baseDate.getDate() - durationValue * 7);
          } else if (durationUnit === "month" || durationUnit === "months") {
            baseDate.setMonth(baseDate.getMonth() - durationValue);
          } else if (durationUnit === "year" || durationUnit === "years") {
            baseDate.setFullYear(baseDate.getFullYear() - durationValue);
          }

          rows[index][key][1] = baseDate.toISOString().split("T")[0]; // Set calculated date
        } else {
          rows[index][key][1] = ""; // Clear date if invalid
        }
      } else {
        rows[index][key][1] = ""; // Clear date if no duration provided
      }

      this.setState({ rows });
    };

    // Function to handle date change
    handleDateChange = (index, dateValue) => {
      const newRows = [...this.state.rows];
      const key = Object.keys(newRows[index])[0]; // Get the complaint key

      newRows[index][key][3] = dateValue; // Update date field

      this.setState({ rows: newRows });
    };
    handleDeleteComplaint = (index) => {
      this.setState((prevState) => {
        const newRows = prevState.rows.filter((_, i) => i !== index);

        // If no rows are left, insert a default empty row
        const updatedRows =
          newRows.length === 0
            ? [
                {
                  "": ["", new Date().toISOString().split("T")[0]],
                },
              ]
            : newRows;
        this.props.onRowsChange(updatedRows);
        return {
          rows: updatedRows, // Update the rows state with the filtered array
        };
      });
    };

    handleDialogOpen = () => {
      this.setState({
        isfrequencySuggestionOpen: -1,
        isseveritySuggestionOpen: -1,
        isSuggestionOpen: -1,
      });

      this.setState({ isSaveTemplateClicked: true });
      this.setState({ dialogOpen: true });
    };

    handleDialogClose = () => {
      this.setState({ isSaveTemplateClicked: false });
      this.setState({ dialogOpen: false });
    };

    handleTemplateSelect = (template) => {
      this.setState(
        (prevState) => ({
          rows: [
            ...prevState.rows.slice(0, -1), // Remove the last empty row
            ...template.json_data, // Add the template's rows
            { "": ["", ""] }, // Add an empty row at the end
          ],
        }),
        () => {
          this.props.onRowsChange(this.state.rows); // Ensure rows are updated in parent component
        }
      );

      // Close suggestions and toggle the list visibility
      this.setState((prevState) => ({
        isListVisible: !prevState.isListVisible,
        isTemplateClicked: false,
        openSuggestionIndex: false,
      }));
    };
    handleSaveTemplate = () => {
      const getToken = () => {
        const cookieString = document.cookie;
        const cookies = cookieString.split("; ").reduce((acc, cookie) => {
          const [name, value] = cookie.split("=");
          acc[name] = value;
          return acc;
        }, {});
        return cookies.authToken;
      };

      const { templateName, rows } = this.state;

      // Filter rows to remove empty keys and values
      const filteredRows = rows.filter((row) => {
        const key = Object.keys(row)[0]; // Get the key (e.g., "Fever")
        const values = row[key]; // Get the values array (e.g., ["1 day", "2024-10-14"])
        return (
          key &&
          key.trim() !== "" &&
          values.every((value) => value.trim() !== "")
        ); // Check that key and all values are non-empty
      });

      if (filteredRows.length > 0 && templateName) {
        const savedData = { name: templateName, json_data: filteredRows }; // Use filtered rows for saving

        fetch(hostInfo + "/addTemplate", {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(savedData),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("API Response:", data);
            if (data.message) {
              alert("Template saved successfully");
            } else {
              alert("Error saving template");
            }

            this.setState((prevState) => ({
              templates: [...(prevState.templates || []), savedData],
              templateName: "", // Reset template name
            }));
          })
          .catch((error) => {
            console.error("Error:", error);
            alert("Failed to save template");
          });

        this.handleDialogClose();
      } else {
        alert("Please enter valid template data and name.");
        this.handleDialogClose();
        this.setState({ templateName: "" });
      }
    };

    toggleTemplateList = () => {
      this.setState((prevState) => ({
        isListVisible: !prevState.isListVisible, // Toggle the visibility state
      }));
      this.setState({ isTemplateClicked: !this.state.isTemplateClicked });
      this.setState({
        isfrequencySuggestionOpen: -1,
        isseveritySuggestionOpen: -1,
        isSuggestionOpen: -1,
      });
    };

    handleSearchChange = (event) => {
      this.setState({ search: event.target.value }); // Update search term
    };

    componentDidMount() {
      document.addEventListener("mousedown", this.handleClickOutside);
      document.addEventListener("keydown", this.handleKeyNavigation);
      // document.addEventListener("mousedown", this.handleClickOutside);
      const getToken = () => {
        const cookieString = document.cookie;
        const cookies = cookieString.split("; ").reduce((acc, cookie) => {
          const [name, value] = cookie.split("=");
          acc[name] = value;
          return acc;
        }, {});
        return cookies.authToken;
      };

      // this.fetchTemplates = () => {
      fetch(hostInfo + "/gettemplates", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((response) => {
          // Return the JSON response
          return response.json(); // Added return statement here
        })
        .then((data) => {
          this.setState({ templates: data }); // Update state with fetched data
          console.log("Fetched Templates:", data); // Log fetched data
        })
        .catch((error) => {
          console.error("Error fetching templates:", error); // Log any errors
        });

      // };
    }

    componentWillUnmount() {
      document.removeEventListener("mousedown", this.handleClickOutside);
      document.removeEventListener("keydown", this.handleKeyNavigation);
    }

    handleClickOutside = (event) => {
      if (
        this.containerRef.current &&
        !this.containerRef.current.contains(event.target)
      ) {
        this.setState({ isListVisible: false, isTemplateClicked: false });
      }
    };
    handleKeyNavigation = (event) => {
      const { templates, highlightedIndex, isListVisible } = this.state;

      // Only trigger if the suggestion list is visible
      if (!isListVisible) return;

      // Prevent default scrolling behavior when navigating suggestions
      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        event.preventDefault();
      }

      // Navigate down (ArrowDown)
      if (event.key === "ArrowDown") {
        this.setState((prevState) => ({
          highlightedIndex: Math.min(
            prevState.highlightedIndex + 1,
            templates.length - 1
          ),
        }));
      }

      // Navigate up (ArrowUp)
      if (event.key === "ArrowUp") {
        this.setState((prevState) => ({
          highlightedIndex: Math.max(prevState.highlightedIndex - 1, 0),
        }));
      }

      // Select the highlighted suggestion (Enter key)
      if (event.key === "Enter" && highlightedIndex >= 0) {
        this.handleTemplateSelect(
          this.props.idx,
          "repName",
          templates[highlightedIndex]
        );
      }
    };

    handleClose = () => {
      this.setState({ open: false });
    };

    handleDiaClickOpen = () => {
      this.setState({ openDia: true });
    };

    handleDiaClickClose = () => {
      this.setState({ openDia: false });
    };

    handleOnChange = (e, name) => {
      const value = e.target.value;
      // console.log("Value change --- ");
      // console.log(e.target);
      // console.log(name);
      // console.log(value);
      this.setState({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          [name]: value,
        },
      });
    };

    handleMultiFld = (name, value) => {
      // console.log("------ Inside Multifiled handler ------");
      // console.log(name);
      // console.log(value);
      this.props.onRowsChange(value);
      // this.setState({
      //   ...this.state,
      //   formdetail: {
      //     ...this.state.formdetail,
      //     [name]: value,
      //   },
      // });
    };
    handleFocus = () => {
      // Optional: Reset filteredDisSug when the field is focused
      this.setState({ filteredDisSug: this.props.disSug });
    };
    handleInsurance = (name, value) => {
      // console.log(name);
      // console.log(value.target.checked);

      this.setState({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          [name]: value.target.checked,
        },
      });
    };

    // Mitesh: TODO make index incremental

    render() {
      const {
        dialogOpen,
        templateName,
        templates,
        search,
        isTemplateClicked,

        isSaveTemplateClicked,
        isListVisible,
      } = this.state;

      const { classes } = this.props;
      // console.log(this.props.disSug);
      return (
        <Fragment>
          {/* <Box> */}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              marginTop: "18px",
            }}
          >
            {/* <Typography
                    variant="h6"
                    gutterBottom
                    style={{ fontWeight: "bold" }}
                  >
                    Complaints
                  </Typography> */}
            <Typography
              gutterBottom
              // variant="h6"
              color="textSecondary"
              // color="black"
              style={{ marginLeft: "10px" }}
            >
              Diagnosis (D/D)
            </Typography>
          </div>
          <Divider variant="left" style={{ marginTop: "-3px" }} />
          {/* <Table
            sx={{
              //   marginTop: "15px",
              border: "1px solid rgba(224, 224, 224, 1)",
              width: "auto", // Table Border
            }}
            size="small" // Reduce padding in the table
          >
            <TableHead>
              <TableRow style={{ backgroundColor: "#f8f9fa" }}>
                <TableCell
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    padding: "2px",
                    width: "35px",
                    fontWeight: "bold",
                  }}
                >
                  #
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    padding: "2px",
                    width: "300px",
                    fontWeight: "bold",
                  }}
                >
                  Diagnosis
                </TableCell>

                <TableCell
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    padding: "2px",
                    width: "200px",
                    fontWeight: "bold",
                  }}
                >
                  Duration
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    padding: "2px",
                    width: "200px",
                    fontWeight: "bold",
                  }}
                >
                  Date
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid white",
                    padding: "2px",
                    backgroundColor: "white",
                  }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.diagnosis?.map((row, index) => {
                const diagnosis = Object.keys(row)[0]; // This gives the complaint (e.g., "fever")
                const values = row[diagnosis]; // This gives the array of values (e.g., ["daily", "mild", "1 day", "15-10-2024"])

                return (
                  <TableRow key={index}>
                  
                    <TableCell
                      sx={{
                        border: "1px solid rgba(224, 224, 224, 1)",
                        padding: "2px",
                      }}
                    >
                      {index + 1}
                    </TableCell>
                  
                    <TableCell
                      sx={{
                        border: "1px solid rgba(224, 224, 224, 1)",
                        padding: "2px",
                      }}
                    >
                      <Autocomplete
                        onFocus={this.handleFocus}
                        options={this.state.filteredDisSug}
                        getOptionLabel={(option) => option.label || option} // Fallback to an empty string if label is undefined
                        disableClearable
                        freeSolo // Allows the user to enter custom values
                        value={diagnosis ? { label: diagnosis } : null} // Display the current complaint (key)
                        onChange={(event, newValue) => {
                          const updatedComplaint = newValue
                            ? newValue.label
                            : event.target.value;
                          this.handleComplaintChange(index, updatedComplaint);
                        }}
                        onInputChange={(event, newInputValue) => {
                          this.handleComplaintChange(index, newInputValue);
                          const inputValue = newInputValue.toLowerCase();

                          // Filter options with added check for label existence
                          const filteredOptions = this.props.disSug.filter(
                            (option) => {
                              const label = option.label || option; // Fallback to option if label is undefined
                              return (
                                label &&
                                label.toLowerCase().includes(inputValue)
                              );
                            }
                          );

                          // Update filteredDisSug in state
                          this.setState({ filteredDisSug: filteredOptions });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                            }}
                          />
                        )}
                      />
                    </TableCell>
                  
                    <TableCell
                      key={index}
                      sx={{
                        border: "1px solid rgba(224, 224, 224, 1)",
                        padding: "2px",
                      }}
                    >
                      <Autocomplete
                        open={this.state.isSuggestionOpen === index} // Open only for the current index
                        options={this.state.durationSuggestions} // Use the state variable
                        disableClearable
                        freeSolo
                        getOptionLabel={(option) => option.label} // Display the label of each option
                        value={
                          values[0] ? { label: values[0] } : null // Set the current value
                        }
                        filterOptions={(options) => options} // Show all suggestions without filtering by input
                        onChange={(event, newValue) => {
                          const formattedValue = newValue
                            ? newValue.label.replace(/__/g, "")
                            : ""; // Format value

                          this.handleDurationChange(
                            index,
                            formattedValue.trim().replace(/\s+/g, " ")
                          ); // Update the duration
                          this.setState({ isSuggestionOpen: -1 }); // Close all suggestions
                        }}
                        onInputChange={(event, newInputValue) => {
                          // console.log(
                          //   /^\d*$/.test(newInputValue) ||
                          //     /^\d+\s*[a-zA-Z]*$/.test(newInputValue)
                          // );
                          if (
                            /^\d*$/.test(newInputValue) ||
                            /^\d+\s*[a-zA-Z]*$/.test(newInputValue)
                          ) {
                            if (
                              !isNaN(newInputValue) &&
                              newInputValue.trim() !== ""
                            ) {
                              const newSuggestions = [
                                { label: `${newInputValue} day` },
                                { label: `${newInputValue} week` },
                                { label: `${newInputValue} month` },
                                { label: `${newInputValue} year` },
                              ];
                              this.setState({
                                durationSuggestions: newSuggestions,
                                isSuggestionOpen: index, // Open suggestion for the current index
                              });
                            } else {
                              // Reset to default suggestions if input is empty or invalid
                              const defaultSuggestions = [
                                { label: "__day" },
                                { label: "__week" },
                                { label: "__month" },
                                { label: "__year" },
                              ];
                              this.setState({
                                durationSuggestions: defaultSuggestions,
                                isSuggestionOpen: -1, // Close all suggestions if invalid input
                              });
                            }
                          }
                          // Check if the input is a valid number

                          // Update duration state on input change
                          this.handleDurationChange(
                            index,
                            newInputValue
                              .trim() // Remove leading and trailing spaces
                              .replace(/\s+/g, " ")
                          ); // Ensure the value is updated in the state
                        }}
                        onBlur={() => {
                          this.setState({ isSuggestionOpen: -1 }); // Close suggestions when the input loses focus
                        }}
                        // PopperComponent={(props) => (
                        //   <Popper {...props} placement="bottom" />
                        // )}
                        // disablePortal
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                            }}
                            inputRef={this.inputRef}
                          />
                        )}
                      />
                    </TableCell>

                  
                    <TableCell
                      sx={{
                        border: "1px solid rgba(224, 224, 224, 1)",
                        padding: "2px",
                      }}
                    >
                      <TextField
                        type="date"
                        variant="standard"
                        value={values[1]} // Bind the date value
                        onChange={(event) =>
                          this.handleDateChange(index, event.target.value)
                        } // Update on change
                        InputProps={{
                          disableUnderline: true,
                        }}
                      />
                    </TableCell>
                   
                    <TableCell
                      sx={{
                        border: "1px solid white",
                        padding: "2px",
                      }}
                    >
                      <IconButton
                        onClick={() => this.handleDeleteComplaint(index)}
                        size="small"
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table> */}
          <Autocomplete
            style={{ marginRight: "16px", marginLeft: "10px" }}
            //////////////////// Multifield
            multiple
            id="multi field"
            value={this.props.drNotes}
            // autoFocus
            // disableClearable
            size="small"
            freeSolo
            classes={{ option: classes.autocomp }}
            options={this.props.disSug || []}
            filterSelectedOptions
            onChange={(e, newValue) => {
              this.handleMultiFld("drNotes", newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                multiline
                rows={2}
                variant="outlined"
                label={visitFormfields.diagnose}
                // placeholder="drNotes"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
          {/* </Box> */}
        </Fragment>
      );
    }
  }
);
