import React, { forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/styles";
// import { useMediaQuery } from "@material-ui/core";
import { Divider, Drawer, List, Button, IconButton } from "@material-ui/core";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"; // Import icons
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import { NavLink as RouterLink } from "react-router-dom";
import { Sidebar, Topbar } from "./components";
import MainSearch from "../../views/MainSearch";
import MainSearchTxt from "../../views/MainSearchTxt/MainSearchTxt";
import { hostInfo, sidebarVal } from "../../store";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 50,
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      paddingTop: 50,
    },
  },

  topbar: {
    background:
      "radial-gradient( circle farthest-corner at 3.8% 11.2%, rgba(89,162,162,1)  0%, rgba(153,215,184,61) 90.2% )",
    height: 50,
  },
  shiftContent: {
    paddingLeft: 65, // Default padding for mobile view
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 65, // Padding for tablets and larger screens
    },
    transition: "padding-left 0.3s ease", // Smooth transition for padding change
  },
  expandedShiftContent: {
    paddingLeft: 65, // Padding when sidebar is open
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 210, // Larger padding for tablets and larger screens
    },
    transition: "padding-left 0.3s ease",
  },
  content: {
    height: "auto",
    width: "auto",
    // background: "#e6e5e4",
    margin: theme.spacing(1),
    // [theme.breakpoints.up("lg")]: {
    //   marginTop: -15,
    //   // height: "calc(100% - 50px)",
    // },

    // marginTop: theme.spacing(-2),
    // minHeight: "100%",
  },
  scrn: {
    // background: "#e6e5e4",
    // height: "full",
  },
  drawer: {
    marginTop: 48,
    height: "calc(100% - 40px)",
    // [theme.breakpoints.down("xs")]: {
    //   width: 40,
    // },
  },
  root2: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "50%",
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
  root1: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  button: {
    color: theme.palette.my2.dark,
    padding: "8px 10px",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightMedium,
    display: "flex",
    alignItems: "center",
  },
  icon: {
    color: theme.palette.my2.main,
    width: 24,
    height: 24,
  },
  active: {
    color: theme.palette.my1.main,
    fontWeight: theme.typography.fontWeightMedium,
    "& $icon": {
      color: theme.palette.my1.main,
    },
  },
  justifyStart: {
    justifyContent: "flex-start",
    marginLeft: "10px",
    marginRight: "10px",
  },
  justifyCenter: {
    justifyContent: "center",
  },
}));

const Main = forwardRef((props, ref) => {
  const { open, onClose, className, children, variant, ...rest } = props;

  const classes = useStyles();
  const [openNav, setOpenNav] = useState(false);
  const isFingerPrintEnabled = false;

  const handleSidebarOpen = () => {
    setOpenNav(!openNav);
  };

  const [filteredNavConfig, setFilteredNavConfig] = useState(sidebarVal);

  const getToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.authToken;
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch(`${hostInfo}/usernames`, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        const a = getToken();
        const b = JSON.parse(atob(a.split(".")[1]));
        const user = data.users.filter((i) => i.username === b.username);

        if (user[0].accesses_flag === 4) {
          setFilteredNavConfig(
            sidebarVal.filter((item) => item.href !== "/maintenance")
          );
        } else if (
          user[0].accesses_flag === 1 ||
          user[0].accesses_flag === 2 ||
          user[0].accesses_flag === 3
        ) {
          setFilteredNavConfig(sidebarVal);
        } else if (user[0].accesses_flag === 0) {
          setFilteredNavConfig(
            sidebarVal.filter((item) => item.href === "/dashboard")
          );
        }
      } catch (error) {
        console.error("There was an error fetching the user info!", error);
      }
    };

    fetchUserInfo();
  }, []);

  const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref} style={{ flexGrow: 1 }}>
      <RouterLink {...props} />
    </div>
  ));
  const theme = useTheme();
  const isMobile = theme.breakpoints.down("xs");

  const drawerVariant = isMobile && openNav ? "temporary" : "persistent";
  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: !openNav, // Apply shiftContent when openNav is false
        [classes.expandedShiftContent]: openNav,
        [classes.scrn]: true,
      })}
    >
      <Topbar className={classes.topbar} />

      <Drawer
        anchor="left"
        open={true}
        onClose={onClose}
        classes={{
          paper: clsx(classes.drawer),
        }}
        variant={
          window.innerWidth < 600 && openNav ? "temporary" : "persistent"
        } // Or "temporary", "permanent"
        ref={ref}
      >
        <div
          {...rest}
          className={clsx(classes.root2, className)}
          style={{
            width: openNav ? 200 : 65,
            marginRight: openNav ? "10px" : "0px",
          }}
        >
          {/* <Divider className={classes.divider} /> */}
          <IconButton
            style={{ outline: "none", alignSelf: "flex-start" }}
            className={classes.root1}
            color="inherit"
            onClick={handleSidebarOpen}
          >
            {openNav ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
          </IconButton>
          {isFingerPrintEnabled ? (
            <MainSearch />
          ) : (
            <MainSearchTxt openNav={openNav} />
          )}
          <List {...rest} className={clsx(classes.root2, className)}>
            {filteredNavConfig.map((text, index) => (
              <Button
                key={index}
                activeClassName={classes.active}
                className={clsx(
                  classes.button,
                  openNav ? classes.justifyStart : classes.justifyCenter
                )}
                component={CustomRouterLink}
                to={text.href}
              >
                <div className={classes.icon}>{text.icon}</div>
                {openNav && (
                  <span style={{ paddingLeft: "8px", paddingTop: "3px" }}>
                    {text.title}
                  </span>
                )}
              </Button>
            ))}
          </List>
        </div>
      </Drawer>

      <main className={classes.content}>{children}</main>
    </div>
  );
});

Main.propTypes = {
  children: PropTypes.node,
};

export default Main;
