import React, { forwardRef } from "react";

// Use forwardRef to properly handle the reference passed by useReactToPrint
const PdfValue = forwardRef((props, ref) => {
  const { meds, values } = props;

  return (
    <div
      ref={ref} // Assign ref for printing
      id="pdf-element"
      style={{
        width: "100%",
        padding: "20px",
        border: "1px solid #ddd",
        borderRadius: "4px",
        fontSize: "16px",
      }}
    >
      <h2>{values} Certificate</h2>
      <hr></hr>
      <p>
        <strong>Name:</strong> {meds.medName}
      </p>
      <p>
        <strong>Purpose:</strong> {meds.medType}
      </p>
    </div>
  );
});

export default PdfValue;
