// import React from "react";
// import Chart from "react-apexcharts";
// import { hostInfo } from "../../../../store";
// // import ApexCharts from "ApexCharts";

// class DailyGraph extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       series: [],
//       options: {
//         chart: {
//           type: "bar",
//           // height: 350,
//           stacked: true,
//           toolbar: {
//             show: true,
//           },
//           zoom: {
//             enabled: true,
//           },
//         },
//         responsive: [
//           {
//             breakpoint: 480,
//             options: {
//               legend: {
//                 position: "bottom",
//                 offsetX: -10,
//                 offsetY: 0,
//               },
//             },
//           },
//         ],
//         title: {
//           text: "Daily Revenue",
//         },
//         plotOptions: {
//           bar: {
//             horizontal: false,
//             borderRadius: 10,
//           },
//         },
//         // colors: ["#F07FB5", "#F0B47F"],
//         xaxis: {
//           type: "datetime",
//           categories: [],
//         },
//         legend: {
//           position: "right",
//           offsetY: 40,
//         },
//         fill: {
//           opacity: 1,
//         },
//       },
//     };
//   }

//   async componentDidMount() {
//     const amountTotal = [];
//     const amountPaid = [];
//     const amountRemain = [];
//     console.log(amountRemain);
//     const days = [];
//     const getToken = () => {
//       const cookieString = document.cookie;
//       const cookies = cookieString.split("; ").reduce((acc, cookie) => {
//         const [name, value] = cookie.split("=");
//         acc[name] = value;
//         return acc;
//       }, {});
//       return cookies.authToken;
//     };

//     let url = hostInfo + "/getdailyrevenue";
//     fetch(url, {
//       headers: {
//         Authorization: `Bearer ${getToken()}`,
//       },
//     })
//       .then((res) => res.json())
//       .then((data) => {
//         console.log(data);
//         // console.log("Got data -----------11111111111111");
//         if (data !== null) {
//           // console.log("Got data length: ", data.length);
//           // console.log(data);
//           // this.medSug = data;
//           for (var i = 0; i < data?.length; i++) {
//             amountTotal.push(data[i]["total"]);
//             amountPaid.push(data[i]["paid"]);
//             amountRemain.push(data[i]["remain"]);
//             console.log(amountRemain.push(data[i]["remain"]));
//             days.push(data[i]["days"] + " GMT");
//             // console.log(data[i]);
//           }
//         }

//         this.setState({
//           ...this.state,
//           options: {
//             chart: {
//               id: "daily-revenue",
//             },
//             zoom: {
//               enabled: true,
//             },
//             xaxis: {
//               type: "datetime",
//               categories: days,
//             },
//           },
//           series: [
//             {
//               name: "Remain",
//               data: amountRemain,
//             },
//             {
//               name: "Paid",
//               data: amountPaid,
//             },
//           ],
//         });
//       });
//   }

//   render() {
//     return (
//       <Chart
//         options={this.state.options}
//         series={this.state.series}
//         type="bar"
//         // width={800}
//         height={400}
//       />
//     );
//   }
// }

// export default DailyGraph;

import React from "react";
import Chart from "react-apexcharts";
import { hostInfo } from "../../../../store";

class DailyGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        { name: "Remain", data: [] },
        { name: "Paid", data: [] },
      ],
      options: {
        chart: {
          id: "daily-revenue",
          type: "bar",
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        title: {
          text: "Daily Revenue",
        },
        legend: {
          position: "right",
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
        xaxis: {
          categories: [],
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
      },
    };
  }

  async componentDidMount() {
    const amountPaid = [];
    const amountRemain = [];
    const days = [];

    const getToken = () => {
      const cookieString = document.cookie;
      const cookies = cookieString.split("; ").reduce((acc, cookie) => {
        const [name, value] = cookie.split("=");
        acc[name] = value;
        return acc;
      }, {});
      return cookies.authToken;
    };

    const url = `${hostInfo}/getdailyrevenue`;

    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });

      const data = await response.json();

      if (Array.isArray(data) && data.length > 0) {
        data.forEach((item) => {
          if (
            item.paid !== undefined &&
            item.remain !== undefined &&
            item.days
          ) {
            amountPaid.push(item.paid);
            amountRemain.push(item.remain);
            const date = new Date(item.days);
            const options = { day: "2-digit", month: "short" };
            const formattedDate = date.toLocaleString("en-GB", options);
            days.push(formattedDate);
          }
        });

        this.setState({
          series: [
            { name: "Remain", data: amountRemain },
            { name: "Paid", data: amountPaid },
          ],
          options: {
            ...this.state.options,
            xaxis: {
              ...this.state.options.xaxis,
              categories: days,
            },
          },
        });
      } else {
        console.error("Invalid data format or empty array received from API");
        this.setState({
          series: [
            { name: "Remain", data: [0] },
            { name: "Paid", data: [0] },
          ],
          options: {
            ...this.state.options,
            xaxis: {
              ...this.state.options.xaxis,
              categories: ["No data"],
            },
          },
        });
      }
    } catch (error) {
      console.error("Failed to fetch daily revenue data:", error);
      this.setState({
        series: [
          { name: "Remain", data: [0] },
          { name: "Paid", data: [0] },
        ],
        options: {
          ...this.state.options,
          xaxis: {
            ...this.state.options.xaxis,
            categories: ["Error"],
          },
        },
      });
    }
  }

  render() {
    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type="bar"
        height={400}
      />
    );
  }
}

export default DailyGraph;
