import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { hostInfo } from "../../store";
// import  "../../../fingerscripts/jquery-1.8.2";
// import "../../../fingerscripts/mfs100";
// import * as finger "../../../fingerscripts/finger";

// import { ICountry, IState, ICity } from "country-state-city";
import { Paper } from "@material-ui/core";
import FingerScan from "./FingerScan";
import { VerifyFinger } from "./mfs100";

// const genderArr = [
//   {
//     value: "M",
//     label: "Male",
//   },
//   {
//     value: "F",
//     label: "Female",
//   },
//   {
//     value: "O",
//     label: "Other",
//   },
// ];

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      minWidth: 250,
    },
  },
  imgpaper: {
    padding: theme.spacing(2),
    margin: "auto",
    // with: "150px",
    height: "220px",
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

async function VerifyMatch(scannedKey, dbKey) {
  try {
    var res = await VerifyFinger(scannedKey, dbKey);
    // console.log("Inside matching ....");

    if (res.httpStaus) {
      if (res.data.Status) {
        // console.log("Finger is matched --- ");
        return true;
      } else {
        if (res.data.ErrorCode != "0") {
          // alert(res.data.ErrorDescription);
        } else {
          return false;
        }
      }
    } else {
      // alert(res.err);
    }
  } catch (e) {
    // alert(e);
  }
  return false;
}

const MainSearchPatient = (props) => {
  const classes = useStyles();
  const { open, closeHdl } = props;
  const [patientFingerList, setPatientFingerList] = useState([]);
  const { isoKey, setIsoKey } = useState("");
  const getToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.authToken;
  };

  useEffect(() => {
    async function getPtFinger() {
      // console.log(" ********** Fetching Fingers ************ ");
      let url = hostInfo + "/getptFinger";
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res != null && res.length > 0) {
            setPatientFingerList(res);
          } else {
            setPatientFingerList([]);
          }
        });
    }

    getPtFinger();
  }, []);

  const handleFingerScan = async (value) => {
    // console.log("Scanened iso_key ... : ", value);
    // console.log("patient finger List ... : ");
    // console.log(patientFingerList);
    for (var i = 0; i < patientFingerList.length; i++) {
      // console.log("In match loop ----------------------------------------");
      if (
        patientFingerList[i].iso_key &&
        patientFingerList[i].iso_key.length > 1
      ) {
        var ret = await VerifyMatch(value, patientFingerList[i].iso_key);
        if (ret === true) {
          // console.log("Finger is matched----");
          handlePatientClick(patientFingerList[i].id);
          break;
        }
      }
    }
  };

  const handlePatientClick = (id) => {
    if (id > 0) {
      closeHdl(false);
      const json_id = {
        patient_id: id,
        opd_id: 0,
      };
      const jsonString = JSON.stringify(json_id);

      // Encode the string to Base64
      const base64Encoded = btoa(jsonString);
      window.location = "/patients/patientprofile?id=" + base64Encoded;
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={closeHdl}
        // aria-labelledby="Add User"
      >
        <DialogTitle id="SearchPatient">Finger Scan</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Press SCAN button and scan patient's finger
          </DialogContentText>
          <Paper className={classes.imgpaper}>
            <FingerScan handleFingerScan={handleFingerScan} />
          </Paper>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MainSearchPatient;
