import React, { Fragment } from "react";

import { withStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { hostInfo } from "../../../store";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
// import  "../../../fingerscripts/jquery-1.8.2";
// import "../../../fingerscripts/mfs100";
// import * as finger "../../../fingerscripts/finger";
import moment from "moment";

import FormGroup from "@material-ui/core/FormGroup";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

//import { countryArr } from "../../../../store";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { Country, State, City } from "country-state-city";

// import { ICountry, IState, ICity } from "country-state-city";
import { Grid, Paper } from "@material-ui/core";
import FingerScan from "../../../FingerScan/FingerScan";
import { appendScript } from "../../Patients/components/diaComponent/AppendScript";

const styles = (theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      minWidth: 250,
    },
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  formControl: {
    // margin: theme.spacing(1),
    width: 250,
  },
  grpControl: {
    marginTop: theme.spacing(2),
    width: 250,
  },
  imgpaper: {
    padding: theme.spacing(2),
    margin: "auto",
    // with: "150px",
    height: "220px",
  },
  countryControl: {
    marginRight: theme.spacing(1),
    width: 165,
  },
  toggleSwitch: {
    marginTop: theme.spacing(3),
  },
  location: {
    direction: "row",
    alignItems: "spce-between",
  },
  // gendercls: {
  //   // margin: theme.spacing(1),
  //   width: 250,
  // },
  addressControl: {
    minWidth: "95%",
  },
  autocomp: {
    '&[data-focus="true"], &:hover': {
      backgroundColor: "#CFE8A9",
    },
  },
});

// const genderArr = [
//   {
//     value: "M",
//     label: "Male",
//   },
//   {
//     value: "F",
//     label: "Female",
//   },
//   {
//     value: "O",
//     label: "Other",
//   },
// ];

export default withStyles(styles)(
  class AddNewUser extends React.Component {
    state = {
      open: false,
      selectedFilter: "",
      formdetail: {
        name: "",
        email: "",
        password: "",
        emailError: false,
        emailErrorTxt: "",
        nameError: false,
        addressError: false,
        nameErrorTxt: "",

        addressErrorTxt: "",
      },
    };

    componentDidMount() {
      appendScript("/fingerscripts/jquery-1.8.2.js");
      appendScript("/fingerscripts/mfs100.js");
      appendScript("/fingerscripts/finger.js");
    }

    validate = () => {
      let isError = false;
      const { formdetail } = this.state;

      // Clean old error status

      formdetail.nameError = false;

      formdetail.addressError = false;

      formdetail.nameErrorTxt = "";

      formdetail.addressErrorTxt = "";

      // Username Validation----
      // 1. Length
      // 2. special character check
      if (formdetail.name.length < 2) {
        isError = true;
        formdetail.nameError = true;
        formdetail.nameErrorTxt = "Name needs to be atleast 2 characters long";
      }
      if (formdetail.email.length < 1) {
        formdetail.email = "-";
      }
      // Lastname Validation

      // illness Validation

      // console.log(
      //   "Selected C: " +
      //     this.selectedCountry +
      //     "S: " +
      //     this.selectedState +
      //     "city: " +
      //     this.selectedCity
      // );
      // console.log(this.cityArr);
      // Country, state, city validation

      // City

      this.setState({
        ...this.state.formdetail,
      });

      return isError;
    };

    handleSubmit = async () => {
      const getToken = () => {
        const cookieString = document.cookie;
        const cookies = cookieString.split("; ").reduce((acc, cookie) => {
          const [name, value] = cookie.split("=");
          acc[name] = value;
          return acc;
        }, {});
        return cookies.authToken;
      };

      const err = this.validate(); // Make sure this.validate() works as intended
      if (!err) {
        // On submit form success
        let { formdetail } = this.state;
        let formObj = {
          name: formdetail.name,
          email: formdetail.email,
          password: formdetail.password,
          accesses_flag: this.state.selectedFilter === "Doctor" ? 3 : 4,
        };

        let url = hostInfo + "/signUp";
        try {
          const response = await fetch(url, {
            method: "POST",
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify(formObj),
          });

          if (response.status === 200) {
            const data = await response.json();
            // Reset form data on successful signup
            this.setState({
              formdetail: {
                name: "",
                email: "",
                password: "",
                selectedFilter: "",
              },
            });
            this.props.closeHdl();
            window.location.reload();
            //   this.setState({ redirect: true }); // Redirect to the dashboard or any protected route
          } else {
            const errorData = await response.json();
            alert(errorData.error); // Show error message if signup fails
          }
        } catch (error) {
          console.error("Error submitting form:", error);
          alert("An error occurred while submitting the form.");
        }

        // Optionally close modal or perform other actions
        this.props.closeHdl();

        return true;
      }

      // If validation fails
      console.log("Validation failed, Error in Submit--------");
      return false;
    };

    handleClickOpen = () => {
      this.setState({ open: true });
    };

    handleClose = () => {
      this.setState({ open: false });
    };

    handleNumericChange = (name, val) => {
      // console.log(name);
      // console.log(val);
      const value = val.floatValue;
      this.setState({
        formdetail: { ...this.state.formdetail, [name]: value },
      });
    };

    handleMultiFld = (e, name, value) => {
      // console.log("------ Inside Multifiled handler ------");
      // console.log(name);
      // console.log(value);
      this.setState({
        formdetail: { ...this.state.formdetail, [name]: value.id },
      });
    };

    handleOnChange = (e, name) => {
      // console.log("On Change log.......");
      // console.log(name);
      // console.log(e.target.value);

      const value = e.target.value;
      this.setState({
        ...this.state,
        formdetail: { ...this.state.formdetail, [name]: value },
      });
    };

    handleDateChange = (name, value) => {
      // setSelectedEndDate(date);
      // console.log("On Date change log.......");
      // console.log(name);
      // console.log(value);
      this.setState({
        ...this.state,
        formdetail: { ...this.state.formdetail, birthdate: value },
      });
    };

    mask = "/[1-4]/g";

    searchFromArray(myArray, id) {
      for (var i = 0; i < myArray.length; i++) {
        if (myArray[i].id === id) {
          return myArray[i].name;
        }
      }
      return " ";
    }
    validateInArry(myArray, nameKey) {
      for (var i = 0; i < myArray.length; i++) {
        if (myArray[i].name === nameKey) {
          return true;
        }
      }
      return false;
    }

    handleDisability = (name, value) => {
      // console.log(name);
      // console.log(value.target.checked);

      this.setState({
        formdetail: {
          ...this.state.formdetail,
          [name]: value.target.checked,
        },
      });
    };

    handleFilterChange = (e) => {
      this.setState({ selectedFilter: e.target.value });
    };

    render() {
      const {
        // open,
        formdetail: {
          name,

          // group_id,
          email,
          password,
          nameError,
          emailError,
          emailErrorTxt,
          addressError,

          nameErrorTxt,

          addressErrorTxt,
        },
      } = this.state;
      const { countryArr, stateArr, cityArr } = this;
      // const {  } = this.props;
      console.log(this.state.selectedFilter, email, name, password);

      const { classes, closeHdl, open } = this.props;
      return (
        // <div>
        <Fragment>
          <Dialog
            open={open}
            onClose={closeHdl}
            // aria-labelledby="Add User"
          >
            <DialogTitle id="AddNewPatient">User Details</DialogTitle>
            <DialogContent>
              {/* <DialogContentText>
                Fill the certificate details
              </DialogContentText> */}
              <form className={classes.root} autoComplete="off">
                <TextField
                  className={classes.addressControl}
                  autoFocus
                  margin="dense"
                  id="name"
                  error={nameError}
                  value={name}
                  onChange={(e) => {
                    this.handleOnChange(e, "name");
                  }}
                  label="Name"
                  type="name"
                  helperText={nameErrorTxt}
                  InputLabelProps={{
                    style: { color: "#71717A" },
                  }}
                />

                {/*
                // Mitesh: Temp change as per costomer req 
                <TextField
                  // autoFocus
                  id="email"
                  error={emailError}
                  value={email}
                  onChange={(e) => {
                    this.handleOnChange(e, "email");
                  }}
                  label="Email"
                  type="email"
                  helperText={emailErrorTxt}
                /> */}

                {/* <div
                  style={{ width: "150px", height: "190px", align: "center" }}
                >
                  <img
                    id="imgFinger"
                    width="145px"
                    height="188px"
                    alt="Finger Image"
                  />
                </div> */}

                <TextField
                  //////////////////  Address
                  className={classes.addressControl}
                  // autoFocus

                  margin="dense"
                  id="Password"
                  error={addressError}
                  value={password}
                  onChange={(e) => {
                    this.handleOnChange(e, "password");
                  }}
                  label="Password"
                  type="password" // Ensure the type is 'text' (default)
                  //   multiline // Enables the textarea functionality
                  //   rows={3}
                  // fullWidth
                  helperText={addressErrorTxt}
                  InputLabelProps={{
                    style: { color: "#71717A" },
                  }}
                />
                <TextField
                  //////////////////  Address
                  className={classes.addressControl}
                  // autoFocus

                  margin="dense"
                  id="Email"
                  error={emailError}
                  value={email}
                  onChange={(e) => {
                    this.handleOnChange(e, "email");
                  }}
                  label="Email"
                  type="email" // Ensure the type is 'text' (default)
                  //   multiline // Enables the textarea functionality
                  //   rows={3}
                  // fullWidth
                  helperText={emailErrorTxt}
                  InputLabelProps={{
                    style: { color: "#71717A" },
                  }}
                />

                {/* <InputLabel id="filter-label">Filter by Purpose</InputLabel> */}
                <FormControl
                  style={{ padding: "12px 18px 0px 8px" }}
                  //   className={classes.addressControl}
                  fullWidth
                  margin="normal"
                >
                  {/* Label for Filter by Purpose */}
                  <InputLabel id="filter-purpose-label">Chooes Role</InputLabel>
                  <Select
                    labelId="filter-purpose-label" // Associates the label with this Select
                    id="filter-dropdown"
                    value={this.state.selectedFilter}
                    onChange={this.handleFilterChange}
                    label="Chooes Role"
                  >
                    <MenuItem value="Doctor">Doctor</MenuItem>
                    <MenuItem value="Reception">Reception</MenuItem>
                  </Select>
                </FormControl>
              </form>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.handleSubmit();
                }}
                color="primary"
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </Fragment>
        // </div>
      );
    }
  }
);
