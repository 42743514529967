import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { IconButton } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { hostInfo } from "../../store";
// import  "../../../fingerscripts/jquery-1.8.2";
// import "../../../fingerscripts/mfs100";
// import * as finger "../../../fingerscripts/finger";

// import { ICountry, IState, ICity } from "country-state-city";
import { Grid } from "@material-ui/core";
// import FingerScan from "./FingerScan";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from "@material-ui/icons/Search";

// const genderArr = [
//   {
//     value: "M",
//     label: "Male",
//   },
//   {
//     value: "F",
//     label: "Female",
//   },
//   {
//     value: "O",
//     label: "Other",
//   },
// ];

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      minWidth: 250,
    },
  },
  imgpaper: {
    padding: theme.spacing(2),
    margin: "auto",
    width: "500px",
    marginRight: "-20px",
    // height: "220px",
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

// async function VerifyMatch(scannedKey, dbKey) {
//   try {
//     var res = await VerifyFinger(scannedKey, dbKey);
//     // console.log("Inside matching ....");

//     if (res.httpStaus) {
//       if (res.data.Status) {
//         // console.log("Finger is matched --- ");
//         return true;
//       } else {
//         if (res.data.ErrorCode != "0") {
//           // alert(res.data.ErrorDescription);
//         } else {
//           return false;
//         }
//       }
//     } else {
//       // alert(res.err);
//     }
//   } catch (e) {
//     // alert(e);
//   }
//   return false;
// }

const MainSearchPatientTxt = (props) => {
  const classes = useStyles();
  const { open, closeHdl } = props;
  const [selectedId, setSelectedId] = useState(0);
  const [patientFingerList, setPatientFingerList] = useState([]);
  const { isoKey, setIsoKey } = useState("");
  // console.log(patientFingerList);
  const getToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.authToken;
  };

  useEffect(() => {
    async function getPtFinger() {
      console.log(" ********** Fetching Fingers ************ ");
      let url = hostInfo + "/getptsearch";
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res != null && res.length > 0) {
            setPatientFingerList(res);
          } else {
            setPatientFingerList([]);
          }
        });
    }

    getPtFinger();
  }, []);

  // const handleFingerScan = async (value) => {
  //   console.log("Scanened iso_key ... : ", value);
  //   console.log("patient finger List ... : ");
  //   console.log(patientFingerList);
  //   // for (var i = 0; i < patientFingerList.length; i++) {
  //   //   // console.log("In match loop ----------------------------------------");
  //   //   if (
  //   //     patientFingerList[i].iso_key &&
  //   //     patientFingerList[i].iso_key.length > 1
  //   //   ) {
  //   //     var ret = await VerifyMatch(value, patientFingerList[i].iso_key);
  //   //     if (ret === true) {
  //   //       console.log("Finger is matched----");
  //   //       handlePatientClick(patientFingerList[i].id);
  //   //       break;
  //   //     }
  //   //   }
  //   // }
  // };

  const handleOnSelect = (value) => {
    // console.log("Selected value ....");
    // console.log(value);
    var id = value.id;
    // console.log("Selected id ....", id);
    setSelectedId(id);
  };

  const handleSubmit = () => {
    // console.log("Handle Submit value ....");

    if (selectedId > 0) {
      closeHdl(false);
      const json_id = {
        patient_id: selectedId,
        opd_id: 0,
      };
      const jsonString = JSON.stringify(json_id);

      // Encode the string to Base64
      const base64Encoded = btoa(jsonString);
      window.location = "/patients/patientprofile?id=" + base64Encoded;
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={closeHdl}
        // aria-labelledby="Add User"
      >
        <DialogTitle id="SearchPatient">Search Patient</DialogTitle>
        <DialogContent style={{ marginTop: "-30px" }}>
          {/* <DialogContentText>Search Patient</DialogContentText> */}
          {/* <Paper className={classes.imgpaper}> */}

          <Grid
            container
            direction="row"
            spacing={1}
            className={classes.imgpaper}
          >
            <Grid item xs={10}>
              <Autocomplete
                //////////////////// Multifield
                // multiple
                id="multi field"
                options={patientFingerList}
                getOptionLabel={(option) => option.mobile}
                filterSelectedOptions
                size="small"
                freeSolo
                autoHighlight={true}
                // classes={{ option: classes.autocomp }}
                onChange={(e, newValue) => handleOnSelect(newValue)}
                renderOption={(option) => (
                  <React.Fragment>
                    {/* <span>{countryToFlag(option.code)}</span> */}
                    {option.mobile}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Phone Number"
                    // placeholder="ptNotes"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={10}>
              <Autocomplete
                //////////////////// Multifield
                // multiple
                id="multi field"
                options={patientFingerList}
                getOptionLabel={(option) => option.name}
                filterSelectedOptions
                size="small"
                freeSolo
                autoHighlight={true}
                // classes={{ option: classes.autocomp }}
                onChange={(e, newValue) => handleOnSelect(newValue)}
                renderOption={(option) => (
                  <React.Fragment>
                    {/* <span>{countryToFlag(option.code)}</span> */}
                    {option.name} ({option.gender}) | {option.mobile}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Patient Name"
                    // placeholder="ptNotes"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={2}>
              <IconButton
                // size="small"
                onClick={() => {
                  handleSubmit();
                }}
                style={{ marginTop: "10px", color: "#FF4A4A" }}
                component="span"
              >
                <SearchIcon />
              </IconButton>
              {/* <Button
                  onClick={() => {
                    handleSubmit();
                  }}
                  variant="outlined"
                >
                  Save
                </Button> */}
            </Grid>
          </Grid>
          {/* </Paper> */}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MainSearchPatientTxt;
