import React, { Fragment, createRef } from "react";
import {
  Container,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
import { withStyles } from "@material-ui/styles";
import {
  Button,
  Divider,
  Checkbox,
  Grid,
  Typography,
  InputAdornment,
  Modal,
  Popper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { hostInfo } from "../../../store";
// import FuncComp from "./testFunc";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SaveIcon from "@mui/icons-material/Save";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddKwDia from "./AddKwDia";
import ClearIcon from "@mui/icons-material/Clear";
import FormMedicine from "./components/Medicine";
import FormReport from "./components/Report";
// import RemoveIcon from "@material-ui/icons/Remove";

import FormGroup from "@material-ui/core/FormGroup";
import { visitFormfields } from "../../../store";

//import { countryArr } from "../../../../store";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useRef } from "react";
import { Search } from "@material-ui/icons";
import e from "cors";
// import PrintHtml from "./PrintHtml/PrintHtml";
// import HtmlContentByInfo from "./PrintHtml/HtmlContentByInfo";

const styles = (theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      // width: 230,
    },
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  saveBtn: {
    background: "#94B49F",
    borderRadius: 3,
    border: 0,
    color: "white",
    // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    "&:hover": {
      background: "#C6A3D4",
    },
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  formControl: {
    // margin: theme.spacing(1),
    width: 230,
  },
  // countryControl: {
  //   // margin: theme.spacing(1),
  //   minWidth: 150,
  // },
  countryControl: {
    marginRight: theme.spacing(1),
    minWidth: 170,
  },
  insurance: {
    // width: 230,
    marginTop: theme.spacing(2),
  },
  bloodGroupFld: {
    minWidth: 150,
  },
  seprator: {
    margin: theme.spacing(2),
  },
  seprator1: {
    marginTop: theme.spacing(2),
  },
  refCls: {
    color: "#D46D91",
    fontWeight: "bold",
    marginTop: theme.spacing(2),
  },
  seprator2: {
    width: "100%",
    ...theme.typography.body2,
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  multilineFld: {
    display: "flex",
    flexWrap: "wrap",
  },
  amountGreen: {
    borderColor: "pink",
  },
  specialOutline: {
    borderColor: "pink",
    //borderWidth: 4
  },
  presc: {
    width: "100%",
  },
  autocomp: {
    '&[data-focus="true"], &:hover': {
      backgroundColor: "#CFE8A9",
    },
  },
  cardClr: {
    background: "#F3F8F8",
  },
  freeTextNotes: {
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

var countryStateCity = require("country-state-city").default;

export default withStyles(styles)(
  class Advice extends React.Component {
    containerRef = createRef();

    state = {
      search: "",
      dialogOpen: false,
      templateName: "",
      isSuggestionOpen: 1,

      openSuggestionIndex: false,
      templates: [],
      isListVisible: false,
      isTemplateClicked: false,
      isSaveTemplateClicked: false,
      isLoadPreviousClicked: false,

      frequencyRefs: [],
      severityRefs: [],
      durationRefs: [],
      selectedComplaint: null,
      openModal: false,
      modalRowIndex: null,
    };

    // countryArr = countryStateCity.getAllCountries();
    stateArr = [];
    cityArr = [];
    selectedCountry = "";
    selectedState = "";
    selectedCity = "";
    stateDisabled = true;
    cityDisabled = true;
    // frequencyRefs = [];
    // severityRefs = [];
    // durationRefs = [];
    medSug = [];
    repSug = [];
    drSug = [];
    symSug = [];
    disSug = [];
    drGroups = [];

    // handleLoadPrevious = async () => {
    //   this.setState({ isLoadPreviousClicked: true });
    //   const getToken = () => {
    //     const cookieString = document.cookie;
    //     const cookies = cookieString.split("; ").reduce((acc, cookie) => {
    //       const [name, value] = cookie.split("=");
    //       acc[name] = value;
    //       return acc;
    //     }, {});
    //     return cookies.authToken;
    //   };

    //   try {
    //     const response = await fetch(
    //       `${hostInfo}/advice/${this.props.patient_id}`,
    //       {
    //         headers: {
    //           Authorization: `Bearer ${getToken()}`,
    //         },
    //       }
    //     );

    //     if (!response.ok) {
    //       throw new Error("Failed to load previous diagnosis");
    //     }

    //     const data = await response.json();

    //     const shouldAddPreviousData =
    //       this.state.rows.length > 0 && this.state.rows[0].diagnosis !== "";

    //     // Create the new rows based on the previous data and current state
    //     const newRows = [
    //       ...(shouldAddPreviousData ? this.state.rows.slice(0, -1) : []), // Remove last row if the first row has data
    //       ...(data || []), // Add previous data
    //       {
    //         diagnosis: "",

    //         duration: "",
    //         date: "",
    //         inputNumber: "",
    //       },
    //     ];

    //     // Update state with new rows and reset suggestion state to prevent autocomplete opening
    //     this.setState({
    //       rows: newRows,
    //       isSuggestionOpen: -1, // Close duration suggestions
    //       isfrequencySuggestionOpen: -1, // Close frequency suggestions
    //       isseveritySuggestionOpen: -1, // Close severity suggestions
    //       // Reset other necessary suggestion states if needed
    //     });
    //     this.props.onRowsChange(newRows);
    //   } catch (error) {
    //     console.error("Error loading previous diagnosis:", error);
    //   }
    // };

    handleLoadPrevious = async () => {
      this.setState({ isLoadPreviousClicked: true });

      const getToken = () => {
        const cookieString = document.cookie;
        const cookies = cookieString.split("; ").reduce((acc, cookie) => {
          const [name, value] = cookie.split("=");
          acc[name] = value;
          return acc;
        }, {});
        return cookies.authToken;
      };

      try {
        const response = await fetch(
          `${hostInfo}/advice/${this.props.patient_id}`,
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to load previous report");
        }

        // Try parsing as JSON
        let data;
        try {
          data = await response.json();
          // console.log("JSON response:", data); // Log parsed JSON data
        } catch (parseError) {
          const textData = await response.text(); // Fallback for non-JSON response
          console.error("Response is not JSON:", textData);
          throw new Error("Invalid JSON response");
        }
        this.props.onAdviceChange(data);
        // Handle the data (assuming `data` is valid JSON)
        // console.log(data); // Log the fetched data
      } catch (error) {
        console.error("Error loading previous reports:", error);
      }
    };

    handleDialogOpen = () => {
      this.setState({
        isfrequencySuggestionOpen: -1,
        isseveritySuggestionOpen: -1,
        isSuggestionOpen: -1,
      });

      this.setState({ isSaveTemplateClicked: true });
      this.setState({ dialogOpen: true });
    };

    handleDialogClose = () => {
      this.setState({ isSaveTemplateClicked: false });
      this.setState({ dialogOpen: false });
    };

    handleSaveTemplate = () => {
      // Function to get the token from cookies
      const getToken = () => {
        const cookieString = document.cookie;
        const cookies = cookieString.split("; ").reduce((acc, cookie) => {
          const [name, value] = cookie.split("=");
          acc[name] = value;
          return acc;
        }, {});
        return cookies.authToken;
      };

      const { templateName, rows } = this.state;

      // Check if rows contain valid data
      const shouldAddPreviousData = this.props.drNotes !== "";

      if (shouldAddPreviousData && templateName) {
        // Payload to send in the API request
        const savedData = {
          name: templateName,
          json_data: [{ notes: this.props.drNotes }],
        };

        fetch(`${hostInfo}/addAdviceTemplate`, {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${getToken()}`, // Ensure correct authorization token
          },
          body: JSON.stringify(savedData), // Send the template data
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Failed to save template"); // Handle response errors
            }
            return response.json();
          })
          .then((data) => {
            console.log("API Response:", data);

            if (data.message) {
              alert("Advice Template saved successfully");

              // Update the templates list and reset form fields
              this.setState((prevState) => ({
                templates: [...(prevState.templates || []), savedData], // Add saved template
                templateName: "", // Clear templateName
                rows: [], // Clear rows (or reset to default structure if necessary)
              }));
            } else {
              alert("Error saving template: " + data.error); // Display error if available
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            alert("Failed to save template. Please try again.");
          });

        this.handleDialogClose(); // Close dialog
      } else {
        alert("Please enter a template name and fill out the required fields.");
        this.handleDialogClose(); // Close dialog even on validation failure
        this.setState({ templateName: "" }); // Reset templateName field
      }
    };

    toggleTemplateList = () => {
      this.setState((prevState) => ({
        isListVisible: !prevState.isListVisible, // Toggle the visibility state
      }));
      this.setState({ isTemplateClicked: !this.state.isTemplateClicked });
      this.setState({
        isfrequencySuggestionOpen: -1,
        isseveritySuggestionOpen: -1,
        isSuggestionOpen: -1,
      });
    };
    handleTemplateSelect = (template) => {
      const selectedNotes = template.json_data[0].notes; // Ensure the correct field is selected
      this.props.onAdviceChange(selectedNotes); // Update `drNotes` via props

      // Combine all setState calls into one for better performance
      this.setState({
        isListVisible: false, // Close the suggestion list
        isTemplateClicked: false, // Reset the template click state
        openSuggestionIndex: false, // Reset suggestion index
      });
    };

    handleSearchChange = (event) => {
      this.setState({ search: event.target.value }); // Update search term
    };

    componentDidMount() {
      document.addEventListener("mousedown", this.handleClickOutside);
      document.addEventListener("keydown", this.handleKeyNavigation);
      // document.addEventListener("mousedown", this.handleClickOutside);
      const getToken = () => {
        const cookieString = document.cookie;
        const cookies = cookieString.split("; ").reduce((acc, cookie) => {
          const [name, value] = cookie.split("=");
          acc[name] = value;
          return acc;
        }, {});
        return cookies.authToken;
      };

      // this.fetchTemplates = () => {
      fetch(hostInfo + "/getadvicetemplates", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((response) => {
          // Return the JSON response
          return response.json(); // Added return statement here
        })
        .then((data) => {
          this.setState({ templates: data }); // Update state with fetched data
          // Log fetched data
        })
        .catch((error) => {
          console.error("Error fetching templates:", error); // Log any errors
        });

      // };
    }

    componentWillUnmount() {
      document.removeEventListener("mousedown", this.handleClickOutside);
      document.removeEventListener("keydown", this.handleKeyNavigation);
    }

    handleClickOutside = (event) => {
      if (
        this.containerRef.current &&
        !this.containerRef.current.contains(event.target)
      ) {
        this.setState({ isListVisible: false, isTemplateClicked: false });
      }
    };
    handleKeyNavigation = (event) => {
      const { templates, highlightedIndex, isListVisible } = this.state;

      // Only trigger if the suggestion list is visible
      if (!isListVisible) return;

      // Prevent default scrolling behavior when navigating suggestions
      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        event.preventDefault();
      }

      // Navigate down (ArrowDown)
      if (event.key === "ArrowDown") {
        this.setState((prevState) => ({
          highlightedIndex: Math.min(
            prevState.highlightedIndex + 1,
            templates.length - 1
          ),
        }));
      }

      // Navigate up (ArrowUp)
      if (event.key === "ArrowUp") {
        this.setState((prevState) => ({
          highlightedIndex: Math.max(prevState.highlightedIndex - 1, 0),
        }));
      }

      // Select the highlighted suggestion (Enter key)
      if (event.key === "Enter" && highlightedIndex >= 0) {
        this.handleTemplateSelect(
          this.props.idx,
          "repName",
          templates[highlightedIndex]
        );
      }
    };

    handleClose = () => {
      this.setState({ open: false });
    };

    handleDiaClickOpen = () => {
      this.setState({ openDia: true });
    };

    handleDiaClickClose = () => {
      this.setState({ openDia: false });
    };

    // handleOnChange = (e, name) => {
    //   const value = e.target.value;
    //   console.log("Value change --- ");
    //   console.log(e.target);
    //   console.log(name);
    //   console.log(value);
    //   this.setState({
    //     ...this.state,
    //     formdetail: {
    //       ...this.state.formdetail,
    //       [name]: value,
    //     },
    //   });
    // };

    // handleMultiFld = (name, value) => {
    //   // console.log("------ Inside Multifiled handler ------");
    //   // console.log(name);
    //   // console.log(value);
    //   this.setState({
    //     ...this.state,
    //     formdetail: {
    //       ...this.state.formdetail,
    //       [name]: value,
    //     },
    //   });
    //   this.props.onAdviceChange(value);
    // };

    // Mitesh: TODO make index incremental

    render() {
      const {
        dialogOpen,
        templateName,
        templates,
        search,
        isTemplateClicked,

        isSaveTemplateClicked,
        isListVisible,
      } = this.state;
      const maxVisibleSuggestions = 4; // Maximum number of visible suggestions
      const suggestionHeight = templates?.length > 0 ? 42 : 60; // Height for each suggestion item (in px)
      const searchBarHeight = 30; // Height of the search bar

      // Calculate the height of the div based on suggestions and search bar
      const divHeight =
        searchBarHeight +
        Math.min(
          templates && templates.length > 0 ? templates.length : 1,
          maxVisibleSuggestions
        ) *
          suggestionHeight;
      const { classes } = this.props;

      return (
        <Fragment>
          {/* <Box> */}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              marginTop: "-8px",
            }}
          >
            {/* <Typography
                    variant="h6"
                    gutterBottom
                    style={{ fontWeight: "bold" }}
                  >
                    Complaints
                  </Typography> */}

            {/* <Typography
                gutterBottom
                // style={{ marginLeft: "20px" }}
                // onClick={() => this.handleDeleteComplaint(item.id)}
                size="small"
              >
                <SkipPreviousIcon
                  fontSize="small"
                  style={{
                    color: "black",
                  }}
                />
              </Typography> */}
            {/* <Typography
                variant="h6"
                gutterBottom
                onClick={this.handleLoadPrevious}
                style={{
                  cursor: "pointer",
                  color: "black",
                }}
              >
                Load Previous
              </Typography> */}
            <Typography
              gutterBottom
              color="textSecondary"
              // variant="h6"
              // color="black"
              // style={{ fontWeight: "bold" }}
            >
              Advice
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              style={{ marginLeft: "5px" }}
              // onClick={() => this.handleDeleteComplaint(item.id)}
              size="small"
            >
              <SkipPreviousIcon
                // color="textSecondary"
                // fontSize="small"
                style={{
                  height: "15px",
                  width: "15px",
                  // color: "rgba(89, 162, 162, 1)",
                }}
              />
            </Typography>
            <Typography
              // variant="h6"
              gutterBottom
              onClick={this.handleLoadPrevious}
              style={{
                fontWeight: "bold",
                cursor: "pointer",
                color: "#ff3369",
                fontSize: "10px",
                marginTop: "2px",
              }}
            >
              Load Previous
            </Typography>

            <Typography
              color="textSecondary"
              gutterBottom
              style={{ marginLeft: "10px" }}
              // onClick={() => this.handleDeleteComplaint(item.id)}
              size="small"
            >
              <SaveIcon
                // fontSize="small"
                // style={{
                //   height: "15px",
                //   width: "15px",
                //   color: "rgba(89, 162, 162, 1)",
                // }}
                style={{
                  color: isSaveTemplateClicked
                    ? "blue"
                    : "rgba(89, 162, 162, 1)",
                  height: "12px",
                  width: "12px",
                }}
              />
            </Typography>
            <Typography
              // variant="h6"
              gutterBottom
              // style={{
              //   cursor: "pointer",
              //   color: "rgba(89, 162, 162, 1)",
              //   fontSize: "12px",
              // }}
              style={{
                fontWeight: "bold",
                marginLeft: "2px",
                cursor: "pointer",
                color: isSaveTemplateClicked ? "blue" : "#ff3369",
                fontSize: "10px",
                marginTop: "3px",
              }}
              onClick={this.handleDialogOpen}
            >
              Save Template
            </Typography>
            <Dialog open={dialogOpen} onClose={this.handleDialogClose}>
              <DialogTitle>Save Template</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  label="Template Name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={templateName}
                  onChange={(e) =>
                    this.setState({ templateName: e.target.value })
                  }
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleDialogClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={this.handleSaveTemplate} color="primary">
                  Save
                </Button>
              </DialogActions>
            </Dialog>

            <div
              style={{ position: "relative", display: "inline-block" }}
              ref={this.containerRef}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={this.toggleTemplateList} // Toggle list on click
              >
                <Typography
                  // variant="h6"
                  gutterBottom
                  style={{
                    fontWeight: "bold",
                    marginLeft: "10px",
                    color: isTemplateClicked ? "blue" : "#ff3369",
                    fontSize: "10px",
                    marginTop: "3px",
                  }}
                >
                  Templates
                </Typography>
                <Typography
                  color="textSecondary"
                  // variant="h6"
                  gutterBottom
                  style={{ marginTop: "3px" }}
                >
                  <ArrowDropDownIcon
                    fontSize="small"
                    style={{
                      color: isTemplateClicked
                        ? "blue"
                        : "rgba(89, 162, 162, 1)",
                    }}
                  />
                </Typography>
                {/* <ArrowDropDownIcon fontSize="small" /> */}
              </div>
              {isListVisible && (
                <div
                  style={{
                    position: "absolute",
                    top: "65%", // Align right under the Typography
                    left: "20px", // Match marginLeft of Typography
                    zIndex: 10, // Ensure it's above other elements
                    backgroundColor: "white", // Set background color for better visibility
                    borderRadius: "4px", // Add border radius for aesthetics
                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)", // Add subtle shadow for depth
                    marginTop: "7px",
                    height: `${divHeight}px`, // Initial height based on suggestions and search bar
                    maxHeight: "156px", // Set max height to 156px
                    overflowY: "auto", // Enable scrolling if content exceeds max height
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center", // Center the items vertically
                      flexWrap: "wrap",
                      flexDirection: "column",
                      padding: "0px 10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center", // Center the items vertically
                        flexDirection: "row",
                        marginBottom: "3px", // Add space below the search bar
                        height: `${searchBarHeight}px`, // Set the search bar height
                      }}
                    >
                      <Search
                        style={{
                          width: "20px",
                          height: "20px",
                          marginTop: "5px",
                          marginRight: "5px", // Add space between the icon and the input
                          color: "#666",
                        }}
                      />
                      <input
                        type="search"
                        placeholder="Search"
                        style={{
                          outline: "none",
                          border: "none", // Remove borders for a cleaner look
                          borderBottom: "1px solid #ccc", // Keep bottom border for input
                          padding: "5px", // Add padding for better UX
                          flexGrow: 1, // Allow input to take available width
                        }}
                        onChange={this.handleSearchChange} // Handle search input change
                      />
                    </div>
                    <div style={{ display: "flex", width: "100%" }}>
                      <Autocomplete
                        options={
                          templates && Array.isArray(templates)
                            ? templates.filter((template) =>
                                template.name
                                  .toLowerCase()
                                  .includes(search.toLowerCase())
                              )
                            : []
                        }
                        getOptionLabel={(option) => option.name} // Display name in the dropdown
                        disableClearable
                        open // Force the dropdown to open when the list is visible
                        onChange={(event, newValue) => {
                          if (newValue) {
                            this.handleTemplateSelect(newValue); // Handle the selected value
                          }
                        }}
                        style={{ width: "100%" }} // Make the dropdown take the full width
                        ListboxProps={{
                          style: {
                            border: "none",
                            padding: "0px",
                            maxHeight: `${
                              maxVisibleSuggestions * suggestionHeight
                            }px`, // Limit the height of suggestions
                            overflowY: "auto", // Add vertical scroll when needed
                            overflowX: "hidden", // Prevent horizontal scroll
                          },
                        }}
                        PopperComponent={(props) => (
                          <Popper {...props} placement="bottom" />
                        )}
                        disablePortal
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            InputProps={{
                              ...params.InputProps,
                              style: {
                                height: "0", // Shrink the input field's height to 0
                                minHeight: "0", // Ensure the input field doesn't take any space
                                visibility: "hidden", // Hide the input field but keep it in the DOM
                                border: "none", // Remove borders from the input
                              },
                              endAdornment: null, // Remove the dropdown arrow if needed
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Divider variant="middle" style={{ marginTop: "-10px" }} />
          <TextField
            style={{ paddingRight: "16px", marginTop: "8px" }}
            // autoFocus
            id="outlined-basic"
            value={this.props.drNotes}
            className={classes.freeTextNotes}
            multiline
            label={visitFormfields.advice}
            rows={2}
            name="notes"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
          {/* </Box> */}
        </Fragment>
      );
    }
  }
);
