import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import EditIcon from "@material-ui/icons/Edit";
import {
  Card,
  // CardHeader,
  Col,
  Row,
  Container,
} from "react-bootstrap";
import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
  Typography,
} from "@material-ui/core";
import EditVisit from "./EditVisit";
import EditPatient from "../../Patients/components/EditPatient";
import { hostInfo } from "../../../store";

const gender = { M: "Male", F: "Female" };
const useStyles = makeStyles((theme) => ({
  remain: {
    color: "#fff",
    width: "100%",
    // maxWidth: "100px",
    background: "#E8625F",
  },
  containerValue: {
    wordWrap: "break-word",
    // marginLeft: "auto",
    // marginRight: "auto",
  },
}));
const PersonalDetail = (props) => {
  const [openEditDia, setOpenEditDia] = useState(false);
  const [patientGrpList, setPatientGrpList] = useState([]);
  const { user, patient_id, paymentInfo } = props;
  const classes = useStyles();
  // const tmp = Math.floor(Math.random() * 14 + 1);
  // console.log("user details in args..........");
  const handleClickEditOpen = () => {
    setOpenEditDia(true);
  };
  const handleClose = () => {
    // setOpenDelDia(false);
    setOpenEditDia(false);
  };

  const getToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.authToken;
  };
  useEffect(() => {
    async function fetchPtGroup() {
      let url = hostInfo + "/getptgroup";
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res != null) {
            if (res != null) {
              setPatientGrpList(res);
            }
          }
        });
    }

    // async function fetchPatient() {
    //   let url = hostInfo + "/getptinfo";
    //   await fetch(url, {
    //     headers: {
    //       Authorization: `Bearer ${getToken()}`,
    //     },
    //   })
    //     .then((res) => res.json())
    //     .then((res) => {
    //       if (res != null) {
    //         if (res != null) {
    //           // console.log(res);
    //           setPatientList(res);
    //         }
    //       }
    //     });
    // }

    fetchPtGroup();
    // fetchPatient();
  }, []);
  return (
    <>
      {/* <Card.Header
        className="border-bottom text-center"
        // style={{ backgroundImage: `url(${backImg})` }}
        style={{
          background: "#EEF9DE",
          // backgroundSize: "100% 10px",
        }}
      > */}
      {/* <Container> */}
      {/* <Row>
            <div className=" mx-auto ">
              <img
                className="rounded-circle"
                src={
                  user.gender === "M"
                    ? require(`./../../../images/avatar/m9.jpg`)
                    : require(`./../../../images/avatar/f8.jpg`)
                }
                alt={user.firstname}
                width="110"
              ></img>
            </div>
          </Row>
          <Row>
            <h4 className="mb-1 mx-auto ">
              {user.firstname} {user.lastname}
            </h4>
          </Row>
          <Row>
            <span className="text-muted d-block mb-0 mx-auto">
              {user.gender ? gender[user.gender] : "No Gender"} |{" "}
              {user.curr_age ? user.curr_age : 0} Yrs | {user.mobile}
            </span>
          </Row>
          <Row>
            <span className="d-block mx-auto">{user.mail_id}</span>
          </Row> */}
      <Grid
        container
        // spacing={2} // Remove spacing in mobile to prevent horizontal scroll
        alignItems="center"
        // style={{ overflowX: "hidden" }}
        style={{
          paddingLeft: "20px",
          // display: "flex",
          // justifyContent: "space-between",
          // width: "100%",
          // maxWidth: "1200px",
          // minWidth: "250px",
        }}
      >
        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            gap: "5px",
            // width: "100%",
          }}
        > */}
        {/* <Grid> */}
        <Grid style={{ marginRight: "15px" }}>
          <img
            className="rounded-circle"
            src={
              user.gender === "M"
                ? require("./../../../images/avatar/m9.jpg")
                : require("./../../../images/avatar/f8.jpg")
            }
            alt={user.firstname}
            width="40"
          />
        </Grid>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "15px",
            // justifyContent: "flex-end",
            // alignItems: "center",
            // justifyContent: "space-between",
            // width: "91%",
            // justifyItems: "space-between",
            // minWidth: "250px",
            // maxWidth: "900px",
            // width: "100%",
          }}
        >
          {/* <Grid item xs={10} sm container> */}
          <Grid
          // xs
          // direction="column"
          // style={{
          //   padding: isMobile
          //     ? "0px 0px 0px 20px"
          //     : "0px 0px 0px 0px",
          // }}
          // spacing={2}
          >
            {/* <Grid container alignItems="center" justifyContent="space-between"> */}
            <Grid>
              <Typography
                gutterBottom
                variant="subtitle1"
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  marginBottom: "0px",
                }}
              >
                {user.firstname} {user.lastname}
              </Typography>
              <Typography style={{ fontSize: "13px" }}>
                {user.gender ? gender[user.gender] : "No Gender"} |{" "}
                {user.curr_age ? user.curr_age : 0} Yrs | {user.mobile} |{" "}
                {user.mail_id}
              </Typography>
            </Grid>
            {/* <Grid item> */}
            {/* <Tooltip title="Delete OPD">
                                  <DeleteIcon
                                    onClick={() => deleteOpd(p.id)}
                                    style={{
                                      marginLeft: 8,
                                      marginTop: -38,
                                      cursor: "pointer",
                                    }}
                                  />
                                </Tooltip> */}
            {/* </Grid> */}
          </Grid>

          {/* <Grid style={{ marginLeft: "10px" }}> */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "15px",
              flexWrap: "wrap",
              alignItems: "center",
              borderLeft: "1px solid #ccc",

              paddingLeft: "15px",
              // justifyContent: "space-between", // Align everything to the right
              // width: "350px",
              // justifyContent: "space-between",
              // justifyItems: "space-between",
              // width: "90%",
              // textAlign: "right",
            }}
          >
            {user.illness !== "" && (
              <div
                style={{
                  fontSize: "14px",
                  // fontWeight: "bold",
                  backgroundColor: "#fbeeea",
                  color: "#f33737",
                  padding: "1px 10px",
                  borderRadius: "5px",
                  border: "1px solid #f33737",
                }}
              >
                {/* <Avatar variant="rounded" style={{ fontSize: "14px" }}> */}
                {user.illness}
                {/* </Avatar> */}
              </div>
            )}

            {/* </Grid> */}

            {/* <Grid xs></Grid> */}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "15px",
              flexWrap: "wrap",
              alignItems: "center",
              borderLeft: user.illness !== "" ? "1px solid #ccc" : "none",
              paddingLeft: user.illness !== "" ? "15px" : "0px", // Adjust padding when no border
            }}
          >
            <div className={classes.containerValue}>
              <Typography color="textPrimary" align="justify">
                Remain (&#x20B9;):{" "}
                <Tooltip title={"Total: " + paymentInfo.total} interactive>
                  <span
                    style={{
                      color: "#fff",
                      backgroundColor:
                        paymentInfo.remain === 0
                          ? "#D1D1D1"
                          : paymentInfo.remain > 0
                          ? "#FF7396"
                          : "#76BA99",
                      fontSize: "14px",
                      padding: "3px 10px",
                      borderRadius: "5px",
                      border:
                        paymentInfo.remain === 0
                          ? "1px solid #D1D1D1"
                          : paymentInfo.remain > 0
                          ? "1px solid #FF7396"
                          : "1px solid #76BA99",
                    }}
                  >
                    {paymentInfo.remain}
                  </span>
                </Tooltip>
              </Typography>
            </div>
            <Button
              size="small"
              onClick={handleClickEditOpen}
              // className={classes.saveBtn}
              startIcon={<EditIcon />}
              // onClick={handleClickEditOpen}
            ></Button>
          </div>
        </div>
        <div></div>

        {/* </div> */}
        {openEditDia ? (
          <EditPatient
            open={openEditDia}
            closeHdl={handleClose}
            // title="Visit Details"
            // isOpen={isDialogOpen}
            // onClose={handleDialogClose}
            patientGrpList={patientGrpList}
            classes={classes}
            patientId={patient_id}
            patientInfo={user}
          />
        ) : null}
        {/* </Grid> */}
      </Grid>
      {/* </Grid>
        </Grid> */}
      {/* <Row>
          <div className={classes.containerValue}>
            <Avatar
              variant="rounded"
              className={classes.remain}
              style={{ padding: "0px 8px", fontSize: "18px" }}
            >
              {user.illness}
            </Avatar>
          </div>
        </Row> */}
      {/* </Container> */}
      {/* //   </Card.Header> */}
    </>
  );
};

PersonalDetail.propTypes = {
  /**
   * The user details object.
   */
  PersonalDetail: PropTypes.object,
};

PersonalDetail.defaultProps = {};

export default PersonalDetail;
