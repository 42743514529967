import React, { forwardRef, useEffect, useState } from "react"; // Import React, forwardRef, and useState
import PropTypes from "prop-types"; // Import PropTypes for prop validation
import clsx from "clsx"; // Import clsx for conditional class names
import { makeStyles } from "@material-ui/styles"; // Import makeStyles for styling
import { Divider, Drawer, List, Button, IconButton } from "@material-ui/core"; // Import Material-UI components
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"; // Import icons
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import MainSearch from "../../../../views/MainSearch"; // Import custom components
import MainSearchTxt from "../../../../views/MainSearchTxt/MainSearchTxt";
import { NavLink as RouterLink } from "react-router-dom"; // Import RouterLink for navigation

const useStyles = makeStyles((theme) => ({
  drawer: {
    marginTop: 35,
    height: "calc(100% - 40px)",
    // [theme.breakpoints.down("xs")]: {
    //   width: 40,
    // },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
  root1: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  button: {
    color: theme.palette.my2.dark,
    padding: "10px 10px",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightMedium,
    display: "flex",
    alignItems: "center",
  },
  icon: {
    color: theme.palette.my2.main,
    width: 24,
    height: 24,
  },
  active: {
    color: theme.palette.my1.main,
    fontWeight: theme.typography.fontWeightMedium,
    "& $icon": {
      color: theme.palette.my1.main,
    },
  },
  justifyStart: {
    justifyContent: "flex-start",
    marginLeft: "10px",
    marginRight: "10px",
  },
  justifyCenter: {
    justifyContent: "center",
  },
}));

const Sidebar = forwardRef((props, ref) => {
  const { open, onClose, className, sidebarVal, variant, ...rest } = props;
  console.log(sidebarVal);
  const classes = useStyles();
  const [openNav, setOpenNav] = useState(false);
  const isFingerPrintEnabled = false;

  const handleSidebarOpen = () => {
    setOpenNav(!openNav);
  };

  const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref} style={{ flexGrow: 1 }}>
      <RouterLink {...props} />
    </div>
  ));

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      classes={{
        paper: clsx(classes.drawer),
      }}
      variant={variant}
      ref={ref}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
        style={{
          width: openNav ? 200 : 65, // Corrected conditional style for width
          marginRight: openNav ? "10px" : "0px",
        }}
      >
        <Divider className={classes.divider} />
        <IconButton
          style={{ outline: "none", alignSelf: "flex-start" }}
          className={classes.root1}
          color="inherit"
          onClick={handleSidebarOpen}
        >
          {openNav ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
        </IconButton>
        {isFingerPrintEnabled ? (
          <MainSearch />
        ) : (
          <MainSearchTxt openNav={openNav} />
        )}
        <List
          {...rest}
          className={clsx(classes.root, className)}
          // style={{
          //   width: openNav ? 200 : 50, // Corrected conditional style for width
          //   // marginRight: "10px",
          // }}
        >
          {sidebarVal.map((text, index) => (
            <Button
              key={index}
              activeClassName={classes.active}
              className={clsx(
                classes.button,
                openNav ? classes.justifyStart : classes.justifyCenter
              )}
              component={CustomRouterLink}
              to={text.href}
            >
              <div className={classes.icon}>{text.icon}</div>
              {openNav && (
                <span style={{ paddingLeft: "8px", paddingTop: "3px" }}>
                  {text.title}
                </span>
              )}
            </Button>
          ))}
        </List>
      </div>
    </Drawer>
  );
});

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  sidebarVal: PropTypes.array.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
