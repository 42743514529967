import React, { Fragment, createRef } from "react";
import {
  Container,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
import { withStyles } from "@material-ui/styles";
import {
  Button,
  Divider,
  Checkbox,
  Grid,
  Typography,
  InputAdornment,
  Modal,
  Popper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { hostInfo } from "../../../../store";
// import FuncComp from "./testFunc";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SaveIcon from "@mui/icons-material/Save";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import ClearIcon from "@mui/icons-material/Clear";

// import RemoveIcon from "@material-ui/icons/Remove";

import FormGroup from "@material-ui/core/FormGroup";

//import { countryArr } from "../../../../store";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useRef } from "react";
import { Search } from "@material-ui/icons";
// import PrintHtml from "./PrintHtml/PrintHtml";
// import HtmlContentByInfo from "./PrintHtml/HtmlContentByInfo";

const styles = (theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      // width: 230,
    },
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  saveBtn: {
    background: "#94B49F",
    borderRadius: 3,
    border: 0,
    color: "white",
    // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    "&:hover": {
      background: "#C6A3D4",
    },
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  formControl: {
    // margin: theme.spacing(1),
    width: 230,
  },
  // countryControl: {
  //   // margin: theme.spacing(1),
  //   minWidth: 150,
  // },
  countryControl: {
    marginRight: theme.spacing(1),
    minWidth: 170,
  },
  insurance: {
    // width: 230,
    marginTop: theme.spacing(2),
  },
  bloodGroupFld: {
    minWidth: 150,
  },
  seprator: {
    margin: theme.spacing(2),
  },
  seprator1: {
    marginTop: theme.spacing(2),
  },
  refCls: {
    color: "#D46D91",
    fontWeight: "bold",
    marginTop: theme.spacing(2),
  },
  seprator2: {
    width: "100%",
    ...theme.typography.body2,
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  multilineFld: {
    display: "flex",
    flexWrap: "wrap",
  },
  amountGreen: {
    borderColor: "pink",
  },
  specialOutline: {
    borderColor: "pink",
    //borderWidth: 4
  },
  presc: {
    width: "100%",
  },
  autocomp: {
    '&[data-focus="true"], &:hover': {
      backgroundColor: "#CFE8A9",
    },
  },
  cardClr: {
    background: "#F3F8F8",
  },
  freeTextNotes: {
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

var countryStateCity = require("country-state-city").default;

export default withStyles(styles)(
  class EditPayment extends React.Component {
    containerRef = createRef();

    state = {
      search: "",
      dialogOpen: false,
      templateName: "",
      isSuggestionOpen: 1,

      openSuggestionIndex: false,
      templates: [],
      isListVisible: false,
      isTemplateClicked: false,
      isSaveTemplateClicked: false,
      isLoadPreviousClicked: false,
      paymentSuggestions: [
        { name: "bandage", price: "50" },
        { name: "injection", price: "70" },
        { name: "botal", price: "200" },
      ],

      //   rows: this.props.payment,

      frequencyRefs: [],
      severityRefs: [],
      durationRefs: [],
      selectedComplaint: null,
      openModal: false,
      modalRowIndex: null,
    };

    // countryArr = countryStateCity.getAllCountries();
    stateArr = [];
    cityArr = [];
    selectedCountry = "";
    selectedState = "";
    selectedCity = "";
    stateDisabled = true;
    cityDisabled = true;
    // frequencyRefs = [];
    // severityRefs = [];
    // durationRefs = [];
    medSug = [];
    repSug = [];
    drSug = [];
    symSug = [];
    disSug = [];
    drGroups = [];

    handleDeleteComplaint = (index) => {
      this.setState((prevState) => {
        // Ensure rows are defined and create a shallow copy
        const rows = [...(prevState.rows || [])];
        console.log("Current Rows Before Deletion:", rows);

        // Remove the row at the given index
        const newRows = rows.filter((_, i) => i !== index);
        console.log("New Rows After Deletion:", newRows);

        // Ensure at least one empty row remains after deletion
        if (newRows.length === 0) {
          newRows.push({
            name: "",
            price: "",
            discount: "",
            total: "0.00", // Ensure default total value
          });
        }

        // Notify the parent component about the updated rows
        this.props.onRowsChange(newRows);
        console.log("Rows After Update:", newRows);

        // Update state with the new filtered rows
        return {
          rows: newRows,
        };
      });
    };

    handlePayment = (index, field, value) => {
      //   console.log("first");
      const newRows = [...this.props.payment]; // Create a shallow copy of the rows array

      // Update the specified field (price, discount, or total) only for the row at the given index
      newRows[index][field] = value;

      // Recalculate total when price or discount is updated
      if (field === "discount" || field === "price") {
        console.log("--------");
        const price = parseFloat(newRows[index].price) || 0;
        const discount = parseFloat(newRows[index].discount) || 0;

        // Calculate total = price - (price * discount / 100)
        const total = price - (price * discount) / 100;
        newRows[index]["total"] = total.toFixed(2); // Round to 2 decimal places
        // console.log(total);
      }

      // Ensure that only one empty row is added when the last row is being modified
      const emptyRow = {
        name: "",
        price: "",
        discount: "",
        total: "",
      };

      // Add an empty row only if the last row's 'name' field is not empty
      const isNameEmpty = newRows[newRows.length - 1].name === "";

      if (!isNameEmpty && index === newRows.length - 1) {
        newRows.push(emptyRow);
      }

      // Update the state with the modified rows
      this.setState({
        rows: newRows,
      });

      // Call parent's onRowsChange to notify about the updates
      this.props.onRowsChange(newRows);
    };

    handleDialogOpen = () => {
      this.setState({
        isfrequencySuggestionOpen: -1,
        isseveritySuggestionOpen: -1,
        isSuggestionOpen: -1,
      });

      this.setState({ isSaveTemplateClicked: true });
      this.setState({ dialogOpen: true });
    };

    handleDialogClose = () => {
      this.setState({ isSaveTemplateClicked: false });
      this.setState({ dialogOpen: false });
    };

    handleSaveTemplate = () => {
      const getToken = () => {
        const cookieString = document.cookie;
        const cookies = cookieString.split("; ").reduce((acc, cookie) => {
          const [name, value] = cookie.split("=");
          acc[name] = value;
          return acc;
        }, {});
        return cookies.authToken;
      };

      const { templateName, rows } = this.state;
      const shouldAddPreviousData = rows.length > 0 && rows[0].name !== "";

      if (shouldAddPreviousData) {
        const savedData = { name: templateName, json_data: rows.slice(0, -1) }; // Change `rows` to `json_data`

        fetch(hostInfo + "/addPaymentTemplate", {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${getToken()}`, // If required for authorization
          },
          body: JSON.stringify(savedData), // Ensure correct field name in payload
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("API Response:", data);
            if (data.message) {
              alert("Template saved successfully");
            } else {
              alert("Error saving template");
            }

            this.setState((prevState) => ({
              templates: [...(prevState.templates || []), savedData],
              // rows: [
              //   {
              //     complaint: "",
              //     frequency: "",
              //     severity: "",
              //     duration: "",
              //     date: "",
              //     inputNumber: "",
              //   },
              // ],
              templateName: "",
            }));
          })
          .catch((error) => {
            console.error("Error:", error);
            alert("Failed to save template");
          });

        this.handleDialogClose();
      } else {
        alert("Please Enter Value...");
        this.handleDialogClose();
        this.setState({ templateName: "" });
      }
    };

    toggleTemplateList = () => {
      this.setState((prevState) => ({
        isListVisible: !prevState.isListVisible, // Toggle the visibility state
      }));
      this.setState({ isTemplateClicked: !this.state.isTemplateClicked });
      this.setState({
        isfrequencySuggestionOpen: -1,
        isseveritySuggestionOpen: -1,
        isSuggestionOpen: -1,
      });
    };
    handleTemplateSelect = (template) => {
      this.setState((prevState) => ({
        rows: [
          ...this.state.rows.slice(0, -1),
          ...template.json_data,
          {
            name: "",
            price: "",
            discount: "",
            total: "",
          },
        ],
      }));
      this.props.onRowsChange(this.state.rows);
      this.setState((prevState) => ({
        isListVisible: !prevState.isListVisible, // Toggle the visibility state
      }));
      this.setState({ isTemplateClicked: false });
      this.setState({ openSuggestionIndex: false });
    };

    handleSearchChange = (event) => {
      this.setState({ search: event.target.value }); // Update search term
    };

    componentDidMount() {
      document.addEventListener("mousedown", this.handleClickOutside);
      document.addEventListener("keydown", this.handleKeyNavigation);
      // document.addEventListener("mousedown", this.handleClickOutside);
      const getToken = () => {
        const cookieString = document.cookie;
        const cookies = cookieString.split("; ").reduce((acc, cookie) => {
          const [name, value] = cookie.split("=");
          acc[name] = value;
          return acc;
        }, {});
        return cookies.authToken;
      };

      // this.fetchTemplates = () => {
      fetch(hostInfo + "/getpaymenttemplates", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((response) => {
          // Return the JSON response
          return response.json(); // Added return statement here
        })
        .then((data) => {
          this.setState({ templates: data }); // Update state with fetched data
          console.log("Fetched Templates:", data); // Log fetched data
        })
        .catch((error) => {
          console.error("Error fetching templates:", error); // Log any errors
        });

      // };
    }

    componentWillUnmount() {
      document.removeEventListener("mousedown", this.handleClickOutside);
      document.removeEventListener("keydown", this.handleKeyNavigation);
    }

    handleClickOutside = (event) => {
      if (
        this.containerRef.current &&
        !this.containerRef.current.contains(event.target)
      ) {
        this.setState({ isListVisible: false, isTemplateClicked: false });
      }
    };
    handleKeyNavigation = (event) => {
      const { templates, highlightedIndex, isListVisible } = this.state;

      // Only trigger if the suggestion list is visible
      if (!isListVisible) return;

      // Prevent default scrolling behavior when navigating suggestions
      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        event.preventDefault();
      }

      // Navigate down (ArrowDown)
      if (event.key === "ArrowDown") {
        this.setState((prevState) => ({
          highlightedIndex: Math.min(
            prevState.highlightedIndex + 1,
            templates.length - 1
          ),
        }));
      }

      // Navigate up (ArrowUp)
      if (event.key === "ArrowUp") {
        this.setState((prevState) => ({
          highlightedIndex: Math.max(prevState.highlightedIndex - 1, 0),
        }));
      }

      // Select the highlighted suggestion (Enter key)
      if (event.key === "Enter" && highlightedIndex >= 0) {
        this.handleTemplateSelect(
          this.props.idx,
          "repName",
          templates[highlightedIndex]
        );
      }
    };

    handleClose = () => {
      this.setState({ open: false });
    };

    handleDiaClickOpen = () => {
      this.setState({ openDia: true });
    };

    handleDiaClickClose = () => {
      this.setState({ openDia: false });
    };

    handleOnChange = (e, name) => {
      const value = e.target.value;
      // console.log("Value change --- ");
      // console.log(e.target);
      // console.log(name);
      // console.log(value);
      this.setState({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          [name]: value,
        },
      });
    };

    handleMultiFld = (name, value) => {
      // console.log("------ Inside Multifiled handler ------");
      // console.log(name);
      // console.log(value);
      this.setState({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          [name]: value,
        },
      });
    };

    handleInsurance = (name, value) => {
      // console.log(name);
      // console.log(value.target.checked);

      this.setState({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          [name]: value.target.checked,
        },
      });
    };

    // Mitesh: TODO make index incremental

    render() {
      const {
        dialogOpen,
        templateName,
        templates,
        search,
        isTemplateClicked,

        isSaveTemplateClicked,
        isListVisible,
      } = this.state;

      //   console.log(this.props.payment);
      const { classes } = this.props;

      return (
        <Fragment>
          {/* <Box> */}
          {/* <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              marginTop: "5px",
            }}
          > */}
          {/* <Typography
                    variant="h6"
                    gutterBottom
                    style={{ fontWeight: "bold" }}
                  >
                    Complaints
                  </Typography> */}
          <Typography
            gutterBottom
            variant="h6"
            color="black"
            // color="textSecondary"
            style={{ fontWeight: "bold" }}
          >
            Payment
          </Typography>
          {/* </div> */}
          {/* <Divider variant="middle" style={{ marginTop: "-2px" }} /> */}
          <Table
            sx={{
              //   marginTop: "15px",
              border: "1px solid rgba(224, 224, 224, 1)",
              width: "auto", // Table Border
            }}
            size="small" // Reduce padding in the table
          >
            <TableHead>
              <TableRow style={{ backgroundColor: "#f8f9fa" }}>
                <TableCell
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    padding: "2px",
                    width: "35px",
                    fontWeight: "bold",
                  }}
                >
                  #
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    padding: "2px",
                    width: "300px",
                    fontWeight: "bold",
                  }}
                >
                  Name
                </TableCell>

                <TableCell
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    padding: "2px",
                    width: "200px",
                    fontWeight: "bold",
                  }}
                >
                  Price
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    padding: "2px",
                    width: "200px",
                    fontWeight: "bold",
                  }}
                >
                  Discount (%)
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    padding: "2px",
                    width: "200px",
                    fontWeight: "bold",
                  }}
                >
                  Total
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid white",
                    padding: "2px",
                    backgroundColor: "white",
                  }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.payment?.map((row, index) => {
                console.log(this.props.payment);
                return (
                  <TableRow key={index}>
                    {/* Row Number */}
                    <TableCell
                      sx={{
                        border: "1px solid rgba(224, 224, 224, 1)",
                        padding: "2px",
                      }}
                    >
                      {index + 1}
                    </TableCell>
                    {/* Complaint Autocomplete */}
                    <TableCell
                      sx={{
                        border: "1px solid rgba(224, 224, 224, 1)",
                        padding: "2px",
                      }}
                    >
                      <Autocomplete
                        options={
                          this.state.paymentSuggestions?.map((option) => ({
                            label: option.name,
                            value: option.price,
                          })) || []
                        } // Ensure there's a fallback for undefined or empty options
                        getOptionLabel={(option) =>
                          option && option.label ? option.label : ""
                        } // Ensure no undefined error on option.label
                        freeSolo
                        disableClearable
                        value={row.name ? { label: row.name } : null} // Ensure the selected value displays correctly
                        onChange={(event, newValue) => {
                          // Update only the specific row's 'name' and 'price'
                          this.handlePayment(
                            index,
                            "name",
                            newValue ? newValue.label : ""
                          );
                          this.handlePayment(
                            index,
                            "price",
                            newValue ? newValue.value : ""
                          );
                        }}
                        onInputChange={(event, newInputValue) => {
                          // Update the name field with manual input
                          this.handlePayment(index, "name", newInputValue);

                          // Find if the manual input matches any suggestion
                          const matchingOption =
                            this.state.paymentSuggestions?.find(
                              (option) => option.name === newInputValue
                            );

                          // If no matching suggestion is found, clear the price
                          if (!matchingOption) {
                            this.handlePayment(index, "price", "");
                          } else {
                            // If a matching suggestion is found, update the price
                            this.handlePayment(
                              index,
                              "price",
                              matchingOption.price
                            );
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                            }}
                          />
                        )}
                      />
                    </TableCell>
                    {/* Price Field */}
                    <TableCell
                      sx={{
                        border: "1px solid rgba(224, 224, 224, 1)",
                        padding: "2px",
                      }}
                    >
                      <TextField
                        value={row.price}
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        onChange={(event) => {
                          const value = event.target.value;
                          // Allow only numeric input (including decimal)
                          if (
                            !isNaN(value) &&
                            /^[0-9]*\.?[0-9]*$/.test(value)
                          ) {
                            this.handlePayment(index, "price", value);
                          }
                        }}
                      />
                    </TableCell>
                    {/* Discount Field */}
                    <TableCell
                      sx={{
                        border: "1px solid rgba(224, 224, 224, 1)",
                        padding: "2px",
                      }}
                    >
                      <TextField
                        value={row.discount}
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        onChange={(event) => {
                          const value = event.target.value;
                          // Allow only numeric input (including decimal)
                          if (
                            !isNaN(value) &&
                            /^[0-9]*\.?[0-9]*$/.test(value)
                          ) {
                            this.handlePayment(index, "discount", value);
                          }
                        }}
                      />
                    </TableCell>
                    {/* Total Field */}
                    <TableCell
                      sx={{
                        border: "1px solid rgba(224, 224, 224, 1)",
                        padding: "2px",
                      }}
                    >
                      <TextField
                        value={row.total}
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: true, // Make total field read-only, as it's auto-calculated
                        }}
                      />
                    </TableCell>
                    {/* Delete Button */}
                    <TableCell
                      sx={{
                        border: "1px solid white",
                        padding: "2px",
                      }}
                    >
                      <IconButton
                        onClick={() => this.handleDeleteComplaint(index)}
                        size="small"
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {/* </Box> */}
        </Fragment>
      );
    }
  }
);
