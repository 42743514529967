import React, { Fragment, useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/styles";
// import MaterialTable from "material-table";
import MaterialTable from "@material-table/core";
import { hostInfo } from "../../../store";
import { parse } from "csv-parse/browser/esm/sync";
import EditIcon from "@material-ui/icons/Edit";
import DrDetailsDia from "./DrDetailsDia";
// import csv from "csv";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import ReactFileReader from "react-file-reader";
import { Grid, Button, Typography } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import { useReactToPrint } from "react-to-print";
import PrintDrDetails from "./PrintDrDetails";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  containerIcon: {
    display: "flex",
    alignItems: "center",
    // paddin: spacing(2),
  },
  headCardDr: {
    background: "#FEF0CA",
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    margin: theme.spacing(1),
    //
    // display: "flex",
    // alignItems: "center",
  },
  inactive: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    border: 0,
    borderRadius: 3,
    // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    marginRight: theme.spacing(2),
  },
  active: {
    background: "linear-gradient(45deg, #279b37 30%, #7ac143 80%)",
    border: 0,
    borderRadius: 3,
    // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",

    marginRight: theme.spacing(2),
  },
  headText: {
    fontFamily: "Work Sans, sans-serif",
    fontWeight: 600,
    color: "#444655",
    // textAlign: "left",
    // background: "linear-gradient(45deg, #81d4fa 10%, #dce775 90%)",
    marginTop: theme.spacing(1),
    // display: "flex",
    // alignItems: "center",
  },
  cardBottom: {
    width: "100%",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  subVisit: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  addGroupBtn: {
    background: "#A9AABC",
    marginLeft: 0,
    borderRadius: 3,
    border: 0,
    color: "white",
    // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    "&:hover": {
      background: "#00E180",
    },
    // marginBottom: theme.spacing(2),
    margin: theme.spacing(1),
  },
  headingCls: {
    alignItems: "right",
  },
  infoIcon: {
    color: "#8BB80E",
    // backgroundColor: "black",
    fontSize: 30,
  },
}));

// Dr Add ------------------------------
const handleDrRefUpload = (file, id) => {
  // Ensure the file is present
  if (!file || !file[0]) {
    alert("No file selected");
    return;
  }

  // Extract file extension
  var ext = file[0].name.split(".").pop();

  if (ext === "xlsx") {
    // Handle xlsx upload
    uplofDrRefxlsx(file[0], id);
  } else {
    // Handle csv upload
    uplodDrRefcsv(file[0], id);
  }

  // Optional: Reload the page (if necessary)
  window.location.reload();
};

const getToken = () => {
  const cookieString = document.cookie;
  const cookies = cookieString.split("; ").reduce((acc, cookie) => {
    const [name, value] = cookie.split("=");
    acc[name] = value;
    return acc;
  }, {});
  return cookies.authToken;
};

const uplofDrRefxlsx = (file, id) => {
  const reader = new FileReader();
  const rABS = !!reader.readAsBinaryString;
  if (id === 0) {
    console.log("Invalid dr group id ...");
    return;
  }
  reader.onload = (e) => {
    /* Parse data */
    const bstr = e.target.result;
    const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_json(ws, {
      header: 1,
    });

    alert(reader.result);
    for (var i = 1; i < data.length; ) {
      var drList = [];
      for (var j = 1; i < data.length && j < 20; i++, j++) {
        // console.log(data[i]);
        if (
          data[i][0] === undefined ||
          data[i][0].match(/^ *$/) !== null ||
          data[i][2] === undefined ||
          data[i][2].match(/^ *$/) !== null
        ) {
          continue;
        }
        var dr = {
          name: data[i][0],
          mobile: isNaN(data[i][1]) ? data[i][1] : String(data[i][1]),
          description: data[i][2],
          dr_group_id: id,
        };
        drList.push(dr);
      }
      if (drList.length > 0) {
        let url = hostInfo + "/adddrinfolist";
        fetch(url, {
          method: "POST",
          // mode: "no-cors",
          headers: {
            Accept: "*/*",
            "Content-type": "application/json; charset=UTF-8",

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(drList),
        });
      }
    }
    // console.log("Data from xlsx -------------");
    // console.log(data);
    /* Update state */
    // this.setState({ data: data, cols: make_cols(ws["!ref"]) });
  };
  if (rABS) reader.readAsBinaryString(file);
  else reader.readAsArrayBuffer(file);
};

const uplodDrRefcsv = (file) => {
  console.log(file);
  // Ensure a file is provided
  if (!file || !(file instanceof Blob)) {
    alert("No file selected or invalid file. Please upload a valid CSV file.");
    return;
  }

  const reader = new FileReader();
  reader.onload = function (e) {
    try {
      // Parse the CSV content using `csv-parse`
      const data = parse(e.target.result, {
        delimiter: ",", // Define CSV delimiter (comma is default)
        skip_empty_lines: true, // Skip empty lines
        trim: true, // Trim spaces around each value
      });

      let drList = [];

      // Iterate over the CSV data and construct the doctor list
      for (let i = 0; i < data.length; i++) {
        if (
          !data[i][0]?.trim() || // Ensure name is present
          !data[i][2]?.trim() // Ensure address is present
        ) {
          continue;
        }

        // Create a doctor object with name, mobile, and address
        let dr = {
          name: data[i][0].trim(),
          mobile: data[i][1]?.trim() || "", // Handle optional mobile field
          address: data[i][2].trim(),
        };

        drList.push(dr);
      }

      // Post the parsed data to the server if there's valid data
      if (drList.length > 0) {
        const url = `${hostInfo}/adddrinfolist`;
        fetch(url, {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(drList),
        })
          .then((response) => response.json())
          .then((data) => {
            alert("Doctors uploaded successfully!");
          })
          .catch((error) => {
            console.error("Error uploading doctors:", error);
            alert("Error uploading data");
          });
      } else {
        alert("No valid data found in the file.");
      }
    } catch (err) {
      console.error("Error parsing CSV:", err);
      alert("Error parsing the CSV file.");
    }
  };

  // Read the file as text
  reader.readAsText(file); // Reads the file content as text for parsing
};

const validateData = (data) => {
  // console.log("Inside data validation...");
  // Data validation remain --------- TODO

  // console.log(data);

  return 0;
};

const DataTables = (props) => {
  const { groupId } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [drId, setDrId] = useState(0);
  const [drName, setDrName] = useState("");
  const [drMobile, setDrMobile] = useState("");
  const [drDesc, setDrDesc] = useState("");
  // console.log("data list --------------");

  const handleDialogClose = (event) => {
    setIsDialogOpen(false);
  };

  const MyNewTitle = ({ text = "Table Title", variant = "h6" }) => (
    // <Typography
    //   variant={variant}
    //   style={{
    //     whiteSpace: "nowrap",
    //     overflow: "hidden",
    //     textOverflow: "ellipsis",
    //   }}
    // >
    //   Group: {groupName}
    // </Typography>
    // <Card className={classes.headCardDr}>
    // {/* <CardContent> */}
    <Typography
      // gutterBottom
      variant="h5"
      component="h2"
      color="textSecondary"
      align="center"
      className={classes.headText}
      // justify="space-between"

      // margin=""
    >
      Doctors Info
    </Typography>
    // {/* </CardContent> */}
    // </Card>
  );

  const classes = useStyles();

  const columns = [
    { title: "No.", field: "num", width: "10%" },
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Mobile",
      field: "mobile",
    },
    {
      title: "Info",
      // white-space: "pre-line",
      field: "description",
      render: (rowData) => (
        <div>
          {rowData.description?.map((line, index) => (
            <div key={index}>{line}</div>
          ))}
        </div>
      ),
      minWidth: "40%",
    },
  ];

  useEffect(() => {
    setTableData(props.drDetails);
    // console.log("table data --- ", tableData);
  }, [props]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const hadleEditClick = (data) => {
    // console.log("Got row data----");

    setIsDialogOpen(true);
    setDrId(data.Id);
    setDrName(data.name);
    setDrMobile(data.mobile);
    setDrDesc(data.description?.join("\n"));
  };
  const handlePrintClick = (data) => {
    setDrName(data.name);
    setDrMobile(data.mobile);
    setDrDesc(data.description?.join("\n"));
    setTimeout(() => {
      handlePrint();
    }, 300);
  };

  return (
    <div>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
      <div>
        {/* <Fragment> */}
        <MaterialTable
          title={<MyNewTitle variant="h4" text="My Cool Table" />}
          columns={columns}
          // data={[{ Id: "1", Name: "abc" }]}
          data={tableData.map(
            (data, idx) =>
              data != null &&
              data !== undefined && {
                num: idx + 1,
                Id: data.id,
                name: data.name,
                mobile: data.mobile,
                description: data.description?.split("\n"),
                groupId: groupId,
              }
          )}
          // style={(borderRadius = 0)}
          actions={[
            {
              icon: "delete",
              tooltip: "Delete data",
              onClick: (event, rowData) => {
                var url;
                var ids = [];
                // for (var i = 0; i < rowData.length; ++i) {
                //   ids.push(rowData[i]["Id"]);
                // }
                ids.push(rowData.Id);
                // console.log("ids to delete-------");
                // console.log(ids);
                if (
                  window.confirm(
                    "You want to delete " + ids.length,
                    " records ???"
                  )
                ) {
                  var url = hostInfo + "/deletedrdetailslist";

                  // console.log("Deleting URL:", url);
                  fetch(url, {
                    headers: {
                      Accept: "*/*",
                      "Content-type": "application/json; charset=UTF-8",
                      Authorization: `Bearer ${getToken()}`,
                    },
                    method: "POST",
                    // mode: "no-cors",
                    body: JSON.stringify(ids),
                  })
                    .then(() => {
                      // for (var i = 0; i < ids.length; ++i) {
                      // // console.log("Remove from list : ", ids[i]);
                      setTableData(
                        tableData.filter((data) => !ids.includes(data.id))
                      );
                      // }
                    })
                    .catch((error) => {
                      console.error("There was an error!", error);
                    });
                }
              },
            },
            {
              // icon: "edit",
              // tooltip: "Edit data",
              icon: () => <EditIcon />,
              // icon: () => <EditIcon />,
              onClick: (e, rowData) => hadleEditClick(rowData),
              tooltip: "Edit details",
            },
            {
              // icon: "edit",
              // tooltip: "Edit data",
              icon: () => <PrintIcon />,
              // icon: () => <EditIcon />,
              onClick: (e, rowData) => handlePrintClick(rowData),
              tooltip: "Print details",
            },
          ]}
          // style={{ height: scrHeight - 180 }}
          options={{
            // selection: true,
            headerStyle: {
              // textItems: "center",
              backgroundColor: "#AFE1CE",
              fontSize: 16,
              position: "sticky",
              color: "#000",
              // height: "2vh",
            },
            sorting: true,
            minBodyHeight: "auto",
            // filterCellStyle: { height: 10 },
            // headerStyle: { height: 10 },
            // searchFieldStyle: { height: 10 },
            padding: "dense",
            cellStyle: { fontSize: 14 },
            // doubleHorizontalScroll: true,
            actionsColumnIndex: -1,
          }}
        />
        <div style={{ display: "none" }}>
          <PrintDrDetails
            ref={componentRef}
            drName={drName}
            drMobile={drMobile}
            drDesc={drDesc}
          />
        </div>
        {/* </div> */}
        {/* <div> */}
        {isDialogOpen ? (
          <DrDetailsDia
            title="Edit DR Details"
            isOpen={isDialogOpen}
            handleCloseDia={handleDialogClose}
            id={drId}
            drName={drName}
            drMobile={drMobile}
            drDesc={drDesc}
            groupId={groupId}
          />
        ) : null}
        {/* </Fragment> */}
      </div>
    </div>
  );
};

const DrListTable = (props) => {
  const { groupId, groupName } = props;
  // console.log("Got group id: ", groupId);
  const [drDetails, setDrDetails] = React.useState([]);

  const [openAddDia, setOpenAddDia] = useState(false);

  const handleAddClose = () => {
    setOpenAddDia(false);
  };

  const handleAddOpen = () => {
    setOpenAddDia(true);
  };
  const classes = useStyles();

  useEffect(() => {
    // Fetch the reports *************************
    async function fetchDrInfo() {
      let url = hostInfo + "/getdrdetails?group_id=" + groupId;
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log("Got data Disease data  ----11111111111111");
          // console.log(data);
          if (data != null) setDrDetails(data);
          else setDrDetails([]);
        });
    }
    fetchDrInfo();
    // console.log("---------------------------------");
  }, [groupId]);

  const addDrDetailsInCurrentList = (data) => {
    setDrDetails((curDrDetails) => [...curDrDetails, data]);
  };

  return (
    <div className={classes.headingCls}>
      {/* <Box
        // boxShadow={3}
        // borderRadius={5}
        bgcolor="background.paper"
        // m={1}
        // p={1}
        // className={classes.cardBottom}
      > */}
      {/* <Paper style={{ height: scrHeight - 190, overflow: "auto" }}> */}
      {/* <Box
        boxShadow={3}
        borderRadius={5}
        bgcolor="background.paper"
        // width="auto"
        // m={1}
        // p={1}
        className={classes.cardBottom}
      > */}
      <Typography
        gutterBottom
        variant="h3"
        component="h2"
        color="textSecondary"
        align="center"
        className={classes.headText}
        // justify="space-between"

        // margin=""
      >
        {groupName}
      </Typography>
      {/* </Box> */}
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item>
          <Button
            variant="outlined"
            className={classes.addGroupBtn}
            onClick={handleAddOpen}
            align="right"
          >
            Add Dr
          </Button>
        </Grid>
        <Grid item>
          <ReactFileReader
            handleFiles={(e) => handleDrRefUpload(e, groupId)}
            fileTypes={[".csv", ".xlsx"]}
            multipleFiles={false}
          >
            <Button
              variant="outlined"
              className={classes.addGroupBtn}
              // onClick={handleAddOpen}
            >
              Upload Drs
            </Button>
          </ReactFileReader>
        </Grid>
      </Grid>
      <DataTables
        types="disease"
        drDetails={drDetails}
        groupId={groupId}
        groupName={groupName}
        scrHeight={props.scrHeight}
        scrWidth={props.scrWidth}
      />
      {/* </Box> */}
      {/* </Paper> */}
      {/* //---------------------------------------------------------------- */}
      <Fragment>
        <DrDetailsDia
          title="Add Dr details"
          isOpen={openAddDia}
          handleCloseDia={handleAddClose}
          updateCurList={addDrDetailsInCurrentList}
          id={0}
          drName=""
          drMobile=""
          drDesc=""
          groupId={groupId}
        />
      </Fragment>
    </div>
  );
};

export default DrListTable;
