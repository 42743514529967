import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import ListIcon from "@material-ui/icons/List";
// import VisitListDia from "./diaComponent/VisitListDia";

// import MaterialTable from "material-table";
import MaterialTable from "@material-table/core";
import { hostInfo } from "../../../store";
import { Button, Tooltip } from "@material-ui/core";
import { Box, Avatar } from "@material-ui/core";
import EditExpenses from "./EditExpenses";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  containerIcon: {
    display: "flex",
    alignItems: "center",
    // paddin: spacing(2),
  },
  inactive: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    marginRight: theme.spacing(2),
  },
  active: {
    background: "linear-gradient(45deg, #279b37 30%, #7ac143 80%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",

    marginRight: theme.spacing(2),
  },
  subVisit: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  infoIcon: {
    color: "#8BB80E",
    // backgroundColor: "black",
    fontSize: 30,
  },
}));

const ExpensesListTbl = (props) => {
  const {
    handleClickOpen,
    open,
    closeHdl,
    // patientGrpList,
    handleDelPatient,
    ExpensesList,
  } = props;

  // const [patientList, setPatientList] = useState([]);
  // const [patientGrpList, setPatientGrpList] = useState([]);
  // const [data, setData] = useState(rawData);
  // const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [patientInfo, setPatientInfo] = useState({});
  const [patientId, setPatientId] = useState(0);

  const [user, setUser] = useState("");
  console.log(patientInfo);
  // const [dialogWord, setDialogWord] = useState('');
  // const [dialogId, setDialogId] = useState('');
  const classes = useStyles();

  // useEffect(() => {
  //   const fetchUserInfo = async () => {
  //     try {
  //       const response = await fetch(`${hostInfo}/usernames`, {
  //         headers: {
  //           Authorization: `Bearer ${getToken()}`,
  //         },
  //       });

  //       if (!response.ok) {
  //         throw new Error(`HTTP error! status: ${response.status}`);
  //       }

  //       const data = await response.json();
  //       const a = getToken();
  //       const b = JSON.parse(atob(a.split(".")[1]));
  //       const user = data.users.filter((i) => i.username === b.username);
  //       setUser(user);
  //     } catch (error) {
  //       console.error("There was an error fetching the user info!", error);
  //     }
  //   };

  //   fetchUserInfo();
  // }, []);

  // const handleDialogClose = (event) => {
  //   setIsDialogOpen(false);
  // };

  // const handleId = event => {
  //   setDialogId(event.target.value);
  // }

  // const handleWord = event => {
  //   setDialogWord(event.target.value);
  // }

  // const handleAddNewRow = event => {
  //   if (!dialogId || !dialogWord) return;

  //   setData(
  //     // Here you can add the new row to whatever index you want
  //     [{ id: dialogId, word: dialogWord }, ...data]
  //   );
  // }

  const columns = [
    {
      title: "Date",
      field: "date",
      searchable: false,
      // render: (rowData) => (
      //   <div
      //     className={classes.containerIcon}
      //     // onClick={(e, rowData) => handlePatientClick(e, rowData)}
      //   >
      //     <Avatar
      //       variant="rounded"
      //       className={`${rowData.status ? classes.active : classes.inactive}`}
      //     >
      //       {rowData.case_no}
      //     </Avatar>

      //     <Box fontSize={16} fontFamily="Helvetica" fontWeight={550}>
      //       {rowData.name}
      //     </Box>
      //   </div>
      // ),
    },
    {
      title: "Purpose",
      field: "purpose",
      searchable: true,
      render: (rowData) => {
        const text = rowData.purpose || ""; // Ensure that `rowData.purpose` exists

        // Truncate text if it exceeds 200 characters
        const truncatedText =
          text.length > 150 ? text.substring(0, 150) + "..." : text;

        return (
          <Tooltip title={text} arrow>
            <Box
              sx={{
                display: "inline-block",
                maxWidth: "200px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {truncatedText}
            </Box>
          </Tooltip>
        );
      },
    },

    { title: "Amount", field: "amount", searchable: false },
  ];

  // useEffect(() => {
  //   let url = "http://" + hostInfo + "/getptinfo";
  //   fetch(url)
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res != null) {
  //         if (res != null) {
  //           console.log(res);
  //           setPatientList(res);
  //         }
  //       }
  //     });
  // }, []);
  const getToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.authToken;
  };

  return (
    <div className={classes.inner}>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
      <Fragment>
        <MaterialTable
          title="Expenses List"
          columns={columns}
          data={ExpensesList.map((expenses) => ({
            id: expenses.id,
            date: new Date(expenses.date_time)
              .toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })
              .replace(/\//g, "-"),
            purpose: expenses.purpose,
            amount: expenses.amount,

            // email: patient.mail_id,
          }))}
          actions={[
            {
              icon: "edit",
              onClick: (event, rowData) => {
                setPatientId(rowData.id);
                let expenses = ExpensesList.find(
                  (item) => item.id === rowData.id
                );
                setPatientInfo(expenses);
                handleClickOpen();
              },

              tooltip: "Edit",
            },
            {
              icon: "delete",
              tooltip: "Delete User",
              onClick: (event, rowData) => {
                const url =
                  hostInfo + "/deleteExpenses?expenses_id=" + rowData.id;
                if (window.confirm("You want to delete " + rowData.id)) {
                  fetch(url, {
                    method: "POST",

                    headers: {
                      Accept: "*/*",
                      Authorization: `Bearer ${getToken()}`,
                    },
                  })
                    .then(() => {
                      handleDelPatient(
                        ExpensesList.filter(
                          (expenses) => expenses.id !== rowData.id
                        )
                      );
                      // console.log("Vist " + rowData.id + " Deleted Successfully");
                    })
                    .catch((error) => {
                      console.error("There was an error!", error);
                    });
                }
              },
            },
          ]}
          options={{
            pageSizeOptions: [5, 10, 20, 50, 100],
            headerStyle: {
              backgroundColor: "#AFE1CE",
              color: "#000",
            },
            sorting: true,

            minBodyHeight: "auto",

            actionsColumnIndex: -1,
          }}
          // onRowClick={(event, rowData, togglePanel) =>
          //   handlePatientClick(rowData.id)
          // }
        />
        {open ? (
          <EditExpenses
            open={open}
            closeHdl={closeHdl}
            // title="Visit Details"
            // isOpen={isDialogOpen}
            // onClose={handleDialogClose}
            // patientGrpList={patientGrpList}
            classes={classes}
            patientId={patientId}
            patientInfo={patientInfo}
          />
        ) : null}
      </Fragment>
    </div>
  );
};

export default ExpensesListTbl;
