import React, { useEffect, useState } from "react";
// import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Paper, Divider, Typography, Button } from "@material-ui/core";
import { Container, Row, Col } from "react-bootstrap";
import { BackupNow } from "./components";
import Restore from "./components/Restore";
import { hostInfo } from "../../store";
import { Label } from "@material-ui/icons";
import AddNewUser from "./components/AddNewUser";
import UserListTbl from "./components/UsersListTbl";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    // backgroundColor: "green",
  },
  addUsrBtn: {
    background: "#5E92C2",
    borderRadius: 2,
    border: 0,
    color: "white",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    "&:hover": {
      background: "#C6A3D4",
    },
    marginRight: theme.spacing(2),
  },
  title: {
    marginTop: theme.spacing(3),
    margin: theme.spacing(2),
  },
}));

const Maintenance = () => {
  const classes = useStyles();
  // const [dashboardCard, setDashboardCard] = useState({});
  const [usernames, setUsernames] = useState([]);
  const [addOpen, setAddOpen] = useState(false);
  const [patientList, setPatientList] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  console.log(usernames);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const getToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.authToken;
  };
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${hostInfo}/usernames`, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        setUsernames(data.users.filter((i) => i.accesses_flag !== 2));
        setSelectedUsers(
          data.users
            .filter((user) => user.accesses_flag === 1)
            .map((user) => user.username)
        );
      } catch (error) {
        console.error("Error fetching usernames:", error);
        alert(error);
      }
    };
    async function fetchPatient() {
      let url = hostInfo + "/getallsignupusers";
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res != null) {
            if (res != null) {
              // console.log(res);
              setPatientList(
                res.filter(
                  (i) => i.accesses_flag !== 1 && i.accesses_flag !== 2
                )
              );
            }
          }
        });
    }

    fetchUsers();
    fetchPatient();
  }, []);

  const handleCheckboxChange = (username) => {
    setSelectedUsers((prevState) => {
      if (prevState.includes(username)) {
        return prevState.filter((user) => user !== username);
      }
      return [...prevState, username];
    });
  };

  const handleGrantAccess = () => {
    fetch(`${hostInfo}/grant-access`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({ users: selectedUsers }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        alert("Access granted!");
        // setSelectedUsers([]);
      })
      .catch((error) => {
        console.error("There was an error granting access!", error);
      });
  };

  const handleClickAddOpen = () => {
    setAddOpen(true);
  };

  const handleAddClose = (value) => {
    setAddOpen(false);
  };

  const handleClickEditOpen = () => {
    setEditOpen(true);
  };

  const handleEditClose = (value) => {
    setEditOpen(false);
  };

  const handleDelPatient = (value) => {
    setPatientList(value);
  };

  return (
    <>
      <Typography
        className={classes.title}
        variant="h2"
        gutterBottom
        color="textSecondary"
      >
        Maintenance
      </Typography>
      <Divider className={classes.deviderCls} />
      <div className={classes.root}>
        <Container fluid className="main-content-container px-1">
          <Row>
            <Col lg="4">
              <div>
                <Typography
                  className="pl-2"
                  gutterBottom
                  variant="h5"
                  component="h2"
                  color="textSecondary"
                >
                  Data Backup
                </Typography>
              </div>
            </Col>
            <Grid container direction="row" className="pl-3">
              <Grid>
                <BackupNow />
              </Grid>
              {/* <Grid>
                <Restore />
              </Grid> */}
            </Grid>

            {/* <Typography className="pt-5">
              <BackupNow />
              <Restore />
            </Typography> */}
          </Row>
        </Container>
      </div>
      <Divider className={classes.deviderCls} />
      <div className={classes.root} style={{ paddingLeft: "13px" }}>
        <Button
          variant="outlined"
          size="small"
          className={classes.addUsrBtn}
          onClick={handleClickAddOpen}
        >
          Add User
        </Button>
      </div>
      {/* <div className={classes.component}>
        <Button
          variant="outlined"
          className={classes.addUsrBtn}
          onClick={handleClickSearchOpen}
        >
          Search Patient
        </Button>
      </div>
      {searchOpen ? (
        <div>
          <SearchPatient
            open={searchOpen}
            patientList={patientList}
            closeHdl={handleSearchClose}
          />
        </div>
      ) : null} */}
      {addOpen ? (
        <div>
          <AddNewUser
            open={addOpen}
            // patientGrpList={patientGrpList}
            closeHdl={handleAddClose}
          />
        </div>
      ) : null}

      <div className={classes.component} style={{ padding: "5px 20px" }}>
        <UserListTbl
          classes={classes}
          handleClickOpen={handleClickEditOpen}
          open={editOpen}
          // patientGrpList={patientGrpList}
          patientList={patientList}
          closeHdl={handleEditClose}
          handleDelPatient={handleDelPatient}
        />
      </div>
      {/* <div className={classes.root}>
        <Container fluid className="main-content-container px-1">
          <Row>
            <Col lg="4">
              <div>
                <Typography
                  className="pl-2"
                  gutterBottom
                  variant="h5"
                  component="h2"
                  color="textSecondary"
                >
                  User List
                </Typography>
              </div>
            </Col>
            <Grid container direction="row" className="pl-3">
              <Grid className="pl-3">
                <ul style={{ listStyle: "none", marginLeft: "-40px" }}>
                  {usernames.length > 0 &&
                    usernames.map((user) => (
                      <li
                        key={user.username}
                        style={{
                          marginBottom: "5px",
                          marginLeft: "0",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={selectedUsers.includes(user.username)}
                          onChange={() => handleCheckboxChange(user.username)}
                        />
                        <label
                          style={{
                            textAlign: "center",
                            // marginLeft: "5px",
                            color: "black",
                            padding: "4px 0px 0px 5px",
                          }}
                        >
                          {user.username}
                        </label>
                      </li>
                    ))}
                </ul>

              
                {usernames.length > 0 ? (
                  <Button
                    onClick={handleGrantAccess}
                    target="_blank"
                    variant="contained"
                    color="primary"
                  >
                    Grant Access
                  </Button>
                ) : (
                  <div>User Not Available</div>
                )}
              </Grid>
             
            </Grid>

          
          </Row>
        </Container>
      </div> */}

      {/* <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}> */}
    </>
  );
};

export default Maintenance;
