import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Row, Col } from "react-bootstrap";
import { Box } from "@material-ui/core";
import PtListTable from "./components/PtListTable"; // default import
import PtGroupList from "./components/PtGroupList"; // default import
import { hostInfo } from "../../store"; // ensure this is correct based on your project structure

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  box: {
    height: "100%",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
  },
  cardBottom: {
    marginBottom: theme.spacing(2),
  },
}));

const PatientGroup = () => {
  const classes = useStyles();
  const [groupId, setGroupId] = useState(0);
  const [groupName, setGroupName] = useState("");
  const [groupList, setGroupList] = useState([]);

  const getToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.authToken;
  };

  useEffect(() => {
    async function getPtGroups() {
      const url = `${hostInfo}/getptgroup`;
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      const res = await response.json();
      if (res) {
        setGroupList(res);
        if (res.length > 0) {
          setGroupId(res[0].id);
          setGroupName(res[0].name);
        }
      } else {
        setGroupList([]);
      }
    }

    if (groupId === 0) {
      getPtGroups();
    }
  }, [groupId]);

  const handleOnSelectGroup = (id, name) => {
    setGroupId(id);
    setGroupName(name);
  };

  const handleOnAdd = (name, tag, desc) => {
    if (!name) return;

    const data = { name, tag, description: desc };
    const url = `${hostInfo}/addptgroup`;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify(data),
    }).then(() => {
      window.location.reload();
    });
  };

  const handleOnDelete = (id) => {
    if (id === 0) return;

    const data = { id };
    const url = `${hostInfo}/delptgroup`;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify(data),
    }).then(() => {
      window.location.reload();
    });
  };

  const handleOnSave = (id, name, tag, desc) => {
    if (id === 0 || !name) return;

    const data = { id, name, tag, description: desc };
    const url = `${hostInfo}/updateptgroup`;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify(data),
    }).then(() => {
      window.location.reload();
    });
  };

  return (
    <div className={classes.root}>
      <Row>
        <Col lg="3">
          <Box className={classes.box}>
            <PtGroupList
              groupList={groupList}
              handleOnSave={handleOnSave}
              handleOnDelete={handleOnDelete}
              handleOnAdd={handleOnAdd}
              handleOnSelectGroup={handleOnSelectGroup}
            />
          </Box>
        </Col>
        <Col lg="9">
          <Box className={classes.box}>
            <PtListTable groupId={groupId} groupName={groupName} />
          </Box>
        </Col>
      </Row>
    </div>
  );
};

export default PatientGroup;
