import React, { Fragment } from "react";

import { withStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { hostInfo } from "../../../store";
import { genderArr } from "../../../store";
import DateFnsUtils from "@date-io/date-fns";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
// import  "../../../fingerscripts/jquery-1.8.2";
// import "../../../fingerscripts/mfs100";
// import * as finger "../../../fingerscripts/finger";
import FormGroup from "@material-ui/core/FormGroup";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
// import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import FingerScan from "../../../FingerScan/FingerScan";

//import { countryArr } from "../../../../store";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { Country, State, City } from "country-state-city";

// import { ICountry, IState, ICity } from "country-state-city";
import { Grid, Paper } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      minWidth: 250,
    },
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  formControl: {
    // margin: theme.spacing(1),
    width: 250,
  },
  grpControl: {
    marginTop: theme.spacing(2),
    width: 250,
  },
  imgpaper: {
    padding: theme.spacing(2),
    margin: "auto",
    // with: "150px",
    height: "220px",
  },
  countryControl: {
    marginRight: theme.spacing(1),
    width: 165,
  },
  toggleSwitch: {
    marginTop: theme.spacing(3),
  },
  location: {
    direction: "row",
    alignItems: "spce-between",
  },
  addressControl: {
    minWidth: "95%",
  },
  autocomp: {
    '&[data-focus="true"], &:hover': {
      backgroundColor: "#CFE8A9",
    },
  },
});

// const genderArr = [
//   {
//     value: "M",
//     label: "Male",
//   },
//   {
//     value: "F",
//     label: "Female",
//   },
//   {
//     value: "O",
//     label: "Other",
//   },
// ];
export default withStyles(styles)(
  class EditUser extends React.Component {
    state = {
      formdetail: {
        id: 0,
        name: "",

        password: "",
        email: "",
        emailError: false,
        emailErrorTxt: "",
        nameError: false,

        passwordError: false,

        nameErrorTxt: "",

        passwordErrorTxt: "",
      },
    };

    // shouldComponentUpdate() {
    //   console.log("shouldComponentUpdate --------------------");
    // }

    // componentDidUpdate(prevProps) {
    //   if (Object.keys(this.props.patientInfo).length === 0) {
    //     console.log("undefined patient --------------------");
    //     return;
    //   } else if (
    //     prevProps.patientInfo.firstname !== this.props.patientInfo.firstname
    //   ) {
    //     return;
    //   }
    //   // console.log(this.props.patientInfo);
    //   console.log("componentDidUpdate  --------------------");
    //   console.log(prevProps.patientInfo);
    //   let patient = this.props.patientInfo;
    //   let formdetail = {
    //     firstname: patient.firstname,
    //     lastname: patient.lastname,
    //     email: patient.mail_id,
    //     gender: patient.gender,
    //     age: patient.age,
    //     // disability: false,
    //     country: patient.country,
    //     state: patient.state,
    //     city: patient.city,
    //     mobile: patient.mobile,
    //     address: patient.address,

    //     firstnameError: false,
    //     lastnameError: false,
    //     emailError: false,
    //     ageError: false,
    //     countryError: false,
    //     stateError: false,
    //     cityError: false,

    //     firstnameErrorTxt: "",
    //     lastnameErrorTxt: "",
    //     emailErrorTxt: "",
    //     ageErrorTxt: "",
    //     disabilityErrorTxt: "",
    //     countryErrorTxt: "",
    //     stateErrorTxt: "",
    //     cityErrorTxt: "",
    //   };

    //   this.setState({ ...this.state, formdetail });
    //   console.log(this.props.patientInfo);
    // }

    // handleChildUnmount() {
    //   console.log("--------Component Un MOUNT! -------------");
    //

    async componentDidMount() {
      // console.log("Component DID MOUNT! -------------");

      let formdetail = { ...this.state.formdetail };

      let patient = this.props.patientInfo;
      // console.log("patient.birthdate --- ", patient.birthdate);
      // console.log("Got patioent from server---");
      console.log(patient);

      formdetail.id = patient.id;
      formdetail.name = patient.name;
      formdetail.password = patient.password;
      formdetail.email = patient.email;
      formdetail.nameError = false;

      formdetail.passwordError = false;
      formdetail.nameErrorTxt = "";
      formdetail.emailError = false;
      formdetail.emailErrorTxt = "";
      formdetail.passwordErrorTxt = "";
      this.setState({ formdetail });

      // this.setState({ ...this.state, formdetail });
      // console.log(this.props.patientInfo);

      // let patient;
      // let url =
      //   "http://" + hostInfo + "/getpatientbyid?patient_id=" + this.patientId;
      // await fetch(url)
      //   .then((res) => res.json())
      //   .then((data) => {
      //     // setTimeout(() => setStory(data), 1500);
      //     // console.log("Success ", data);
      //     // if (!Object.entries(data).length) {
      //     //   window.location = "/dashboard";
      //     // }
      //     if (data != null) {
      //       patient = data;
      //     }
      //   });
      // console.log("Did mount call ---End-----");
      // console.log(patient);
      // appendScript("/fingerscripts/jquery-1.8.2.js");
      // appendScript("/fingerscripts/mfs100.js");
      // appendScript("/fingerscripts/finger.js");
      // console.log(formdetail);
    }

    validate = () => {
      return false;
      let isError = false;
      const { formdetail } = this.state;

      // Clean old error status
      formdetail.firstnameError = false;
      formdetail.lastnameError = false;
      formdetail.emailError = false;
      formdetail.birthdateError = false;
      formdetail.countryError = false;
      formdetail.stateError = false;
      formdetail.cityError = false;
      formdetail.ageError = false;
      formdetail.disabilityError = false;

      formdetail.firstnameErrorTxt = "";
      formdetail.lastnameErrorTxt = "";
      formdetail.emailErrorTxt = "";
      formdetail.birthdateErrorTxt = "";
      formdetail.countryErrorTxt = "";
      formdetail.stateErrorTxt = "";
      formdetail.cityErrorTxt = "";
      formdetail.genderErrorTxt = "";
      formdetail.ageErrorTxt = "";

      // firstname Validation----
      // 1. Length
      // 2. special character check
      if (formdetail.firstname.length < 2) {
        isError = true;
        formdetail.firstnameError = true;
        formdetail.firstnameErrorTxt =
          "firstname needs to be atleast 2 characters long";
      }

      // Lastname Validation
      if (formdetail.lastname.length < 2) {
        isError = true;
        formdetail.lastnameError = true;
        formdetail.lastnameErrorTxt =
          "Lastname needs to be atleast 2 characters long";
      }

      // Email Validation
      // 1. @ check
      if (formdetail.email.length < 1) {
        formdetail.email = "-";
      }

      // Mobile
      if (formdetail.mobile.length < 1) {
        formdetail.mobile = "-";
      }

      // console.log(
      //   "Selected C: " +
      //     this.selectedCountry +
      //     "S: " +
      //     this.selectedState +
      //     "city: " +
      //     this.selectedCity
      // );
      // console.log(this.cityArr);
      // Country, state, city validation
      if (!this.selectedCountry.length) {
        // console.log("contry vaidation --------");
        // console.log(this.selectedCountry);
        // console.log(this.countryArr);
        isError = true;
        formdetail.countryError = true;
        formdetail.countryErrorTxt = "Invalid Country";
      }
      if (false === this.validateInArry(this.stateArr, this.selectedState)) {
        // console.log("State vaidation --------");
        // console.log(this.selectedState);
        // console.log(this.stateArr);
        // console.log(this.cityArr);
        isError = true;
        formdetail.stateError = true;
        formdetail.stateErrorTxt = "Invalid State";
      }

      // City
      if (false === this.validateInArry(this.cityArr, this.selectedCity)) {
        // console.log("city arry len: " + this.cityArr.length);
        // console.log(this.selectedCity);
        // console.log(this.cityArr.indexOf[this.selectedCity]);
        isError = true;
        formdetail.cityError = true;
        formdetail.cityErrorTxt = "Invalid City";
      }

      this.setState({
        ...this.state.formdetail,
      });

      return isError;
    };

    handleSubmit = async () => {
      const err = this.validate();

      // const err = false;
      if (!err) {
        // On submit form success
        let { formdetail } = this.state;
        // console.log("---------", formdetail.birthdate);

        // Date Validation...

        let formObj = {
          id: formdetail.id,
          name: formdetail.name,

          // disability: formdetail.disability,
          email: formdetail.email,
          password: formdetail.password,
        };
        const getToken = () => {
          const cookieString = document.cookie;
          const cookies = cookieString.split("; ").reduce((acc, cookie) => {
            const [name, value] = cookie.split("=");
            acc[name] = value;
            return acc;
          }, {});
          return cookies.authToken;
        };

        // console.log("Final from object...");
        // console.log(JSON.stringify(formObj));
        let url = hostInfo + "/updateUser";
        await fetch(url, {
          method: "POST",
          // mode: "no-cors",
          headers: {
            Accept: "*/*",
            "Content-type": "application/json; charset=UTF-8",

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(formObj),
        });

        // console.log("response id --------");

        // const content = await rawResponse.json();
        // console.log(content);
        this.props.closeHdl();
        window.location.reload();
        // window.location = "/patients/patientprofile?patient_id=" + id;
        return true;
      }
      //Error in Submit..............
      // console.log("Validation failed, Error in Submit--------");
      return false;
    };

    handleDiaClose = () => {
      this.props.closeHdl();
    };

    handleClickOpen = () => {
      this.setState({ open: true });
    };

    handleClose = () => {
      this.setState({ open: false });
    };

    handleGrpChange = (e, name, value) => {
      // console.log("Inside handle Gen change change---");
      // // console.log(id);
      // console.log(name);
      // console.log(value);

      this.setState({
        formdetail: { ...this.state.formdetail, [name]: value.id },
      });
    };

    handleNumericChange = (name, val) => {
      // console.log(name);
      // console.log(val);
      const value = val.floatValue;
      // this.setState({ ...this.state.formdetail, [name]: value });
      this.setState({
        formdetail: { ...this.state.formdetail, [name]: value },
      });
    };

    handleDateChange = (name, value) => {
      // setSelectedEndDate(date);
      // console.log("On Date change log.......");
      // console.log(name);
      // console.log(value);
      this.setState({
        formdetail: { ...this.state.formdetail, birthdate: value },
      });
    };

    handleOnChange = (e, name) => {
      // console.log("On Change log.......");
      // console.log(name);
      // console.log(e.target.value);

      const value = e.target.value;
      // this.setState({ ...this.props.patientInfo, [name]: value });
      this.setState({
        formdetail: { ...this.state.formdetail, [name]: value },
      });
      // console.log(this.state.formdetail);
    };

    handleFilterChange = (e) => {
      this.setState({ selectedFilter: e.target.value });
    };

    render() {
      const {
        // open,
        formdetail: {
          name,

          password,
          // age,

          nameError,

          passwordError,
          emailError,
          emailErrorTxt,
          nameErrorTxt,
          email,
          passwordErrorTxt,
        },
      } = this.state;
      // const {} = this;
      // const {  } = this.props;
      // console.log(this.countryArr);
      // console.log(this.cityArr);
      const {
        patientInfo,
        // patientId,
        classes,
        // closeHdl,
        open,
        EditReport,
        // patientGrpList,
      } = this.props;
      // console.log("patient state Info --------------");
      // console.log(this.state.formdetail);
      return (
        // <div>
        <Fragment>
          <Dialog
            open={open}
            onClose={this.handleDiaClose}
            // aria-labelledby="Add User"
          >
            <DialogTitle id="AddNewPatient">Edit User Detail</DialogTitle>
            <DialogContent>
              {/* <DialogContentText>Fill the patient details</DialogContentText> */}
              <form className={classes.root} autoComplete="off">
                <TextField
                  className={classes.addressControl}
                  //////////////////  First Name
                  autoFocus
                  margin="dense"
                  id="name"
                  error={nameError}
                  value={name}
                  onChange={(e) => {
                    this.handleOnChange(e, "name");
                  }}
                  label="Name"
                  type="name"
                  helperText={nameErrorTxt}
                />

                {/*
                // Mitesh: Temp change as per costomer req 
                <TextField
                  // autoFocus
                  id="email"
                  error={emailError}
                  value={email}
                  onChange={(e) => {
                    this.handleOnChange(e, "email");
                  }}
                  label="Email"
                  type="email"
                  helperText={emailErrorTxt}
                /> */}

                {/* <div
                  style={{ width: "150px", height: "190px", align: "center" }}
                >
                  <img
                    id="imgFinger"
                    width="145px"
                    height="188px"
                    alt="Finger Image"
                  />
                </div> */}

                <TextField
                  //////////////////  Address
                  className={classes.addressControl}
                  // autoFocus
                  margin="dense"
                  id="Password"
                  error={passwordError}
                  value={password}
                  onChange={(e) => {
                    this.handleOnChange(e, "password");
                  }}
                  label="Password"
                  type="password" // Ensure the type is 'text' (default)
                  //   multiline // Enables the textarea functionality
                  //   rows={3}
                  // disabled={true}
                  // fullWidth
                  helperText={passwordErrorTxt}
                />
                <TextField
                  //////////////////  Address
                  className={classes.addressControl}
                  // autoFocus

                  margin="dense"
                  id="Email"
                  error={emailError}
                  value={email}
                  onChange={(e) => {
                    this.handleOnChange(e, "email");
                  }}
                  label="Email"
                  type="email" // Ensure the type is 'text' (default)
                  //   multiline // Enables the textarea functionality
                  //   rows={3}
                  // fullWidth
                  helperText={emailErrorTxt}
                  InputLabelProps={{
                    style: { color: "#71717A" },
                  }}
                />

                {/* <InputLabel id="filter-label">Filter by Purpose</InputLabel> */}
              </form>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.handleSubmit();
                }}
                color="primary"
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </Fragment>
        // </div>
      );
    }
  }
);
