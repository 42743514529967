import React, { Fragment, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import AddNewExpenses from "./components/AddNewExpenses";
import { hostInfo } from "../../store";

import ExpensesListTbl from "./components/ExpensesListTbl";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(2),
    background: "linear-gradient(45deg, #81d4fa 10%, #dce775 90%)",
    borderRadius: 3,
    border: 0,
    color: "white",
  },
  inner: {
    minWidth: "100%",
  },
  addUsrBtn: {
    background: "#5E92C2",
    borderRadius: 2,
    border: 0,
    color: "white",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    "&:hover": {
      background: "#C6A3D4",
    },
    marginRight: theme.spacing(2),
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  component: {
    padding: theme.spacing(1),
    // marginBottom: theme.spacing(1),
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

const Expenses = () => {
  const classes = useStyles();
  const [addOpen, setAddOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  // const [patientGrpList, setPatientGrpList] = useState([]);
  const [ExpensesList, setExpensesList] = useState([]);

  // const [products] = useState(mockData);
  const handleClickAddOpen = () => {
    setAddOpen(true);
  };

  const handleAddClose = (value) => {
    setAddOpen(false);
  };

  const handleClickSearchOpen = () => {
    setSearchOpen(true);
  };

  const handleSearchClose = (value) => {
    setSearchOpen(false);
  };

  const handleClickEditOpen = () => {
    setEditOpen(true);
  };

  const handleEditClose = (value) => {
    setEditOpen(false);
  };

  const handleDelPatient = (value) => {
    setExpensesList(value);
  };
  const getToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.authToken;
  };

  useEffect(() => {
    // async function fetchPtGroup() {
    //   let url = hostInfo + "/getptgroup";
    //   await fetch(url, {
    //     headers: {
    //       Authorization: `Bearer ${getToken()}`,
    //     },
    //   })
    //     .then((res) => res.json())
    //     .then((res) => {
    //       if (res != null) {
    //         if (res != null) {
    //           setPatientGrpList(res);
    //         }
    //       }
    //     });
    // }

    async function fetchExpenses() {
      let url = hostInfo + "/getexpenses";
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res != null) {
            if (res != null) {
              // console.log(res);
              setExpensesList(res);
            }
          }
        });
    }

    // fetchPtGroup();
    fetchExpenses();
  }, []);

  return (
    <Fragment>
      {/* <FingerScan /> */}
      <div className={classes.component}>
        <Button
          variant="outlined"
          size="small"
          className={classes.addUsrBtn}
          onClick={handleClickAddOpen}
        >
          Add Expenses
        </Button>
      </div>
      {/* <div className={classes.component}>
        <Button
          variant="outlined"
          className={classes.addUsrBtn}
          onClick={handleClickSearchOpen}
        >
          Search Patient
        </Button>
      </div>
      {searchOpen ? (
        <div>
          <SearchPatient
            open={searchOpen}
            patientList={patientList}
            closeHdl={handleSearchClose}
          />
        </div>
      ) : null} */}
      {addOpen ? (
        <div>
          <AddNewExpenses
            open={addOpen}
            // patientGrpList={patientGrpList}
            closeHdl={handleAddClose}
          />
        </div>
      ) : null}

      <div className={classes.component}>
        <ExpensesListTbl
          classes={classes}
          handleClickOpen={handleClickEditOpen}
          open={editOpen}
          // patientGrpList={patientGrpList}
          ExpensesList={ExpensesList}
          closeHdl={handleEditClose}
          handleDelPatient={handleDelPatient}
        />
      </div>
    </Fragment>
  );
};

export default Expenses;
