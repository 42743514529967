import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Paper from "@material-ui/core/Paper";

import {
  Avatar,
  Typography,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import { visitFormfields } from "../../../../store";

// import { getInitials } from "../../../../helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    // margin: theme.spacing(1),
    fontSize: 12,
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: "100%",
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  notesCls: {
    padding: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  medRowOut: {
    color: "#8AA65C",
    fontWeight: "bold",
  },
  medRowIn: {
    color: "#282E29",
    // fontWeight: "bold",
  },
  expandGrid: {
    padding: theme.spacing(2),
    minWidth: "100%",
  },
  prescription: {
    minWidth: "40%",
    margin: theme.spacing(0.5),
  },
  reports: {
    minWidth: "20%",
    margin: theme.spacing(1),
  },
}));

const VisitDetailInfo = (props) => {
  const { visit } = props;

  const classes = useStyles();
  // const history = useHistory();

  // let contextTypes = {
  //   router: React.PropTypes.object,
  // };

  let medList = JSON.parse(visit.medicine_info);
  // console.log("medicine_info ----- ");
  // console.log(medList);
  let repList = JSON.parse(visit.report_info);
  let diagnosis =
    // visit.report_info ?
    JSON.parse(visit.dr_notes);
  // console.log("report_info ----- ");
  // console.log(repList);
  return (
    <div className={classes.expandGrid} style={{ marginTop: "-10px" }}>
      <Grid container direction="row" spacing={1} style={{ marginLeft: "1px" }}>
        <Grid item>
          <Typography color="textPrimary" style={{ fontSize: "11px" }}>
            Temp :{" "}
            <span style={{ fontSize: "11px", color: "#546e7a" }}>
              {visit.temperature ? visit.temperature : "-"} {"|"}
            </span>
          </Typography>
        </Grid>

        <Grid item>
          <Typography color="textPrimary" style={{ fontSize: "11px" }}>
            Pulse :{" "}
            <span style={{ fontSize: "11px", color: "#546e7a" }}>
              {visit.pulse ? visit.pulse : "-"} {"|"}
            </span>
          </Typography>
        </Grid>
        <Grid item>
          <Typography color="textPrimary" style={{ fontSize: "11px" }}>
            BP :{" "}
            <span style={{ fontSize: "11px", color: "#546e7a" }}>
              {visit.bp ? visit.bp : "-"} {"|"}
            </span>
          </Typography>
        </Grid>
        <Grid item>
          <Typography color="textPrimary" style={{ fontSize: "11px" }}>
            SpO2 :{" "}
            <span style={{ fontSize: "11px", color: "#546e7a" }}>
              {visit.spo ? visit.spo : "-"} {"|"}
            </span>
          </Typography>
        </Grid>
        <Grid item>
          <Typography color="textPrimary" style={{ fontSize: "11px" }}>
            RR :{" "}
            <span style={{ fontSize: "11px", color: "#546e7a" }}>
              {visit.rr ? visit.rr : "-"} {"|"}
            </span>
          </Typography>
        </Grid>
        <Grid item>
          <Typography color="textPrimary" style={{ fontSize: "11px" }}>
            Height :{" "}
            <span style={{ fontSize: "11px", color: "#546e7a" }}>
              {visit.height ? visit.height : "-"}
            </span>
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        style={{ marginLeft: "5px", marginBottom: "2px" }}
      >
        {/* <Grid item xs> */}
        {/* <Box borderLeft={5} className={classes.prescription}>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="Prescription"
              > */}
        {/* <TableHead>
                  <TableRow>
                    <TableCell align="left">Complains</TableCell>
                  </TableRow>
                </TableHead> */}
        {/* <TableBody>
                  <TableRow>
                    <TableCell align="left"> */}

        <Typography color="textPrimary" style={{ fontSize: "11px" }}>
          <span
            style={{
              display: "inline-block",
              width: "40px",
            }}
          >
            c/o :{" "}
          </span>
          {visit.complaints?.length > 0
            ? visit.complaints?.map((complaintObj, idx) => {
                const complaintName = Object.keys(complaintObj)[0]; // Get the complaint name (e.g., "fever")
                const [frequency, severity, duration, date] =
                  complaintObj[complaintName]; // Destructure the values

                return (
                  <span
                    key={idx}
                    style={{ fontSize: "11px", color: "#546e7a" }}
                  >
                    {complaintName} ({duration})
                    {idx < visit.complaints.length - 1 && (
                      <span
                        style={{
                          color: "rgb(243, 55, 55)",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        |{" "}
                      </span>
                    )}
                  </span>
                );
              })
            : "-"}
        </Typography>

        {/* //           </TableCell>
          //         </TableRow>
          //       </TableBody> */}
        {/* //     </Table>
          //   </TableContainer>
          // </Box> */}
        {/* </Grid> */}
      </Grid>
      <Grid
        container
        direction="row"
        style={{ marginLeft: "5px", marginBottom: "2px" }}
      >
        <Grid item xs>
          {/* <Box borderLeft={5} className={classes.prescription}>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="Prescription"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Diagnosis</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="left"> */}
          <Typography color="textPrimary" style={{ fontSize: "11px" }}>
            <span
              style={{
                display: "inline-block",
                width: "40px",
              }}
            >
              D/D :{" "}
            </span>
            {diagnosis?.length > 0
              ? diagnosis?.map((rep, idx) => (
                  <span
                    key={idx}
                    style={{ fontSize: "11px", color: "#546e7a" }}
                  >
                    {rep}
                    {idx < diagnosis.length - 1 && (
                      <span
                        style={{
                          color: "rgb(243, 55, 55)",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        |{" "}
                      </span>
                    )}
                  </span>
                ))
              : "-"}
          </Typography>
          {/* </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box> */}
        </Grid>
      </Grid>

      <Grid container direction="row">
        <Grid item xs style={{ marginLeft: "5px", marginBottom: "2px" }}>
          {/* <Box borderLeft={5} className={classes.reports}>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="Prescription"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Reports</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="left"> */}
          <Typography color="textPrimary" style={{ fontSize: "11px" }}>
            <span
              style={{
                display: "inline-block",
                width: "40px",
              }}
            >
              Ix :{" "}
            </span>
            {repList?.length > 0
              ? repList?.map((rep, idx) => (
                  <span
                    key={idx}
                    style={{ fontSize: "11px", color: "#546e7a" }}
                  >
                    {rep}
                    {idx < repList.length - 1 && (
                      <span
                        style={{
                          color: "rgb(243, 55, 55)",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        |{" "}
                      </span>
                    )}
                  </span>
                ))
              : "-"}
          </Typography>
          {/* </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box> */}
        </Grid>
      </Grid>

      <Grid container direction="row">
        <Grid item xs>
          <Box borderLeft={5} className={classes.prescription}>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="Prescription">
                <TableHead>
                  <TableRow>
                    <TableCell align="left" style={{ fontSize: "11px" }}>
                      Prescription
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: "11px" }}>
                      Dose-At
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: "11px" }}>
                      when
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: "11px" }}>
                      #
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {medList.map((med, idx) => (
                    <TableRow key={idx}>
                      <TableCell
                        style={{ fontSize: "11px" }}
                        align="left"
                        className={
                          med.medOut ? classes.medRowOut : classes.medRowIn
                        }
                      >
                        {med.medName ? med.medName : "-"}
                      </TableCell>
                      <TableCell
                        style={{ fontSize: "11px" }}
                        align="center"
                        className={
                          med.medOut ? classes.medRowOut : classes.medRowIn
                        }
                      >
                        {"medQnt" in med ? med.medQnt : ""}{" "}
                        {med.medSchd ? med.medSchd : "-"}
                      </TableCell>
                      <TableCell
                        style={{ fontSize: "11px" }}
                        align="center"
                        className={
                          med.medOut ? classes.medRowOut : classes.medRowIn
                        }
                      >
                        {med.medTime ? med.medTime : "-"}
                      </TableCell>
                      <TableCell
                        style={{ fontSize: "11px" }}
                        align="right"
                        className={
                          med.medOut ? classes.medRowOut : classes.medRowIn
                        }
                      >
                        {med.medCount ? med.medCount : "-"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

VisitDetailInfo.propTypes = {
  className: PropTypes.string,
  // caselist: PropTypes.array.isRequired,
};

export default VisitDetailInfo;
