import React, { useEffect, useRef, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { enableflag, hostInfo } from "../../store";
import DateFnsUtils from "@date-io/date-fns";
// import DateUtils from "@date-io/moment";
import {
  Button,
  // Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
// import CardMedia from "@material-ui/core/CardMedia";
// import { PDFViewer, PDFDownloadLink, pdf } from "@react-pdf/renderer";
// import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
// import { alpha } from "@material-ui/core/styles";
import myLogo from "../../../src/images/avatar/logo22.png";
import styles from "./mystyle.css";
import {
  MuiPickersUtilsProvider,
  // KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
// import DatePicker from "@mui/lab/DatePicker";
// import Moment from "moment";
// import ComingSoon from "react-coming-soon";
// import csImg from "../../../images/avatar/c_s.jpeg";
// import csImg from "../../images/avatar/c_s.jpeg";
import moment from "moment";
// import { Card, CardContent } from "@material-ui/core";
import ReactApexChart from "react-apexcharts";
import ReactToPrint from "react-to-print";

// const useStyles = (theme) => ({
//   card: {
//     display: "flex",
//     margin: 100,
//   },
//   details: {
//     display: "flex",
//     flexDirection: "column",
//   },
//   content: {
//     flex: "1 0 auto",
//   },
//   cover: {
//     width: 151,
//   },
//   controls: {
//     display: "flex",
//     alignItems: "center",
//   },
//   container: {
//     position: "relative",
//     textAlign: "center",
//     color: "white",
//   },
//   topasad: {
//     color: "blue",
//     position: "absolute",
//     top: "10%",
//     left: "50%",
//   },

//   media: {
//     display: "flex",
//     height: 100,
//     objectFit: "contain",
//     alignItems: "left",
//   },
// });

const AssForm = () => {
  // const classes = useStyles();
  let componentRef = useRef();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  // const { handleSubmitAss, setIsAssReport } = props;
  const [selectedStartDate, setSelectedStartDate] = React.useState(
    params.get("str_dt")
  );
  const [selectedEndDate, setSelectedEndDate] = React.useState(
    params.get("end_dt")
  );
  // const [isDisabled, setIsDisabled] = React.useState(false);
  const [type, settype] = React.useState(params.get("type"));
  const [subtype, setsubtype] = React.useState("");

  const [graphData, setgraphData] = React.useState([]);

  const [data, setdata] = React.useState([]);
  const [appendedData, setappendedData] = React.useState([]);
  const [graphtype, setgraphtype] = React.useState([]);

  const generateMonthsBetween = (startDate, endDate) => {
    const start = new Date(startDate); // Parse the start date
    const end = new Date(endDate); // Parse the end date
    const monthsArray = [];

    // Iterate through months between start and end dates
    while (start <= end) {
      const monthName = start.toLocaleString("default", { month: "short" }); // Get short month name
      const year = start.getFullYear(); // Get the year
      monthsArray.push(`${monthName}-${year}`);

      // Move to the next month
      start.setMonth(start.getMonth() + 1);
    }

    return monthsArray;
  };

  // End date (mm-dd-yyyy)
  const months = generateMonthsBetween(selectedStartDate, selectedEndDate);

  const [chartVisible, setChartVisible] = React.useState({
    showrevenu: false,
    showpatient: false,
    showvisit: false,
    showreffer: false,
  });
  const [pData, setpData] = useState("");

  const [rData, setrData] = useState("");
  // var type = null;
  let datesInMonth = [];
  const categories = [];
  // console.log(categories);
  const count = [];
  const totalData = [];
  const paidData = [];
  const remainData = [];
  const start = selectedStartDate;
  const end = selectedEndDate;
  const genderdata = [];

  genderdata.push(pData.genderwise?.male || 0);
  genderdata.push(pData.genderwise?.female || 0);
  genderdata.push(pData.genderwise?.other || 0);

  var st = parseInt(moment(start, "MM-DD-YYYY").valueOf() / 1000);

  var en = parseInt(moment(end, "MM-DD-YYYY").valueOf() / 1000);

  const getToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.authToken;
  };

  useEffect(() => {
    if (type === "revenue" && selectedEndDate && selectedStartDate) {
      if (st < en) {
        const diff = (en - st) / 60 / 60 / 24;

        if (diff <= 30) {
          setsubtype("daily");
        } else if (diff > 30 && diff <= 365) {
          setsubtype("monthly");
        } else if (diff > 365) {
          setsubtype("yearly");
        }
      } else {
        alert("start date must be less than the end date");
        setChartVisible({
          showrevenu: false,
          showpatient: false,
          showvisit: false,
          showreffer: false,
        });
        return;
      }
      let url = hostInfo + "/getassesmentdata";
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-type": "application/json",

          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({
          type: type,
          data_type: subtype,
          start_date: st,
          end_date: en,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("HTTP request failed");
          }
        })
        .then((res) => {
          console.log(res);
          if (res && res.graph_data.data && res.graph_data.data.length > 0) {
            // Data is available
            setdata(res);
            if (enableflag === 1 && res?.expenses) {
              setappendedData([
                {
                  amount: res.total,
                  date_time: selectedStartDate + " to " + selectedEndDate, // Replace with the actual start date
                  purpose: "visit info",
                },
                ...res?.expenses,
              ]);
            }

            setgraphData(res.graph_data.data);

            if (subtype === "daily") {
              const dateString = res.graph_data.data[0].x_val.toString();
              // const dateString = res.revenue.graphData.map(
              //   (i) => i.value.map((i) => i.name)[0]
              // )[0];
              // console.log(dateString);
              const parts = dateString.split("/");
              // const day = parseInt(parts[1], 10);
              const month = parseInt(parts[0], 10) - 1;
              const year = parseInt(parts[2], 10);

              const startDate = new Date(year, month, 1);
              const endDate = new Date(year, month + 1, 0);

              for (
                let date = startDate;
                date <= endDate;
                date.setDate(date.getDate() + 1)
              ) {
                const formattedDate = `${String(date.getMonth() + 1).padStart(
                  2,
                  "0"
                )}/${String(date.getDate()).padStart(
                  2,
                  "0"
                )}/${date.getFullYear()}`;
                datesInMonth.push(formattedDate);
                // console.log(datesInMonth);
                setgraphtype(datesInMonth);
              }
              setChartVisible({
                showrevenu: true,
                showpatient: false,
                showvisit: false,
                showreffer: false,
              });
            } else if (subtype === "monthly") {
              setgraphtype(months);
              setChartVisible({
                showrevenu: true,
                showpatient: false,
                showvisit: false,
                showreffer: false,
              });
            } else if (subtype === "yearly") {
              // const dateString =
              //   res.revenue.graph_data.data[0].x_val.toString();
              // const lastValue = (parseInt(dateString, 10) - 1).toString();
              // const newValue = (parseInt(dateString, 10) + 1).toString();
              const graphData = res.graph_data.data.map((i) => i.x_val); // Assuming x_val contains the year

              // Find the maximum year in the data
              const maxYear = Math.max(...graphData);

              // Add missing years up to 6 years if needed
              const currentYear = new Date().getFullYear(); // Get the current year
              const yearsToAdd = [];

              for (let year = maxYear + 1; year <= currentYear + 5; year++) {
                yearsToAdd.push(year); // Add the missing years up to 6 years
              }

              // Combine the original data with the missing years
              const completeGraphData = [...graphData, ...yearsToAdd];

              setgraphtype(completeGraphData);
              // setgraphtype(res.graph_data.data.map((i) => i.x_val));
              setChartVisible({
                showrevenu: true,
                showpatient: false,
                showvisit: false,
                showreffer: false,
              });
            }
          } else {
            // alert("No data found or invalid response structure");
            // console.log("Data not found");
            setdata([]);
            setgraphData([]);
            setChartVisible({
              showrevenu: false,
              showpatient: false,
              showvisit: false,
              showreffer: false,
            });
          }
        })
        .catch((error) => {
          // alert("No data found or invalid response structure");
          // console.error("Error fetching data:", error);
          // Handle error, set state accordingly
          setdata([]);
          setgraphData([]);
          setChartVisible({
            showrevenu: false,
            showpatient: false,
            showvisit: false,
            showreffer: false,
          });
        });
    } else if (type === "visit" && selectedEndDate && selectedStartDate) {
      if (st < en) {
        const diff = (en - st) / 60 / 60 / 24;

        if (diff <= 30) {
          setsubtype("daily");
        } else if (diff > 30 && diff <= 365) {
          setsubtype("monthly");
        } else if (diff > 365) {
          setsubtype("yearly");
        }
      } else {
        alert("start date must be less than the end date");
        setChartVisible({
          showrevenu: false,
          showpatient: false,
          showvisit: false,
          showreffer: false,
        });
        return;
      }

      let url = hostInfo + "/getassesmentdata";
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-type": "application/json",

          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({
          type: type,
          data_type: subtype,
          start_date: st,
          end_date: en,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("HTTP request failed");
          }
        })
        .then((res) => {
          // console.log(res);
          if (
            res &&
            res.graph_data &&
            res.graph_data.data &&
            res.graph_data.data.length > 0
          ) {
            setdata(res);
            console.log(res);
            setgraphData(res.graph_data.data);

            if (subtype === "daily") {
              const dateString = res.graph_data.data[0].x_val.toString();
              const parts = dateString.split("/");
              const month = parseInt(parts[0], 10) - 1;
              const year = parseInt(parts[2], 10);

              const startDate = new Date(year, month, 1);
              const endDate = new Date(year, month + 1, 0);

              for (
                let date = startDate;
                date <= endDate;
                date.setDate(date.getDate() + 1)
              ) {
                const formattedDate = `${String(date.getMonth() + 1).padStart(
                  2,
                  "0"
                )}/${String(date.getDate()).padStart(
                  2,
                  "0"
                )}/${date.getFullYear()}`;
                datesInMonth.push(formattedDate);
                setgraphtype(datesInMonth);
              }

              setChartVisible({
                showrevenu: false,
                showpatient: false,
                showvisit: true,
                showreffer: false,
              });
            } else if (subtype === "monthly") {
              setgraphtype(months);
              setChartVisible({
                showrevenu: false,
                showpatient: false,
                showvisit: true,
                showreffer: false,
              });
            } else if (subtype === "yearly") {
              const graphData = res.graph_data.data.map((i) => i.x_val); // Assuming x_val contains the year

              // Find the maximum year in the data
              const maxYear = Math.max(...graphData);

              // Add missing years up to 6 years if needed
              const currentYear = new Date().getFullYear(); // Get the current year
              const yearsToAdd = [];

              for (let year = maxYear + 1; year <= currentYear + 5; year++) {
                yearsToAdd.push(year); // Add the missing years up to 6 years
              }

              // Combine the original data with the missing years
              const completeGraphData = [...graphData, ...yearsToAdd];

              setgraphtype(completeGraphData);
              // setgraphtype(res.graph_data.data.map((i) => i.x_val));
              setChartVisible({
                showrevenu: false,
                showpatient: false,
                showvisit: true,
                showreffer: false,
              });
            }
          } else {
            // alert("No data found or invalid response structure");
            setdata([]);
            setgraphData([]);
            setChartVisible({
              showrevenu: false,
              showpatient: false,
              showvisit: false,
              showreffer: false,
            });
          }
        })
        .catch((error) => {
          // console.error("Error fetching data:", error);
          // alert("No data found or invalid response structure");
          setdata([]);
          setgraphData([]);
          setChartVisible({
            showrevenu: false,
            showpatient: false,
            showvisit: false,
            showreffer: false,
          });
        });
    } else if (type === "patient" && selectedEndDate && selectedStartDate) {
      const url = hostInfo + "/getassesmentdata";
      const data = {
        type: type,
        data_type: " ",
        start_date: st,
        end_date: en,
      };

      fetch(url, {
        // mode: "no-cors",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",

          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          if (data) {
            setpData(data);
          } else {
            setpData([]);
          }
        })
        .catch((error) => console.error("Error:", error));
      setChartVisible({
        showrevenu: false,
        showpatient: true,
        showvisit: false,
        showreffer: false,
      });
    } else if (type === "reffered" && selectedEndDate && selectedStartDate) {
      let url = hostInfo + "/getassesmentdata";
      fetch(url, {
        method: "POST",
        // mode: "no-cors",
        headers: {
          Accept: "*/*",
          "Content-type": "application/json",

          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({
          type: type,
          data_type: "",
          start_date: st,
          end_date: en,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            console.log(data.visit_counts);
            setrData(data);
          } else {
            setrData([]);
          }
        });
      setChartVisible({
        showrevenu: false,
        showpatient: false,
        showvisit: false,
        showreffer: true,
      });
    }
  }, [type, subtype]);

  const revenuInfo = async () => {
    if (!selectedStartDate || !selectedEndDate) {
      alert("Please enter start and end date");
      return;
    }
    const url =
      "/assessment?type=revenue&str_dt=" +
      selectedStartDate +
      "&end_dt=" +
      selectedEndDate;
    window.location.href = url;
  };

  const visitInfo = async () => {
    if (!selectedStartDate || !selectedEndDate) {
      alert("Please enter start and end date");
      return;
    }
    const url =
      "/assessment?type=visit&str_dt=" +
      selectedStartDate +
      "&end_dt=" +
      selectedEndDate;
    window.location.href = url;
  };

  const patientInfo = async () => {
    if (!selectedStartDate || !selectedEndDate) {
      alert("Please enter start and end date");
      return;
    }
    const url =
      "/assessment?type=patient&str_dt=" +
      selectedStartDate +
      "&end_dt=" +
      selectedEndDate;
    window.location.href = url;
  };
  const refferInfo = async () => {
    if (!selectedStartDate || !selectedEndDate) {
      alert("Please enter start and end date");
      return;
    }
    const url =
      "/assessment?type=reffered&str_dt=" +
      selectedStartDate +
      "&end_dt=" +
      selectedEndDate;
    window.location.href = url;
  };

  const handleReset = () => {
    const url = "/assessment";

    window.location.href = url;
  };

  for (const month of graphtype) {
    const matchingData = graphData?.find((item) => item.x_val === month);

    if (matchingData) {
      categories.push(month);
      totalData.push(matchingData.y_val.total);
      paidData.push(matchingData.y_val.paid);
      remainData.push(matchingData.y_val.remain);
      count.push(matchingData.y_val.count);
    } else {
      categories.push(month);
      totalData.push(0);
      paidData.push(0);
      remainData.push(0);
      count.push(0);
    }
  }

  const options = {
    chart: {
      id: "bar",
    },
    xaxis: {
      categories: categories,
    },

    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
  };
  const series = [
    {
      name: "Total",
      data: totalData,
    },
    {
      name: "Paid",
      data: paidData,
    },
    {
      name: "Remain",
      data: remainData,
    },
  ];

  const voptions = {
    chart: {
      id: "bar",
    },
    xaxis: {
      categories: categories,
    },

    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },
  };
  const vseries = [
    {
      name: "Patient",
      data: count,
    },
  ];
  // console.log(genderdata);
  const pseries = genderdata;
  const poptions = {
    chart: {
      width: 380,
      type: "pie",
    },
    labels: ["Male", "Female", "Other"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const handleStartDateChange = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    const formattedDate = `${month}-${day}-${year}`;

    setSelectedStartDate(formattedDate);
  };
  const handleEndDateChange = (date) => {
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();

    const formattedDate = `${month}-${day}-${year}`;

    setSelectedEndDate(formattedDate);
  };

  return (
    <>
      <div>
        <form autoComplete="off">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid
              container
              spacing={2}
              justifyContent="space-around"
              style={{ marginBottom: "20px" }}
            >
              <Grid item xs={12} sm={6}>
                <KeyboardDatePicker
                  fullWidth
                  margin="normal"
                  id="date-picker-dialog-start"
                  label="From"
                  format="dd-MM-yyyy"
                  value={selectedStartDate}
                  onChange={handleStartDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <KeyboardDatePicker
                  fullWidth
                  margin="normal"
                  id="date-picker-dialog-end"
                  label="To"
                  format="dd-MM-yyyy"
                  value={selectedEndDate}
                  onChange={handleEndDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>

          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={2}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="small"
                onClick={patientInfo}
              >
                Patient Info
              </Button>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="small"
                onClick={visitInfo}
              >
                Visit Info
              </Button>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="small"
                onClick={revenuInfo}
              >
                Revenue Info
              </Button>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="small"
                onClick={refferInfo}
              >
                Referred Info
              </Button>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                size="small"
                onClick={handleReset}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
      {chartVisible.showrevenu && (
        <>
          <div
            style={{ margin: "20px 20px 0px" }}
            ref={(el) => (componentRef = el)}
          >
            <div className="extra-content-to-print">
              <div style={{ backgroundColor: "#30968e", padding: "25px" }}>
                <img alt="Logo" height={"40px"} src={myLogo} />
              </div>
            </div>
            <div
              style={{
                marginTop: "35px",
                textAlign: "left",
                marginBottom: "30px",
              }}
            >
              <h5>
                <b>1. Revenue Info</b>
              </h5>
              <h6 style={{ marginLeft: "32px" }}>Total (Rs.): {data.total}</h6>
              <h6 style={{ marginLeft: "32px" }}>Paid (Rs.): {data.paid}</h6>
              <h6 style={{ marginLeft: "32px" }}>
                Remain (Rs.): {data.remain}
              </h6>
            </div>
            <div>
              <h5>
                {subtype === "monthly" ? (
                  <b>2. Month wise revenue graph</b>
                ) : subtype === "daily" ? (
                  <b>2. Day wise revenue graph</b>
                ) : (
                  <b>2. Year wise revenue graph</b>
                )}
              </h5>
              <div
                style={{
                  border: "2px solid",
                  width: "100%",
                  maxWidth: "1000px",
                  margin: "0px auto 20px",
                  // height: 360,
                  // width: 1000,
                  // margin: "0px auto 20px",
                }}
              >
                <ReactApexChart
                  options={options}
                  series={series}
                  type="bar"
                  height={350}
                />
              </div>
            </div>
            <div>
              <h5>
                {subtype === "monthly" ? (
                  <b>3. Month wise revenue table</b>
                ) : subtype === "daily" ? (
                  <b>3. Day wise revenue table</b>
                ) : (
                  <b>3. Year wise revenue table</b>
                )}
              </h5>
              <TableContainer
                // style={{
                //   height: "100%",
                //   width: 1000,
                //   margin: "20px auto 10px",
                // }}
                style={{
                  width: "100%", // Full width
                  maxWidth: "1000px", // Maximum width for larger screens
                  margin: "0 auto 20px", // Centered with margins
                }}
              >
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  {subtype === "monthly" ? (
                    <>
                      <TableHead>
                        <TableRow>
                          <TableCell>Month</TableCell>
                          <TableCell align="right">Total</TableCell>
                          <TableCell align="right">Paid</TableCell>
                          <TableCell align="right">Remain</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {graphData?.map((i) => {
                          return (
                            <>
                              {" "}
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell>{i.x_val}</TableCell>
                                <TableCell align="right">
                                  {i.y_val.total}
                                </TableCell>
                                <TableCell align="right">
                                  {i.y_val.paid}
                                </TableCell>
                                <TableCell align="right">
                                  {i.y_val.remain}
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })}
                      </TableBody>
                    </>
                  ) : subtype === "daily" ? (
                    <>
                      {" "}
                      <TableHead>
                        <TableRow>
                          <TableCell>Day</TableCell>
                          <TableCell align="right">Total</TableCell>
                          <TableCell align="right">Paid</TableCell>
                          <TableCell align="right">Remain</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {graphData?.map((i) => {
                          return (
                            <>
                              {" "}
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell>{i.x_val}</TableCell>
                                <TableCell align="right">
                                  {i.y_val.total}
                                </TableCell>
                                <TableCell align="right">
                                  {i.y_val.paid}
                                </TableCell>
                                <TableCell align="right">
                                  {i.y_val.remain}
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })}
                      </TableBody>
                    </>
                  ) : (
                    <>
                      {" "}
                      <TableHead>
                        <TableRow>
                          <TableCell>Year</TableCell>
                          <TableCell align="right">Total</TableCell>
                          <TableCell align="right">Paid</TableCell>
                          <TableCell align="right">Remain</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {graphData?.map((i) => {
                          return (
                            <>
                              {" "}
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell>{i.x_val}</TableCell>
                                <TableCell align="right">
                                  {i.y_val.total}
                                </TableCell>
                                <TableCell align="right">
                                  {i.y_val.paid}
                                </TableCell>
                                <TableCell align="right">
                                  {i.y_val.remain}
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })}
                      </TableBody>
                    </>
                  )}
                </Table>
              </TableContainer>
            </div>
            {enableflag === 1 && data?.expenses && (
              <div>
                <h5>
                  {subtype === "monthly" ? (
                    <b>4. Month wise profit loss table</b>
                  ) : subtype === "daily" ? (
                    <b>4. Day wise profit loss table</b>
                  ) : (
                    <b>4. Year wise profit loss table</b>
                  )}
                </h5>
                <TableContainer
                  // style={{
                  //   height: "100%",
                  //   width: 1000,
                  //   margin: "20px auto 10px",
                  // }}
                  style={{
                    width: "100%", // Full width
                    maxWidth: "1000px", // Maximum width for larger screens
                    margin: "0 auto 20px", // Centered with margins
                  }}
                >
                  <Table
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                  >
                    {subtype === "monthly" ? (
                      <>
                        <TableHead>
                          <TableRow>
                            <TableCell>Month</TableCell>
                            <TableCell align="right">Total</TableCell>
                            {/* <TableCell align="right">Paid</TableCell>
                          <TableCell align="right">Remain</TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {appendedData?.map((i) => {
                            return (
                              <>
                                {" "}
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell>
                                    {i.date_time.includes("to")
                                      ? // Handle the case where date_time is a range
                                        i.date_time
                                      : // Convert a single date to the desired format
                                        new Date(
                                          i.date_time
                                        ).toLocaleDateString("en-US", {
                                          year: "numeric",
                                          month: "2-digit",
                                          day: "2-digit",
                                        })}
                                  </TableCell>{" "}
                                  <TableCell align="right">
                                    {i.purpose}
                                  </TableCell>
                                  <TableCell align="right">
                                    {i.amount}
                                  </TableCell>
                                  {/* <TableCell align="right">
                                  {i.y_val.remain}
                                </TableCell> */}
                                </TableRow>
                              </>
                            );
                          })}
                        </TableBody>
                      </>
                    ) : subtype === "daily" ? (
                      <>
                        {" "}
                        <TableHead>
                          <TableRow>
                            <TableCell>Day</TableCell>
                            <TableCell align="center">Purpose</TableCell>
                            <TableCell align="right">Total</TableCell>
                            {/* <TableCell align="right">Remain</TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {appendedData?.map((i) => {
                            return (
                              <>
                                {" "}
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  {" "}
                                  <TableCell>
                                    {i.date_time.includes("to")
                                      ? // Handle the case where date_time is a range
                                        i.date_time
                                      : // Convert a single date to the desired format
                                        new Date(
                                          i.date_time
                                        ).toLocaleDateString("en-US", {
                                          year: "numeric",
                                          month: "2-digit",
                                          day: "2-digit",
                                        })}
                                  </TableCell>
                                  <TableCell align="center">
                                    {i.purpose}
                                  </TableCell>
                                  <TableCell align="right">
                                    {i.amount}
                                  </TableCell>
                                  {/* <TableCell align="right">
                                  {i.y_val.remain}
                                </TableCell> */}
                                </TableRow>
                              </>
                            );
                          })}
                        </TableBody>
                      </>
                    ) : (
                      <>
                        {" "}
                        <TableHead>
                          <TableRow>
                            <TableCell>Year</TableCell>
                            <TableCell align="right">Purpose</TableCell>
                            <TableCell align="right">Total</TableCell>
                            {/* <TableCell align="right">Remain</TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {appendedData?.map((i) => {
                            return (
                              <>
                                {" "}
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  {" "}
                                  <TableCell>
                                    {i.date_time.includes("to")
                                      ? // Handle the case where date_time is a range
                                        i.date_time
                                      : // Convert a single date to the desired format
                                        new Date(
                                          i.date_time
                                        ).toLocaleDateString("en-US", {
                                          year: "numeric",
                                          month: "2-digit",
                                          day: "2-digit",
                                        })}
                                  </TableCell>
                                  <TableCell align="right">
                                    {i.purpose}
                                  </TableCell>
                                  <TableCell align="right">
                                    {i.amount}
                                  </TableCell>
                                  {/* <TableCell align="right">
                                  {i.y_val.remain}
                                </TableCell> */}
                                </TableRow>
                              </>
                            );
                          })}
                        </TableBody>
                      </>
                    )}
                  </Table>
                </TableContainer>
                <h6 style={{ marginLeft: "32px" }}>
                  Profit (Rs.):{" "}
                  {data?.total -
                    data?.expenses
                      ?.map((i) => i.amount)
                      ?.reduce((x, y) => x + y, 0)}
                </h6>
                <h6 style={{ marginLeft: "32px", marginBottom: "15px" }}>
                  Total Profit (Rs.):{" "}
                  {data?.total -
                    (data?.expenses
                      ?.map((i) => i.amount)
                      ?.reduce((x, y) => x + y, 0) +
                      data.remain)}
                </h6>
                {/* <h6 style={{ marginLeft: "32px" }}>
                Remain (Rs.): {data.remain}
              </h6> */}
              </div>
            )}
          </div>
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" color="primary" size="small">
                Print
              </Button>
            )}
            content={() => componentRef}
          />
        </>
      )}

      {chartVisible.showpatient && (
        <>
          <div
            style={{ margin: "20px 20px 0px" }}
            ref={(el) => (componentRef = el)}
          >
            <div className="extra-content-to-print">
              <div style={{ backgroundColor: "#30968e", padding: "25px" }}>
                <img alt="Logo" height={"40px"} src={myLogo} />
              </div>
            </div>
            <div
              style={{
                marginTop: "35px",
                textAlign: "left",
                marginBottom: "20px",
              }}
            >
              <h5>
                <b>1. Patient Info</b>
              </h5>
              <h6 style={{ marginLeft: "32px" }}>
                Total New Patients:{" "}
                {pData?.genderwise
                  ? Object.values(pData.genderwise).reduce(
                      (sum, value) => sum + value,
                      0
                    )
                  : 0}
              </h6>
            </div>
            <div>
              <h5>
                <b>2. Genderwise patient table</b>
              </h5>
              <TableContainer
                // style={{
                //   height: 200,
                //   width: 1000,
                //   margin: "20px auto -50px",
                // }}
                style={{
                  maxHeight: "200px",
                  margin: "0 auto 20px",
                  width: "100%",
                  maxWidth: "1000px",
                }}
              >
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Type</TableCell>
                      <TableCell align="center">Count</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center">Male</TableCell>
                      <TableCell align="center">
                        {pData.genderwise?.male}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center">Female</TableCell>
                      <TableCell align="center">
                        {pData.genderwise?.female}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div>
              <h5>
                <b>3. Genderwise patient graph</b>
              </h5>
              <div
                style={{
                  border: "2px solid",
                  // height: 360,
                  // width: 1000,
                  width: "100%",
                  maxWidth: "1000px",
                  margin: "0px auto 20px",
                }}
              >
                <ReactApexChart
                  type="pie"
                  options={poptions}
                  series={pseries}
                  height={350}
                />
              </div>
            </div>
            <div>
              <h5>
                <b>4. Top 10 most visited patient table</b>
              </h5>
              <TableContainer
                // style={{
                //   height: "100%",
                //   width: 1000,
                //   margin: "0px auto 10px",
                // }}
                style={{
                  maxHeight: "400px",
                  width: "100%",
                  margin: "0 auto 20px",
                  maxWidth: "1000px",
                }}
              >
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Name</TableCell>
                      <TableCell align="center">age</TableCell>
                      <TableCell align="center">Count</TableCell>
                      <TableCell align="center">gender</TableCell>
                      <TableCell align="center">mobile</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pData && pData.graph_data?.data ? (
                      pData.graph_data.data.map((i) => (
                        <TableRow
                          key={i.x_val} // Assuming x_val is unique, use an appropriate unique key
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell align="center">{i.x_val}</TableCell>
                          <TableCell align="center">{i.y_val.age}</TableCell>
                          <TableCell align="center">{i.y_val.count}</TableCell>
                          <TableCell align="center">{i.y_val.gender}</TableCell>
                          <TableCell align="center">{i.y_val.mobile}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          No data available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div style={{ marginTop: "20px" }}>
              <h5>
                <b>5. Most visited patient table</b>
              </h5>
              <TableContainer
                // style={{
                //   height: "100%",
                //   width: 1000,
                //   margin: "0px auto 10px",
                // }}
                style={{
                  maxHeight: "400px",
                  width: "100%",
                  margin: "0 auto 20px",
                  maxWidth: "1000px",
                }}
              >
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Name</TableCell>
                      <TableCell align="center">age</TableCell>
                      <TableCell align="center">Date</TableCell>
                      <TableCell align="center">gender</TableCell>
                      <TableCell align="center">mobile</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pData && pData.patient_list ? (
                      pData.patient_list.map((i) => (
                        <TableRow
                          key={i.x_val} // Assuming x_val is unique, use an appropriate unique key
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell align="center">
                            {i.firstname}
                            {i.lastname}
                          </TableCell>
                          <TableCell align="center">{i.curr_age}</TableCell>
                          <TableCell align="center">
                            {new Date(i.date_time).toISOString().split("T")[0]}
                          </TableCell>
                          <TableCell align="center">{i.gender}</TableCell>
                          <TableCell align="center">{i.mobile}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          No data available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" color="primary" size="small">
                Print
              </Button>
            )}
            content={() => componentRef}
          />
        </>
      )}
      {chartVisible.showvisit && (
        <>
          <div
            style={{ margin: "20px 20px 0px" }}
            ref={(el) => (componentRef = el)}
          >
            <div className="extra-content-to-print">
              <div style={{ backgroundColor: "#30968e", padding: "25px" }}>
                <img alt="Logo" height={"40px"} src={myLogo} />
              </div>
            </div>
            <div
              style={{
                marginTop: "35px",
                textAlign: "left",
                marginBottom: "20px",
              }}
            >
              <h5>
                <b>1. Visit Info</b>
              </h5>
              <h6 style={{ marginLeft: "32px" }}>Total Visit: {data.total}</h6>
            </div>
            <div>
              <h5>
                {subtype === "monthly" ? (
                  <b>2. Month wise visit graph</b>
                ) : subtype === "daily" ? (
                  <b>2. Day wise visit graph</b>
                ) : (
                  <b>2. Year wise visit graph</b>
                )}
              </h5>
              <div
                style={{
                  border: "2px solid",
                  // height: 360,
                  // width: 1000,
                  width: "100%",
                  maxWidth: "1000px",
                  margin: "0px auto 20px",
                }}
              >
                <ReactApexChart
                  options={voptions}
                  series={vseries}
                  type="bar"
                  height={350}
                />
              </div>
            </div>
            <div>
              <h5>
                {subtype === "monthly" ? (
                  <b>3. Month wise visit table</b>
                ) : subtype === "daily" ? (
                  <b>3. Day wise visit table</b>
                ) : (
                  <b>3. Year wise visit table</b>
                )}
              </h5>
              <TableContainer
                // style={{
                //   height: "100%",
                //   width: 1000,
                //   margin: "20px auto 10px",
                // }}
                style={{
                  width: "100%", // Full width
                  maxWidth: "1000px", // Maximum width for larger screens
                  margin: "0 auto 20px", // Centered with margins
                }}
              >
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  {subtype === "monthly" ? (
                    <>
                      <TableHead>
                        <TableRow>
                          <TableCell>Month</TableCell>
                          <TableCell align="right">Visit Count</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {graphData?.map((i) => {
                          return (
                            <>
                              {" "}
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell>{i.x_val}</TableCell>
                                <TableCell align="right">
                                  {i.y_val.count}
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })}
                      </TableBody>
                    </>
                  ) : subtype === "daily" ? (
                    <>
                      {" "}
                      <TableHead>
                        <TableRow>
                          <TableCell>Day</TableCell>
                          <TableCell align="right">Visit Count</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {graphData?.map((i) => {
                          return (
                            <>
                              {" "}
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell>{i.x_val}</TableCell>
                                <TableCell align="right">
                                  {i.y_val.count}
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })}
                      </TableBody>
                    </>
                  ) : (
                    <>
                      {" "}
                      <TableHead>
                        <TableRow>
                          <TableCell>Year</TableCell>
                          <TableCell align="right">Visit Count</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {graphData?.map((i) => {
                          return (
                            <>
                              {" "}
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell>{i.x_val}</TableCell>
                                <TableCell align="right">
                                  {i.y_val.count}
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })}
                      </TableBody>
                    </>
                  )}
                </Table>
              </TableContainer>
            </div>
          </div>
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" color="primary" size="small">
                Print
              </Button>
            )}
            content={() => componentRef}
          />
        </>
      )}
      {chartVisible.showreffer && (
        <>
          <div
            style={{ margin: "20px 20px 0px" }}
            ref={(el) => (componentRef = el)}
          >
            <div className="extra-content-to-print">
              <div style={{ backgroundColor: "#30968e", padding: "25px" }}>
                <img alt="Logo" height={"40px"} src={myLogo} />
              </div>
            </div>
            <div
              style={{
                marginTop: "35px",
                textAlign: "left",
                marginBottom: "30px",
              }}
            >
              <h5>
                <b>1. Reffered Info</b>
              </h5>
              <h6 style={{ marginLeft: "32px" }}>
                Total reffered patient:{" "}
                {rData?.visit_counts
                  ?.map((i) => i.visit_count)
                  ?.reduce((x, y) => x + y, 0)}
              </h6>
            </div>
            <div>
              <h5>
                <b>2. Reffered table (Dr. wise)</b>
              </h5>
              <TableContainer
                // style={{
                //   height: 200,
                //   width: 1000,
                //   margin: "20px auto -50px",
                // }}
                style={{
                  maxHeight: "400px",
                  width: "100%",
                  margin: "10px auto 20px",
                  maxWidth: "1000px",
                }}
              >
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Dr. name</TableCell>
                      <TableCell align="center">Patient count</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rData && rData.visit_counts ? (
                      rData.visit_counts.map((i) => (
                        <TableRow
                          // key={i.x_val} // Assuming x_val is unique, use an appropriate unique key
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell align="center">{i.table_name}</TableCell>
                          <TableCell align="center">{i.visit_count}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={2} align="center">
                          No data available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" color="primary" size="small">
                Print
              </Button>
            )}
            content={() => componentRef}
          />
        </>
      )}

      {/* Mitesh :coming soon banner */}
      {/* <div>
        <CardContent className={classes.content}>
          <div style={{ position: "relative" }}>
            <CardMedia
              component="img"
              className={classes.media}
              image="https://picsum.photos/1000/400"
            />
            <div
              style={{
                position: "absolute",
                color: "white",
                top: 80,
                fontSize: 80,
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              Coming Soon
            </div>
          </div>
        </CardContent>
      </div> */}
      {/* <div></div> */}
    </>
  );
};
export default AssForm;
