import React, { useEffect, useRef } from "react";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { makeStyles } from "@material-ui/core/styles";
import { hostInfo, medSchd } from "../../../../store";
import { medSchdTime } from "../../../../store";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SaveIcon from "@mui/icons-material/Save";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Search } from "@material-ui/icons";
import {
  // Card,
  Grid,
  // Box,
  // Paper,
  // CardActions,
  // Container,
  // CardContent,
  // Avatar,
  // Checkbox,
  Popper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  TextField,
  Divider,
  Typography,
  Button,
  // Table,
  // TableBody,
  // TableCell,
  // TableHead,
  // TableRow,
  // Typography,
  // TablePagination,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    fontSize: 12,
  },
  fieldSpace: {
    "& > *": {
      margin: theme.spacing(1),
      // marginLeft: theme.spacing(2),
    },
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    // marginTop: theme.spacing(1),
  },
  tableHeader: { textAlign: "center" },
  formField: {
    // margin: theme.spacing(),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 250,
  },
  countryControl: {
    // margin: theme.spacing(1),
    minWidth: 150,
  },
  medName: {
    marginRight: theme.spacing(1),
    width: "30%",
  },
  // medQnt: {
  //   marginRight: theme.spacing(1),
  //   width: "30",
  // },
  medSchd: {
    marginRight: theme.spacing(1),
    minWidth: "10%",
  },
  medSchdTime: {
    marginRight: theme.spacing(1),
    minWidth: "14%",
  },
  medCountField: {
    marginRight: theme.spacing(1),
    width: "7%",
  },
  fabBut: {
    // marginRight: theme.spacing(1),
    minWidth: "6%",
  },
  addBut: {
    background: "#00C39B",
    "&:hover": {
      background: "#9BE27A",
    },
  },
  autocomp: {
    '&[data-focus="true"], &:hover': {
      backgroundColor: "#CFE8A9",
    },
  },
}));

const VisitFormMedicine = (props) => {
  const containerRef = useRef(null);
  const classes = useStyles();
  // console.log("medicine list inside med component");
  // console.log(props.medList);
  const { handleMedChange } = props;
  // const [selectedValue, setSelectedValue] = React.useState(medSchdTime[0]);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [isListVisible, setIsListVisible] = React.useState(false);
  const [templateName, setTemplateName] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [templates, setTemplates] = React.useState([]);
  const [isTemplateClicked, setIsTemplateClicked] = React.useState(false);
  const [isSaveTemplateClicked, setIsSaveTemplateClicked] =
    React.useState(false);
  const [isLoadPreviousClicked, setIsLoadPreviousClicked] =
    React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const handleChangeCheckbox = (event, idx) => {
    setChecked(event.target.checked);
    handleMedChange(idx, "medOut", event.target.checked);
  };

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsListVisible(false);
      setIsTemplateClicked(false);
    }
  };

  const handleSaveTemplate = () => {
    const getToken = () => {
      const cookieString = document.cookie;
      const cookies = cookieString.split("; ").reduce((acc, cookie) => {
        const [name, value] = cookie.split("=");
        acc[name] = value;
        return acc;
      }, {});
      return cookies.authToken;
    };

    // const { templateName, rows } = this.state;
    const shouldAddPreviousData =
      props.medList.length > 0 && props.medList[0].medName !== "";

    if (shouldAddPreviousData) {
      const savedData = { name: templateName, json_data: props.medList }; // Change `rows` to `json_data`

      fetch(hostInfo + "/addPrescriptionTemplate", {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getToken()}`, // If required for authorization
        },
        body: JSON.stringify(savedData), // Ensure correct field name in payload
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("API Response:", data);
          if (data.message) {
            alert("Template saved successfully");
          } else {
            alert("Error saving template");
          }

          setTemplates((prevTemplates) => {
            if (!Array.isArray(prevTemplates)) {
              console.error(
                "prevTemplates is not an array, resetting to empty array"
              );
              return [savedData]; // Fallback to an array with savedData
            }
            return [...prevTemplates, savedData];
          });

          setTemplateName("");
        })
        .catch((error) => {
          console.error("Error:", error);
          alert("Failed to save template");
        });

      handleDialogClose();
    } else {
      alert("Please Enter Value...");
      handleDialogClose();
    }
  };

  const toggleTemplateList = () => {
    setIsListVisible((prev) => !prev); // Toggle the visibility state

    setIsTemplateClicked(!isTemplateClicked);
    // this.setState({
    //   isfrequencySuggestionOpen: -1,
    //   isseveritySuggestionOpen: -1,
    //   isSuggestionOpen: -1,
    // });
  };
  // console.log(props.medList);
  const handleTemplateSelect = (template) => {
    props.handleMedRowsChange(template.json_data);
    // Always add rows regardless of the current length

    // Update visibility and reset template clicked state
    setIsListVisible((prevState) => !prevState);
    setIsTemplateClicked(false);
  };

  const getToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.authToken;
  };

  // Function to load previous prescriptions
  const handleLoadPrevious = async () => {
    console.log("Loading previous prescriptions...");
    setIsLoadPreviousClicked(true);

    const patientId = props.patient_id; // Check if patient_id is defined
    console.log("Patient ID:", patientId); // Log the patient ID

    try {
      const response = await fetch(`${hostInfo}/prescriptions/${patientId}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to load previous prescriptions");
      }

      const data = await response.json();
      console.log(JSON.parse(data));

      // Call the handler function to update the rows in the parent component
      props.handleMedRowsChange(JSON.parse(data));
    } catch (error) {
      console.error("Error loading previous prescriptions:", error);
    }
  };

  // Optional: useEffect to handle side effects, e.g., when `patient_id` changes
  useEffect(() => {
    if (isLoadPreviousClicked) {
      // Reset the state if needed or perform any additional side effects
      // For example: setIsLoadPreviousClicked(false);
    }
  }, [isLoadPreviousClicked]);

  const handleDialogOpen = () => {
    setIsSaveTemplateClicked(true);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsSaveTemplateClicked(false);
    setDialogOpen(false);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value); // Update search term
  };
  // return (props.medList || []).map((val, idx) => {
  // console.log("index---------" + idx);
  // console.log("menOut --- " + val.medOut);
  useEffect(() => {
    const getToken = () => {
      const cookieString = document.cookie;
      const cookies = cookieString.split("; ").reduce((acc, cookie) => {
        const [name, value] = cookie.split("=");
        acc[name] = value;
        return acc;
      }, {});
      return cookies.authToken;
    };
    fetch(hostInfo + "/getprescriptiontemplates", {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((response) => {
        // Return the JSON response
        return response.json(); // Added return statement here
      })
      .then((data) => {
        setTemplates(data); // Update state with fetched data
        console.log("Fetched Templates:", data); // Log fetched data
      })
      .catch((error) => {
        console.error("Error fetching templates:", error); // Log any errors
      });
  }, []);

  useEffect(() => {
    // Add event listeners for mouse clicks and key presses
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup function to remove event listeners when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const maxVisibleSuggestions = 4; // Maximum number of visible suggestions
  const suggestionHeight = templates?.length > 0 ? 42 : 60; // Height for each suggestion item (in px)
  const searchBarHeight = 30; // Height of the search bar

  // Calculate the height of the div based on suggestions and search bar
  const divHeight =
    searchBarHeight +
    Math.min(
      templates && templates.length > 0 ? templates.length : 1,
      maxVisibleSuggestions
    ) *
      suggestionHeight;
  return (
    <>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          marginTop: "-12px",
        }}
      >
        {/* <Typography
    variant="h6"
    gutterBottom
    style={{ fontWeight: "bold" }}
  >
    Complaints
  </Typography> */}
        <Typography
          gutterBottom
          // variant="h6"
          // color="black"
          color="textSecondary"
          // style={{ fontWeight: "bold" }}
        >
          Rx
          {/* Prescriptions */}
        </Typography>
        <Typography
          color="textSecondary"
          gutterBottom
          style={{ marginLeft: "5px" }}
          // onClick={() => this.handleDeleteComplaint(item.id)}
          size="small"
        >
          <SkipPreviousIcon
            // color="textSecondary"
            // fontSize="small"
            style={{
              height: "15px",
              width: "15px",
              // color: "rgba(89, 162, 162, 1)",
            }}
          />
        </Typography>
        <Typography
          // variant="h6"
          gutterBottom
          onClick={handleLoadPrevious}
          style={{
            fontWeight: "bold",
            cursor: "pointer",
            color: "#ff3369",
            fontSize: "10px",
            marginTop: "2px",
          }}
        >
          Load Previous
        </Typography>

        <Typography
          color="textSecondary"
          gutterBottom
          style={{ marginLeft: "10px" }}
          // onClick={() => this.handleDeleteComplaint(item.id)}
          size="small"
        >
          <SaveIcon
            // fontSize="small"
            // style={{
            //   height: "15px",
            //   width: "15px",
            //   color: "rgba(89, 162, 162, 1)",
            // }}
            style={{
              color: isSaveTemplateClicked ? "blue" : "rgba(89, 162, 162, 1)",
              height: "12px",
              width: "12px",
            }}
          />
        </Typography>
        <Typography
          // variant="h6"
          gutterBottom
          // style={{
          //   cursor: "pointer",
          //   color: "rgba(89, 162, 162, 1)",
          //   fontSize: "12px",
          // }}
          style={{
            fontWeight: "bold",
            marginLeft: "2px",
            cursor: "pointer",
            color: isSaveTemplateClicked ? "blue" : "#ff3369",
            fontSize: "10px",
            marginTop: "3px",
          }}
          onClick={handleDialogOpen}
        >
          Save Template
        </Typography>
        <Dialog open={dialogOpen} onClose={handleDialogClose}>
          <DialogTitle>Save Template</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Template Name"
              type="text"
              fullWidth
              variant="outlined"
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSaveTemplate} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <div
          style={{
            position: "relative",
            display: "inline-block",
          }}
          ref={containerRef}
          // ref={this.containerRef}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={toggleTemplateList} // Toggle list on click
          >
            <Typography
              // variant="h6"
              gutterBottom
              style={{
                fontWeight: "bold",
                marginLeft: "10px",
                color: isTemplateClicked ? "blue" : "#ff3369",
                fontSize: "10px",
                marginTop: "3px",
              }}
            >
              Templates
            </Typography>
            <Typography
              color="textSecondary"
              // variant="h6"
              gutterBottom
              style={{ marginTop: "3px" }}
            >
              <ArrowDropDownIcon
                fontSize="small"
                style={{
                  color: isTemplateClicked ? "blue" : "rgba(89, 162, 162, 1)",
                }}
              />
            </Typography>
            {/* <ArrowDropDownIcon fontSize="small" /> */}
          </div>
          {isListVisible && (
            <div
              style={{
                position: "absolute",
                top: "65%", // Align right under the Typography
                left: "20px", // Match marginLeft of Typography
                zIndex: 10, // Ensure it's above other elements
                backgroundColor: "white", // Set background color for better visibility
                borderRadius: "4px", // Add border radius for aesthetics
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)", // Add subtle shadow for depth
                marginTop: "7px",
                height: `${divHeight}px`, // Initial height based on suggestions and search bar
                maxHeight: "156px", // Set max height to 156px
                overflowY: "auto", // Enable scrolling if content exceeds max height
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center", // Center the items vertically
                  flexWrap: "wrap",
                  flexDirection: "column",
                  padding: "0px 10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center", // Center the items vertically
                    flexDirection: "row",
                    marginBottom: "3px", // Add space below the search bar
                    height: `${searchBarHeight}px`, // Set the search bar height
                  }}
                >
                  <Search
                    style={{
                      width: "20px",
                      height: "20px",
                      marginTop: "5px",
                      marginRight: "5px", // Add space between the icon and the input
                      color: "#666",
                    }}
                  />
                  <input
                    type="search"
                    placeholder="Search"
                    style={{
                      outline: "none",
                      border: "none", // Remove borders for a cleaner look
                      borderBottom: "1px solid #ccc", // Keep bottom border for input
                      padding: "5px", // Add padding for better UX
                      flexGrow: 1, // Allow input to take available width
                    }}
                    onChange={handleSearchChange} // Handle search input change
                  />
                </div>
                <div style={{ display: "flex", width: "100%" }}>
                  <Autocomplete
                    options={
                      templates && Array.isArray(templates)
                        ? templates.filter((template) =>
                            template.name
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          )
                        : []
                    }
                    getOptionLabel={(option) => option.name} // Display name in the dropdown
                    disableClearable
                    open // Force the dropdown to open when the list is visible
                    onChange={(event, newValue) => {
                      if (newValue) {
                        handleTemplateSelect(newValue); // Handle the selected value
                      }
                    }}
                    style={{ width: "100%" }} // Make the dropdown take the full width
                    ListboxProps={{
                      style: {
                        border: "none",
                        padding: "0px",
                        maxHeight: `${
                          maxVisibleSuggestions * suggestionHeight
                        }px`, // Limit the height of suggestions
                        overflowY: "auto", // Add vertical scroll when needed
                        overflowX: "hidden", // Prevent horizontal scroll
                      },
                    }}
                    PopperComponent={(props) => (
                      <Popper {...props} placement="bottom" />
                    )}
                    disablePortal
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            height: "0", // Shrink the input field's height to 0
                            minHeight: "0", // Ensure the input field doesn't take any space
                            visibility: "hidden", // Hide the input field but keep it in the DOM
                            border: "none", // Remove borders from the input
                          },
                          endAdornment: null, // Remove the dropdown arrow if needed
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Divider />
      {props.medList.map((val, idx) => (
        <div className={classes.fieldSpace} key={val.index}>
          <Grid container direction="row">
            <Grid item className={classes.medName}>
              <Autocomplete
                id={`medName-${idx}`} // Unique ID for accessibility
                margin="dense"
                classes={{ option: classes.autocomp }}
                autoSelect
                freeSolo
                disableClearable
                options={props.medSug}
                getOptionLabel={(option) => option}
                value={val.medName || ""} // Set the value from medList
                onChange={(e, value) => {
                  props.handleMedChange(idx, "medName", value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Meds"
                    margin="dense"
                    InputProps={{
                      ...params.InputProps,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <TextField
                style={{ width: "60px", marginRight: "5px" }}
                id={`medQnt-${idx}`} // Unique ID for accessibility
                label="Dose"
                name="medQnt"
                margin="dense"
                value={val.medQnt || 1} // Set value from medList or default to 1
                onChange={(e) =>
                  props.handleMedChange(idx, "medQnt", e.target.value)
                } // Handle change
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item className={classes.medSchd}>
              <Autocomplete
                id={`medSchd-${idx}`} // Unique ID for accessibility
                margin="dense"
                classes={{ option: classes.autocomp }}
                freeSolo
                disableClearable
                options={medSchd}
                getOptionLabel={(option) => option}
                value={val.medSchd || ""} // Set value from medList
                onChange={(e, value) => {
                  props.handleMedChange(idx, "medSchd", value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Schd"
                    margin="dense"
                    InputProps={{
                      ...params.InputProps,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item className={classes.medSchdTime}>
              <Autocomplete
                id={`medTime-${idx}`} // Unique ID for accessibility
                margin="dense"
                classes={{ option: classes.autocomp }}
                freeSolo
                options={medSchdTime}
                getOptionLabel={(option) => option}
                value={val.medTime || ""} // Set value from medList
                onChange={(e, value) => {
                  props.handleMedChange(idx, "medTime", value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Schd Time"
                    margin="dense"
                    InputProps={{
                      ...params.InputProps,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item className={classes.medCountField}>
              <TextField
                margin="dense"
                id={`medCount-${idx}`} // Unique ID for accessibility
                name="medCount"
                label="Count"
                type="number"
                value={val.medCount || ""} // Set value from medList
                onChange={(e) =>
                  props.handleMedChange(idx, "medCount", e.target.value)
                } // Handle change
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid>
              <Tooltip title="Is from outside ?">
                <Checkbox
                  checked={val.medOut}
                  onChange={(e) => {
                    props.handleMedChange(idx, "medOut", e.target.checked); // Update medOut state
                  }}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </Tooltip>
            </Grid>
            <Grid className={classes.fabBut}>
              {idx === props.medList.length - 1 ? (
                <Fab
                  color="primary"
                  aria-label="add"
                  size="small"
                  onClick={props.add} // Call add function
                  className={classes.addBut}
                >
                  <AddIcon />
                </Fab>
              ) : (
                <Fab
                  color="inherit"
                  aria-label="del"
                  size="small"
                  onClick={() => props.delete(val.index)} // Call delete function
                >
                  <RemoveIcon />
                </Fab>
              )}
            </Grid>
          </Grid>
        </div>
      ))}

      {/* }); */}
    </>
  );
  // });
};
export default VisitFormMedicine;
