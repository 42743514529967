import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Divider, Typography, Tooltip, Fab } from "@material-ui/core";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import BlurOnIcon from "@material-ui/icons/BlurOn";
import { Search } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      // margin: theme.spacing(1),
    },
  },

  formFieldCls: {
    marginRight: theme.spacing(3),
  },
  containerIcon: {
    display: "flex",
    alignItems: "center",
    // paddin: spacing(2),
  },
  inactive: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    marginRight: theme.spacing(2),
  },
  active: {
    background: "linear-gradient(45deg, #279b37 30%, #7ac143 80%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",

    marginRight: theme.spacing(2),
  },
  searchbar: {
    // display: "flex",
    // borderRadius: 1,
    // flexDirection: "row-reverse",
    justifyContent: "space-between",
    marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(1),
    // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  },
  addGroupBtn: {
    background: "#54BAB9",
    borderRadius: 3,
    border: 0,
    color: "white",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    "&:hover": {
      background: "#9BE27A",
    },
    // marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  diaHeadText: {
    // background: "linear-gradient(45deg, #81d4fa 10%, #dce775 90%)",
    margin: theme.spacing(3),
    // display: "flex",
    // alignItems: "center",
  },
  subVisit: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  innerEle: {
    // flexGrow: 1,
    // paddingBottom: theme.spacing(2),
  },
  infoIcon: {
    color: "#8BB80E",
    // backgroundColor: "black",
    fontSize: 30,
  },
  deviderCls: {
    margin: theme.spacing(2),
    // marginBottom: theme.spacing(),
  },

  headText: {
    // background: "linear-gradient(45deg, #81d4fa 10%, #dce775 90%)",
    // fontFamily: "Arial",
    fontWeight: 600,
    fontSize: 20,
    color: "#444655",
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    // display: "flex",
    // alignItems: "center",
  },
}));

const PtGroupList = (props) => {
  // const { scrHeight, scrWidth } = props;
  const [openAddDia, setOpenAddDia] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [groupTag, setGroupTag] = useState("");
  const [groupDesc, setGroupDesc] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  // Update dimensions on window resize
  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const groupList = props.groupList;

  const classes = useStyles();

  const handleChange = (e) => {
    // console.log("Inside handle on click --");
    let name = e.target.name;
    let value = e.target.value;
    // console.log(name);
    // console.log(value);
    if (name === "GroupName") {
      setGroupName(value);
    }
    if (name === "GroupDescription") {
      setGroupDesc(value);
    }
    if (name === "Tag") {
      setGroupTag(value);
    }
  };

  const handleAdd = () => {
    // console.log("Inside handle on save");
    props.handleOnAdd(groupName, groupTag, groupDesc);
    handleAddClose();
  };

  const handleAddClose = () => {
    setOpenAddDia(false);
  };

  const handleAddOpen = () => {
    setOpenAddDia(true);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  // console.log(searchQuery);
  // console.log(groupList);

  const filteredGroups = groupList.filter((group) =>
    group.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  // console.log(filteredGroups);
  return (
    <div>
      <Fragment>
        <div>
          <Typography
            gutterBottom
            variant="subtitle1"
            component="h5"
            color="textSecondary"
            align="center"
            className={classes.headText}
          >
            Family Groups
            <Tooltip title="Add New">
              <Fab
                size="small"
                variant="outlined"
                className={classes.addGroupBtn}
                alignSelf="flex-end"
                onClick={handleAddOpen}
                // alignItems="bottom"
              >
                <AddIcon />
              </Fab>
            </Tooltip>
          </Typography>
          <Typography align="center">
            <div
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                justifyItems: "center",
                // outline: "none",
                // borderBottom: "1px solid #ccc",
                // borderTop: "none",
                // borderLeft: "none",
                // borderRight: "none",
              }}
            >
              <Search
                style={{
                  width: "20px",
                  height: "20px",
                  borderBottom: "1px solid #ccc",
                  marginBottom: "-12px",
                  color: "#666",
                }}
              />
              <input
                type="search"
                placeholder="Search"
                style={{
                  outline: "none",
                  // border: "none",
                  borderBottom: "1px solid #ccc",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  paddingLeft: "5px",
                }}
                className={classes.searchbar}
                onChange={handleSearchChange}
                // onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </Typography>
        </div>
        <Divider className={classes.deviderCls} />
        <div>
          {/* <Paper style={{ height: scrHeight - 190, overflow: "auto" }}> */}
          <List
            component="nav"
            aria-label="secondary mailbox folders"
            style={{
              height:
                dimensions.width <= 599.95 ? "200px" : dimensions.height - 190, // Responsive height
              overflow: "auto",
            }}
          >
            {filteredGroups.map((grp, index) => (
              <ShowGroup
                key={index}
                group={grp}
                handleOnSave={props.handleOnSave}
                handleOnAdd={props.handleOnAdd}
                handleOnDelete={props.handleOnDelete}
                handleOnSelectGroup={props.handleOnSelectGroup}
              />
            ))}
          </List>
          {/* </Paper> */}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
            // height: scrHeight - 250,
          }}
        ></div>
      </Fragment>
      <div>
        <Dialog
          open={openAddDia}
          keepMounted
          fullWidth={true}
          maxWidth={"md"}
          onClose={handleAddClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <Typography
            gutterBottom
            variant="h4"
            component="h2"
            color="textSecondary"
            className={classes.diaHeadText}
            // justify="space-between"

            // margin=""
          >
            New Group
          </Typography>
          <DialogContent>
            <form noValidate autoComplete="off" onChange={handleChange}>
              <TextField
                margin="normal"
                id="group-name-id"
                name="GroupName"
                value={groupName}
                label="Group Name"
                type="string"
                className={classes.formFieldCls}

                // onChange={(e) => handleOnMedValChange(e, "medName")}
              />
              <TextField
                margin="normal"
                id="group-tag-id"
                name="Tag"
                value={groupTag}
                label="Tag"
                type="string"
                // onChange={(e) => handleOnMedValChange(e, "medName")}
              />
              <TextField
                style={{ marginLeft: "25px" }}
                margin="normal"
                id="group-desc-id"
                name="GroupDescription"
                value={groupDesc}
                label="Description"
                type="string"
                // onChange={(e) => handleOnMedValChange(e, "medName")}
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAdd} color="primary">
              Add
            </Button>
            <Button onClick={handleAddClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

function ShowGroup(props) {
  const [open, setOpen] = useState(false);
  const [groupName, setGroupName] = useState(props.group.name);
  const [groupTag, setGroupTag] = useState(props.group.tag);
  const [groupDesc, setGroupDesc] = useState(props.group.description);
  // console.log(props.group.name);
  const id = props.group.id;

  const classes = useStyles();

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleSelectGroup = () => {
    // console.log("CLicked on button");
    props.handleOnSelectGroup(id, groupName);
  };

  const handleEdit = () => {
    // console.log("Inside handle on click 1");
    setOpen(true);
  };
  const handleDelete = () => {
    // console.log("Inside handle on delete");
    props.handleOnDelete(id);
    handleClickClose();
  };

  const handleSave = () => {
    // console.log("Inside handle on save");
    props.handleOnSave(id, groupName, groupTag, groupDesc);
    handleClickClose();
  };

  const handleChange = (e) => {
    // console.log("Inside handle on click --");
    let name = e.target.name;
    let value = e.target.value;
    // console.log(name);
    // console.log(value);
    if (name === "GroupName") {
      setGroupName(value);
    }
    if (name === "GroupDescription") {
      setGroupDesc(value);
    }
    if (name === "Tag") {
      setGroupTag(value);
    }
  };

  return (
    <div>
      <ListItem button key={id} onClick={handleSelectGroup}>
        <BlurOnIcon />
        <Tooltip title={props.group.name}>
          <ListItemText
            primary={
              <span className={classes.truncate}>
                {props.group.name.length > 20
                  ? `${groupName.substring(0, 18)}...`
                  : props.group.name}
              </span>
            }
          />
        </Tooltip>
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="edit"
            onClick={() => handleEdit()}
            // className={classes.root}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => handleDelete()}
            // className={classes.root}
          >
            <DeleteIcon style={{ color: "#DF646A" }} />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>

      <Dialog
        open={open}
        // TransitionComponent={Transition}
        keepMounted
        // color={"primary"}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleClickClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle id="alert-dialog-slide-title">
        {"Use Google's location service?"}
      </DialogTitle> */}
        <DialogContent>
          <form noValidate autoComplete="off" onChange={handleChange}>
            <Typography
              gutterBottom
              variant="h4"
              component="h2"
              color="textSecondary"
              className={classes.diaHeadText}
              // justify="space-between"

              // margin=""
            >
              Edit Group
            </Typography>
            <TextField
              margin="normal"
              id="group-name-id"
              name="GroupName"
              value={groupName}
              label="Group Name"
              type="string"
              className={classes.formFieldCls}
              // onChange={(e) => handleOnMedValChange(e, "medName")}
            />

            <TextField
              margin="normal"
              id="group-tag-id"
              name="Tag"
              value={groupTag}
              label="Tag"
              type="string"
              className={classes.formFieldCls}
              // onChange={(e) => handleOnMedValChange(e, "medName")}
            />

            <TextField
              margin="normal"
              id="group-desc-id"
              name="GroupDescription"
              value={groupDesc}
              label="Description"
              type="string"
              // onChange={(e) => handleOnMedValChange(e, "medName")}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
          <Button onClick={handleClickClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PtGroupList;
