import React, { Fragment } from "react";

import { withStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { hostInfo } from "../../../store";
import { appendScript } from "./diaComponent/AppendScript";
import { genderArr } from "../../../store";
// import  "../../../fingerscripts/jquery-1.8.2";
// import "../../../fingerscripts/mfs100";
// import * as finger "../../../fingerscripts/finger";
import moment from "moment";

import FormGroup from "@material-ui/core/FormGroup";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

//import { countryArr } from "../../../../store";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { Country, State, City } from "country-state-city";

// import { ICountry, IState, ICity } from "country-state-city";
import { Grid, Paper } from "@material-ui/core";
import FingerScan from "../../../FingerScan/FingerScan";

const styles = (theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      minWidth: 250,
    },
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  formControl: {
    // margin: theme.spacing(1),
    width: 250,
  },
  grpControl: {
    marginTop: theme.spacing(2),
    width: 250,
  },
  imgpaper: {
    padding: theme.spacing(2),
    margin: "auto",
    // with: "150px",
    height: "220px",
  },
  countryControl: {
    marginRight: theme.spacing(1),
    width: 165,
  },
  toggleSwitch: {
    marginTop: theme.spacing(3),
  },
  location: {
    direction: "row",
    alignItems: "spce-between",
  },
  // gendercls: {
  //   // margin: theme.spacing(1),
  //   width: 250,
  // },
  addressControl: {
    minWidth: "95%",
  },
  autocomp: {
    '&[data-focus="true"], &:hover': {
      backgroundColor: "#CFE8A9",
    },
  },
});

// const genderArr = [
//   {
//     value: "M",
//     label: "Male",
//   },
//   {
//     value: "F",
//     label: "Female",
//   },
//   {
//     value: "O",
//     label: "Other",
//   },
// ];

export default withStyles(styles)(
  class AddNewPatient extends React.Component {
    state = {
      open: false,

      formdetail: {
        username: "",
        lastname: "",
        email: "",
        gender: "",
        age: "",
        birthdate: null,
        disability: false,
        country: "101",
        state: "12",
        city: "1041",
        mobile: "",
        address: "",
        iso_key: "",
        group_id: 0,
        illness: "",
        illnessError: false,
        usernameError: false,
        lastnameError: false,
        emailError: false,
        birthdateError: false,
        ageError: false,
        countryError: false,
        stateError: false,
        cityError: false,

        illnessErrorTxt: "",
        usernameErrorTxt: "",
        lastnameErrorTxt: "",
        emailErrorTxt: "",
        birthdateErrorTxt: "",
        ageErrorTxt: "",
        disabilityErrorTxt: "",
        countryErrorTxt: "",
        stateErrorTxt: "",
        cityErrorTxt: "",
      },
    };
    countryArr = Country.getAllCountries();
    stateArr = State.getStatesOfCountry(
      Country.getAllCountries().find((v) => v.isoCode === "IN").isoCode
    );
    cityArr = City.getCitiesOfState(
      Country.getAllCountries().find((v) => v.isoCode === "IN").isoCode,
      State.getStatesOfCountry(
        Country.getAllCountries().find((v) => v.isoCode === "IN").isoCode
      ).find((v) => v.isoCode === "GJ").isoCode
    );
    selectedCountry = "India";
    selectedState = "Gujarat";
    selectedCity = "Surat";
    stateDisabled = true;
    cityDisabled = true;

    componentDidMount() {
      appendScript("/fingerscripts/jquery-1.8.2.js");
      appendScript("/fingerscripts/mfs100.js");
      appendScript("/fingerscripts/finger.js");
    }

    validate = () => {
      let isError = false;
      const { formdetail } = this.state;

      // Clean old error status
      formdetail.illnessError = false;
      formdetail.usernameError = false;
      formdetail.lastnameError = false;
      formdetail.emailError = false;
      formdetail.birthdateError = false;
      formdetail.countryError = false;
      formdetail.stateError = false;
      formdetail.cityError = false;
      formdetail.ageError = false;
      formdetail.disabilityError = false;

      formdetail.illnessErrorTxt = "";
      formdetail.usernameErrorTxt = "";
      formdetail.lastnameErrorTxt = "";
      formdetail.emailErrorTxt = "";
      formdetail.birthdateErrorTxt = "";
      formdetail.countryErrorTxt = "";
      formdetail.stateErrorTxt = "";
      formdetail.cityErrorTxt = "";
      formdetail.genderErrorTxt = "";
      formdetail.ageErrorTxt = "";

      // Username Validation----
      // 1. Length
      // 2. special character check
      if (formdetail.username.length < 2) {
        isError = true;
        formdetail.usernameError = true;
        formdetail.usernameErrorTxt =
          "Username needs to be atleast 2 characters long";
      }

      // Lastname Validation
      if (formdetail.lastname.length < 2) {
        isError = true;
        formdetail.lastnameError = true;
        formdetail.lastnameErrorTxt =
          "Lastname needs to be atleast 2 characters long";
      }

      // illness Validation
      if (formdetail.illness.length < 2) {
        // isError = true;
        // formdetail.illnessError = true;
        // formdetail.illnessErrorTxt =
        //   "illness needs to be atleast 2 characters long";
      }

      // Email Validation
      // 1. @ check
      if (formdetail.email.length < 1) {
        formdetail.email = "-";
      }

      // Mobile
      if (formdetail.mobile.length < 1) {
        formdetail.mobile = "-";
      }

      // console.log(
      //   "Selected C: " +
      //     this.selectedCountry +
      //     "S: " +
      //     this.selectedState +
      //     "city: " +
      //     this.selectedCity
      // );
      // console.log(this.cityArr);
      // Country, state, city validation
      if (!this.selectedCountry.length) {
        // console.log("contry vaidation --------");
        // console.log(this.selectedCountry);
        // console.log(this.countryArr);
        isError = true;
        formdetail.countryError = true;
        formdetail.countryErrorTxt = "Invalid Country";
      }
      if (false === this.validateInArry(this.stateArr, this.selectedState)) {
        // console.log("State vaidation --------");
        // console.log(this.selectedState);
        // console.log(this.stateArr);
        // console.log(this.cityArr);
        isError = true;
        formdetail.stateError = true;
        formdetail.stateErrorTxt = "Invalid State";
      }

      // City
      if (false === this.validateInArry(this.cityArr, this.selectedCity)) {
        // console.log("city arry len: " + this.cityArr.length);
        // console.log(this.selectedCity);
        // console.log(this.cityArr.indexOf[this.selectedCity]);
        isError = true;
        formdetail.cityError = true;
        formdetail.cityErrorTxt = "Invalid City";
      }

      this.setState({
        ...this.state.formdetail,
      });

      return isError;
    };

    handleSubmit = async () => {
      const getToken = () => {
        const cookieString = document.cookie;
        const cookies = cookieString.split("; ").reduce((acc, cookie) => {
          const [name, value] = cookie.split("=");
          acc[name] = value;
          return acc;
        }, {});
        return cookies.authToken;
      };

      const err = this.validate();
      // const err = false;
      if (!err) {
        // On submit form success
        let { formdetail } = this.state;
        let formObj = {
          illness: formdetail.illness,
          firstname: formdetail.username,
          lastname: formdetail.lastname,
          mail_id: formdetail.email,
          birthdate: formdetail.birthdate
            ? moment(formdetail.birthdate).format("DD-MM-YYYY")
            : null,
          gender: formdetail.gender,
          age: formdetail.age,
          // disability: formdetail.disability,
          city: this.selectedCity,
          state: this.selectedState,
          country: this.selectedCountry,
          address:
            formdetail.address +
            ", " +
            this.selectedCity +
            ", " +
            this.selectedState +
            ", " +
            this.selectedCountry,
          mobile: formdetail.mobile,
          iso_key: formdetail.iso_key,
          patient_grp_id: formdetail.group_id,
        };
        // console.log("Final from object...");
        // console.log(JSON.stringify(formObj));
        let url = hostInfo + "/adduser";
        const rawResponse = await fetch(url, {
          method: "POST",
          // mode: "no-cors",
          headers: {
            Accept: "*/*",
            "Content-type": "application/json; charset=UTF-8",

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(formObj),
        });

        // console.log("response id --------");

        // const content = await rawResponse.json();
        // console.log(content);
        this.props.closeHdl();
        window.location.reload();
        // window.location = "/patients/patientprofile?patient_id=" + id;
        return true;
      }
      //Error in Submit..............
      // console.log("Validation failed, Error in Submit--------");
      return false;
    };

    handleClickOpen = () => {
      this.setState({ open: true });
    };

    handleClose = () => {
      this.setState({ open: false });
    };

    handleNumericChange = (name, val) => {
      // console.log(name);
      // console.log(val);
      const value = val.floatValue;
      this.setState({
        formdetail: { ...this.state.formdetail, [name]: value },
      });
    };

    handleMultiFld = (e, name, value) => {
      // console.log("------ Inside Multifiled handler ------");
      // console.log(name);
      // console.log(value);
      this.setState({
        formdetail: { ...this.state.formdetail, [name]: value.id },
      });
    };

    handleOnChange = (e, name) => {
      // console.log("On Change log.......");
      // console.log(name);
      // console.log(e.target.value);

      const value = e.target.value;
      this.setState({
        ...this.state,
        formdetail: { ...this.state.formdetail, [name]: value },
      });
    };

    handleDateChange = (name, value) => {
      // setSelectedEndDate(date);
      // console.log("On Date change log.......");
      // console.log(name);
      // console.log(value);
      this.setState({
        ...this.state,
        formdetail: { ...this.state.formdetail, birthdate: value },
      });
    };

    handleGenChange = (e, name, value) => {
      // console.log("Inside handle Gen change change---");
      // console.log(id);
      // console.log(name);
      // console.log(value.label);

      this.setState({
        formdetail: { ...this.state.formdetail, [name]: value.value },
      });
    };

    handleGrpChange = (e, name, value) => {
      // console.log("Inside handle Gen change change---");
      // // console.log(id);
      // console.log(name);
      // console.log(value);

      this.setState({
        formdetail: { ...this.state.formdetail, [name]: value.id },
      });
    };

    mask = "/[1-4]/g";
    handleCountryChange = (name, value) => {
      // console.log("In handle country change");
      // console.log(name);
      // console.log(value);
      this.selectedCountry = value.name;
      this.setState({
        formdetail: {
          ...this.state.formdetail,
          [name]: value.id,
        },
      });
      // console.log("Latest country id : " + value.id);

      this.stateArr = State.getStatesOfCountry(
        Country.getAllCountries().find((v) => v.isoCode === value.isoCode)
          .isoCode
      );
      this.stateDisabled = false;
      // console.log(this.stateArr);
    };

    handleStateChange = (name, value) => {
      this.selectedState = value.name;

      // console.log(name);
      // console.log(value);
      this.setState({
        formdetail: {
          ...this.state.formdetail,
          [name]: value.id,
        },
      });

      // console.log("Latest state id : " + value.id);
      this.cityArr = City.getCitiesOfState(
        Country.getAllCountries().find((v) => v.isoCode === "IN").isoCode,
        State.getStatesOfCountry(
          Country.getAllCountries().find((v) => v.isoCode === "IN").isoCode
        ).find((v) => v.isoCode === value.isoCode).isoCode
      );
      // console.log("city array length..: " + this.cityArr.length);
      if (!this.cityArr.length) {
        this.selectedCity = "NA";
        this.cityDisabled = true;
      } else {
        this.cityDisabled = false;
        // console.log(this.cityArr);
      }
    };

    searchFromArray(myArray, id) {
      for (var i = 0; i < myArray.length; i++) {
        if (myArray[i].id === id) {
          return myArray[i].name;
        }
      }
      return " ";
    }
    validateInArry(myArray, nameKey) {
      for (var i = 0; i < myArray.length; i++) {
        if (myArray[i].name === nameKey) {
          return true;
        }
      }
      return false;
    }

    handleDisability = (name, value) => {
      // console.log(name);
      // console.log(value.target.checked);

      this.setState({
        formdetail: {
          ...this.state.formdetail,
          [name]: value.target.checked,
        },
      });
    };

    handleFingerScan = (value) => {
      // console.log(value);

      this.setState({
        formdetail: {
          ...this.state.formdetail,
          iso_key: value,
        },
      });
    };

    handleCityChange = (name, value) => {
      this.selectedCity = value.name;
      // console.log(value);
      // console.log("name: " + name + " id: " + value.id);
      this.setState({
        formdetail: {
          ...this.state.formdetail,
          [name]: value.id,
        },
      });
    };

    render() {
      const {
        // open,
        formdetail: {
          username,
          lastname,
          // email,
          birthdate,
          // age,
          mobile,
          address,
          // group_id,
          illness,
          illnessError,
          usernameError,
          lastnameError,
          countryError,
          stateError,
          cityError,
          mobileError,
          addressError,

          illnessErrorTxt,
          usernameErrorTxt,
          lastnameErrorTxt,
          countryErrorTxt,
          stateErrorTxt,
          cityErrorTxt,
          mobileErrorTxt,
          addressErrorTxt,
        },
      } = this.state;
      const { countryArr, stateArr, cityArr } = this;
      // const {  } = this.props;
      console.log(cityArr);
      console.log(stateArr);
      console.log(
        "Cities in Gujarat: ",
        City.getCitiesOfState(
          Country.getAllCountries().find((v) => v.isoCode === "IN").isoCode,
          State.getStatesOfCountry(
            Country.getAllCountries().find((v) => v.isoCode === "IN").isoCode
          ).find((v) => v.isoCode === "GJ").isoCode
        )
      );

      const { classes, closeHdl, open, patientGrpList } = this.props;
      return (
        // <div>
        <Fragment>
          <Dialog
            open={open}
            onClose={closeHdl}
            // aria-labelledby="Add User"
          >
            <DialogTitle id="AddNewPatient">Patient Profile</DialogTitle>
            <DialogContent>
              <DialogContentText>Fill the patient details</DialogContentText>
              <form className={classes.root} autoComplete="off">
                <TextField
                  autoFocus
                  margin="dense"
                  id="username"
                  error={usernameError}
                  value={username}
                  onChange={(e) => {
                    this.handleOnChange(e, "username");
                  }}
                  label="Username"
                  type="username"
                  helperText={usernameErrorTxt}
                  InputLabelProps={{
                    style: { color: "#71717A" },
                  }}
                />
                <TextField
                  margin="dense"
                  id="lastname"
                  error={lastnameError}
                  value={lastname}
                  onChange={(e) => {
                    this.handleOnChange(e, "lastname");
                  }}
                  label="Lastname"
                  type="lastname"
                  helperText={lastnameErrorTxt}
                  InputLabelProps={{
                    style: { color: "#71717A" },
                  }}
                />
                <Grid container direction="row">
                  <Grid item xs>
                    <Autocomplete
                      id="gender"
                      margin="dense"
                      classes={{ option: classes.autocomp }}
                      // autoFocus
                      className={classes.formControl}
                      disableClearable
                      options={genderArr}
                      getOptionLabel={(option) => option.label}
                      onChange={(e, value) => {
                        this.handleGenChange(e, "gender", value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Gender"
                          name="gender"
                          margin="dense"
                          InputProps={{
                            ...params.InputProps,
                            // autoComplete: "new-password",
                          }}
                          InputLabelProps={{
                            style: { color: "#71717A" },
                          }}
                        />
                      )}
                    />

                    <MuiPickersUtilsProvider
                      // libInstance={moment}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        id="birthdate"
                        label="Birthday"
                        keyboard
                        className={classes.formControl}
                        placeholder="DD-MM-YYYY"
                        format={"dd-MM-yyyy"}
                        value={birthdate}
                        onChange={(e) => {
                          this.handleDateChange("birthdate", e);
                        }}
                        animateYearScrolling={true}
                        autoOk={true}
                        clearable
                        onInputChange={(e) =>
                          console.log("Keyboard:", e.target.value)
                        }
                        InputLabelProps={{
                          style: { color: "#71717A" },
                        }}
                      />
                    </MuiPickersUtilsProvider>

                    <TextField
                      ///////////////////////// Mobile
                      className={classes.formControl}
                      // autoFocus
                      id="mobile"
                      error={mobileError}
                      value={mobile}
                      onChange={(e) => {
                        this.handleOnChange(e, "mobile");
                      }}
                      label="Mobile"
                      type="mobile"
                      helperText={mobileErrorTxt}
                      InputLabelProps={{
                        style: { color: "#71717A" },
                      }}
                    />
                    <Autocomplete
                      id="group"
                      // margin="dense"
                      classes={{ option: classes.autocomp }}
                      // autoFocus
                      className={classes.grpControl}
                      disableClearable
                      options={patientGrpList}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, value) => {
                        this.handleGrpChange(e, "group_id", value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Group"
                          name="group_id"
                          margin="dense"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            // autoComplete: "new-password",
                          }}
                          InputLabelProps={{
                            shrink: true,
                            style: { color: "#71717A" },
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs>
                    <Paper className={classes.imgpaper}>
                      <FingerScan handleFingerScan={this.handleFingerScan} />
                    </Paper>
                  </Grid>
                </Grid>
                {/*
                // Mitesh: Temp change as per costomer req 
                <TextField
                  // autoFocus
                  id="email"
                  error={emailError}
                  value={email}
                  onChange={(e) => {
                    this.handleOnChange(e, "email");
                  }}
                  label="Email"
                  type="email"
                  helperText={emailErrorTxt}
                /> */}

                {/* <div
                  style={{ width: "150px", height: "190px", align: "center" }}
                >
                  <img
                    id="imgFinger"
                    width="145px"
                    height="188px"
                    alt="Finger Image"
                  />
                </div> */}
                <TextField
                  className={classes.addressControl}
                  margin="dense"
                  id="illness"
                  error={illnessError}
                  value={illness}
                  onChange={(e) => {
                    this.handleOnChange(e, "illness");
                  }}
                  label="illness"
                  type="illness"
                  helperText={illnessErrorTxt}
                  // fullWidth
                  InputLabelProps={{
                    style: { color: "#71717A" },
                  }}
                />

                <TextField
                  //////////////////  Address
                  className={classes.addressControl}
                  // autoFocus
                  margin="dense"
                  id="Address"
                  error={addressError}
                  value={address}
                  onChange={(e) => {
                    this.handleOnChange(e, "address");
                  }}
                  label="Address"
                  type="address"
                  // fullWidth
                  helperText={addressErrorTxt}
                  InputLabelProps={{
                    style: { color: "#71717A" },
                  }}
                />
                <FormGroup row={true}>
                  <Autocomplete
                    className={classes.location}
                    id="country"
                    classes={{ option: classes.autocomp }}
                    // autoFocus
                    disableClearable
                    disabled={true}
                    defaultValue={countryArr.find((v) => v.isoCode === "IN")}
                    options={countryArr}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, value) => {
                      this.handleCountryChange("country", value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={classes.countryControl}
                        defaultValue="India"
                        margin="normal"
                        variant="outlined"
                        error={countryError}
                        helperText={countryErrorTxt}
                        InputProps={{
                          ...params.InputProps,

                          autoComplete: "new-password",
                        }}
                        InputLabelProps={{
                          style: { color: "#71717A" },
                        }}
                      />
                    )}
                  />

                  <Autocomplete
                    //////////////////// State
                    freeSolo
                    id="state"
                    classes={{ option: classes.autocomp }}
                    className={classes.location}
                    // autoFocus
                    disableClearable
                    includeInputInList
                    defaultValue={stateArr.find((v) => v.isoCode === "GJ")} // Gujarat
                    options={stateArr}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, value) => {
                      this.handleStateChange("state", value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        className={classes.countryControl}
                        {...params}
                        label="State"
                        margin="normal"
                        error={stateError}
                        variant="outlined"
                        defaultValue="Guj"
                        helperText={stateErrorTxt}
                        InputProps={{
                          ...params.InputProps,
                          autoComplete: "new-password",
                        }}
                        InputLabelProps={{
                          style: { color: "#71717A" },
                        }}
                      />
                    )}
                  />
                  <Autocomplete
                    //////////////////// City
                    freeSolo
                    id="city"
                    classes={{ option: classes.autocomp }}
                    // autoFocus
                    className={classes.location}
                    disableClearable
                    includeInputInList
                    defaultValue={cityArr.find((v) => v.name === "Surat")}
                    options={cityArr}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, value) => {
                      this.handleCityChange("city", value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        className={classes.countryControl}
                        {...params}
                        label="City"
                        error={cityError}
                        variant="outlined"
                        helperText={cityErrorTxt}
                        margin="normal"
                        InputProps={{
                          ...params.InputProps,
                          // type: "search",
                          autoComplete: "new-password",
                        }}
                        InputLabelProps={{
                          style: { color: "#71717A" },
                        }}
                      />
                    )}
                  />
                </FormGroup>
              </form>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.handleSubmit();
                }}
                color="primary"
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </Fragment>
        // </div>
      );
    }
  }
);
