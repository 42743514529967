import React, { Component } from "react";

import {
  Container,
  Grid,
  Card,
  Divider,
  CardContent,
  Table,
} from "@material-ui/core";
import moment from "moment";

// function PaymentInfo(props) {
//   const { total, paid } = props;

//   return (
//     <address>
//       <strong>Payment</strong>
//       <br />
//       Total: {total}
//       <br />
//       Paid Amount: {paid}
//     </address>
//   );
// }

class BillPrint extends Component {
  render() {
    // const { selectedReportDetails, stateName, schoolName, startDate, endDate } =
    //   this.props;
    const { visit, user } = this.props;
    console.log(visit.payment.slice(0, -1));
    // console.log("Inside printing ----data is--");

    const dateStr = moment(visit.date_time, "YYYY-MM-DDTHH:mm:ss").format(
      "DD-MM-YYYY"
    );
    // const dateStr = visit.date_time;

    return (
      <div
        id="pdf-element"
        style={{
          // maxWidth: "210mm",
          width: "90%",
          height: "100%",
          position: "relative",
          marginTop: "26%",
          marginLeft: "auto",
          marginRight: "auto",
          fontSize: "24px",
        }}
      >
        <Container fluid="true">
          <Grid container direction="row">
            <Grid item container direction="column">
              <Card>
                <CardContent>
                  <hr />
                  <Table className="table-nowrap" style={{ fontSize: "30px" }}>
                    <tbody>
                      {visit?.payment?.length === 0 ? (
                        <tr>
                          <td style={{ width: "35%" }} className="py-2">
                            {"-"}
                          </td>
                          <td className="text-center">{"-"}</td>
                          <td className="text-right">{"-"}</td>
                          <td className="text-right">{"-"}</td>
                        </tr>
                      ) : (
                        visit.payment.slice(0, -1).map((i, idx) => (
                          <tr key={idx}>
                            <td style={{ width: "35%" }} className="py-2">
                              {i.name ? i.name : "-"}
                            </td>
                            <td className="text-center">
                              {i.price ? i.price : "-"}
                            </td>
                            <td className="text-right">
                              {i.discount ? i.discount : "-"} (%)
                            </td>
                            <td className="text-right">
                              {i.total ? i.total : "-"}
                            </td>
                          </tr>
                        ))
                      )}
                      {/* Full-width Horizontal line before the Total row */}
                      <tr>
                        <td colSpan="4">
                          <hr
                            style={{
                              margin: "10px 0",
                              width: "100%",
                              border: "1px solid #ccc",
                            }}
                          />
                        </td>
                      </tr>
                      {/* Total Row */}
                      <tr>
                        <td className="font-weight-bold">Total</td>
                        <td className="text-center font-weight-bold">
                          {visit.payment
                            .slice(0, -1)
                            .map((i) => parseFloat(i.price))
                            .reduce((x, y) => x + y, 0)}
                        </td>
                        <td className="text-right font-weight-bold">
                          {/* Discount total if needed */}
                        </td>
                        <td className="text-right font-weight-bold">
                          {visit.payment
                            .slice(0, -1)
                            .map((i) => parseFloat(i.total))
                            .reduce((x, y) => x + y, 0)}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default BillPrint;
