import React, { Fragment, createRef } from "react";
import {
  Container,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
import { withStyles } from "@material-ui/styles";
import {
  Button,
  Divider,
  Checkbox,
  Grid,
  Typography,
  InputAdornment,
  Modal,
  Popper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { hostInfo, paymentSuggestions } from "../../../store";
// import FuncComp from "./testFunc";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SaveIcon from "@mui/icons-material/Save";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddKwDia from "./AddKwDia";
import ClearIcon from "@mui/icons-material/Clear";
import FormMedicine from "./components/Medicine";
import FormReport from "./components/Report";
// import RemoveIcon from "@material-ui/icons/Remove";

import FormGroup from "@material-ui/core/FormGroup";
import { visitFormfields } from "../../../store";

//import { countryArr } from "../../../../store";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useRef } from "react";
import { Search } from "@material-ui/icons";
// import PrintHtml from "./PrintHtml/PrintHtml";
// import HtmlContentByInfo from "./PrintHtml/HtmlContentByInfo";

const styles = (theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      // width: 230,
    },
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  saveBtn: {
    background: "#94B49F",
    borderRadius: 3,
    border: 0,
    color: "white",
    // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    "&:hover": {
      background: "#C6A3D4",
    },
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  formControl: {
    // margin: theme.spacing(1),
    width: 230,
  },
  // countryControl: {
  //   // margin: theme.spacing(1),
  //   minWidth: 150,
  // },
  countryControl: {
    marginRight: theme.spacing(1),
    minWidth: 170,
  },
  insurance: {
    // width: 230,
    marginTop: theme.spacing(2),
  },
  bloodGroupFld: {
    minWidth: 150,
  },
  seprator: {
    margin: theme.spacing(2),
  },
  seprator1: {
    marginTop: theme.spacing(2),
  },
  refCls: {
    color: "#D46D91",
    fontWeight: "bold",
    marginTop: theme.spacing(2),
  },
  seprator2: {
    width: "100%",
    ...theme.typography.body2,
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  multilineFld: {
    display: "flex",
    flexWrap: "wrap",
  },
  amountGreen: {
    borderColor: "pink",
  },
  specialOutline: {
    borderColor: "pink",
    //borderWidth: 4
  },
  presc: {
    width: "100%",
  },
  autocomp: {
    '&[data-focus="true"], &:hover': {
      backgroundColor: "#CFE8A9",
    },
  },
  cardClr: {
    background: "#F3F8F8",
  },
  freeTextNotes: {
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

export default withStyles(styles)(
  class Payment extends React.Component {
    containerRef = createRef();

    state = {
      search: "",
      dialogOpen: false,
      templateName: "",
      isSuggestionOpen: 1,

      openSuggestionIndex: false,
      templates: [],
      isListVisible: false,
      isTemplateClicked: false,
      isSaveTemplateClicked: false,
      isLoadPreviousClicked: false,

      rows: this.props.payment,

      frequencyRefs: [],
      severityRefs: [],
      durationRefs: [],
      selectedComplaint: null,
      openModal: false,
      modalRowIndex: null,
    };

    // countryArr = countryStateCity.getAllCountries();
    stateArr = [];
    cityArr = [];
    selectedCountry = "";
    selectedState = "";
    selectedCity = "";
    stateDisabled = true;
    cityDisabled = true;
    // frequencyRefs = [];
    // severityRefs = [];
    // durationRefs = [];
    medSug = [];
    repSug = [];
    drSug = [];
    symSug = [];
    disSug = [];
    drGroups = [];

    handleDeleteComplaint = (index) => {
      this.setState((prevState) => {
        // Ensure rows are defined and create a shallow copy
        const rows = [...(prevState.rows || [])];
        console.log("Current Rows Before Deletion:", rows);

        // Remove the row at the given index
        const newRows = rows.filter((_, i) => i !== index);
        console.log("New Rows After Deletion:", newRows);

        // Ensure at least one empty row remains after deletion
        if (newRows.length === 0) {
          newRows.push({
            name: "",
            price: "",
            discount: "",
            total: "0.00", // Ensure default total value
          });
        }

        // Notify the parent component about the updated rows
        this.props.onRowsChange(newRows);
        console.log("Rows After Update:", newRows);

        // Update state with the new filtered rows
        return {
          rows: newRows,
        };
      });
    };

    handlePayment = (index, field, value) => {
      const newRows = [...this.state.rows]; // Create a shallow copy of the rows array

      // Update the specified field (price, discount, or total) only for the row at the given index
      newRows[index][field] = value;

      // Recalculate total when price or discount is updated
      if (field === "discount" || field === "price") {
        console.log("--------");
        const price = parseFloat(newRows[index].price) || 0;
        const discount = parseFloat(newRows[index].discount) || 0;

        // Calculate total = price - (price * discount / 100)
        const total = price - (price * discount) / 100;
        newRows[index]["total"] = total.toFixed(2); // Round to 2 decimal places
        console.log(total);
      }

      // Ensure that only one empty row is added when the last row is being modified
      const emptyRow = {
        name: "",
        price: "",
        discount: "",
        total: "",
      };

      // Add an empty row only if the last row's 'name' field is not empty
      const isNameEmpty = newRows[newRows.length - 1].name === "";

      if (!isNameEmpty && index === newRows.length - 1) {
        newRows.push(emptyRow);
      }

      // Update the state with the modified rows
      this.setState({
        rows: newRows,
      });

      // Call parent's onRowsChange to notify about the updates
      this.props.onRowsChange(newRows);
    };

    handleDialogOpen = () => {
      this.setState({
        isfrequencySuggestionOpen: -1,
        isseveritySuggestionOpen: -1,
        isSuggestionOpen: -1,
      });

      this.setState({ isSaveTemplateClicked: true });
      this.setState({ dialogOpen: true });
    };

    handleDialogClose = () => {
      this.setState({ isSaveTemplateClicked: false });
      this.setState({ dialogOpen: false });
    };

    handleSaveTemplate = () => {
      const getToken = () => {
        const cookieString = document.cookie;
        const cookies = cookieString.split("; ").reduce((acc, cookie) => {
          const [name, value] = cookie.split("=");
          acc[name] = value;
          return acc;
        }, {});
        return cookies.authToken;
      };

      const { templateName, rows } = this.state;
      const shouldAddPreviousData = rows.length > 0 && rows[0].name !== "";

      if (shouldAddPreviousData) {
        const savedData = { name: templateName, json_data: rows.slice(0, -1) }; // Change `rows` to `json_data`

        fetch(hostInfo + "/addPaymentTemplate", {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${getToken()}`, // If required for authorization
          },
          body: JSON.stringify(savedData), // Ensure correct field name in payload
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("API Response:", data);
            if (data.message) {
              alert("Template saved successfully");
            } else {
              alert("Error saving template");
            }

            this.setState((prevState) => ({
              templates: [...(prevState.templates || []), savedData],
              // rows: [
              //   {
              //     complaint: "",
              //     frequency: "",
              //     severity: "",
              //     duration: "",
              //     date: "",
              //     inputNumber: "",
              //   },
              // ],
              templateName: "",
            }));
          })
          .catch((error) => {
            console.error("Error:", error);
            alert("Failed to save template");
          });

        this.handleDialogClose();
      } else {
        alert("Please Enter Value...");
        this.handleDialogClose();
        this.setState({ templateName: "" });
      }
    };

    toggleTemplateList = () => {
      this.setState((prevState) => ({
        isListVisible: !prevState.isListVisible, // Toggle the visibility state
      }));
      this.setState({ isTemplateClicked: !this.state.isTemplateClicked });
      this.setState({
        isfrequencySuggestionOpen: -1,
        isseveritySuggestionOpen: -1,
        isSuggestionOpen: -1,
      });
    };
    handleTemplateSelect = (template) => {
      this.setState((prevState) => ({
        rows: [
          ...this.state.rows.slice(0, -1),
          ...template.json_data,
          {
            name: "",
            price: "",
            discount: "",
            total: "",
          },
        ],
      }));
      this.props.onRowsChange(this.state.rows);
      this.setState((prevState) => ({
        isListVisible: !prevState.isListVisible, // Toggle the visibility state
      }));
      this.setState({ isTemplateClicked: false });
      this.setState({ openSuggestionIndex: false });
    };

    handleSearchChange = (event) => {
      this.setState({ search: event.target.value }); // Update search term
    };

    componentDidMount() {
      document.addEventListener("mousedown", this.handleClickOutside);
      document.addEventListener("keydown", this.handleKeyNavigation);
      // document.addEventListener("mousedown", this.handleClickOutside);
      const getToken = () => {
        const cookieString = document.cookie;
        const cookies = cookieString.split("; ").reduce((acc, cookie) => {
          const [name, value] = cookie.split("=");
          acc[name] = value;
          return acc;
        }, {});
        return cookies.authToken;
      };

      // this.fetchTemplates = () => {
      fetch(hostInfo + "/getpaymenttemplates", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((response) => {
          // Return the JSON response
          return response.json(); // Added return statement here
        })
        .then((data) => {
          this.setState({ templates: data }); // Update state with fetched data
          console.log("Fetched Templates:", data); // Log fetched data
        })
        .catch((error) => {
          console.error("Error fetching templates:", error); // Log any errors
        });

      // };
    }

    componentWillUnmount() {
      document.removeEventListener("mousedown", this.handleClickOutside);
      document.removeEventListener("keydown", this.handleKeyNavigation);
    }

    handleClickOutside = (event) => {
      if (
        this.containerRef.current &&
        !this.containerRef.current.contains(event.target)
      ) {
        this.setState({ isListVisible: false, isTemplateClicked: false });
      }
    };
    handleKeyNavigation = (event) => {
      const { templates, highlightedIndex, isListVisible } = this.state;

      // Only trigger if the suggestion list is visible
      if (!isListVisible) return;

      // Prevent default scrolling behavior when navigating suggestions
      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        event.preventDefault();
      }

      // Navigate down (ArrowDown)
      if (event.key === "ArrowDown") {
        this.setState((prevState) => ({
          highlightedIndex: Math.min(
            prevState.highlightedIndex + 1,
            templates.length - 1
          ),
        }));
      }

      // Navigate up (ArrowUp)
      if (event.key === "ArrowUp") {
        this.setState((prevState) => ({
          highlightedIndex: Math.max(prevState.highlightedIndex - 1, 0),
        }));
      }

      // Select the highlighted suggestion (Enter key)
      if (event.key === "Enter" && highlightedIndex >= 0) {
        this.handleTemplateSelect(
          this.props.idx,
          "repName",
          templates[highlightedIndex]
        );
      }
    };

    handleClose = () => {
      this.setState({ open: false });
    };

    handleDiaClickOpen = () => {
      this.setState({ openDia: true });
    };

    handleDiaClickClose = () => {
      this.setState({ openDia: false });
    };

    handleOnChange = (e, name) => {
      const value = e.target.value;
      // console.log("Value change --- ");
      // console.log(e.target);
      // console.log(name);
      // console.log(value);
      this.setState({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          [name]: value,
        },
      });
    };

    handleMultiFld = (name, value) => {
      // console.log("------ Inside Multifiled handler ------");
      // console.log(name);
      // console.log(value);
      this.setState({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          [name]: value,
        },
      });
    };

    handleInsurance = (name, value) => {
      // console.log(name);
      // console.log(value.target.checked);

      this.setState({
        ...this.state,
        formdetail: {
          ...this.state.formdetail,
          [name]: value.target.checked,
        },
      });
    };

    // Mitesh: TODO make index incremental

    render() {
      const {
        rows,
        dialogOpen,
        templateName,
        templates,
        search,
        isTemplateClicked,

        isSaveTemplateClicked,
        isListVisible,
      } = this.state;
      const maxVisibleSuggestions = 4; // Maximum number of visible suggestions
      const suggestionHeight = templates?.length > 0 ? 42 : 60; // Height for each suggestion item (in px)
      const searchBarHeight = 30; // Height of the search bar

      // Calculate the height of the div based on suggestions and search bar
      const divHeight =
        searchBarHeight +
        Math.min(
          templates && templates.length > 0 ? templates.length : 1,
          maxVisibleSuggestions
        ) *
          suggestionHeight;
      const { classes } = this.props;
      console.log(this.props.payment);
      return (
        <div style={{ padding: "0px 20px 20px 20px" }}>
          {/* <Box> */}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              marginTop: "-2px",
            }}
          >
            {/* <Typography
                    variant="h6"
                    gutterBottom
                    style={{ fontWeight: "bold" }}
                  >
                    Complaints
                  </Typography> */}
            {/* <Typography
              gutterBottom
              // variant="h6"
              // color="black"
              color="textSecondary"
              // style={{ fontWeight: "bold" }}
            >
              Payment
            </Typography> */}

            <Typography
              color="textSecondary"
              gutterBottom
              // style={{ marginLeft: "10px" }}
              // onClick={() => this.handleDeleteComplaint(item.id)}
              size="small"
            >
              <SaveIcon
                // fontSize="small"
                // style={{
                //   height: "15px",
                //   width: "15px",
                //   color: "rgba(89, 162, 162, 1)",
                // }}
                style={{
                  color: isSaveTemplateClicked
                    ? "blue"
                    : "rgba(89, 162, 162, 1)",
                  height: "12px",
                  width: "12px",
                }}
              />
            </Typography>
            <Typography
              // variant="h6"
              gutterBottom
              // style={{
              //   cursor: "pointer",
              //   color: "rgba(89, 162, 162, 1)",
              //   fontSize: "12px",
              // }}
              style={{
                fontWeight: "bold",
                marginLeft: "2px",
                cursor: "pointer",
                color: isSaveTemplateClicked ? "blue" : "#ff3369",
                fontSize: "10px",
                marginTop: "3px",
              }}
              onClick={this.handleDialogOpen}
            >
              Save Template
            </Typography>
            <Dialog open={dialogOpen} onClose={this.handleDialogClose}>
              <DialogTitle>Save Template</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  label="Template Name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={templateName}
                  onChange={(e) =>
                    this.setState({ templateName: e.target.value })
                  }
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleDialogClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={this.handleSaveTemplate} color="primary">
                  Save
                </Button>
              </DialogActions>
            </Dialog>

            <div
              style={{ position: "relative", display: "inline-block" }}
              ref={this.containerRef}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={this.toggleTemplateList} // Toggle list on click
              >
                <Typography
                  // variant="h6"
                  gutterBottom
                  style={{
                    fontWeight: "bold",
                    marginLeft: "10px",
                    color: isTemplateClicked ? "blue" : "#ff3369",
                    fontSize: "10px",
                    marginTop: "3px",
                  }}
                >
                  Templates
                </Typography>
                <Typography
                  color="textSecondary"
                  // variant="h6"
                  gutterBottom
                  style={{ marginTop: "3px" }}
                >
                  <ArrowDropDownIcon
                    fontSize="small"
                    style={{
                      color: isTemplateClicked
                        ? "blue"
                        : "rgba(89, 162, 162, 1)",
                    }}
                  />
                </Typography>
                {/* <ArrowDropDownIcon fontSize="small" /> */}
              </div>
              {isListVisible && (
                <div
                  style={{
                    position: "absolute",
                    top: "65%", // Align right under the Typography
                    left: "20px", // Match marginLeft of Typography
                    zIndex: 10, // Ensure it's above other elements
                    backgroundColor: "white", // Set background color for better visibility
                    borderRadius: "4px", // Add border radius for aesthetics
                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)", // Add subtle shadow for depth
                    marginTop: "7px",
                    height: `${divHeight}px`, // Initial height based on suggestions and search bar
                    maxHeight: "156px", // Set max height to 156px
                    overflowY: "auto", // Enable scrolling if content exceeds max height
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center", // Center the items vertically
                      flexWrap: "wrap",
                      flexDirection: "column",
                      padding: "0px 10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center", // Center the items vertically
                        flexDirection: "row",
                        marginBottom: "3px", // Add space below the search bar
                        height: `${searchBarHeight}px`, // Set the search bar height
                      }}
                    >
                      <Search
                        style={{
                          width: "20px",
                          height: "20px",
                          marginTop: "5px",
                          marginRight: "5px", // Add space between the icon and the input
                          color: "#666",
                        }}
                      />
                      <input
                        type="search"
                        placeholder="Search"
                        style={{
                          outline: "none",
                          border: "none", // Remove borders for a cleaner look
                          borderBottom: "1px solid #ccc", // Keep bottom border for input
                          padding: "5px", // Add padding for better UX
                          flexGrow: 1, // Allow input to take available width
                        }}
                        onChange={this.handleSearchChange} // Handle search input change
                      />
                    </div>
                    <div style={{ display: "flex", width: "100%" }}>
                      <Autocomplete
                        options={
                          templates && Array.isArray(templates)
                            ? templates.filter((template) =>
                                template.name
                                  .toLowerCase()
                                  .includes(search.toLowerCase())
                              )
                            : []
                        }
                        getOptionLabel={(option) => option.name} // Display name in the dropdown
                        disableClearable
                        open // Force the dropdown to open when the list is visible
                        onChange={(event, newValue) => {
                          if (newValue) {
                            this.handleTemplateSelect(newValue); // Handle the selected value
                          }
                        }}
                        style={{ width: "100%" }} // Make the dropdown take the full width
                        ListboxProps={{
                          style: {
                            border: "none",
                            padding: "0px",
                            maxHeight: `${
                              maxVisibleSuggestions * suggestionHeight
                            }px`, // Limit the height of suggestions
                            overflowY: "auto", // Add vertical scroll when needed
                            overflowX: "hidden", // Prevent horizontal scroll
                          },
                        }}
                        PopperComponent={(props) => (
                          <Popper {...props} placement="bottom" />
                        )}
                        disablePortal
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            InputProps={{
                              ...params.InputProps,
                              style: {
                                height: "0", // Shrink the input field's height to 0
                                minHeight: "0", // Ensure the input field doesn't take any space
                                visibility: "hidden", // Hide the input field but keep it in the DOM
                                border: "none", // Remove borders from the input
                              },
                              endAdornment: null, // Remove the dropdown arrow if needed
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Divider style={{ marginTop: "-1px" }} />
          <Table
            sx={{
              marginTop: "15px",
              border: "1px solid rgba(224, 224, 224, 1)",
              width: "auto", // Table Border
            }}
            size="small" // Reduce padding in the table
          >
            <TableHead>
              <TableRow
                style={{
                  backgroundColor: "rgb(238, 249, 222)",
                }}
              >
                <TableCell
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    padding: "2px",
                    color: "#546e7a",
                    fontSize: "12px",
                    width: "35px",
                    fontWeight: "bold",
                  }}
                >
                  #
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    padding: "2px",
                    width: "300px",
                    color: "#546e7a",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  Name
                </TableCell>

                <TableCell
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    padding: "2px",
                    width: "200px",
                    color: "#546e7a",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  Price
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    padding: "2px",
                    width: "200px",
                    color: "#546e7a",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  Discount (%)
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    padding: "2px",
                    width: "200px",
                    color: "#546e7a",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  Total
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid white",
                    padding: "2px",
                    backgroundColor: "white",
                  }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row, index) => {
                return (
                  <TableRow key={index}>
                    {/* Row Number */}
                    <TableCell
                      sx={{
                        border: "1px solid rgba(224, 224, 224, 1)",
                        padding: "2px",
                      }}
                    >
                      {index + 1}
                    </TableCell>
                    {/* Complaint Autocomplete */}
                    <TableCell
                      sx={{
                        border: "1px solid rgba(224, 224, 224, 1)",
                        padding: "2px",
                      }}
                    >
                      <Autocomplete
                        options={
                          this.props.paymentSug?.map((option) => ({
                            label: option?.split(".")[1]?.trim() || "",
                            value: option?.split(".")[0] || "",
                          })) || []
                        }
                        getOptionLabel={(option) =>
                          option && option.label ? option.label : ""
                        }
                        freeSolo
                        disableClearable
                        value={row.name ? { label: row.name } : null}
                        onChange={(event, newValue) => {
                          // Update only the specific row's 'name' and 'price'
                          this.handlePayment(
                            index,
                            "name",
                            newValue ? newValue.label : ""
                          );
                          this.handlePayment(
                            index,
                            "price",
                            newValue ? newValue.value : ""
                          );
                        }}
                        onInputChange={(event, newInputValue) => {
                          // Update the name field with manual input
                          this.handlePayment(index, "name", newInputValue);

                          // Check if the manual input matches any suggestion
                          const matchingOption = this.props.paymentSug?.find(
                            (option) =>
                              option?.split(".")[1]?.trim() === newInputValue
                          );

                          // Update the price only if a match is found
                          if (matchingOption) {
                            this.handlePayment(
                              index,
                              "price",
                              matchingOption.split(".")[0]
                            );
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                            }}
                          />
                        )}
                      />
                    </TableCell>
                    {/* Price Field */}
                    <TableCell
                      sx={{
                        border: "1px solid rgba(224, 224, 224, 1)",
                        padding: "2px",
                      }}
                    >
                      <TextField
                        value={row.price}
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        onChange={(event) => {
                          const value = event.target.value;
                          // Allow only numeric input (including decimal)
                          if (
                            !isNaN(value) &&
                            /^[0-9]*\.?[0-9]*$/.test(value)
                          ) {
                            this.handlePayment(index, "price", value);
                          }
                        }}
                      />
                    </TableCell>
                    {/* Discount Field */}
                    <TableCell
                      tabIndex={-1}
                      sx={{
                        border: "1px solid rgba(224, 224, 224, 1)",
                        padding: "2px",
                      }}
                    >
                      <TextField
                        tabIndex={-1}
                        value={row.discount}
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        onChange={(event) => {
                          const value = event.target.value;
                          // Allow only numeric input (including decimal)
                          if (
                            !isNaN(value) &&
                            /^[0-9]*\.?[0-9]*$/.test(value)
                          ) {
                            this.handlePayment(index, "discount", value);
                          }
                        }}
                      />
                    </TableCell>
                    {/* Total Field */}
                    <TableCell
                      tabIndex={-1}
                      sx={{
                        border: "1px solid rgba(224, 224, 224, 1)",
                        padding: "2px",
                        backgroundColor: "#f8f9fa",
                      }}
                    >
                      <TextField
                        tabIndex={-1}
                        value={row.total}
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: true, // Make total field read-only, as it's auto-calculated
                        }}
                      />
                    </TableCell>
                    {/* Delete Button */}
                    <TableCell
                      tabIndex={-1}
                      sx={{
                        border: "1px solid white",
                        padding: "2px",
                      }}
                    >
                      <IconButton
                        onClick={() => this.handleDeleteComplaint(index)}
                        size="small"
                        tabIndex={-1}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          {/* </Box> */}
        </div>
      );
    }
  }
);
