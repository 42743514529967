import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import EditVisit from "./EditVisit";
import { hostInfo, visitFormfields } from "../../../store";
import PrintIcon from "@material-ui/icons/Print";
// import { PdfGen } from "../..";

import {
  Box,
  Grid,
  Button,
  Table,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TableContainer,
  TableRow,
  AccordionDetails,
  Avatar,
  Accordion,
  AccordionSummary,
  Tooltip,
} from "@material-ui/core";
import moment from "moment";
import BillPrint from "../../Opd/components/BillPrint";
import { useReactToPrint } from "react-to-print";
// import PrintHtml from "./PrintHtml/PrintHtml";

// const mediclaim = [{ 0: "No" }, { 1: "Yes" }];

const useStyles = makeStyles((theme) => ({
  root: {
    // margin: theme.spacing(1),
    fontSize: 12,
  },
  expandGrid: {
    minWidth: "100%",
  },
  medRowOut: {
    color: "#8AA65C",
    fontWeight: "bold",
    fontSize: "12px",
  },
  medRowIn: {
    color: "#282E29",
    fontSize: "12px",
  },
  prescription: {
    // width: "50%",
    margin: theme.spacing(0.5),
  },
  reports: {
    Width: "20%",
    margin: theme.spacing(1),
  },
  saveBtn: {
    background: "#94B49F",
    borderRadius: 3,
    border: 0,
    color: "white",
    // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    "&:hover": {
      background: "#C6A3D4",
    },
    padding: theme.spacing(0.5),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }

const VisitDetailInfo = (props) => {
  const { visit, handleClickDelete } = props;
  // console.log(visit);
  const [openDelDia, setOpenDelDia] = useState(false);
  const [openEditDia, setOpenEditDia] = useState(false);
  // const [openPrint, setOpenPrint] = useState(false);

  // const componentRef = useRef();
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });

  const classes = useStyles();
  const componentBillRef = useRef();
  const handleClickDelOpen = () => {
    setOpenDelDia(true);
  };

  const handleClickEditOpen = () => {
    setOpenEditDia(true);
  };

  // const handleClickPrint = () => {
  //   setOpenEditDia(true);
  // };

  const handleClose = () => {
    setOpenDelDia(false);
    setOpenEditDia(false);
  };

  // const handleOpenPrint = (val) => {
  //   setOpenPrint(val);
  // };

  // function handleEditDiaClose() {
  //   setOpenEditDia(false);
  // }

  const handleDelClose = () => {
    handleClickDelete(visit.id);
    setOpenDelDia(false);
  };

  const handleSubmit = () => {
    // console.log("Added for Submit");
    setOpenEditDia(false);
    window.location.reload();
  };

  // console.log("Inside visit detail info--------");
  // console.log(visit);
  let medList =
    // visit.medicine_info ?
    JSON.parse(visit.medicine_info);
  // : [];
  // console.log(medList);
  // console.log(medList);
  let repList =
    // visit.report_info ?
    JSON.parse(visit.report_info);
  // : [];
  // Set how many items per column
  let diagnosis =
    // visit.report_info ?
    JSON.parse(visit.dr_notes);

  const triggerBillPrint = useReactToPrint({
    content: () => componentBillRef.current,
  });
  const handleBillPrint = () => {
    setTimeout(() => {
      triggerBillPrint(); // Trigger consult print
    }, 100);
  };
  return (
    <div className={classes.expandGrid} style={{ marginTop: "-10px" }}>
      <Grid container direction="row" spacing={1} style={{ marginLeft: "2px" }}>
        <Grid item>
          <Typography color="textPrimary" style={{ fontSize: "11px" }}>
            Temp :{" "}
            <span style={{ fontSize: "11px", color: "#546e7a" }}>
              {visit.temperature ? visit.temperature : "-"} {"|"}
            </span>
          </Typography>
        </Grid>

        <Grid item>
          <Typography color="textPrimary" style={{ fontSize: "11px" }}>
            Pulse :{" "}
            <span style={{ fontSize: "11px", color: "#546e7a" }}>
              {visit.pulse ? visit.pulse : "-"} {"|"}
            </span>
          </Typography>
        </Grid>
        <Grid item>
          <Typography color="textPrimary" style={{ fontSize: "11px" }}>
            BP :{" "}
            <span style={{ fontSize: "11px", color: "#546e7a" }}>
              {visit.bp ? visit.bp : "-"} {"|"}
            </span>
          </Typography>
        </Grid>
        <Grid item>
          <Typography color="textPrimary" style={{ fontSize: "11px" }}>
            SpO2 :{" "}
            <span style={{ fontSize: "11px", color: "#546e7a" }}>
              {visit.spo ? visit.spo : "-"} {"|"}
            </span>
          </Typography>
        </Grid>
        <Grid item>
          <Typography color="textPrimary" style={{ fontSize: "11px" }}>
            RR :{" "}
            <span style={{ fontSize: "11px", color: "#546e7a" }}>
              {visit.rr ? visit.rr : "-"} {"|"}
            </span>
          </Typography>
        </Grid>
        <Grid item>
          <Typography color="textPrimary" style={{ fontSize: "11px" }}>
            Height :{" "}
            <span style={{ fontSize: "11px", color: "#546e7a" }}>
              {visit.height ? visit.height : "-"}
            </span>
          </Typography>
        </Grid>
      </Grid>
      {/* <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs>
          <div className={classes.prescription}>
            <Typography color="textPrimary">
              {visitFormfields.complain}
            </Typography>
            <Typography color="textSecondary" style={{ fontSize: "12px" }}>
              {visit.patient_notes.length
                ? JSON.parse(visit.patient_notes).join(", ")
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs>
          <div className={classes.prescription}>
            <Typography color="textPrimary">
              {visitFormfields.diagnose}
            </Typography>
            <Typography color="textSecondary" style={{ fontSize: "12px" }}>
              {visit.dr_notes.length
                ? JSON.parse(visit.dr_notes).join(", ")
                : "-"}
            </Typography>
          </div>
        </Grid>
      </Grid> */}
      {/* <Grid container direction="row">
        <Grid item xs>
          <div className={classes.prescription}>
            <Typography color="textPrimary">{visitFormfields.notes}</Typography>
            <Typography color="textSecondary" style={{ fontSize: "12px" }}>
              {visit.notes ? visit.notes : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs>
          <div className={classes.prescription}>
            <Typography color="textPrimary">
              {visitFormfields.diaNotes}
            </Typography>
            <Typography color="textSecondary" style={{ fontSize: "12px" }}>
              {visit.dia_notes ? visit.dia_notes : "-"}
            </Typography>
          </div>
        </Grid>
      </Grid> */}
      <Grid container direction="row">
        <Grid item xs style={{ marginLeft: "5px", marginBottom: "2px" }}>
          {/* <Box borderLeft={5} className={classes.reports}>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="Prescription"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Reports</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="left"> */}
          <Typography
            color="textPrimary"
            style={{ fontSize: "11px", display: "flex", alignItems: "center" }}
          >
            <Grid container direction="row">
              <Grid item xs={2}>
                <span
                  style={{
                    display: "inline-block",
                    // width: "40px",
                  }}
                >
                  Notes :
                </span>
              </Grid>

              <Grid item xs={10}>
                {visit.notes?.length > 40 ? (
                  <Tooltip
                    title={visit.notes} // Show the full notes in the tooltip
                    placement="top"
                    arrow
                  >
                    <span
                      style={{
                        fontSize: "11px",
                        color: "#546e7a",
                        cursor: "pointer",
                        maxWidth: "300px", // Optional max-width for truncation
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {visit.notes.slice(0, 40)}... {/* Truncated text */}
                    </span>
                  </Tooltip>
                ) : (
                  <span style={{ fontSize: "11px", color: "#546e7a" }}>
                    {visit.notes || "-"}
                  </span>
                )}
              </Grid>
            </Grid>
          </Typography>

          {/* </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box> */}
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        style={{ marginLeft: "5px", marginBottom: "2px" }}
      >
        {/* <Grid item xs> */}
        {/* <Box borderLeft={5} className={classes.prescription}>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="Prescription"
              > */}
        {/* <TableHead>
                  <TableRow>
                    <TableCell align="left">Complains</TableCell>
                  </TableRow>
                </TableHead> */}
        {/* <TableBody>
                  <TableRow>
                    <TableCell align="left"> */}

        <Typography color="textPrimary" style={{ fontSize: "11px" }}>
          <Grid container direction="row">
            <Grid item xs={2}>
              <span
                style={{
                  display: "inline-block",
                  // width: "40px",
                }}
              >
                c/o :{" "}
              </span>
            </Grid>
            <Grid item xs={10}>
              {visit.complaints?.length > 0
                ? visit.complaints?.map((complaintObj, idx) => {
                    const complaintName = Object.keys(complaintObj)[0]; // Get the complaint name (e.g., "fever")
                    const [frequency, severity, duration, date] =
                      complaintObj[complaintName]; // Destructure the values

                    return (
                      <span
                        key={idx}
                        style={{ fontSize: "11px", color: "#546e7a" }}
                      >
                        {complaintName} ({duration})
                        {idx < visit.complaints.length - 1 && (
                          <span
                            style={{
                              color: "rgb(243, 55, 55)",
                              fontWeight: "bold",
                            }}
                          >
                            {" "}
                            |{" "}
                          </span>
                        )}
                      </span>
                    );
                  })
                : "-"}
            </Grid>{" "}
          </Grid>
        </Typography>

        {/* //           </TableCell>
          //         </TableRow>
          //       </TableBody> */}
        {/* //     </Table>
          //   </TableContainer>
          // </Box> */}
        {/* </Grid> */}
      </Grid>
      <Grid
        container
        direction="row"
        style={{ marginLeft: "5px", marginBottom: "2px" }}
      >
        <Grid item xs>
          {/* <Box borderLeft={5} className={classes.prescription}>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="Prescription"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Diagnosis</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="left"> */}
          <Typography color="textPrimary" style={{ fontSize: "11px" }}>
            <Grid container direction="row">
              <Grid item xs={2}>
                <span
                  style={{
                    display: "inline-block",
                    // width: "40px",
                  }}
                >
                  D/D :{" "}
                </span>
              </Grid>

              <Grid item xs={10}>
                {diagnosis?.length > 0
                  ? diagnosis?.map((rep, idx) => (
                      <span
                        key={idx}
                        style={{ fontSize: "11px", color: "#546e7a" }}
                      >
                        {rep}
                        {idx < diagnosis.length - 1 && (
                          <span
                            style={{
                              color: "rgb(243, 55, 55)",
                              fontWeight: "bold",
                            }}
                          >
                            {" "}
                            |{" "}
                          </span>
                        )}
                      </span>
                    ))
                  : "-"}
              </Grid>
            </Grid>
          </Typography>
          {/* </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box> */}
        </Grid>
      </Grid>

      <Grid container direction="row">
        <Grid item xs style={{ marginLeft: "5px", marginBottom: "2px" }}>
          {/* <Box borderLeft={5} className={classes.reports}>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="Prescription"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Reports</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="left"> */}
          <Typography color="textPrimary" style={{ fontSize: "11px" }}>
            <Grid container direction="row">
              <Grid item xs={2}>
                <span
                  style={{
                    display: "inline-block",
                    // width: "40px",
                  }}
                >
                  Ix :{" "}
                </span>
              </Grid>

              <Grid item xs={10}>
                {repList?.length > 0
                  ? repList?.map((rep, idx) => (
                      <span
                        key={idx}
                        style={{ fontSize: "11px", color: "#546e7a" }}
                      >
                        {rep}
                        {idx < repList.length - 1 && (
                          <span
                            style={{
                              color: "rgb(243, 55, 55)",
                              fontWeight: "bold",
                            }}
                          >
                            {" "}
                            |{" "}
                          </span>
                        )}
                      </span>
                    ))
                  : "-"}
              </Grid>
            </Grid>
          </Typography>
          {/* </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box> */}
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs style={{ marginLeft: "5px", marginBottom: "2px" }}>
          {/* <Box borderLeft={5} className={classes.reports}>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="Prescription"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Reports</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="left"> */}
          <Typography color="textPrimary" style={{ fontSize: "11px" }}>
            <Grid container direction="row">
              <Grid item xs={2}>
                <span
                  style={{
                    display: "inline-block",
                    // width: "40px",
                  }}
                >
                  Advice :{" "}
                </span>
              </Grid>

              <Grid item xs={10}>
                {visit.dia_notes?.length > 40 ? (
                  <Tooltip
                    title={visit.dia_notes} // Show full text in tooltip
                    placement="top"
                    arrow
                  >
                    <span
                      style={{
                        fontSize: "11px",
                        color: "#546e7a",
                        cursor: "pointer",
                      }}
                    >
                      {visit.dia_notes.slice(0, 40)}... {/* Truncate text */}
                    </span>
                  </Tooltip>
                ) : (
                  <span style={{ fontSize: "11px", color: "#546e7a" }}>
                    {visit.dia_notes || "-"}
                  </span>
                )}
              </Grid>
            </Grid>
          </Typography>
          {/* </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box> */}
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs>
          <Box borderLeft={5} className={classes.prescription}>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="Prescription">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      style={{ fontSize: "11px", padding: "2px" }}
                    >
                      Prescription
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ fontSize: "11px", padding: "2px" }}
                    >
                      Dose-At
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ fontSize: "11px", padding: "2px" }}
                    >
                      when
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ fontSize: "11px", padding: "2px" }}
                    >
                      #
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {medList.map((med, idx) => (
                    <TableRow key={idx}>
                      <TableCell
                        style={{ fontSize: "11px", padding: "2px" }}
                        align="left"
                        className={
                          med.medOut ? classes.medRowOut : classes.medRowIn
                        }
                      >
                        {med.medName ? med.medName : "-"}
                      </TableCell>
                      <TableCell
                        style={{ fontSize: "11px", padding: "2px" }}
                        align="center"
                        className={
                          med.medOut ? classes.medRowOut : classes.medRowIn
                        }
                      >
                        {"medQnt" in med ? med.medQnt : ""}{" "}
                        {med.medSchd ? med.medSchd : "-"}
                      </TableCell>
                      <TableCell
                        style={{ fontSize: "11px", padding: "2px" }}
                        align="center"
                        className={
                          med.medOut ? classes.medRowOut : classes.medRowIn
                        }
                      >
                        {med.medTime ? med.medTime : "-"}
                      </TableCell>
                      <TableCell
                        style={{ fontSize: "11px", padding: "2px" }}
                        align="right"
                        className={
                          med.medOut ? classes.medRowOut : classes.medRowIn
                        }
                      >
                        {med.medCount ? med.medCount : "-"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
      <div style={{ display: "flex", gap: "7px", alignItems: "center" }}>
        <Button
          style={{ fontSize: "12px", padding: "2px 3px" }}
          size="small"
          className={classes.saveBtn}
          startIcon={<DeleteIcon />}
          onClick={handleClickDelOpen}
        >
          Delete
        </Button>
        <Button
          style={{ fontSize: "12px", padding: "2px 3px" }}
          size="small"
          className={classes.saveBtn}
          startIcon={<EditIcon />}
          onClick={handleClickEditOpen}
        >
          Edit
        </Button>
        <Button
          style={{ fontSize: "12px", padding: "2px 3px" }}
          variant="contained"
          size="small"
          className={classes.saveBtn}
          startIcon={<PrintIcon />}
          onClick={() => handleBillPrint()}
        >
          Bill Print
        </Button>
        <div style={{ display: "none" }}>
          <BillPrint
            ref={componentBillRef}
            visit={visit}
            // user={patientList.find(
            //   (i) => i.visit_id === visitlist[0].id
            // )}
          />
        </div>

        {/* Delete Dialog */}
        <Dialog
          open={openDelDia}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={"sm"}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              CANCEL
            </Button>
            <Button onClick={handleDelClose} color="primary" autoFocus>
              DELETE IT
            </Button>
          </DialogActions>
        </Dialog>

        {/* Edit Dialog */}
        <Dialog
          open={openEditDia}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={"lg"}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Edit Details</DialogTitle>
          <DialogContent>
            <EditVisit
              visit={visit}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
            />
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

VisitDetailInfo.propTypes = {
  className: PropTypes.string,
  // caselist: PropTypes.array.isRequired,
};

export default VisitDetailInfo;
