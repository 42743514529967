import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";

import IconButton from "@material-ui/core/IconButton";
import FingerprintIcon from "@material-ui/icons/Fingerprint";
import MainSearchPatientTxt from "./MainSearchPatientTxt";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    // marginBottom: theme.spacing(2),
  },
  // button: {
  //   color: theme.palette.my2.dark,
  //   padding: "0px 0px",
  //   justifyContent: "flex-start",
  //   textTransform: "none",
  //   letterSpacing: 0,
  //   width: "auto",
  //   fontWeight: theme.typography.fontWeightMedium,
  // },
  content: {
    marginTop: theme.spacing(2),
  },
  uploadBtn: {
    background: "#FF6085",
    borderRadius: 3,
    border: 0,
    color: "white",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    "&:hover": {
      background: "#9BE27A",
    },
    // marginBottom: theme.spacing(2),
    // marginTop: theme.spacing(2),
  },
  iconCls: {
    marginRight: theme.spacing(1),
  },
}));

const MainSearchTxt = ({ openNav }) => {
  const classes = useStyles();
  // const [open, setOpen] = useState(false);
  // const [groupId, setGroupId] = useState(0);
  // const [groupName, setGroupName] = useState("");
  // const [groupList, setGroupList] = useState([]);
  const [searchOpen, setSearchOpen] = useState(false);

  // const [dimensions, setDimensions] = React.useState({
  //   height: window.innerHeight,
  //   width: window.innerWidth,
  // });

  const handleClickSearchOpen = () => {
    setSearchOpen(true);
  };

  const handleSearchClose = (value) => {
    setSearchOpen(false);
  };

  // useEffect(() => {
  //   async function getDrGroups() {
  //     let url = "http://" + hostInfo + "/getdrgroups";
  //     await fetch(url)
  //       .then((res) => res.json())
  //       .then((res) => {
  //         if (res != null) {
  //           if (res != null) {
  //             setGroupList(res);
  //             setGroupId(res[0].id);
  //             setGroupName(res[0].name);
  //           }
  //         } else {
  //           setGroupList([]);
  //         }
  //       });
  //   }

  //   function handleResize() {
  //     setDimensions({
  //       height: window.innerHeight,
  //       width: window.innerWidth,
  //     });
  //   }

  //   if (groupId === 0) {
  //     getDrGroups();
  //   }
  //   console.log("inside the hook---");
  //   window.addEventListener("resize", handleResize);
  // }, [groupId]);

  // const handleOnDelete = (id) => {
  //   if (id === 0) {
  //     return;
  //   }

  //   let data = {
  //     id: id,
  //   };

  //   alert("Sure want to delete ???");

  //   let url = "http://" + hostInfo + "/deletegroup";
  //   fetch(url, {
  //     method: "POST",
  //     mode: "no-cors",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(data),
  //   });

  //   window.location.reload();
  // };

  // const handleOnAdd = (name, desc) => {
  //   if (name === null) {
  //     return;
  //   }

  //   let data = {
  //     name: name,
  //     description: desc,
  //   };

  //   let url = "http://" + hostInfo + "/addgroup";
  //   fetch(url, {
  //     method: "POST",
  //     mode: "no-cors",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(data),
  //   });
  //   window.location.reload();
  // };

  // const handleOnAddDr = (groupId, name, mobile, desc) => {
  //   if (groupId === 0 || name === null) {
  //     return;
  //   }

  //   let data = {
  //     dr_group_id: groupId,
  //     name: name,
  //     mobile: mobile,
  //     description: desc,
  //   };

  //   let url = "http://" + hostInfo + "/adddrdetails";
  //   fetch(url, {
  //     method: "POST",
  //     mode: "no-cors",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(data),
  //   });
  //   window.location.reload();
  // };

  // const handleOnSave = (id, name, desc) => {
  //   if (id === 0 || name === null) {
  //     return;
  //   }

  //   let data = {
  //     id: id,
  //     name: name,
  //     description: desc,
  //   };

  //   let url = "http://" + hostInfo + "/updategroup";
  //   fetch(url, {
  //     method: "POST",
  //     mode: "no-cors",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(data),
  //   });
  // };

  // const handleOnSaveDr = (id, name, mobile, desc) => {
  //   if (id === 0 || name === null) {
  //     return;
  //   }

  //   let data = {
  //     id: id,
  //     name: name,
  //     module: mobile,
  //     description: desc,
  //   };

  //   let url = "http://" + hostInfo + "/updatedrdetails";
  //   fetch(url, {
  //     method: "POST",
  //     mode: "no-cors",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(data),
  //   });
  // };

  return (
    <>
      <div className={classes.root}>
        <IconButton
          // color="primary"
          aria-label="upload picture"
          component="span"
          onClick={handleClickSearchOpen}
        >
          <FingerprintIcon fontSize="medium" />
          {openNav && (
            <div
              style={{
                paddingLeft: "3px",
                // fontWeight: "bold",
                fontSize: "18px",
              }}
            >
              Search
            </div>
          )}
        </IconButton>
        {searchOpen ? (
          <div>
            <MainSearchPatientTxt
              open={searchOpen}
              closeHdl={handleSearchClose}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default MainSearchTxt;
