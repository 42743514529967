import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import NewPatient from "./NewPatient";

import {
  Card,
  Box,
  Grid,
  Button,
  CardContent,
  Avatar,
  Typography,
  // TablePagination,
} from "@material-ui/core";

const colorlist = [
  "#F0FAE1",
  "#FA8787",
  "#75c57f",
  "#f2b902",
  "#FFC457",
  "#FF9D6E",
  "#f55681",
  "#FFEBEB",
  "#09b1c7",
  "#E61E50",
  "#FFE5B4",
  "#8cbeef",
  "#876C5C",
  "#FA7268",
];

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },

  inner: {
    minWidth: "100%",
  },
  media: {
    display: "flex",
    direction: "row-reverse",
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  actions: {
    justifyContent: "flex-end",
  },
  avtar: {
    // display: "flex",
    // alignItems: "center",
    // width: theme.spacing(7),
    // height: theme.spacing(7),
    marginBottom: theme.spacing(2),
  },
  avtarName: {
    marginLeft: theme.spacing(2),
  },
  searchbar: {
    display: "flex",
    // borderRadius: 1,
    // flexDirection: "row-reverse",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
    // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  },
  cardBack: {
    // width: "100%",
    // height: "100%",
    // backgroundImage: `url(${backImg})`,
    // display: "flex-end",
    // alignItems: "center",
    // background:
    //   "linear-gradient(to right top, #bff7f8, #a0f5e7, #8df1cc, #8beaa7, #99e17c)",
    // background:
    //   "linear-gradient(to right top, #bff7f8, #c2f8ef, #c9f9e6, #d3f9dd, #dff8d5)",
    // background:
    //   "linear-gradient(to right top, #edf7f7, #e4f8f4, #def9ee, #dcf8e3, #e0f7d7)",
    backgroundColor: `#FFFFFA`,

    // justify: "center",
    backgroundSize: "cover",
  },
  avtarCell: {
    display: "flex",
    alignItems: "center",
  },
  addUsrBtn: {
    background: "#5E92C2",
    borderRadius: 3,
    border: 0,
    color: "white",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    "&:hover": {
      background: "#C6A3D4",
    },
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

const gender = { M: "Male", F: "Female", O: "Other" };

const handleOnClick = (e, patient_id) => {
  const json_id = {
    patient_id: patient_id,
    opd_id: 0,
  };
  const jsonString = JSON.stringify(json_id);

  // Encode the string to Base64
  const base64Encoded = btoa(jsonString);
  window.location = "/patients/patientprofile?id=" + base64Encoded;
};

function MainCard(props) {
  const { patient, clr } = props;

  const classes = useStyles();

  return (
    <div>
      <Box boxShadow={1} border={2} borderRadius={4} color={clr}>
        <Card onClick={(e) => handleOnClick(e, patient.id)}>
          <CardContent className={classes.cardBack} align="center">
            <Avatar
              className={classes.avtar}
              // variant="rounded"
              // justify="flex-end"
              // alt="Remy Sharp"
              // src="/static/images/avatar/1.jpg"
            >
              M
            </Avatar>
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              color="textSecondary"
              align="center"
            >
              {patient.firstname} {patient.lastname}
            </Typography>
            <Grid container direction="column">
              <Grid item>
                <Box
                  fontSize={14}
                  // noWrap={true}
                  fontFamily="Arial"
                  letterSpacing={1}
                  fontWeight={500}
                >
                  {patient.age} Yrs - {gender[patient.gender]}
                </Box>

                <Box
                  fontSize={12}
                  // noWrap={true}
                  fontFamily="Arial"
                  letterSpacing={1}
                  fontWeight="fontWeightRegular"
                >
                  {patient.mobile}
                </Box>

                <Box fontSize={12}>
                  <Typography gutterBottom color="secondary">
                    {patient.mail_id}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
}

const PatientList = (props) => {
  const { patientlist } = props;
  const [search, setSearch] = useState("");

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const rand = Math.floor(Math.random() * 1000);

  // console.log("patinet list value -----------------------");
  // console.log(patientlist);

  const filterPatient = patientlist.filter((patient) => {
    return (
      patient.firstname.toLowerCase().includes(search.toLowerCase()) ||
      patient.lastname.toLowerCase().includes(search.toLowerCase())
    );
  });
  return (
    // <div>
    <div>
      {/* <div>
       mitesh
      </div> */}
      <Grid
        container
        direction="row"
        //   justify="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Button
            variant="outlined"
            className={classes.addUsrBtn}
            onClick={handleClickOpen}
          >
            Add User
          </Button>
          <div>
            <NewPatient open={open} closeHdl={handleClose} />
          </div>
        </Grid>
        <Grid item>
          <input
            type="text"
            placeholder="Search"
            className={classes.searchbar}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        //   justify="space-between"
        alignItems="center"
        spacing={2}
      >
        {filterPatient.map((patient, idx) => (
          <Grid item key={patient.id} lg={3} sm={6} xl={3} md={4} xs={12}>
            <MainCard
              idx={rand + idx}
              patient={patient}
              // clr={colorlist[idx % totalClr]}
              clr={colorlist[0]}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

PatientList.propTypes = {
  className: PropTypes.string,
  patientlist: PropTypes.array.isRequired,
};

export default PatientList;
