// import React, { Fragment, useState, useEffect } from "react";
// import { makeStyles } from "@material-ui/styles";
// import { Button } from "@material-ui/core";
// import AddNewPatient from "./components/AddNewCertificate";
// import { hostInfo } from "../../store";
// import { Typography, Divider, Box } from "@material-ui/core";
// import Grid from "@material-ui/core/Grid";
// import DateFnsUtils from "@date-io/date-fns";
// import {
//   MuiPickersUtilsProvider,
//   // KeyboardTimePicker,
//   KeyboardDatePicker,
// } from "@material-ui/pickers";
// import { PatientsListTbl } from "./components";

// const useStyles = makeStyles((theme) => ({
//   // root: {

//   //   padding: theme.spacing(1),
//   // },
//   root: {
//     margin: theme.spacing(1),
//     // backgroundColor: "green",
//   },
//   content: {
//     marginTop: theme.spacing(2),
//     background: "linear-gradient(45deg, #81d4fa 10%, #dce775 90%)",
//     borderRadius: 3,
//     border: 0,
//     color: "white",
//   },
//   inner: {
//     minWidth: "100%",
//   },
//   addUsrBtn: {
//     background: "#5E92C2",
//     borderRadius: 2,
//     border: 0,
//     color: "white",
//     boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
//     "&:hover": {
//       background: "#C6A3D4",
//     },
//     marginRight: theme.spacing(2),
//   },
//   nameContainer: {
//     display: "flex",
//     alignItems: "center",
//   },
//   component: {
//     padding: theme.spacing(1),
//     // marginBottom: theme.spacing(1),
//   },
//   avatar: {
//     marginRight: theme.spacing(2),
//   },
//   actions: {
//     justifyContent: "flex-end",
//   },
// }));

// const Certificate = () => {
//   const search = window.location.search;
//   const params = new URLSearchParams(search);
//   // const { handleSubmitAss, setIsAssReport } = props;
//   const [selectedStartDate, setSelectedStartDate] = React.useState(
//     params.get("str_dt")
//   );
//   const [selectedEndDate, setSelectedEndDate] = React.useState(
//     params.get("end_dt")
//   );
//   const classes = useStyles();
//   const [addOpen, setAddOpen] = useState(false);
//   const [searchOpen, setSearchOpen] = useState(false);
//   const [editOpen, setEditOpen] = useState(false);
//   const [patientGrpList, setPatientGrpList] = useState([]);
//   const [patientList, setPatientList] = useState([]);

//   // const [products] = useState(mockData);
//   const handleClickAddOpen = () => {
//     setAddOpen(true);
//   };

//   const handleAddClose = (value) => {
//     setAddOpen(false);
//   };

//   const handleClickSearchOpen = () => {
//     setSearchOpen(true);
//   };

//   const handleSearchClose = (value) => {
//     setSearchOpen(false);
//   };

//   const handleClickEditOpen = () => {
//     setEditOpen(true);
//   };

//   const handleEditClose = (value) => {
//     setEditOpen(false);
//   };

//   const handleDelPatient = (value) => {
//     setPatientList(value);
//   };
//   const getToken = () => {
//     const cookieString = document.cookie;
//     const cookies = cookieString.split("; ").reduce((acc, cookie) => {
//       const [name, value] = cookie.split("=");
//       acc[name] = value;
//       return acc;
//     }, {});
//     return cookies.authToken;
//   };

//   const handleStartDateChange = (date) => {
//     const day = date.getDate().toString().padStart(2, "0");
//     const month = (date.getMonth() + 1).toString().padStart(2, "0");
//     const year = date.getFullYear();

//     const formattedDate = `${month}-${day}-${year}`;

//     setSelectedStartDate(formattedDate);
//   };
//   const handleEndDateChange = (date) => {
//     const month = (date.getMonth() + 1).toString().padStart(2, "0");
//     const day = date.getDate().toString().padStart(2, "0");
//     const year = date.getFullYear();

//     const formattedDate = `${month}-${day}-${year}`;

//     setSelectedEndDate(formattedDate);
//   };

//   const fetchPatient= () =>{
//       let url = hostInfo + "/getdatewisevisits";
//       await fetch(url, {
//         headers: {
//           Authorization: `Bearer ${getToken()}`,
//         },
//       })
//         .then((res) => res.json())
//         .then((res) => {
//           if (res != null) {
//             if (res != null) {
//               // console.log(res);
//               setPatientList(res);
//             }
//           }
//         });
//     }

//   return (
//     <Fragment>
//       {/* <FingerScan /> */}
//       <div className={classes.root}>
//         <Typography
//           className={classes.maintitle}
//           variant="h3"
//           gutterBottom
//           color="textSecondary"
//         >
//           Report
//         </Typography>
//         <Divider />
//         <Box
//           boxShadow={2}
//           borderRadius={5}
//           bgcolor="background.paper"
//           // m={1}
//           p={1}
//         >
//           <Typography
//             className={classes.title}
//             variant="h5"
//             gutterBottom
//             color="textSecondary"
//           >
//             Select Date
//           </Typography>
//           <Divider />

//           <div>
//             <form autoComplete="off">
//               <MuiPickersUtilsProvider utils={DateFnsUtils}>
//                 <Grid
//                   container
//                   spacing={2}
//                   justifyContent="space-around"
//                   style={{ marginBottom: "20px" }}
//                 >
//                   <Grid item xs={12} sm={6}>
//                     <KeyboardDatePicker
//                       fullWidth
//                       margin="normal"
//                       id="date-picker-dialog-start"
//                       label="From"
//                       format="dd-MM-yyyy"
//                       value={selectedStartDate}
//                       onChange={handleStartDateChange}
//                       KeyboardButtonProps={{
//                         "aria-label": "change date",
//                       }}
//                     />
//                   </Grid>
//                   <Grid item xs={12} sm={6}>
//                     <KeyboardDatePicker
//                       fullWidth
//                       margin="normal"
//                       id="date-picker-dialog-end"
//                       label="To"
//                       format="dd-MM-yyyy"
//                       value={selectedEndDate}
//                       onChange={handleEndDateChange}
//                       KeyboardButtonProps={{
//                         "aria-label": "change date",
//                       }}
//                     />
//                   </Grid>
//                 </Grid>
//               </MuiPickersUtilsProvider>

//               <Grid container spacing={2} justifyContent="center">
//                 <Grid item xs={12} sm={2}>
//                   <Button
//                     fullWidth
//                     variant="contained"
//                     color="primary"
//                     size="small"
//                     // onClick={patientInfo}
//                   >
//                     All
//                   </Button>
//                 </Grid>
//                 <Grid item xs={12} sm={2}>
//                   <Button
//                     fullWidth
//                     variant="contained"
//                     color="primary"
//                     size="small"
//                     // onClick={visitInfo}
//                   >
//                     Dr1
//                   </Button>
//                 </Grid>
//                 <Grid item xs={12} sm={2}>
//                   <Button
//                     fullWidth
//                     variant="contained"
//                     color="primary"
//                     size="small"
//                     // onClick={revenuInfo}
//                   >
//                     Dr2
//                   </Button>
//                 </Grid>
//               </Grid>
//             </form>
//           </div>
//           {/* <div>
//             <Button
//               variant="outlined"
//               size="small"
//               color="primary"
//               onClick={handleSubmitAss(formData)}
//             >
//               Submit
//             </Button>
//           </div> */}
//           {/* <Grid container spacing={2}></Grid> */}
//         </Box>
//         {/* {isAssReport ? (
//           <Box
//             boxShadow={2}
//             borderRadius={5}
//             bgcolor="background.paper"
//             mt={3}
//             p={1}
//           >
//             <StatReports isAssReport={isAssReport} />
//           </Box>
//         ) : null} */}
//       </div>

//       {/* <div style={{ padding: "0px 10px 10px 10px" }}>
//         <Button
//           variant="outlined"
//           size="small"
//           className={classes.addUsrBtn}
//           onClick={handleClickAddOpen}
//         >
//           Add Certificate
//         </Button>
//       </div> */}
//       {/* <div className={classes.component}>
//         <Button
//           variant="outlined"
//           className={classes.addUsrBtn}
//           onClick={handleClickSearchOpen}
//         >
//           Search Patient
//         </Button>
//       </div>
//       {searchOpen ? (
//         <div>
//           <SearchPatient
//             open={searchOpen}
//             patientList={patientList}
//             closeHdl={handleSearchClose}
//           />
//         </div>
//       ) : null} */}
//       {/* {addOpen ? (
//         <div>
//           <AddNewPatient
//             open={addOpen}
//             patientGrpList={patientGrpList}
//             closeHdl={handleAddClose}
//           />
//         </div>
//       ) : null} */}
//       {patientList.length > 0 && (
//         <div className={classes.component}>
//           <PatientsListTbl
//             classes={classes}
//             handleClickOpen={handleClickEditOpen}
//             open={editOpen}
//             patientGrpList={patientGrpList}
//             patientList={patientList}
//             closeHdl={handleEditClose}
//             handleDelPatient={handleDelPatient}
//           />
//         </div>
//       )}
//     </Fragment>
//   );
// };

// export default Certificate;

import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Button, Typography, Divider, Box, Grid } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { hostInfo } from "../../store"; // Replace with actual host info
import { PatientsListTbl } from "./components";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(2),
    background: "linear-gradient(45deg, #81d4fa 10%, #dce775 90%)",
    borderRadius: 3,
    color: "white",
  },
  addUsrBtn: {
    background: "#5E92C2",
    color: "white",
    "&:hover": {
      background: "#C6A3D4",
    },
    marginRight: theme.spacing(2),
  },
}));

const Certificate = () => {
  const classes = useStyles();
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [patientList, setPatientList] = useState([]);
  const [user, setUser] = useState([]);

  const handleStartDateChange = (date) => setSelectedStartDate(date);
  const handleEndDateChange = (date) => setSelectedEndDate(date);

  const getToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.authToken;
  };

  // Helper function to format date in MM-DD-YYYY format
  const formatDate = (date) => {
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure two digits
    const day = String(date.getDate()).padStart(2, "0"); // Ensure two digits
    const year = date.getFullYear();
    return `${month}-${day}-${year}`; // MM-DD-YYYY format
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch(`${hostInfo}/usernames`, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setUser(data.users.filter((i) => i.accesses_flag === 3));
      } catch (error) {
        console.error("There was an error fetching the user info!", error);
      }
    };

    fetchUserInfo();
  }, []);

  const fetchPatientData = async (startDate, endDate, username) => {
    const url = `${hostInfo}/getdatewisevisits?start_date=${startDate}&end_date=${endDate}&username=${username}`;
    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setPatientList(data);
      } else {
        alert("Failed to fetch patient data. Please try again.");
      }
    } catch (error) {
      console.error("Error fetching patient data:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const handleFetchButtonClick = (username) => {
    return () => {
      if (!selectedStartDate || !selectedEndDate) {
        alert("Please select both start and end dates.");
        return;
      }
      const formattedStartDate = formatDate(selectedStartDate);
      const formattedEndDate = formatDate(selectedEndDate);

      fetchPatientData(formattedStartDate, formattedEndDate, username);
    };
  };

  const handleFetchAllButtonClick = () => {
    const formattedStartDate = "01-01-1900"; // Replace with the desired earliest date
    const formattedEndDate = formatDate(new Date()); // Current date in MM-DD-YYYY
    fetchPatientData(formattedStartDate, formattedEndDate, "alluser");
  };

  return (
    <Fragment>
      <div className={classes.root}>
        <Typography variant="h3" gutterBottom color="textSecondary">
          Report
        </Typography>
        <Box boxShadow={2} borderRadius={5} bgcolor="background.paper" p={1}>
          <Typography variant="h5" gutterBottom color="textSecondary">
            Select Date
          </Typography>
          <Divider />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={2} justifyContent="space-around">
              <Grid item xs={12} sm={6}>
                <KeyboardDatePicker
                  fullWidth
                  margin="normal"
                  id="date-picker-dialog-start"
                  label="From"
                  format="MM/dd/yyyy" // Ensures date is displayed in MM/DD/YYYY format
                  value={selectedStartDate}
                  onChange={handleStartDateChange}
                  KeyboardButtonProps={{ "aria-label": "change date" }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <KeyboardDatePicker
                  fullWidth
                  margin="normal"
                  id="date-picker-dialog-end"
                  label="To"
                  format="MM/dd/yyyy" // Ensures date is displayed in MM/DD/YYYY format
                  value={selectedEndDate}
                  onChange={handleEndDateChange}
                  KeyboardButtonProps={{ "aria-label": "change date" }}
                />
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            style={{ marginTop: "20px" }}
          >
            <Grid item xs={12} sm={2}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="small"
                onClick={handleFetchAllButtonClick}
              >
                All
              </Button>
            </Grid>
            {user.map((i) => (
              <Grid item xs={12} sm={2} key={i.username}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleFetchButtonClick(i.username)}
                >
                  {i.username}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>
      </div>
      {patientList.length > 0 && (
        <div className={classes.root}>
          <PatientsListTbl patientList={patientList} />
        </div>
      )}
    </Fragment>
  );
};

export default Certificate;
